@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* Fonts */
/* Colors */
/* Shadows */
/*Media query values*/
/*Buttons*/
/* Custom dropdown*/
/*Job action bg colors*/
/*Status icon colors*/
/* Dates */
/* Consumer */
/* Filter */
/* Font Sizes */
/* Margins */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Rubik:300,400,500,700,900&subset=cyrillic,cyrillic-ext,greek,greek-ext,hebrew,latin-ext,vietnamese");
@import url("https://use.fontawesome.com/releases/v5.9.0/css/all.css");
@import "@angular/material/prebuilt-themes/indigo-pink.css";
.mcc-modal {
  background: rgba(0, 0, 0, 0.75);
}
.mcc-modal .modal-dialog {
  width: 1400px;
  max-width: 90%;
  height: calc(100% - 60px);
  margin: 30px auto;
  justify-content: center;
}
.mcc-modal .modal-dialog .height-auto .content-body {
  height: auto;
}
.mcc-modal .modal-content {
  padding: 20px;
  border: none;
  height: 100%;
}
.mcc-modal .modal-content .content-body {
  overflow: auto;
}
.mcc-modal .modal-body {
  padding: 0;
}
.mcc-modal .modal-body-main-content {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow: auto;
  width: 100%;
  height: calc(100vh - 260px);
}
.mcc-modal .close {
  font-weight: normal;
  padding: 0;
  font-size: 50px;
  margin: -10px 30px 0 0;
  position: absolute;
  right: 0;
  z-index: 10;
}
.mcc-modal .modal-header {
  padding-right: 50px;
}
.mcc-modal .content-wrapper .content-body {
  height: calc(100vh - 260px);
}
.mcc-modal .content-wrapper .content-header {
  margin-right: 50px;
}

.modal-small .modal-dialog {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 800px;
  max-width: 100%;
}
.modal-small .modal-content {
  height: auto;
  max-height: 100%;
}
.modal-small .modal-body-main-content {
  height: auto;
  max-height: calc(100vh - 260px);
  min-height: 100px;
}

.modal-medium .modal-dialog {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 1020px;
  max-width: 100%;
}
.modal-medium .modal-content {
  height: auto;
  max-height: 100%;
}
.modal-medium .modal-body-main-content {
  height: auto;
  max-height: calc(100vh - 260px);
  min-height: 100px;
}

.wrapper-padding {
  padding-top: 10px;
}
.wrapper-padding .content-body {
  padding: 0 !important;
}

.content-wrapper {
  position: relative;
  flex: 1 1 auto;
}
.content-wrapper .content-header {
  padding: 20px 1rem 0 1rem;
  border-bottom: 1px solid #dee2e6;
  margin-top: -20px;
  width: calc(100% + 40px);
  margin-left: -20px;
}
.content-wrapper .content-header-title {
  font-family: "Maven Pro", sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #53589B;
  text-transform: uppercase;
  margin-left: 20px;
}
.content-wrapper .content-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  width: 100%;
}
.content-wrapper .content-body .mcc-fi {
  margin-bottom: 15px;
}
.content-wrapper .content-body .newStyle {
  margin-bottom: -10px !important;
}
.content-wrapper .content-body .content-section {
  padding: 0 20px 20px;
  margin-bottom: 20px;
}
.content-wrapper .content-body .content-section h2 {
  margin-left: -20px;
  font-size: 22px;
  padding: 8px 20px;
  margin-bottom: 20px;
  background: #f3f3f3;
  border-radius: 4px;
}
.content-wrapper .content-footer {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
}
.content-wrapper .content-footer > :not(:last-child) {
  margin-right: 1rem;
}
.content-wrapper .content-footer .move-right {
  margin-left: auto;
}
.content-wrapper .section {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
}
.content-wrapper .section_header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}
.content-wrapper .section_header_title {
  font-size: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: #53589B;
}
.content-wrapper .section_content {
  padding: 15px 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  border-top: 1px solid #D6D7E1;
}
.content-wrapper .section_content_item {
  display: flex;
  flex-direction: column;
}
.content-wrapper .section_content_text {
  font-size: 16px;
  color: #333867;
}

.back-option {
  cursor: pointer;
  color: #5eabbe;
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  margin-left: 20px;
}

.cardbox {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  margin-top: 10px;
  padding: 20px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.cardbox_title {
  font-size: 20px;
  font-weight: 700;
  color: #333867;
}

/**/
.info-data .row {
  align-items: start;
  padding: 7px;
  margin: 0;
}
.info-data .title {
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-right: 0;
  margin-top: 3px;
}
.info-data.title-left .title {
  text-align: left;
}
.info-data.title-right .title {
  text-align: right;
}

.dialog-row-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 4fr));
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.dialog-col {
  display: flex;
  min-width: 250px;
  word-break: break-word;
  padding: 0 10px 10px 10px;
}
.dialog-col .dialog-col-label {
  color: #333867;
  font-size: 16px;
  margin-bottom: 5px;
  opacity: 0.6;
}
.dialog-col .dialog-col-value {
  color: #333867;
  font-weight: 700;
  width: fit-content;
}

.dialog-col {
  flex-direction: column;
}

.tabs {
  min-height: 64px;
  display: flex;
  padding: 0 40px;
  width: 100%;
  gap: 30px;
  justify-content: space-around;
  font-family: "Maven Pro", sans-serif;
}
.tabs_option {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tabs_option_title {
  color: #333867;
  font-size: 1.125rem;
  line-height: 22px;
}

.activeTab {
  border-bottom: 2px solid #5eabbe;
  cursor: auto;
}
.activeTab .tabs_option_title {
  color: #5eabbe;
  font-weight: 700;
}

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: middle;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-content-icon {
  position: absolute;
  cursor: pointer;
  right: 20px;
  z-index: 10;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000 !important;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.5rem;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* Fonts */
/* Colors */
/* Shadows */
/*Media query values*/
/*Buttons*/
/* Custom dropdown*/
/*Job action bg colors*/
/*Status icon colors*/
/* Dates */
/* Consumer */
/* Filter */
/* Font Sizes */
/* Margins */
/* Fonts */
/* Colors */
/* Shadows */
/*Media query values*/
/*Buttons*/
/* Custom dropdown*/
/*Job action bg colors*/
/*Status icon colors*/
/* Dates */
/* Consumer */
/* Filter */
/* Font Sizes */
/* Margins */
:root {
  --input-padding-x: 0;
  --input-padding-y: 10px;
}

.mc-create-invoice-modal .mc-create-user-modal-header button.close.mc-consumers-close-btn, .mc-job-create-edit-modal .modal-content .mc-job-create-edit-modal-close-button, .mc-add-consumer-modal button.close.mc-consumers-close-btn, .mc-create-edit-product .modal-dialog button.close.mc-product-close-btn, .mc-create-new-user-modal .mc-create-user-modal-header button.close.mc-new-user-close-btn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 60px;
  font-weight: 100;
  color: #b6b8ba;
}
.mc-create-invoice-modal .mc-create-user-modal-header button.close.mc-consumers-close-btn:focus, .mc-job-create-edit-modal .modal-content .mc-job-create-edit-modal-close-button:focus, .mc-add-consumer-modal button.close.mc-consumers-close-btn:focus, .mc-create-edit-product .modal-dialog button.close.mc-product-close-btn:focus, .mc-create-new-user-modal .mc-create-user-modal-header button.close.mc-new-user-close-btn:focus {
  outline: none;
}

.mc-create-new-user-modal .mc-user-first-name .mc-new-user-field-names::after,
.mc-create-new-user-modal .mc-user-last-name .mc-new-user-field-names::after,
.mc-create-new-user-modal .mc-user-gender .mc-new-user-field-names::after, .mc-create-new-user-modal .mc-user-email > label::after, .mc-incomplete-profile-card .mc-user-first-name .mc-new-user-field-names::after,
.mc-incomplete-profile-card .mc-user-last-name .mc-new-user-field-names::after,
.mc-incomplete-profile-card .mc-user-gender .mc-new-user-field-names::after, .mc-mandatory-star::after {
  content: "*";
  color: #EC230D;
  padding-left: 3px;
}

body {
  font-family: "Maven Pro", sans-serif, sans-serif;
  background: #f2f2f2;
}

router-outlet.main-content ~ * {
  position: absolute;
  height: 100%;
  left: 220px;
  right: 0;
}
router-outlet.main-content ~ * .container-fluid {
  padding-right: 60px;
  padding-left: 60px;
  background-color: #f7f7f7;
  transition: all 250ms linear;
}

.blurred {
  filter: blur(5px);
  transition: all 250ms linear;
}

router-outlet ~ * {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
}

.valign-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 100%;
}
.valign-container.full-height {
  min-height: 100vh;
}

.v-container {
  height: 100vh;
  display: table;
  width: 100%;
}

.valign {
  vertical-align: middle;
  display: table-cell;
  padding: 100px 0;
}

.mc-overflow-hidden {
  overflow: hidden;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 34, 34, 0.1);
  z-index: 1039;
  overflow-y: scroll;
}

.info-data .info-container-secondary-billing {
  background-color: #F8f8f8;
  border-radius: 4px;
  padding: 10px 16px;
  margin: 0 0 10px 0;
  padding: 20px;
  height: 100%;
}
.info-data .info-container-secondary-billing span {
  text-transform: none;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 10px;
}
.info-data .info-container-billing {
  background-color: #F8f8f8;
  border-radius: 0 0 4px 4px;
  padding: 10px 16px;
  font-size: 16px !important;
}
.info-data .info-container-billing span {
  text-transform: none;
  margin-left: 5px;
  font-size: 1rem;
  font-weight: 400;
}

.floating-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1039;
}

.mc-login-btn-area {
  padding-top: 70px;
}
.mc-login-btn-area .mc-login-btn {
  width: 50%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.mc-input-password {
  position: relative;
}

.mc-active {
  color: #35B1F1;
}

.mc-semi-active {
  color: #7dcdf6;
}

.mc-active-background {
  background-color: #35B1F1;
}

.mc-semi-active-background {
  background-color: #7dcdf6;
}

.mc-error-input-wrapper {
  border-color: #EC230D !important;
}

.mc-success-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: none;
  background: #35B1F1;
  color: #fff;
  padding: 5px 0;
}
.mc-success-button:focus {
  outline: none;
}
.mc-success-button:hover {
  background: #109be3;
}

.mc-close-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: none;
  background: #a6a9b9;
  color: #fff;
  padding: 5px 30px;
}
.mc-close-button:focus {
  outline: none;
}
.mc-close-button:hover {
  background: #9598ac;
}

.mc-date {
  font-weight: 500;
}

.mc-warning-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: none;
  background: #EC230D;
  color: #fff;
  padding: 5px 0;
}
.mc-warning-button:focus {
  outline: none;
}
.mc-warning-button:hover {
  background: #bc1c0a;
}

.mc-login-card {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.mc-login-card .mc-login-header {
  padding-left: 40px;
  padding-right: 40px;
}
.mc-login-card .mc-login-header img {
  margin: 30px 0 0 0;
}
.mc-login-card .mc-login-header h4 {
  text-align: center;
  line-height: 1.5;
  margin-bottom: 0;
}
.mc-login-card .mc-card-title {
  margin-top: 30px;
}
.mc-login-card .boarding-btn {
  background-color: #35B1F1;
  color: white;
  border: 1px solid #35B1F1;
}
.mc-login-card .mc-divider {
  border-right: 1px solid rgba(0, 0, 0, 0.16);
}

.mc-row-no-margin {
  margin: 0;
}

.mc-arrow {
  color: #fff;
  background-color: #35b1ec;
  font-size: 14px;
  padding: 2px 5px 1px 5px;
  margin-left: 10px;
  margin-bottom: 2px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
}
.mc-arrow.mc-arrow-sidebar {
  width: 20px;
  height: 18px;
  text-align: center;
}

.mc-notification-badge-gray {
  background: rgba(0, 0, 0, 0.16);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  padding: 3px 6px;
  margin-right: 5px;
}

.mc-notification-badge-blue {
  background: rgba(42, 175, 244, 0.23);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  font-size: 11px !important;
  line-height: 1;
  padding: 3px 6px;
  margin-right: 5px;
  position: absolute;
  bottom: 3px;
}

.mc-stepper-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.16);
}
.mc-stepper-header-title span:not(.mc-notification-badge-gray) {
  font-size: 20px;
  position: relative;
}
.mc-stepper-header-title .mc-notification-badge-gray {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*Checkbox all*/
.mc-custom-checkbox {
  display: inline-block;
  height: 12px;
  margin-top: -20px;
  margin-left: -24px;
  padding: 0;
}
.mc-custom-checkbox.fill-checkbox {
  --color: #35B1F1;
}
.mc-custom-checkbox.fill-checkbox .fill-control-input {
  display: none;
}
.mc-custom-checkbox.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-custom-checkbox.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 19px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #f8f9fa;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-custom-checkbox.fill-checkbox .fill-control-description {
  font-size: 14px;
}

.mc-error-field {
  border-color: #EC230D;
}
.mc-error-field:focus {
  border-color: #EC230D;
  box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, 0.25);
}

.mc-selected-row {
  background: #DFF1FB;
}

.mc-font-semi-weight {
  font-weight: 500;
}

.mc-font-semi-weight-1 {
  font-weight: 600;
  font-size: 80%;
}

.font {
  font-size: 1rem;
  font-weight: 400;
}

.mc-next-save-btn {
  background: #128ac2;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;
}
.mc-next-save-btn:focus {
  box-shadow: none;
}
.mc-next-save-btn:hover {
  background: #1079ab;
}

.mc-prev-btn {
  border: none;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.mc-close-btn {
  position: absolute;
  right: 11px;
  top: 0;
}

.mc-cancel-btn {
  border: none;
  background: #990000;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 25px;
  text-decoration: none;
}
.mc-cancel-btn:hover {
  background: #8f0000;
  color: #ffffff;
}
.mc-cancel-btn:focus {
  outline: none;
}

.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/*Go back button in edit management component*/
.mc-go-back-to-all {
  display: flex;
  align-items: center;
  color: #35B1F1;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: bold;
  width: fit-content;
}
.mc-go-back-to-all:hover {
  color: #32424f;
}
.mc-go-back-to-all i {
  font-size: 20px;
  margin-right: 10px;
}

/* Dates */
.mc-dates-inputs[readonly] {
  background: #fff;
}

.header-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: #32424f;
  color: #f8f9fa;
  border-radius: 5px;
  min-height: 50px;
  margin-bottom: 20px;
}
.header-info p {
  font-size: 18px;
  padding: 0px 10px;
  margin: 0px;
}
.header-info span {
  font-weight: 500;
  padding-left: 5px;
}

.header-info-light {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-height: 50px;
  user-select: none;
  padding: 0px 20px;
}
.header-info-light p {
  font-weight: 500;
  font-size: 18px;
  padding: 0px 10px;
  margin: 0px;
}
.header-info-light span {
  font-weight: 700;
  padding-left: 5px;
}

/*ngb-datepicker {
  .ngb-dp-header.bg-light {
    background-color: $mc-datepicker-bg !important;

    .ngb-dp-arrow .ngb-dp-arrow-btn {
      color: $mc-datepicker-date;

      &:focus {
        outline: none;
      }

      .ngb-dp-navigation-chevron {
        width: 9px;
        height: 9px;
        border-width: 2px 2px 0 0;
      }
    }

    .ngb-dp-navigation-select {
      flex: 1 1 66px;

      .custom-select {
        background: $mc-datepicker-bg none;
        border: 0;
        border-radius: 0;
        color: $mc-datepicker-date;
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 .3rem;

        &:first-of-type {
          padding-right: 0;
        }

        &:last-of-type {
          padding-left: 0;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

  }

  .ngb-dp-months {
    background-color: $mc-datepicker-bg;

    .ngb-dp-weekdays {
      background-color: $mc-datepicker-week !important;
      margin-top: 5px;
      margin-bottom: 5px;
      border-bottom: 0;
    }

    .ngb-dp-weekday {
      color: #585c60;
      font-style: normal;
      font-size: 17px;
    }

    .ngb-dp-week {
      font-size: 17px;
    }

    .ngb-dp-day div.bg-primary {
      background-color: $mc-datepicker-day-selected !important;
      color: #000 !important;
      border-radius: 0;
    }

    .ngb-dp-day:focus {
      box-shadow: none;
      border: 0;
      outline: none;
    }
  }
}*/
/* Dates #*/
.mc-chevron {
  color: #55616D;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
.mc-chevron::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  position: relative;
  transform: rotate(-45deg);
  width: 0.45em;
}
.mc-chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}
.mc-chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}
.mc-chevron.bottom:before {
  top: 0;
  transform: translateY(-60%) rotate(135deg);
}

.mc-date-ico-0 {
  width: 24px;
  height: 24px;
  background-position: 0px -288px !important;
}
.mc-date-ico-1 {
  width: 24px;
  height: 18px;
  background-position: -25px -284px !important;
}
.mc-date-ico-2 {
  width: 24px;
  height: 18px;
  background-position: -50px -284px !important;
}
.mc-date-ico-3 {
  width: 24px;
  height: 18px;
  background-position: -71px -284px !important;
}
.mc-date-ico-4 {
  width: 24px;
  height: 18px;
  background-position: -95px -284px !important;
}
.mc-date-ico-5 {
  width: 24px;
  height: 18px;
  background-position: -117px -284px !important;
}

.mc-attribute-info {
  background-color: #D4E7F0;
  border-radius: 11px;
  width: 18px;
  height: 18px;
  padding: 3px 3px 3px 2px !important;
}
.mc-attribute-info:hover {
  background-color: #35b1f2 !important;
}

.mc-custom-filter-dropdown {
  border: 0 !important;
  position: relative;
}
.mc-custom-filter-dropdown .mc-dropdown-text:last-of-type {
  border-right: 1px solid #DBE2E8;
}
.mc-custom-filter-dropdown.show .mc-dropdown-text {
  background: #DBE2E8;
}
.mc-custom-filter-dropdown .mc-dropdown-text {
  border-left: 1px solid #DBE2E8;
  padding: 5px 30px 5px 20px;
  background: #e8ecef;
  cursor: pointer;
  position: relative;
}
.mc-custom-filter-dropdown .mc-dropdown-text::after {
  display: none;
}
.mc-custom-filter-dropdown .mc-amount-due form {
  height: 175px;
  width: 230px;
  text-align: center;
}
.mc-custom-filter-dropdown .mc-amount-due .apply-btn {
  position: absolute;
  bottom: 10px !important;
  right: 10px !important;
  font-size: 80%;
  width: 40%;
}
.mc-custom-filter-dropdown .mc-amount-due .apply-btn:after {
  display: none;
}
.mc-custom-filter-dropdown .mc-amount-due .mc-dropdown-item {
  margin: 0;
  padding: 15px;
  padding-left: 0 !important;
  align-items: center;
  width: 80%;
}
.mc-custom-filter-dropdown .mc-amount-due .mc-dropdown-item input {
  position: absolute;
  right: 10px;
  width: 40%;
  height: 20%;
}
.mc-custom-filter-dropdown .mc-amount-due .mc-dropdown-item label {
  width: 70%;
  font-size: 14px;
  color: #55616D;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
}
.mc-custom-filter-dropdown .mc-dropdown-list {
  background: #DBE2E8;
  border: none;
  border-radius: 0;
  box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.4);
  padding: 0;
  margin: 0;
  min-width: 180px;
  left: auto !important;
  right: 0 !important;
}
.mc-custom-filter-dropdown li {
  border-bottom: 1px solid;
  border-color: #bcc9d4;
  color: #55616D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.2;
  padding: 5px 20px;
}
.mc-custom-filter-dropdown li:hover {
  cursor: pointer;
  background-color: #55616D;
  color: #fff;
}
.mc-custom-filter-dropdown li:hover .mc-date-ico-0 {
  background-position: 0px -312px !important;
}
.mc-custom-filter-dropdown li:hover .mc-date-ico-1 {
  background-position: -25px -301px !important;
}
.mc-custom-filter-dropdown li:hover .mc-date-ico-2 {
  background-position: -50px -301px !important;
}
.mc-custom-filter-dropdown li:hover .mc-date-ico-3 {
  background-position: -71px -301px !important;
}
.mc-custom-filter-dropdown li:hover .mc-date-ico-4 {
  background-position: -95px -301px !important;
}
.mc-custom-filter-dropdown li:hover .mc-date-ico-5 {
  background-position: -117px -301px !important;
}
.mc-custom-filter-dropdown li:hover:last-child::after {
  color: #fff;
}
.mc-custom-filter-dropdown li:last-child {
  border-bottom: 0;
  justify-content: flex-end;
}
.mc-custom-filter-dropdown li:last-child::after {
  color: #55616D;
  position: relative;
  transform: rotate(45deg);
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  width: 0.45em;
  margin-left: 5px;
}

.mc-details-tabs {
  width: 100%;
}
.mc-details-tabs ul {
  justify-content: center !important;
}
.mc-details-tabs li {
  font-size: 20px;
}
.mc-details-tabs li:hover {
  cursor: pointer;
}
.mc-details-tabs a {
  color: #9ca0a3;
}
.mc-details-tabs a:hover {
  color: #7dcdf6;
  cursor: pointer;
}
.mc-details-tabs a.nav-link.active {
  color: #35B1F1;
  border-bottom: 3px solid #35B1F1;
  padding-bottom: 0;
}

.disable-input {
  background-color: #f0f0f5 !important;
  color: #f0f0f5 !important;
  pointer-events: none;
}

.disable-label {
  opacity: 0.3;
  pointer-events: none;
}

.contact-person-wrapper {
  border: 3px solid #b6b8ba;
  padding: 5%;
  margin-bottom: 15px;
  position: relative;
}

.contact-person {
  background: #f5f5f5;
  color: #b6b8ba;
  position: absolute;
  top: -14px;
  padding: 0 20px;
}

.set-font {
  font-size: 12px;
  color: #B6B8BA;
  font-weight: 600;
  padding-right: 30px;
}

.position-top {
  margin-top: -1px;
  margin-left: 0;
}

.low-opacity {
  opacity: 0.3;
}

.arrow-position {
  position: absolute;
  left: 230px;
  top: 9px;
}

.active-link {
  color: #f8f9fa !important;
}

.dropdown {
  position: relative;
  width: 270px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0px 2px 2px 0px;
  display: flex;
  align-items: center;
}

.dropdown-collapsed {
  width: 50px;
  height: 46px;
}

.custom_tool_tip,
.tabs_tool_tip,
.tabs_tool_tip_active,
.dashboard_tool_tip {
  border-radius: 5px;
}
.custom_tool_tip .mat-mdc-tooltip-surface,
.tabs_tool_tip .mat-mdc-tooltip-surface,
.tabs_tool_tip_active .mat-mdc-tooltip-surface,
.dashboard_tool_tip .mat-mdc-tooltip-surface {
  height: 40px;
  width: max-content !important;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 12px !important;
  text-align: center !important;
  background: #fff !important;
  color: #3B457C !important;
}

.custom_tool_tip .mat-mdc-tooltip-surface {
  position: absolute;
  left: -66px;
}

.tabs_tool_tip .mat-mdc-tooltip-surface,
.tabs_tool_tip_active .mat-mdc-tooltip-surface {
  position: absolute;
  left: 30px;
  top: -70px;
}

.tabs_tool_tip_active .mat-mdc-tooltip-surface {
  background: #5eabbe !important;
  color: #fff !important;
}

.mc-sidebar-submenu {
  position: relative;
  margin-left: -19px;
  padding: 0;
  z-index: 1;
  background: #fff;
  width: 279px;
  overflow-y: hidden;
  transition: all 0.5s ease-in-out;
}
.mc-sidebar-submenu .submenu-item {
  font-size: 20px;
  text-decoration: none;
}
.mc-sidebar-submenu .submenu-item .submenu-item-hover {
  width: 100%;
}
.mc-sidebar-submenu .submenu-item .submenu-item-hover .mc-sidebar-link:last-of-type {
  padding-bottom: 0px !important;
}
.mc-sidebar-submenu .submenu-item .submenu-item-hover .mc-sidebar-icon.dropdown-icon,
.mc-sidebar-submenu .submenu-item .submenu-item-hover .dropdown-link.mc-sidebar-link {
  font-size: 13px !important;
  width: 100%;
}
.mc-sidebar-submenu .submenu-item .submenu-item-hover:hover a, .mc-sidebar-submenu .submenu-item .submenu-item-hover:hover i {
  color: #f8f9fa;
}
.mc-sidebar-submenu .submenu-item .dropdown-link, .mc-sidebar-submenu .submenu-item .dropdown-icon {
  text-decoration: none;
  color: #8aa4af;
  font-weight: bold;
  font-size: 14px;
}

.left-border {
  border-left: 3px solid #35b1ec;
}

.mc-card-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  width: 30px;
  height: 30px;
  top: -1px;
  left: -1px;
  transform: translateX(-50%);
  border-radius: 50%;
  color: #FFF;
  background-color: #35b1ec;
  z-index: 1;
}

/*Drag and drop icon*/
.mc-drag-and-drop {
  color: #ccc;
}
.mc-drag-and-drop:hover {
  cursor: pointer;
}

.mc-text-align-right {
  text-align: right;
}

.mc-text-align-center {
  text-align: center;
}

.mc-text-align-left {
  text-align: left;
}

.mc-blue-btn {
  border: 1px solid #35b1ec;
  color: #35b1ec;
}
.mc-blue-btn:hover {
  background: #35b1ec;
  color: #f8f9fa;
}

.mc-table-button {
  border-radius: 20px;
  width: 110px;
  text-align: center;
}

.mc-detect-html-changes {
  -webkit-animation: onChangeHtmlValue 2s infinite;
  -o-animation: onChangeHtmlValue 2s infinite;
  animation: onChangeHtmlValue 2s infinite;
}

.mc-input-field-wrapper input:required {
  box-shadow: none;
}

.mc-highlight-grey {
  background: #e5e5e5;
  border-radius: 5px;
  padding: 3px 8px;
}

.mc-mandatory-border {
  border: 2px solid #EC230D !important;
}
.mc-mandatory-border:focus {
  border-color: #EC230D;
  box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, 0.25) !important;
}

[type=date]::-webkit-inner-spin-button,
[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.mc-table-detect-changes-once {
  -webkit-animation: onChangeHtmlValue 2s infinite;
  -o-animation: onChangeHtmlValue 2s infinite;
  animation: onChangeHtmlValue 2s infinite;
  animation-iteration-count: 0;
}

@keyframes onChangeHtmlValue {
  0% {
    background-color: #55616D;
  }
  100% {
    background-color: #a5d7f2;
  }
}
.mc-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
}
.mc-icon * {
  width: 100%;
  height: 100%;
}
.mc-icon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.mc-custom-link {
  width: 100%;
  color: #35b1ec;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}
.mc-custom-link:hover {
  text-decoration: underline;
}

.clear {
  clear: both;
}

.mc-background-loading {
  background: #dae9ff;
  color: #0081be;
}

.mc-font-weight-500 {
  font-weight: 500;
}

.mc-font-weight-600 {
  font-weight: 600;
}

/*For all confirm buttons*/
.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.button-wrapper .mc-filter-button {
  background: #35B1F1;
  border: none;
  outline: none;
}

.mc-blue-link {
  color: #35b1ec !important;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none !important;
}
.mc-blue-link:hover {
  color: #149ada !important;
}

.mc-blue-button {
  color: #35B1F1;
  background-color: #fff;
  cursor: pointer;
  font-weight: 500;
  padding: 6px 10px;
  margin: 5px;
  border-radius: 6px;
  border: 1px solid #35B1F1;
}

.mc-button-grayout {
  color: gray;
  border-color: gray;
  opacity: 35%;
}

.mc-message-info {
  position: relative;
  margin-top: 30px;
  top: -33px;
}

.mc-unselectable-content-on-press-shift {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.mc-width-fit-content {
  width: fit-content;
}

.mc-width-webkit-fill-available {
  width: -webkit-fill-available;
}

.defaultLengthForInputField {
  width: 220px;
}

.radio-button-error:before {
  border: 1px solid #ff0000 !important;
}

.mc-factoring-textbox-filter {
  width: fit-content;
  display: inline-block;
}

.mc-notify-msg {
  position: relative;
  margin-top: 37px;
  top: -37px;
}

.mc-tooltip-top-margin {
  font-size: 1.3rem;
  margin-top: 39px;
  color: #35B1F1;
}

.mc-tooltip {
  font-size: 1.4rem;
  color: #35B1F1;
}

.mc-tooltip-length .tooltip-inner {
  min-width: fit-content;
  white-space: nowrap;
}

.mc-tooltip-length-350 .tooltip-inner {
  min-width: 250px;
}

.mc-disable-button {
  pointer-events: none;
  opacity: 0.3;
}

.select-in-component-width-50 select {
  width: 50%;
}

.mc-background-light-blue {
  background-color: #E5F8FF;
  min-height: 100%;
}

.mc-light-blue-btn {
  background-color: #3EB2EF !important;
  border-color: #3EB2EF !important;
}

.mc-background-light-grey {
  background-color: #FAF8F8;
}

.mc-background-gray {
  background: #f3f3f3;
}
.mc-background-gray.min-height {
  min-height: 100%;
}

.mc-light-green-text {
  color: #28a745;
}

.mc-higher-box {
  line-height: 3;
}

.mc-border-small-radius {
  border-radius: 4px;
}
.mc-border-small-radius .title {
  font-weight: 600;
}

.mc-blue-icon {
  color: #35B1F1 !important;
}

.mc-disable {
  pointer-events: none;
  opacity: 0.3;
}

.mc-disabled-table-row {
  opacity: 0.35;
  pointer-events: none;
}

.mc-button-link {
  background: none;
  color: #35B1F1;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.mc-tooltip-top-margin-40 {
  margin-top: 40px;
}

.border-test {
  border: 1px solid red;
}

.mc-pointer-events-none {
  pointer-events: none !important;
}

.text-orange-color {
  color: #F47407;
}

.icon-tooltip {
  position: relative;
  display: flex;
}
.icon-tooltip img {
  width: 24px;
  margin-right: 0.625rem;
}
.icon-tooltip .fa-pause-circle {
  font-size: 24px;
  margin-right: 0.625rem;
}

.icon-tooltip .icon-tooltip-text {
  visibility: hidden;
  min-width: 150px;
  max-width: 250px;
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
  border-radius: 4px;
  padding: 8px 4px;
  position: absolute;
  z-index: 1;
  top: 115%;
  left: 50%;
  margin-left: -60px;
  font-size: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}

.icon-tooltip .icon-tooltip-text:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 35%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.icon-tooltip:hover .icon-tooltip-text {
  visibility: visible;
}

.icon-tooltip:hover .icon-tooltip-hidden {
  visibility: hidden;
}

.radio-button-info-message {
  margin-top: 9% !important;
}
.radio-button-info-message .tooltip {
  position: absolute;
  top: 41% !important;
}
.radio-button-info-message:first-of-type i {
  padding-bottom: 12px;
}

.mc-dropdown-menu .dropdown-menu.show {
  top: 0 !important;
  left: 0 !important;
  will-change: transform !important;
  position: absolute;
  transform: translate(-76px, 44px) !important;
}

.mc-position-relative {
  position: relative;
}

.mc-width-30-percetage {
  width: 30%;
}

.text-uppercase {
  text-transform: uppercase;
}

.mc-html-blue-button {
  height: 20px;
  line-height: 0.5;
  background: #3EB2EF;
  border-color: #3EB2EF;
  color: #FFFFFF;
}

.mc-html-gray-button {
  background: #6C757C;
  color: #FFFFFF;
}

.product-quantity {
  display: contents;
}

#toast-container > .toast-error {
  color: red;
  background-color: #FFFFFF;
  width: 450px;
}

.toast-error {
  background-image: url("/assets/images/times-circle-solid.svg") !important;
}

#toast-container {
  margin-top: 25px;
}

#toast-container > .toast-success {
  color: green;
  background-color: #FFFFFF;
  width: 360px;
}

.toast-success {
  background-image: url("/assets/images/check-circle-solid.svg");
}

#toast-container > div {
  opacity: 1;
  z-index: 11111;
}

.toast-message a {
  color: #35b1ec;
}
.toast-message a:hover {
  color: #1079ab;
}

.reset, .mc-notify-message-wrapper .success-message, .mc-notify-message-wrapper .error-message {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  vertical-align: baseline;
}

.mc-button {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: none;
  outline: none;
  color: #fff;
  padding: 5px 30px;
}
.mc-button:focus {
  outline: none;
}

.mc-link {
  border: none;
  outline: none;
  padding: 0;
  color: #35B1F1;
  background: none;
  margin-bottom: 10px;
  font-weight: 500;
}
.mc-link:hover {
  text-decoration: underline;
}
.mc-link:focus {
  outline: none;
}

.mc-go-back {
  position: absolute;
  top: 5px;
  left: 8px;
  font-size: 14px;
  color: #35B1F1;
  cursor: pointer;
}

.mc-logo {
  display: block;
  height: 70px;
  margin: 20px auto;
}

.mc-alert-error-input {
  border-color: #EC230D;
}

.mc-cursor-pointer {
  cursor: pointer;
}
.mc-cursor-pointer:hover {
  color: #3a7897;
}

.mc-cursor-pointer-radio-button {
  cursor: pointer;
}

.capitalize {
  display: inline-block;
  margin: 0;
}
.capitalize:first-letter {
  text-transform: capitalize;
}

.mc-text-capitalize {
  text-transform: capitalize;
}

.mc-submit-color {
  color: #5eabbe;
}

.mc-submit-button {
  background: #35b1f1;
  border: none;
  outline: 0;
}

.mc-gray-background {
  background: #f7f7f7;
}

/*Vertical scrollbar*/
.mc-scrollbar-vertical::-webkit-scrollbar {
  width: 6px;
  background-color: #f7f7f7;
}

.mc-scrollbar-vertical::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #555f68;
}
.mc-scrollbar-vertical::-webkit-scrollbar-thumb:hover {
  background-color: #B6B8BA;
  cursor: pointer;
}

.mc-property-title {
  padding: 5px 0;
  color: #9ca0a3;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  flex-basis: 100px;
}

.mc-field-width-15 {
  width: 15%;
}

.mc-field-width-7 {
  width: 7%;
}

.mc-field-width-30 {
  width: 30%;
}

.custom-select.mc-field-width-10 {
  width: 10%;
}

/*Placeholder style*/
::-webkit-input-placeholder {
  text-transform: initial;
}

:-moz-placeholder {
  text-transform: initial;
}

::-moz-placeholder {
  text-transform: initial;
}

:-ms-input-placeholder {
  text-transform: initial;
}

.mc-input-field-invalid {
  border: 2px solid #EC230D !important;
}

.mc-border-bottom {
  border-bottom: 4px solid #000 !important;
}

.mc-border-bottom-light {
  border-bottom: 1px solid #dee2e6;
}

.mc-border-top {
  border-top: 4px solid #000 !important;
}

.mc-reporting-table-td-gap {
  padding: 5px !important;
}

.mc-text-click {
  color: #35b1ec;
  cursor: pointer;
  font-weight: 500;
}
.mc-text-click:hover {
  color: #149ada;
}

.mc-click-not-allow {
  background: #adb5bd;
  color: #fff;
  cursor: not-allowed;
  border: none;
}
.mc-click-not-allow:focus {
  outline: none;
}

.page-title {
  font-weight: 800;
  font-size: 24px;
  color: #5eabbe;
  text-transform: uppercase;
}

.actions-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-height: 60px;
  user-select: none;
  margin-top: 1.25rem;
  margin-bottom: 0.938rem;
  padding: 0px 20px;
  height: 64px;
  background: #fff;
}
.actions-container span {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #3B457C;
}
.actions-container .actions-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.actions-container .icon-tooltip {
  width: 30px;
}
.actions-container .icon-tooltip img {
  width: 30px;
}
.actions-container .mc-transaction-status {
  display: flex;
}

.actions-title {
  display: flex;
  align-items: center;
  background-color: #32424f;
  height: 60px;
  padding: 0px 20px;
  margin-left: -20px;
  border-radius: 4px 4px;
}
.actions-title span {
  color: white;
}

.system-action-btn {
  color: #fff !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 8px 15px;
  font-size: 16px;
  transition: background 200ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 180ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  background: #53589B;
  border-radius: 4px;
  height: 44px;
  font-weight: 500;
  line-height: 19px;
}
.system-action-btn.dark-system-action-btn {
  background-color: #32424f;
}
.system-action-btn.dark-system-action-btn:hover {
  background-color: #465c6e !important;
}
.system-action-btn.light-system-action-btn {
  background-color: #fff;
  color: #32424f !important;
  border: 1px solid #32424f;
}
.system-action-btn.light-system-action-btn:hover {
  background-color: #e6e6e6 !important;
}
.system-action-btn:focus {
  border: none;
  outline: none;
}
.system-action-btn:hover {
  background-color: #41457a !important;
}
.system-action-btn i {
  font-size: 16px;
  margin-right: 8px;
}

.system-action-btn-disabled {
  background-color: rgba(204, 204, 204, 0.5607843137) !important;
  color: #fff !important;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 400;
  transition: background 200ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 180ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  height: 44px;
  pointer-events: none;
}
.system-action-btn-disabled i {
  font-size: 16px;
  margin-right: 8px;
}

.primary-btn,
.secondary-btn {
  font-family: "Maven Pro", sans-serif !important;
  width: 100px;
  height: 36px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px !important;
}

.primary-btn {
  background: #333867 !important;
  color: #ffffff !important;
}
.primary-btn:disabled {
  opacity: 0.5;
  color: #ffffff !important;
}

.secondary-btn {
  border: 1px solid #333867 !important;
  color: #333867 !important;
}

.icon-text-table-cell {
  display: flex;
  gap: 10px;
  align-items: center;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgb(214, 215, 225);
  margin: 1.25rem 0;
}

.mc-login-area {
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, 0.01);
}
.mc-login-area .mc-login-card .mc-login-header img {
  max-width: 200px;
  padding: 60px 0 80px;
  box-sizing: content-box;
}
.mc-login-area .mc-login-card .mc-login-card-body {
  padding: 0 60px 60px;
}
.mc-login-area .mc-login-card .mc-login-card-body .mc-notify-message-wrapper {
  top: 300px;
  width: 100%;
}
.mc-login-area .mc-login-card .mc-login-card-body form {
  padding-top: 70px;
}

.mc-login-password-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0;
}
.mc-login-password-fields .mc-login-input {
  margin-bottom: 40px;
}
.mc-login-password-fields .mc-login-input label {
  display: none;
}
.mc-login-password-fields input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
  padding: 20px;
}

.mc-login-btn-area {
  padding: 10px 0 0 0;
  width: 100%;
}
.mc-login-btn-area button {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 30px;
}

.mc-login-loginAlternatives {
  align-items: center;
  color: #35B1F1;
}
.mc-login-loginAlternatives .mc-divider a {
  float: right;
}
.mc-login-loginAlternatives a {
  color: #35B1F1;
  text-decoration: none;
}
.mc-login-loginAlternatives .mc-keep-me-logged div, .mc-login-loginAlternatives .mc-keep-me-logged .custom-control.fill-checkbox {
  margin-bottom: 0;
}
.mc-login-loginAlternatives .mc-keep-me-logged .custom-control.fill-checkbox .fill-control-indicator {
  border-radius: 0;
  border-color: #35B1F1;
}
.mc-login-loginAlternatives .mc-keep-me-logged .custom-control.fill-checkbox .fill-control-description {
  font-size: 1rem;
  text-transform: none;
}

@media only screen and (max-width: 1200px) {
  .mc-login-area .mc-login-card .mc-login-card-body {
    padding: 0 40px 40px;
  }
}
@media only screen and (max-width: 768px) {
  .mc-login-area .mc-login-header img {
    height: 60px;
  }
  .mc-login-area .mc-login-card .mc-login-card-body {
    padding: 0 30px 30px;
  }
}
@media only screen and (max-width: 375px) {
  .mc-login-area .mc-login-header img {
    height: 50px;
  }
  .mc-login-area .mc-login-loginAlternatives .mc-divider {
    border-right: none;
    min-width: 100%;
  }
  .mc-login-area .mc-login-loginAlternatives .mc-divider a {
    float: left;
  }
  .mc-login-area .mc-login-loginAlternatives .mc-keep-me-logged {
    margin-top: 15px;
    min-width: 100%;
  }
}
.mc-setup-area {
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, 0.65);
}
.mc-setup-area .mc-login-card .boarding-btn {
  margin-top: 20px;
}
.mc-setup-area .mc-login-card .mc-login-header img {
  padding: 0;
  height: 70px;
}
.mc-setup-area .mc-login-card-body {
  position: relative;
  padding: 0 40px;
}
.mc-setup-area .mc-login-card-body .mc-notify-message-wrapper {
  padding-top: 20px;
}
.mc-setup-area .mc-login-card-body .mc-tabset ul.nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 10px 0;
  justify-content: center !important;
  color: #6C6A6A;
}
.mc-setup-area .mc-login-card-body .mc-tabset ul.nav-tabs li:first-of-type:after {
  content: "- or -";
}
.mc-setup-area .mc-login-card-body .mc-tabset ul.nav-tabs a {
  display: inline-block;
  color: #6C6A6A;
  cursor: pointer;
  border: none;
}
.mc-setup-area .mc-login-card-body .mc-tabset ul.nav-tabs a.active {
  color: #35B1F1;
}
.mc-setup-area .mc-login-card-body .custom-control.fill-checkbox {
  --color: $dialog-text-color;
  margin-bottom: 5px;
}
.mc-setup-area .mc-login-card-body .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-setup-area .mc-login-card-body .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: #333867;
  border-color: #333867;
  background-size: 80%;
}
.mc-setup-area .mc-login-card-body .custom-control.fill-checkbox .fill-control-indicator {
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-setup-area .mc-login-card-body .custom-control.fill-checkbox .fill-control-description {
  font-size: 10px;
  text-transform: uppercase;
}
.mc-setup-area .mc-login-card-body .custom-control.fill-checkbox .fill-control-description strong {
  font-size: 13px;
}
.mc-setup-area .mc-login-card-body .tab-content {
  padding: 0 15px;
}
.mc-setup-area .mc-login-card-body form {
  width: 100%;
  padding-top: 70px;
}

.tab-content {
  position: relative;
}

.mc-login-password-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}
.mc-login-password-fields input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 20px;
  font-size: 14px;
}

.mc-password-policy {
  padding-left: 20px;
}
.mc-password-policy p {
  font-weight: 500;
}
.mc-password-policy .form-group {
  margin: 0;
  font-size: 14px;
}

.rule-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.rule-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.rule-checkbox-wrapper .form-group {
  margin: 0 0.5rem 0 0;
}

.password-wrapper {
  flex-direction: column;
}

.password-input-wrapper {
  justify-content: space-between;
}

.generate-container {
  /*#length {
    background: linear-gradient(to right, white 60%, $light-blue-color 50%);
  }*/
}
.generate-container .arrows .up span {
  color: #35B1F1;
  display: block;
  transform: rotateZ(-90deg);
  position: absolute;
  left: 160px;
  cursor: pointer;
}
.generate-container .arrows .up span::selection {
  background: none;
}
.generate-container .arrows .down span {
  color: #35B1F1;
  display: block;
  transform: rotateZ(-90deg);
  position: absolute;
  top: 35px;
  left: 160px;
  cursor: pointer;
}
.generate-container .arrows .down span::selection {
  background: none;
}
.generate-container .row .btn-sync {
  position: absolute;
  right: 50px;
}
.generate-container .row .sync-icon {
  cursor: pointer;
}
.generate-container .row .sync-icon img {
  height: 25px;
  width: 25px;
  position: absolute;
  right: 69px;
  bottom: 156px;
}
.generate-container .row .copy-text {
  font-size: 90%;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
}
.generate-container .row .copy-text a {
  cursor: pointer;
}
.generate-container .row .copy-text img {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 235px;
}
.generate-container .row .form-group .checkbox-input {
  left: 0 !important;
  position: absolute;
  top: 5px;
}
.generate-container ul {
  padding: 0 !important;
  margin: 0;
  clear: both;
  border-bottom: none !important;
}
.generate-container li {
  list-style-type: none;
  list-style-position: outside;
  padding: 10px;
  float: left;
}
.generate-container input[type=checkbox]:not(:checked),
.generate-container input[type=checkbox]:checked {
  position: absolute;
  left: -9999%;
}
.generate-container input[type=checkbox] + label {
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 60px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #B6B8BA;
  color: #B6B8BA;
  background-color: white;
  margin-bottom: 10px;
}
.generate-container input[type=checkbox]:checked + label {
  border: 1px solid #B6B8BA;
  color: #35B1F1;
  background-color: rgba(42, 175, 244, 0.23);
}
.generate-container input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.generate-container .first-row {
  margin-bottom: 15px;
}
.generate-container .first-row .char-class {
  position: absolute !important;
  top: 28px;
  bottom: 0;
  right: 310px;
}
.generate-container .first-row .length-input {
  top: -8px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .generate-container .mc-setup-area .mc-login-header img {
    height: 60px;
  }
  .generate-container .mc-setup-area .mc-login-card .mc-login-card-body {
    padding: 0 30px 30px;
  }
  .generate-container .mc-setup-area .mc-login-password-fields.mc-divider {
    border-right: none;
  }
  .generate-container .mc-setup-area .mc-password-policy p {
    width: 100%;
  }
  .generate-container .mc-setup-area .mc-password-policy .mc-password-policy-requirements {
    column-count: 2;
  }
}
@media only screen and (max-width: 460px) {
  .generate-container .mc-setup-area .mc-login-card-body ul {
    flex-direction: column;
    align-items: center;
  }
  .generate-container .mc-setup-area .mc-login-card-body ul li a {
    line-height: 1;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .generate-container .mc-setup-area .mc-login-card-body ul li:first-of-type:after {
    display: block;
    text-align: center;
  }
  .generate-container .mc-setup-area .mc-input-password {
    margin-bottom: 0;
  }
  .generate-container .mc-setup-area .mc-login-card .mc-login-card-body form {
    padding-top: 50px;
  }
  .generate-container .mc-setup-area .mc-password-policy {
    margin-top: 10px;
  }
  .generate-container .mc-setup-area .mc-login-btn-area {
    padding-top: 30px;
  }
  .generate-container .mc-setup-area .mc-seepassword-btn {
    width: 17%;
  }
}

.mc-incomplete-profile-card.card {
  border-radius: 10px;
}
.mc-incomplete-profile-card .mc-incomplete-profile-header {
  background-color: #f2f2f2;
  border-radius: 10px;
}
.mc-incomplete-profile-card .mc-incomplete-profile-header img {
  padding: 30px 0 40px;
  box-sizing: content-box;
  height: 70px;
}
.mc-incomplete-profile-card .mc-incomplete-profile-header div {
  font-size: 20px;
  font-weight: 500;
}
.mc-incomplete-profile-card .mc-incomplete-profile-back-to-login .mc-go-back {
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
}
.mc-incomplete-profile-card .mc-create-user-notify-wrapper .mc-notify-message-wrapper {
  padding: 0 20px;
}
.mc-incomplete-profile-card .mc-user-gender {
  padding: 0;
}
.mc-incomplete-profile-card .mc-user-gender label {
  margin-right: 10px;
  font-size: 14px;
}
.mc-incomplete-profile-card .mc-user-gender > div {
  margin-bottom: 10px;
}
.mc-incomplete-profile-card .mc-new-user-image-area input[type=file] {
  display: none;
}
.mc-incomplete-profile-card .mc-new-user-image {
  margin-top: 10px;
  min-width: 100%;
  height: 210px;
  cursor: pointer;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.mc-incomplete-profile-card .mc-new-user-image-area .mc-upload-image-btn {
  display: inline-block;
  font-size: 12px;
  color: #35b1ec;
  cursor: pointer;
  padding: 5px 0 5px 5px;
  float: right;
}
.mc-incomplete-profile-card .mc-new-user-image-area .mc-upload-image-btn:hover {
  color: #149ada;
}
.mc-incomplete-profile-card .mc-next-save-btn {
  background: #35b1ec;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;
}
.mc-incomplete-profile-card .mc-next-save-btn:focus {
  box-shadow: none;
}
.mc-incomplete-profile-card .mc-next-save-btn:hover {
  background: #128ac2;
}
.mc-incomplete-profile-card .mc-error-field {
  border-color: #EC230D;
}
.mc-incomplete-profile-card .mc-create-user-genders [type=radio]:not(:checked) + label:before {
  border-color: #EC230D;
}

.mc-notify-message-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.mc-notify-message-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mc-notify-message-wrapper .error-message {
  background: #FEE2DF;
  color: #EC230D;
}
.mc-notify-message-wrapper .success-message {
  background: #D7F4ED;
  color: #0BB683;
}

.mc-two-fa {
  height: inherit;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, 0.01);
}
.mc-two-fa .mc-card {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.mc-two-fa .mc-card .mc-card-body {
  position: relative;
}
.mc-two-fa .mc-card .mc-icon-section .mc-mobile-icon, .mc-two-fa .mc-card .mc-icon-section .mc-ios-icon, .mc-two-fa .mc-card .mc-icon-section .mc-android-icon {
  height: 100px;
  padding: 7px;
}
.mc-two-fa .mc-card .mc-qr-code {
  display: block;
  width: fit-content;
  margin: 30px auto;
}
.mc-two-fa .mc-card .mc-qr-code-form {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75px;
}
@media only screen and (max-width: 768px) {
  .mc-two-fa {
    height: auto;
    padding: 30px 0;
  }
}

.mc-seepassword-btn {
  position: absolute;
  bottom: 0;
  color: rgba(0, 0, 0, 0.16);
  right: 0;
  border: none;
  background: transparent;
  height: calc(1.5em + 0.75rem + 5px);
  width: 12%;
}
.mc-seepassword-btn:focus {
  outline: none;
}

@media only screen and (max-width: 375px) {
  .mc-seepassword-btn {
    width: 17%;
  }
}
.mc-forgot-password {
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, 0.01);
}
.mc-forgot-password .mc-card {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.mc-forgot-password .mc-card .mc-card-header {
  padding: 40px 20px 0;
}
.mc-forgot-password .mc-card .mc-card-body {
  position: relative;
}
.mc-forgot-password .mc-card .mc-submit-button {
  background-color: #35B1F1;
}
.mc-forgot-password .mc-card .mc-error {
  height: fit-content;
  margin-bottom: 50px;
}
@media only screen and (max-width: 1024px) {
  .mc-forgot-password .mc-card .mc-error {
    margin-bottom: 70px;
  }
}

.mc-auth-locked-wrapper {
  height: inherit;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, 0.01);
}
.mc-auth-locked-wrapper .mc-card {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.mc-auth-locked-wrapper .mc-card .mc-card-header {
  padding: 50px 30px 0;
}
.mc-auth-locked-wrapper .mc-card .mc-card-header .mc-go-back {
  position: absolute;
  top: 5px;
  left: 8px;
  font-size: 14px;
  color: #35B1F1;
  cursor: pointer;
}
.mc-auth-locked-wrapper .mc-card .mc-card-body {
  position: relative;
}
.mc-select-entity-modal {
  z-index: 1200;
}
.mc-select-entity-modal .modal-dialog {
  min-width: 1200px;
}
.mc-select-entity-modal .modal-dialog .modal-content {
  min-height: 800px;
}
.mc-select-entity-modal .modal-dialog .modal-body {
  background-color: #f0f8ff;
}

.mc-select-entity-wrapper .mc-search-entity-wrapper {
  position: relative;
}
.mc-select-entity-wrapper .mc-search-entity-wrapper .mc-search-entity-icon {
  position: absolute;
  top: 10px;
  right: 30px;
  color: #adb5bd;
}
.mc-select-entity-wrapper .mc-letter-filter .mc-latter-item {
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
}
.mc-select-entity-wrapper .mc-letter-filter .selected {
  color: #007bff;
  background-color: #e8ecef;
}
.mc-select-entity-wrapper .mc-entities-tree-wrapper .mc-entity-list-wrapper {
  display: inline-block;
}
.mc-select-entity-wrapper .mc-entities-tree-wrapper .mc-root-entity-name {
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 5px;
}
.mc-select-entity-wrapper .mc-entities-tree-wrapper .mc-selected-entity {
  width: fit-content;
  background-color: #35B1F1;
  color: #fff;
}
.mc-select-entity-wrapper .mc-entities-tree-wrapper .mc-arrow-icon {
  padding-right: 5px;
}
.mc-select-entity-wrapper .mc-entities-tree-wrapper .mc-entity-name {
  display: inline-block;
  padding: 0px 10px 0px 10px;
}
.mc-select-entity-wrapper .mc-proceed-button {
  position: relative;
  float: right;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 5px 30px;
  color: #35B1F1;
  background-color: #fff;
}
/*Dashboard wrapper*/
.mc-dashboard-wrapper {
  padding: 0 40px;
}
.mc-dashboard-wrapper h1 {
  font-size: 32px;
  color: #111245;
}
.mc-dashboard-wrapper .mc-dashboard-separator-wrapper {
  position: relative;
  height: 1px;
  width: 100%;
}
.mc-dashboard-wrapper .mc-dashboard-separator-wrapper .mc-dashboard-separator {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 2px;
  background-image: linear-gradient(to right, rgba(51, 0, 51, 0.0666666667), rgba(48, 49, 51, 0.4117647059), rgba(51, 0, 51, 0.0666666667));
}
.mc-dashboard-wrapper .mc-dashboard-welcome-box {
  width: 100%;
  border: 1px solid #EEF4FC;
  box-shadow: rgba(0, 0, 0, 0.0901960784) 0 3px 20px;
  height: 98px;
  background: #FFFFFF;
  margin-top: 12px;
  border-radius: 6px;
}
.mc-dashboard-wrapper .mc-dashboard-welcome-box .mc-dashboard-info-icon {
  align-self: center;
  margin-left: 15px;
}
.mc-dashboard-wrapper .mc-dashboard-welcome-box .mc-dashboard-welcome-message {
  align-self: center;
  margin: 15px 0 0 15px;
}
.mc-dashboard-wrapper .mc-dashboard-welcome-box .mc-dashboard-welcome-message h3 {
  color: #2C3A45;
  font-size: 22px;
}
.mc-dashboard-wrapper .mc-dashboard-welcome-box .mc-dashboard-welcome-message p {
  font-size: 16px;
  color: #646262;
}
.mc-dashboard-wrapper .mc-dashboard-card-col-wrapper {
  padding: 0;
  width: 100%;
}
.mc-dashboard-wrapper .mc-dashboard-card-col-wrapper .mc-dashboard-card-heading {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
  text-transform: capitalize;
}
.mc-dashboard-wrapper .mc-dashboard-card-col-wrapper .mc-dashboard-card-row {
  justify-content: space-between;
}
.mc-dashboard-wrapper .mc-dashboard-card-col-wrapper .mc-dashboard-card-empty {
  max-width: 30.5%;
  height: 120px;
  width: 350px;
}

/*todo: need refactoring, cannot write scss code for sidebar in dashboard scss file (this is current solution until we done refactoring)*/
.mc-dashboard {
  height: 100%;
  /*mc-sidebar-collapsed*/
  /*mc-content-width*/
  /*MC content container*/
}
.mc-dashboard.mc-dashboard-gray {
  background: #F8F8F8;
}
.mc-dashboard .mc-sidebar-wrapper.mc-sidebar-collapsed {
  width: 44px;
  display: inline-block;
  padding-left: 15px;
}
.mc-dashboard .mc-content-width {
  width: 100%;
  display: inline-block;
  margin-top: 50px;
  padding-left: 125px;
  padding-right: 40px;
}
.mc-dashboard .mc-sidebar-wrapper {
  width: 260px;
  flex: 1;
  max-width: 260px;
  margin-top: 60px;
  z-index: 60;
  /*Collapse button*/
}
.mc-dashboard .mc-sidebar-wrapper .mc-sidebar-first-child {
  padding-top: 65px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  max-width: inherit;
  z-index: 9;
  height: 100vh;
}
.mc-dashboard .mc-sidebar-wrapper .mc-sidebar-collapse-button-wrapper {
  position: fixed;
  display: inline-block;
  top: 55px;
  z-index: 99;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  /*Entity name*/
}
.mc-dashboard .mc-sidebar-wrapper .mc-sidebar-collapse-button-wrapper .mc-entity-name {
  vertical-align: middle;
  color: #92d5f5;
  cursor: auto;
}
.mc-dashboard .mc-content-container {
  padding-top: 75px !important;
  max-width: 100%;
  width: 100%;
  flex: 2;
  margin-top: 60px;
  padding-right: 40px;
  padding-left: 40px;
}
.mc-dashboard .mc-sidebar-header {
  padding: 15px 15px 20px 20px;
  border-bottom: 1px solid #555f68;
}
.mc-dashboard .mc-sidebar-header img {
  box-sizing: content-box;
  margin-bottom: 40px;
  height: 30px;
}
.mc-dashboard .mc-sidebar-header .mc-sidebar-header-title {
  color: #35b1ec;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1;
}
.mc-dashboard .mc-sidebar {
  /*Vertical scrollbar*/
}
.mc-dashboard .mc-sidebar .mc-scrollbar-vertical::-webkit-scrollbar {
  width: 0;
  background-color: #f7f7f7;
}
.mc-dashboard .mc-sidebar .mc-scrollbar-vertical::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #555f68;
}
.mc-dashboard .mc-sidebar .mc-scrollbar-vertical::-webkit-scrollbar-thumb:hover {
  background-color: #B6B8BA;
  cursor: pointer;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body {
  padding: 5px 15px 20px 0;
  height: calc(100vh - 65px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  z-index: 1;
  /*todo: do not use element selector, only class*/
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list {
  list-style: none;
  padding: 0;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item {
  margin-bottom: 5px;
  border-radius: 0px 2px 2px 0px;
  background: #fff;
  width: 260px;
  /*Sidebar icon*/
  /*Mc sidebar link*/
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item_collapsed {
  width: 50px;
  height: 46px;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-icon {
  display: inline-block;
  font-size: 14px;
  position: relative;
  z-index: 9;
  padding: 15px 8px 15px 15px;
  color: #3B457C;
  cursor: pointer;
  width: 30px;
  height: auto;
  margin-right: 6px;
  outline: none;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-icon.active {
  color: #e1eaef;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-link,
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-link-no-subitems {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 10px 25px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3B457C;
  min-height: 40px;
  margin-top: 5px;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-link.active {
  color: #fff;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-link-subitem {
  padding: 10px 20px 10px 25px;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .link-with-icon {
  display: flex;
  justify-content: space-between;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .dropdown:hover .mc-sidebar-icon.mc-submenu,
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .dropdown:hover .mc-menu-arrow-toggle,
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .dropdown:hover .mc-sidebar-link.mc-submenu {
  color: #3B457C;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-links:focus {
  background: linear-gradient(267.71deg, #60C1C7 0%, #4E358C 100%) !important;
}
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-links:focus .mc-sidebar-link,
.mc-dashboard .mc-sidebar .mc-sidebar-body .sidebar-list .sidebar-list-item .mc-sidebar-links:focus .mc-sidebar-icon {
  color: #fff;
}
.mc-dashboard .sidebar-collapsed-page-content .mcc-slide-action-set-collapsed {
  left: 65px;
}

.mc-header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: inherit;
  z-index: 999;
  padding-top: 10px;
  /*TODO: discuss about logo position width and height! */
  /*Logo wrapper*/
}
.mc-header .mc-header-logo {
  width: auto;
  max-width: 200px;
  height: auto;
  margin-top: 3px;
  outline: none;
}
.mc-header div.mc-header-logo-wrapper {
  flex: 1;
  height: auto;
  width: fit-content;
  background: #fff;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 0px 4px 4px 0px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  margin-left: -15px;
}
.mc-header .mc-header-collapse-icon {
  min-width: 60px;
  justify-content: center;
  height: 40px;
  display: flex;
  border-right: 1px solid rgba(59, 69, 124, 0.38);
  cursor: pointer;
  align-items: center;
}
.mc-header .logo-icon {
  cursor: pointer;
}
.mc-header .mc-header-content {
  display: flex;
  justify-content: flex-end;
  flex: 2;
  padding: 0;
}
.mc-header .mc-header-user-section {
  display: flex;
  gap: 10px;
  padding-left: 15px;
}
.mc-header .mc-header-user-section img {
  width: 30px;
  height: 30px;
}
.mc-header .mc-header-user-section_name {
  font-weight: 500;
  font-size: 16px;
  color: #3B457C;
}
.mc-header .mc-row-no-margin {
  display: flex;
  justify-content: space-between;
  line-height: 32px !important;
}
.mc-header .mc-arrow {
  margin-left: 0;
  padding-top: 4px;
  width: 20px;
  height: 20px;
}
.mc-header .center-content {
  display: flex;
  align-items: center;
  height: 35px;
}
.mc-header .center-content .mc-header-path {
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(59, 69, 124, 0.38);
}
.mc-header .center-content .mc-header-path .mc-entity-toggle-btn {
  background: transparent;
  border: none;
  line-height: normal;
}
.mc-header .center-content .mc-header-path .mc-entity-toggle-btn:focus {
  outline: none;
}
.mc-header .center-content .mc-header-path span {
  font-weight: 600;
  font-size: 16px;
  color: #3B457C;
  margin-right: 5px;
}
.mc-header .right-content {
  display: flex;
  justify-content: flex-end;
}
.mc-header .right-content .mc-header-actions {
  color: #8d969d;
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  background: #fff;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 50px;
  padding: 0 !important;
}
.mc-header .right-content .mc-header-actions .mc-header-right-action {
  position: relative;
  padding: 0 16px;
  line-height: 2;
  border-right: 1px solid rgba(59, 69, 124, 0.38);
  height: 35px;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
}
.mc-header .right-content .mc-header-actions .mc-header-right-action:last-child {
  border: none;
}
.mc-header .right-content .mc-header-actions .mc-header-right-action .software-version {
  margin-left: 5px;
}
.mc-header .right-content .mc-header-actions li.mc-header-notifications {
  cursor: pointer;
}
.mc-header .right-content .mc-header-actions li.mc-header-notifications .far {
  font-size: 22px;
  padding-right: 5px;
}
.mc-header .right-content .mc-header-actions li.mc-header-notifications .mc-notification-badge-orange {
  background: #f97f3b;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  font-size: 8px;
  line-height: 1.1;
  padding: 4px 5px 2px;
  position: absolute;
  top: 12px;
  right: 12px;
}
.mc-header .right-content .mc-header-actions .help-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mc-header .right-content .mc-header-actions .help-link i {
  color: #3B457C;
  font-size: 20px;
}
.mc-header .right-content .mc-header-lang > div {
  position: static;
  width: 100%;
}
.mc-header .right-content .mc-dropdown-toggle {
  cursor: pointer;
  padding: 1px 2.5px;
  position: static;
  -webkit-appearance: none;
  outline: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3B457C;
  background: #fff;
}
.mc-header .right-content .mc-dropdown-toggle::after {
  display: none;
}
.mc-header .right-content .mc-dropdown-toggle svg {
  margin-right: -5px;
}
.mc-header .right-content .mc-dropdown-toggle svg path {
  fill: #3B457C;
}
.mc-header .right-content #languageDropdown {
  background: #fff;
  align-items: center;
  display: flex;
}
.mc-header .right-content .mc-dropdown-menu {
  background: #fff;
  border-radius: 0;
  min-width: auto;
  padding: 0;
  transform: translate(0, 0) !important;
  top: 100% !important;
}
.mc-header .right-content .mc-dropdown-menu a {
  color: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.mc-header .right-content .mc-dropdown-menu a:hover {
  color: #32424f;
}
.mc-header .right-content .mc-dropdown-menu a:active {
  background: #f8f9fa;
}
.mc-header .right-content .mc-dropdown-language {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  top: 100%;
  left: 0;
  z-index: 1000;
  margin: 0.125rem 0;
  font-size: 1rem;
  text-align: left;
}
.mc-header .right-content .mc-dropdown-language .language-link {
  padding: 5px 10px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  color: #8d969d;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  margin-right: 50px;
}
.mc-header .right-content .mc-dropdown-language .language-link:active, .mc-header .right-content .mc-dropdown-language .language-link:hover {
  color: #32424f;
  background: #ebeff1;
}
.mc-header .right-content .mc-dropdown-language .language-link:hover .mc-dropdown-toggle-small {
  background: #f8f9fa;
}
.mc-header .right-content .mc-dropdown-language .language-link .mc-dropdown-toggle-small {
  background: #ccdfe8;
  border: none;
  border-radius: 50%;
  color: #32424f;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 1px 2.3px;
  position: static;
  -webkit-appearance: none;
  outline: 0;
  margin-top: 2px;
}
.mc-header .right-content .mc-dropdown-language .language-link .toggle-it {
  padding: 1px 5px !important;
}
.mc-header .right-content .mc-header-profile {
  padding: 0 !important;
  max-width: 230px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mc-header .right-content .mc-header-profile > div {
  display: flex;
  align-items: center;
  position: static;
  width: 100%;
}
.mc-header .right-content .mc-header-profile img {
  width: 20px;
  height: 20px;
  margin: 0 12px;
}
.mc-header .right-content .mc-header-profile .mc-header-profile-name {
  padding: 10px 30px 10px 0;
  position: relative;
  cursor: pointer;
  line-height: 1;
  white-space: normal;
  word-break: break-word;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #3B457C;
}
.mc-header .right-content .mc-header-profile .mc-header-profile-name::after {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.mc-header .right-content .mc-header-profile .mc-header-profile-name:hover {
  color: #e1eaef;
}
.mc-header .right-content .mc-header-profile .mc-dropdown-profile {
  width: fit-content;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.15);
  left: -50px !important;
  z-index: 1000;
  margin: 0.125rem 0;
  font-size: 1rem;
  text-align: left;
}
.mc-header .right-content .mc-header-profile .mc-dropdown-profile .profile-link {
  color: #8d969d;
  font-size: 15px;
}
.mc-header .right-content .mc-header-profile .mc-dropdown-profile .profile-link:active, .mc-header .right-content .mc-header-profile .mc-dropdown-profile .profile-link:hover {
  color: #f8f9fa;
  background: none !important;
}
.mc-header .right-content .mc-header-logout {
  padding-right: 20px;
}
.mc-header .right-content .mc-header-logout a {
  cursor: pointer;
  color: #8d969d;
  display: flex;
  align-items: center;
}
.mc-header .right-content .mc-header-logout a:hover {
  color: #e1eaef;
}
.mc-header .right-content .mc-header-logout .mc-header-logout-icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*Filter wrapper*/
.mc-filter {
  background: #e8ecef;
  padding: 30px 20px 10px 30px;
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  position: relative;
}
.mc-filter > * {
  margin-right: 20px;
  margin-bottom: 10px;
}
.mc-filter .fill-checkbox {
  margin-bottom: 0 !important;
}
.mc-filter .mc-login-input {
  padding: 14px 0;
  margin-bottom: 0 !important;
}
.mc-filter .mc-login-input .form-control {
  border-radius: 0;
}
.mc-filter .label-wrapper {
  display: flex;
  align-items: center;
}
.mc-filter .label-wrapper .custom-control {
  display: flex;
}
.mc-filter .label-wrapper .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-filter .label-wrapper .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-filter .label-wrapper .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-filter .label-wrapper .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #f8f9fa;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-filter .label-wrapper .custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
}
.mc-filter .select-wrapper .form-control {
  border-radius: 0;
}
.mc-filter .mc-filter-button {
  background: #35B1F1;
  margin: 25px 0 20px auto;
  height: 41px;
}
.mc-filter .mc-filter-clear-button {
  font-size: 0.8rem;
}
.mc-filter .filter-clear-top-right {
  position: absolute;
  top: 10px;
  right: 0;
}
.mc-filter .mc-search-top .filter-types-wrapper .filter-label {
  font-weight: 500;
  font-size: 15px;
}
.mc-filter .mc-search-top .filter-types-wrapper .filter-background {
  background: #526a7d;
  color: #f8f9fa;
  padding: 2px 15px;
  font-size: 15px;
}
.mc-filter .mc-search-top .filter-types-wrapper .filter-background .filter-name {
  text-transform: capitalize !important;
}
.mc-filter .mc-search-top .filter-types-wrapper .filter-background .remove-filter {
  background: #EC230D;
  color: #f8f9fa;
  font-size: 13px;
  font-weight: 500;
  padding: 0 3px;
}
.mc-filter .mc-search-top .filter-types-wrapper .filter-background .remove-filter:hover {
  background: #f8f9fa;
  color: #EC230D;
}

.filter-container {
  display: flex;
  position: relative;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 0px 0px 4px 4px;
}
.filter-container .filter-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  width: 100%;
}
.filter-container .filter-inputs .mcc-fi-textbox input, .filter-container .filter-inputs .mcc-fi-select select {
  width: 100%;
  border: 1px solid rgba(80, 105, 126, 0.6);
}
.filter-container .filter-inputs .close-ms {
  margin-top: 0.625rem;
}
.filter-container .filter-inputs .mcc-multiselect {
  margin-top: 0px;
}
.filter-container .filter-inputs .mcc-multiselect-item-name {
  border: none;
  background-color: transparent;
  color: #32424f;
}
.filter-container .filter-inputs label {
  position: absolute;
  color: rgba(50, 66, 79, 0.6);
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  left: 13px;
  top: -7px;
  padding: 0px 3px;
  text-transform: capitalize;
}
.filter-container .filter-inputs input {
  border: 1px solid rgba(80, 105, 126, 0.6);
}
.filter-container .filter-inputs input::placeholder {
  color: #32424f;
}
.filter-container .filter-inputs input::placeholder {
  font-weight: 300;
  opacity: 0.5;
}
.filter-container .filter-actions {
  position: relative;
  margin-left: 1.875rem;
}
.filter-container .filter-actions .clear-all {
  position: absolute;
  padding: 0px;
  right: 0;
  top: -30px;
  font-size: 14px;
  font-weight: 500;
  color: #35b1ec !important;
  border: none;
  outline: none;
  background-color: #ffffff !important;
  transition: all 200ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}
.filter-container .filter-actions .clear-all .mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before {
  opacity: 0 !important;
}
.filter-container .filter-actions .filter-button {
  margin-top: 3px;
  background: #35B1F1 !important;
  border-radius: 4px;
  color: #ffffff !important;
  min-width: 100px;
  height: 36px;
  transition: all 200ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}
.filter-container .filter-actions .filter-button:hover {
  background-color: #149ada !important;
}
.filter-container .date-field {
  margin-top: 0 !important;
}
.filter-container .date-field .mcc-fi-date-picker {
  width: 100%;
}
.filter-container .date-field .mcc-fi-date-picker .date-range-input {
  padding-left: 15px !important;
}
.filter-container .date-field .form-group, .filter-container .date-field .input-group, .filter-container .date-field .input-group input {
  width: 100%;
}
.filter-container .date-field .form-group {
  position: relative;
}
.filter-container .date-field .form-group i {
  position: absolute;
  display: flex;
  flex-basis: inherit;
  justify-content: flex-end;
  margin-right: 1.25rem;
  font-size: 18px;
  color: #32424f;
  right: 0;
  top: 12px;
  z-index: 10;
  pointer-events: none;
}
.filter-container .date-range-input {
  height: 42px;
}
.filter-container .mc-range-date-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -7px !important;
  left: 29px !important;
}

.mc-management-area {
  position: relative;
  /*Error div*/
  /*Popup wrapper*/
  /*Show popup*/
}
.mc-management-area .mc-management-table {
  padding-bottom: 60px;
}
.mc-management-area .mc-error {
  position: relative;
  height: fit-content;
  margin-bottom: 30px;
  top: -4px;
}
.mc-management-area .mc-popup-wrapper {
  position: fixed;
  height: 80px;
  bottom: -110px;
  color: #fff;
  background: #35B1F1;
  transition: bottom 0.2s ease-in-out;
  -webkit-box-shadow: 0px -10px 51px -15px rgba(0, 0, 0, 0.68);
  -moz-box-shadow: 0px -10px 51px -15px rgba(0, 0, 0, 0.68);
  box-shadow: 0px -10px 51px -15px rgba(0, 0, 0, 0.68);
  /*Wrapper for user popup buttons*/
  /*Popup button*/
}
.mc-management-area .mc-popup-wrapper .mc-user-popup-wrapper {
  width: fit-content;
  margin: 0 auto;
}
.mc-management-area .mc-popup-wrapper .mc-user-popup-wrapper .mc-border-left {
  border-left: 1px solid #fff;
}
.mc-management-area .mc-popup-wrapper .mc-user-popup-wrapper .mc-border-right {
  border-right: 1px solid #fff;
}
.mc-management-area .mc-popup-wrapper .mc-user-popup-wrapper .mc-border-left-right {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.mc-management-area .mc-popup-wrapper .mc-popup-button {
  display: inline-block;
  width: fit-content;
  height: -webkit-fill-available;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}
.mc-management-area .mc-popup-wrapper .mc-popup-button i {
  font-size: 25px;
}
.mc-management-area .mc-popup-wrapper .mc-popup-button:hover {
  opacity: 0.8;
  transition: 0.3s;
}
.mc-management-area .mc-popup-wrapper-show {
  bottom: 0;
  transition: bottom 0.5s ease-in-out;
}

.mc-create-new-user-modal .mc-user-first-name .mc-new-user-field-names::after,
.mc-create-new-user-modal .mc-user-last-name .mc-new-user-field-names::after,
.mc-create-new-user-modal .mc-user-gender .mc-new-user-field-names::after, .mc-create-new-user-modal .mc-user-email > label::after, .mc-mandatory-star::after, .mc-incomplete-profile-card .mc-user-first-name .mc-new-user-field-names::after,
.mc-incomplete-profile-card .mc-user-last-name .mc-new-user-field-names::after,
.mc-incomplete-profile-card .mc-user-gender .mc-new-user-field-names::after {
  content: "*";
  color: #EC230D;
  padding-left: 3px;
}

.mc-create-new-user-modal .mc-new-user-entities-list, .mc-create-new-user-modal .mc-new-user-entity-name, .mc-create-new-user-modal .mc-user-roles-item .mc-role-entity {
  border-bottom: 1px solid #9ca0a3;
}

.mc-management-area a.mc-create-new-user-btn {
  color: #35b1ec !important;
  cursor: pointer;
  font-weight: 500;
}
.mc-management-area a.mc-create-new-user-btn:hover {
  color: #149ada !important;
}
.mc-management-area a.create-template-role-disabled-button {
  color: rgba(204, 204, 204, 0.5607843137) !important;
}
.mc-management-area a.create-template-role-disabled-button:hover {
  color: rgba(204, 204, 204, 0.5607843137) !important;
}

.mc-create-new-user-modal .modal-dialog {
  max-width: 1200px;
}
.mc-create-new-user-modal .mc-active {
  color: #35B1F1;
  border-bottom: 3px solid #35B1F1;
}
.mc-create-new-user-modal .mc-active-background {
  background: #35B1F1;
}
.mc-create-new-user-modal .mc-semi-active {
  color: #7dcdf6;
}
.mc-create-new-user-modal .mc-semi-active-background {
  background-color: #7dcdf6;
}
.mc-create-new-user-modal .mc-error-field {
  border-color: #EC230D;
}
.mc-create-new-user-modal .mc-error-field:focus {
  border-color: #EC230D;
  box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, 0.25);
}
.mc-create-new-user-modal .modal-content {
  border-radius: 0;
  padding: 10px 50px 50px 50px;
  position: relative;
}
.mc-create-new-user-modal .mc-create-user-modal-header {
  display: block;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 0;
}
.mc-create-new-user-modal .mc-create-user-modal-header .mc-create-user-entity-path {
  color: #35b1ec;
  font-size: 20px;
  font-weight: 500;
}
.mc-create-new-user-modal .mc-create-user-modal-header .mc-create-user-entity-path span::after {
  content: "\f105";
  color: #8d969d;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
}
.mc-create-new-user-modal .mc-create-user-modal-header .mc-create-user-entity-path span:last-child::after {
  content: "";
}
.mc-create-new-user-modal .modal-body {
  background: #f7f7f7;
  border-top: 3px solid #9ca0a3;
}
.mc-create-new-user-modal ul.nav.nav-tabs {
  justify-content: center !important;
  border-bottom: none;
}
.mc-create-new-user-modal li.nav-item {
  padding: 0 10px;
}
.mc-create-new-user-modal li.nav-item > a.nav-link {
  background: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.16);
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.mc-create-new-user-modal li.nav-item > a.nav-link.active {
  border-bottom: 3px solid #35B1F1;
  color: #35B1F1;
}
.mc-create-new-user-modal li.nav-item > a.nav-link.active .mc-notification-badge-gray {
  background: #35B1F1;
}
.mc-create-new-user-modal .mc-add-user-information {
  display: inline-block;
}
.mc-create-new-user-modal .mc-create-user-genders::before {
  border-color: #EC230D;
}
.mc-create-new-user-modal .mc-user-information {
  display: none;
}
.mc-create-new-user-modal .mc-show-user-info {
  display: flex;
}
.mc-create-new-user-modal .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-create-new-user-modal .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-create-new-user-modal .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-create-new-user-modal .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-create-new-user-modal .custom-control.fill-checkbox .fill-control-description {
  font-size: 11px;
}
.mc-create-new-user-modal .mc-create-new-user-entity-name {
  color: #35b1ec;
  font-size: 14px;
  font-weight: 500;
  padding-right: 5px;
  text-transform: uppercase;
}
.mc-create-new-user-modal .mc-create-user-parent-form {
  padding-top: 30px;
}
.mc-create-new-user-modal .mc-user-general-data-wrapper {
  height: 360px;
}
.mc-create-new-user-modal .mc-create-user-notify-wrapper {
  position: relative;
}
.mc-create-new-user-modal .mc-user-first-name .mc-new-user-field-names.mc-no-mandatory::after,
.mc-create-new-user-modal .mc-user-last-name .mc-new-user-field-names.mc-no-mandatory::after,
.mc-create-new-user-modal .mc-user-gender .mc-new-user-field-names.mc-no-mandatory::after {
  content: "";
}
.mc-create-new-user-modal .mc-new-user-field-names {
  font-size: 14px;
  font-weight: 500;
}
.mc-create-new-user-modal .mc-user-gender {
  padding: 0;
}
.mc-create-new-user-modal .mc-user-gender label {
  margin-right: 10px;
  font-size: 14px;
}
.mc-create-new-user-modal .mc-user-gender > div {
  margin-bottom: 10px;
}
.mc-create-new-user-modal .mc-new-user-image-area input[type=file] {
  display: none;
}
.mc-create-new-user-modal .mc-new-user-image {
  margin-top: 10px;
  min-width: 100%;
  height: 210px;
  cursor: pointer;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.mc-create-new-user-modal .mc-upload-image-btn:not([href]) {
  display: inline-block;
  font-size: 12px;
  color: #35b1ec;
  cursor: pointer;
  padding: 5px 0 5px 5px;
  float: right;
}
.mc-create-new-user-modal .mc-upload-image-btn:not([href]):hover {
  color: #149ada;
}
.mc-create-new-user-modal .mc-new-user-search {
  position: relative;
  display: flex;
  align-items: center;
}
.mc-create-new-user-modal .mc-new-user-search-icon {
  color: #e1eaef;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.mc-create-new-user-modal .mc-user-roles-area .mc-roles-entity {
  font-size: 20px;
  font-weight: 500;
  padding-right: 10px;
}
.mc-create-new-user-modal .mc-user-roles-area .mc-role-is-selected {
  background-color: #d2e4f0;
}
.mc-create-new-user-modal .mc-user-roles-area .mc-roles-entity-selected {
  font-size: 20px;
  padding-right: 5px;
}
.mc-create-new-user-modal .mc-user-roles-area .mc-notification-badge-gray {
  background-color: #35b1ec;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.mc-create-new-user-modal .mc-user-roles-area .mc-user-roles-list {
  height: 330px;
  overflow-y: auto;
}
.mc-create-new-user-modal .mc-user-roles-area .mc-user-roles-list .fill-control-indicator {
  background-color: #f8f9fa;
}
.mc-create-new-user-modal .mc-user-roles-area .mc-user-roles-list i {
  color: #35b1ec;
  float: right;
  line-height: inherit;
}
.mc-create-new-user-modal .mc-user-roles-item {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  column-gap: 7%;
}
.mc-create-new-user-modal .mc-user-roles-item .mc-role-entity {
  display: block;
  padding: 10px 5px;
  break-inside: avoid-column;
}
.mc-create-new-user-modal .mc-user-roles-item label {
  display: inline-block;
}
.mc-create-new-user-modal .mc-user-roles-item i {
  color: #35b1ec;
}
.mc-create-new-user-modal .mc-show-selected-only {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mc-create-new-user-modal .mc-show-selected-only .fill-control-indicator {
  background-color: #f8f9fa;
}
.mc-create-new-user-modal .mc-user-roles-content {
  background-color: #eeedf2;
}
.mc-create-new-user-modal .mc-new-user-entity-name {
  font-size: 20px;
  font-weight: 500;
  padding-left: 5px;
}
.mc-create-new-user-modal .mc-new-user-entity-name span::before {
  font-family: Font Awesome\ 5 Free;
  display: inline-block;
  padding-right: 15px;
  content: "\f078";
  font-size: 14px;
  font-weight: 700;
  color: #35b1ec;
  cursor: pointer;
}
.mc-create-new-user-modal .mc-new-user-entities-list .mc-notification-badge-gray {
  background: #35B1F1;
  padding: 2px 5px;
}
.mc-create-new-user-modal .mc-user-summary-section {
  border-right: 1px solid #9ca0a3;
}
.mc-create-new-user-modal .mc-user-summary-section .mc-new-user-image {
  cursor: default !important;
}
.mc-create-new-user-modal .mc-user-summary-title {
  color: #35B1F1;
}
.mc-create-new-user-modal .mc-user-summary-title .fa-pen {
  cursor: pointer;
}
.mc-create-new-user-modal .mc-user-summary-title h6 {
  display: inline-block;
}
.mc-create-new-user-modal .mc-user-summary-basic-info div:first-child {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.mc-create-new-user-modal .mc-user-summary-basic-info div:last-child {
  font-size: 16px;
}
.mc-create-new-user-modal .mc-user-summary-value {
  min-height: 20px;
  height: auto;
}
.mc-create-new-user-modal .mc-user-summary-role-info {
  font-size: 14px;
}
.mc-create-new-user-modal .mc-user-summary-role-info .mc-user-summary-role-name span:first-child {
  color: #35B1F1;
  padding-right: 5px;
  font-weight: 500;
  text-transform: uppercase;
}
.mc-create-new-user-modal .mc-user-summary-action-btn {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}
.mc-create-new-user-modal .step-navigation-bar div {
  padding: 0;
}
.mc-create-new-user-modal .mc-user-summary-send-invite {
  text-align: left;
  display: flex;
  align-items: center;
}
.mc-create-new-user-modal .mc-user-summary-send-invite label {
  display: inline-block;
  padding-right: 10px;
}
.mc-create-new-user-modal .mc-user-summary-send-invite i {
  font-size: 18px;
  color: #35B1F1;
}
.mc-create-new-user-modal .mc-user-summary-create-btn {
  text-align: center;
}
.mc-create-new-user-modal .mc-user-summary-createAdd-btn {
  text-align: right;
}
.mc-create-new-user-modal .mc-user-summary-create-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mc-create-new-user-modal .mc-user-summary-create-btns .mc-next-save-btn:first-child {
  background-color: #109be3;
}

.mc-create-user-parent-form section.container {
  position: relative;
}

.mc-edit-user .mc-edit-user-general-data {
  background: #f7f7f7;
  border-radius: 10px;
  padding: 20px 70px;
}
.mc-edit-user .mc-edit-user-general-data .mc-create-user-notify-wrapper {
  width: 100%;
}
.mc-edit-user .mc-edit-user-general-data-user-name {
  border-bottom: 1px solid #9ca0a3;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 10px;
  text-align: center;
}
.mc-edit-user .mc-edit-user-general-data-personal-info {
  padding: 40px 0 20px 0;
  border-bottom: 1px solid #b6b9bc;
}
.mc-edit-user .mc-edit-user-personal-info-link:not([href]) {
  font-size: 20px;
  color: #35B1F1;
  padding-bottom: 10px;
  position: relative;
  padding-right: 22px;
}
.mc-edit-user .mc-edit-user-personal-info-link:not([href]) .fa-pen {
  font-size: 16px;
  position: absolute;
  top: 3px;
  right: 0;
}
.mc-edit-user .mc-new-user-image {
  min-width: 100%;
  height: 210px;
  cursor: auto;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}
.mc-edit-user .mc-edit-user-preview-data {
  padding: 0;
}
.mc-edit-user .mc-edit-user-first-block {
  border-bottom: 1px solid #b6b9bc;
  margin: 0 0 0 30px;
}
.mc-edit-user .mc-edit-user-second-block {
  margin: 0 0 0 30px;
}
.mc-edit-user .mc-second-block-fields {
  padding-left: 0;
}
.mc-edit-user .mc-edit-user-preview-data {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.mc-edit-user .mc-edit-user-preview-data-field-name {
  color: #9ca0a3;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  flex-basis: 100px;
}
.mc-edit-user .mc-edit-user-preview-data-field-value {
  font-size: 16px;
  flex: 1;
}
.mc-edit-user .mc-edit-user-general-data-roles {
  padding-top: 30px;
}
.mc-edit-user .mc-edit-user-general-data-roles .mc-edit-user-personal-info-roles {
  border-bottom: 1px solid #b6b9bc;
}
.mc-edit-user .mc-create-new-user-entity-name {
  color: #35b1ec;
  font-size: 14px;
  font-weight: 500;
  padding-right: 5px;
  text-transform: uppercase;
}

.info-container-secondary {
  background-color: #F8f8f8;
  border-radius: 4px;
  border: 1px solid #F2F0F0;
  margin: 0 0 10px 0;
  padding: 20px;
}
.info-container-secondary span {
  text-transform: none;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 10px;
}

.info-cell {
  border-radius: 4px;
  border: 1px solid #F2F0F0;
  background-color: #FFF;
  min-height: 96px;
  padding: 20px;
  display: flex;
}
.info-cell .icon-holder {
  float: left;
}
.info-cell .icon-holder img {
  height: 40px;
  width: auto;
  margin: 0 10px 0 0;
}

.account-data {
  align-self: center;
}
.account-data .info-title {
  font-size: 1.2rem;
  font-weight: 500;
}

/*Table wrapper*/
.mc-table-wrapper {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  /*Horizontal scrolling*/
  /*Table body*/
  /*Edit row icon*/
  /*Table image*/
  /*Checkbox td*/
}
.mc-table-wrapper.mc-table-horizontal-scroll {
  overflow-x: auto;
}
.mc-table-wrapper .vertical-align-middle {
  vertical-align: middle;
}
.mc-table-wrapper .mc-table-total-numbers-wrapper {
  width: fit-content;
  font-size: 0.8rem;
  font-weight: 500;
  padding-bottom: 5px;
  color: #32424f;
}
.mc-table-wrapper .mc-table {
  margin: 0;
  margin-top: -1px;
  border-radius: 4px 4px 0px 0px;
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  /*Table header*/
}
.mc-table-wrapper .mc-table tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}
.mc-table-wrapper .mc-table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}
.mc-table-wrapper .mc-table .mc-table-head {
  color: black;
  /*Checkbox all*/
  /*Arrow wrapper*/
}
.mc-table-wrapper .mc-table .mc-table-head tr th {
  border-top: none;
  letter-spacing: 0.3px;
  border-top-left-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: #3B457C;
}
.mc-table-wrapper .mc-table .mc-table-head tr th:first-child {
  border-top-left-radius: 4px;
}
.mc-table-wrapper .mc-table .mc-table-head tr th:last-child {
  border-top-right-radius: 4px;
}
.mc-table-wrapper .mc-table .mc-table-head .custom-control {
  display: inline-block;
  height: 12px;
  margin-top: -20px;
  margin-left: -24px;
  padding: 0;
}
.mc-table-wrapper .mc-table .mc-table-head .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-table-wrapper .mc-table .mc-table-head .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-table-wrapper .mc-table .mc-table-head .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: #0c63bf;
  border-color: #0c63bf;
  background-size: 80%;
}
.mc-table-wrapper .mc-table .mc-table-head .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 18px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid black;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-table-wrapper .mc-table .mc-table-head .custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
}
.mc-table-wrapper .mc-table .mc-table-head .mc-arrow-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  position: relative;
  margin-left: 0.625rem;
}
.mc-table-wrapper .mc-table .mc-table-head .mc-arrow-wrapper .mc-arrow-up-icon, .mc-table-wrapper .mc-table .mc-table-head .mc-arrow-wrapper .mc-arrow-down-icon {
  display: block;
  cursor: pointer;
}
.mc-table-wrapper .mc-table-body {
  /*Text area*/
  /*Selected row*/
  /*table input field - invoice*/
  /*Cursor pointer for table*/
}
.mc-table-wrapper .mc-table-body td {
  vertical-align: middle;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3B457C;
}
.mc-table-wrapper .mc-table-body tr {
  height: 50px;
}
.mc-table-wrapper .mc-table-body tr:hover {
  background-color: #ebeff1 !important;
}
.mc-table-wrapper .mc-table-body tr .link-cell {
  display: flex;
  width: max-content;
}
.mc-table-wrapper .mc-table-body tr .tr-icon {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  height: 24px;
  width: 24px;
  margin-right: 0.625rem;
  color: #fff;
  background-color: #35b1ec;
  border-radius: 4px;
}
.mc-table-wrapper .mc-table-body .mc-table-textarea {
  display: block;
  position: relative;
  min-width: 300px;
  /*Apply button*/
}
.mc-table-wrapper .mc-table-body .mc-table-textarea .mc-table-textarea-apply-button {
  background: #fff;
  position: absolute;
  width: 20px;
  height: 15px;
  top: 3px;
  right: 5px;
  border-radius: 3px;
  opacity: 1;
  border: 1px solid #B6B8BA;
}
.mc-table-wrapper .mc-table-body .mc-table-textarea .mc-table-textarea-apply-button:focus {
  outline-style: none;
}
.mc-table-wrapper .mc-table-body .mc-table-textarea .mc-table-textarea-apply-button .mc-table-more-options {
  position: absolute;
  top: -11px;
  right: 4px;
  height: 10px;
  color: #000;
}
.mc-table-wrapper .mc-table-body .mc-table-textarea .dropdown-toggle::after {
  display: none;
}
.mc-table-wrapper .mc-table-body .mc-table-textarea .mc-table-options-dropdown {
  border: 1px solid #B6B8BA;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: fit-content;
  height: auto;
  z-index: 500;
  position: absolute;
  right: -27px;
  top: 20px;
  font-size: 0.8rem;
}
.mc-table-wrapper .mc-table-body .mc-table-textarea .mc-table-options-dropdown .mc-table-dropdown-item:hover {
  cursor: pointer;
  background-color: #f7f7f7;
  opacity: 0.6;
}
.mc-table-wrapper .mc-table-body .mc-table-textarea textarea {
  min-height: 35px;
}
.mc-table-wrapper .mc-table-body .mc-selected-row {
  background: #DFF1FB;
}
.mc-table-wrapper .mc-table-body .mc-table-category-name {
  font-style: italic;
}
.mc-table-wrapper .mc-table-body .mc-table-input-field {
  width: 110px;
}
.mc-table-wrapper .mc-table-body .mc-table-cursor-pointer {
  cursor: pointer;
}
.mc-table-wrapper .mc-table-body .mc-table-cursor-pointer:hover {
  color: #3a7897;
}
.mc-table-wrapper .mc-table-body .mc-table-cursor-pointer:hover > * {
  color: #3a7897;
}
.mc-table-wrapper .mc-table-edit-row {
  color: rgb(42, 175, 244);
  cursor: pointer;
}
.mc-table-wrapper .mc-table-image {
  width: 40px;
  border-radius: 50%;
}
.mc-table-wrapper .mc-checkbox-td {
  vertical-align: middle;
  width: 50px;
}
.mc-table-wrapper .mc-checkbox-td .custom-control {
  display: inline-block;
  height: 12px;
  margin-top: -20px;
  margin-left: -24px;
  padding: 0;
}
.mc-table-wrapper .mc-checkbox-td .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-table-wrapper .mc-checkbox-td .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-table-wrapper .mc-checkbox-td .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-table-wrapper .mc-checkbox-td .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 18px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid black;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-table-wrapper .mc-checkbox-td .custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
}
.mc-table-wrapper td {
  padding: 8px 12px;
  text-align: center;
}
.mc-table-wrapper .mc-readonly {
  background: gainsboro;
  cursor: not-allowed;
}

.mc-table-summary {
  background: #DFF1FB;
}
.mc-table-summary .mc-tr-black {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.mc-table-summary .mc-tr-silver {
  background-color: #d1d3d4;
  color: #000;
}

/*MC table footer*/
.mc-table-footer {
  height: 50px;
  color: #3B457C;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  margin: 0;
  border-top: 1px solid rgba(1, 21, 34, 0.2);
  align-items: center;
  justify-content: end;
  padding-right: 10px;
  /*Go to page*/
  /*Pagination page error message*/
}
.mc-table-footer .mat-mdc-paginator {
  color: #3B457C;
  font-size: 14px;
  border-top: none !important;
}
.mc-table-footer .number-per-page-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #3B457C;
  font-weight: 500;
  font-size: 14px;
  /*Number per page*/
}
.mc-table-footer .number-per-page-wrap .mc-number-per-page {
  width: 100px;
  height: 40px;
  border: 1px solid rgba(59, 69, 124, 0.38);
  border-radius: 4px;
  flex: none;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: rgba(59, 69, 124, 0.6);
}
.mc-table-footer .mc-go-to-page {
  width: 50px;
}
.mc-table-footer .mc-page-error-message {
  position: absolute;
  padding: 5px;
}

.mc-permissions-by-role-modal .modal-content {
  background: #F5F5F5;
  padding-bottom: 20px;
}
.mc-permissions-by-role-modal .modal-content .modal-title {
  margin: 0 auto;
}
.mc-permissions-by-role-modal .modal-content .mc-label-text {
  font-size: 14px;
  line-height: 25px;
}

/*Table settings button*/
.mc-table-settings-button {
  width: auto;
  padding: 3px 6px;
  border-radius: 5px;
}
.mc-table-settings-button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.mc-table-settings-button .mc-table-setting-icon {
  color: #35B1F1;
  font-size: 17px;
}

/*Import Consumers By CSV TAble*/
.mc-consumers-import-table {
  /*  overflow-y: scroll;
    height: 300px;*/
}
.mc-consumers-import-table td {
  vertical-align: middle;
}

.mc-my-billing-statement-items-table td:nth-of-type(8),
.mc-my-billing-statement-items-table td:nth-of-type(9),
.mc-my-billing-statement-items-table td:nth-of-type(10) {
  text-align: right !important;
}

.mc-transactions-table .icon-tooltip {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.mc-transactions-table .icon-tooltip .icon-tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
  border-radius: 4px;
  padding: 8px 4px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 70%;
  margin-left: -60px;
  font-size: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}
.mc-transactions-table .icon-tooltip .icon-tooltip-hidden {
  visibility: hidden;
  width: 150px;
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 3px 3px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 70%;
  margin-left: -60px;
  font-size: 12px;
}
.mc-transactions-table .icon-tooltip .icon-tooltip-text:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 35%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.mc-transactions-table .icon-tooltip:hover .icon-tooltip-text {
  visibility: visible;
}
.mc-transactions-table .icon-tooltip:hover .icon-tooltip-hidden {
  visibility: hidden;
}
.mc-transactions-table .mc-text-capitalize {
  text-transform: capitalize;
}
.mc-transactions-table td:last-child {
  width: 15%;
}
.mc-transactions-table img {
  padding-right: 5px;
  width: auto;
  height: 24px;
}
.mc-transactions-table img:first-child {
  width: auto;
  height: 30px !important;
}

.mc-pricelist-table td:nth-of-type(4) {
  width: 30%;
}
.mc-pricelist-table td:nth-of-type(5) {
  width: 20%;
}

.mc-table-head-item {
  font-weight: bold;
  text-align: left;
}
.mc-table-head-item .table-head-title {
  display: flex;
  align-items: center;
}

/*Table column width*/
.table-col-width-50 {
  width: 50px;
}

/*Background grey color*/
.import-consumers-grey-background {
  background-color: #dee2e6;
}

/*Table input text fields*/
.import-consumers-input-text-height {
  height: 30px;
}

@media only screen and (max-width: 1450px) {
  .mc-transactions-table td:last-child {
    width: 25%;
  }
}
@media only screen and (max-width: 1310px) {
  .mc-transactions-table td:last-child {
    width: 29%;
  }
}
@media only screen and (max-width: 1220px) {
  .mc-transactions-table td:last-child {
    width: 35%;
  }
}
[type=radio]:checked,
[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type=radio]:checked + label,
[type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type=radio]:checked + label:before,
[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type=radio]:checked + label:after,
[type=radio]:not(:checked) + label:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #35b1ec;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.mc-account-info .mc-account-info-title {
  color: #35b1ec;
  font-weight: 400;
}
.mc-account-info .mc-account-info-text {
  font-weight: 500;
}
.mc-account-info mc-two-fa-account-authentication-modal {
  color: #35b1ec;
}
.mc-account-info .fa-check, .mc-account-info .fa-pen {
  color: #35b1ec;
  font-size: small;
}
.mc-account-info .fa-times {
  color: #EC230D;
  font-size: small;
}
.mc-account-info mc-change-password-modal {
  color: #35b1ec;
  text-decoration: underline;
}
.mc-account-info .mc-notify-wrapper {
  padding-top: 20px;
}

.mc-account-info-authentication-modal {
  text-align: center;
}
.mc-account-info-authentication-modal .modal-content {
  border-radius: 0;
}
.mc-account-info-authentication-modal .modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.mc-account-info-authentication-modal .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-account-info-authentication-modal .modal-content .modal-body {
  padding: 0 9% 3% 9%;
}
.mc-account-info-authentication-modal .modal-content .modal-body .modal-title {
  margin-bottom: 5%;
  border-bottom: 3px solid #B6B8BA;
}
.mc-account-info-authentication-modal .modal-content .modal-body .mc-notify-wrapper {
  padding-top: 20px;
}
.mc-account-info-authentication-modal .modal-content .modal-footer {
  border-top: none;
  padding: 5%;
}
.mc-account-info-authentication-modal .modal-content .modal-footer .modal-save {
  background-color: #35b1ec;
  color: #fff;
  margin: 0 auto;
  padding: 4px 25px;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}

.mc-change-password-modal {
  text-align: center;
}
.mc-change-password-modal .mc-change-password-area {
  padding: 0;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58, 120, 151, 0.65);
}
.mc-change-password-modal .mc-change-password-area .mc-notify-message-wrapper {
  margin-top: 190px;
}
.mc-change-password-modal .mc-change-password-area .mc-btn-group {
  display: flex;
  justify-content: space-between;
}
.mc-change-password-modal .mc-change-password-area .mc-btn-group .mc-cancel-btn {
  width: 25%;
  height: 40%;
  margin-top: 47px;
  background-color: #BDBFCB;
  color: #fff;
  border: 1px solid #BDBFCB;
  border-radius: 20px;
}
.mc-change-password-modal .mc-change-password-area .mc-btn-group .mc-confirm-btn {
  width: 50%;
  height: 50%;
  margin-top: 40px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: #35B1F1;
  border: 1px solid #35B1F1;
  color: #fff;
}
.mc-change-password-modal .mc-change-password-area .mc-btn-group .mc-disable-btn {
  width: 50%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: #fff;
}
.mc-change-password-modal .mc-change-password-area .mc-login-header {
  background-color: #e8ecef;
  padding: 40px;
}
.mc-change-password-modal .mc-change-password-area .mc-login-header img {
  height: 45px;
  margin-top: 12px;
}
.mc-change-password-modal .mc-change-password-area .mc-login-header .mc-card-title {
  margin-top: 15px;
}
.mc-change-password-modal .mc-change-password-area .mc-login-card-body {
  margin-top: 50px;
  padding: 40px;
}
.mc-change-password-modal .mc-change-password-area .mc-login-card-body .mc-change-password-body {
  padding: 15%;
}
.mc-change-password-modal .mc-change-password-area .mc-login-card-body .mc-seepassword-btn {
  right: 10px;
  bottom: -3px;
}
.mc-change-password-modal .mc-change-password-area .tab-content {
  position: relative;
}
.mc-change-password-modal .mc-change-password-area .mc-notify-message-wrapper {
  padding-top: 20px;
}
.mc-change-password-modal .mc-change-password-area .mc-login-password-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}
.mc-change-password-modal .mc-change-password-area .mc-login-password-fields .label-position {
  display: flex;
  margin-bottom: 3px;
  color: #474747;
}
.mc-change-password-modal .mc-change-password-area .mc-login-password-fields input {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400 !important;
}
.mc-change-password-modal .mc-change-password-area .mc-password-policy {
  padding-left: 17px;
  text-align: left !important;
}
.mc-change-password-modal .mc-change-password-area .mc-password-policy .mc-password-policy-title {
  color: #474747;
  text-align: left;
}
.mc-change-password-modal .mc-change-password-area .mc-password-policy .form-group {
  margin: 0;
  font-size: 14px;
}
.mc-change-password-modal .mc-change-password-area .mc-password-policy .form-group label {
  text-align: left;
}
.mc-change-password-modal .mc-change-password-area .mc-password-policy .form-group label .fill-control-description {
  font-size: 9px;
  color: #888;
}
.mc-change-password-modal .mc-change-password-area .rule-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.mc-change-password-modal .mc-change-password-area .rule-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.mc-change-password-modal .mc-change-password-area .rule-checkbox-wrapper .form-group {
  margin: 0 0.5rem 0 0;
}
.mc-change-password-modal .mc-change-password-area .password-wrapper {
  flex-direction: column;
}
.mc-change-password-modal .mc-change-password-area .password-input-wrapper {
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .mc-change-password-modal .mc-setup-area .mc-login-header img {
    height: 60px;
  }
  .mc-change-password-modal .mc-setup-area .mc-login-card .mc-login-card-body {
    padding: 0 30px 30px;
  }
  .mc-change-password-modal .mc-setup-area .mc-login-password-fields.mc-divider {
    border-right: none;
  }
  .mc-change-password-modal .mc-setup-area .mc-password-policy p {
    width: 100%;
  }
  .mc-change-password-modal .mc-setup-area .mc-password-policy .mc-password-policy-requirements {
    column-count: 2;
  }
}
@media only screen and (max-width: 460px) {
  .mc-change-password-modal .mc-setup-area .mc-login-card-body ul {
    flex-direction: column;
    align-items: center;
  }
  .mc-change-password-modal .mc-setup-area .mc-login-card-body ul li a {
    line-height: 1;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .mc-change-password-modal .mc-setup-area .mc-login-card-body ul li:first-of-type:after {
    display: block;
    text-align: center;
  }
  .mc-change-password-modal .mc-setup-area .mc-input-password {
    margin-bottom: 0;
  }
  .mc-change-password-modal .mc-setup-area .mc-login-card .mc-login-card-body form {
    padding-top: 50px;
  }
  .mc-change-password-modal .mc-setup-area .mc-password-policy {
    margin-top: 10px;
  }
  .mc-change-password-modal .mc-setup-area .mc-login-btn-area {
    padding-top: 30px;
  }
  .mc-change-password-modal .mc-setup-area .mc-seepassword-btn {
    width: 17%;
  }
}

.mc-personal-info .mc-personal-info-title {
  color: #35b1ec;
  font-weight: 400;
}
.mc-personal-info .mc-personal-info-wrapper {
  padding: 2% 5% 5%;
  border-radius: 5px;
  background-color: #F5F5F5;
}
.mc-personal-info .mc-personal-info-wrapper h5 {
  display: inline-block;
  font-weight: 400;
}
.mc-personal-info .mc-personal-info-wrapper .mc-personal-info-edit {
  color: #35b1ec;
  font-weight: 400;
}
.mc-personal-info .mc-personal-info-wrapper .mc-personal-info-edit:hover {
  color: #35b1ec;
}
.mc-personal-info .mc-personal-info-wrapper .mc-personal-info-edit .fa-pen {
  color: #35b1ec;
  font-size: small;
}
.mc-personal-info .mc-personal-info-wrapper .mc-notify-wrapper {
  position: relative;
  top: -18px;
  margin-bottom: 9%;
}
.mc-personal-info .mc-personal-info-wrapper hr {
  margin-top: 0;
  margin-bottom: 2%;
  background-color: #B6B8BA;
  height: 0.1px;
}
.mc-personal-info .mc-personal-info-wrapper .user-image {
  width: 200px;
  max-width: 100%;
  display: block;
}
.mc-personal-info .mc-personal-info-wrapper .top-margin {
  margin-top: 2%;
}
.mc-personal-info .mc-personal-info-wrapper .top-margin .user-label {
  font-size: 12px;
  font-weight: 600;
  color: #B6B8BA;
}
.mc-personal-info .mc-personal-info-wrapper .top-margin .user-info {
  margin-top: -1px;
}

.mc-edit-personal-info-modal .modal-dialog {
  max-width: 1200px;
  top: 30px;
  left: 100px;
}
.mc-edit-personal-info-modal .gender, .mc-edit-personal-info-modal .image {
  font-size: 100%;
  font-weight: 500;
}
.mc-edit-personal-info-modal .modal-content {
  border-radius: 0;
  max-width: 1200px;
}
.mc-edit-personal-info-modal .modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.mc-edit-personal-info-modal .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-edit-personal-info-modal .modal-content .modal-body {
  padding: 0 9% 3% 9%;
}
.mc-edit-personal-info-modal .modal-content .modal-body .modal-title {
  margin-bottom: 5%;
  border-bottom: 3px solid #B6B8BA;
}
.mc-edit-personal-info-modal .modal-content .modal-body .mc-notify-wrapper {
  position: relative;
  top: -18px;
  margin-bottom: 9%;
}
.mc-edit-personal-info-modal .modal-content .modal-body .personal-image {
  width: 200px;
  max-width: 100%;
  max-height: 215px;
  border: 1px solid grey;
  display: block;
}
.mc-edit-personal-info-modal .modal-content .modal-body .hidden-input {
  opacity: 0;
  z-index: 10;
  position: absolute;
  right: 12px;
  width: 104px;
  height: 26px;
}
.mc-edit-personal-info-modal .modal-content .modal-body .upload-img {
  color: #35b1ec;
  float: right;
  position: relative;
  top: 10px;
  font-size: 90%;
}
.mc-edit-personal-info-modal .modal-content .modal-body label {
  margin-bottom: 1%;
  color: #474747;
  font-size: 100%;
  font-weight: 500;
}
.mc-edit-personal-info-modal .modal-content .modal-body input, .mc-edit-personal-info-modal .modal-content .modal-body select {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
  height: 40px;
}
.mc-edit-personal-info-modal .modal-content .modal-body .required-input {
  color: #ff0000;
}
.mc-edit-personal-info-modal .modal-content .modal-body .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  position: absolute;
  bottom: 20px;
  right: 40px;
}
.mc-edit-personal-info-modal .modal-content .modal-body .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

/*Mc Entity profile wrapper*/
.mc-entity-profile-wrapper .mc-entity-profile-content {
  background: #F5F5F5;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .pause-title-color {
  font-weight: 500;
  text-transform: uppercase;
  color: #B6B8BA;
  padding-top: 10px;
  margin-left: 10px;
  border-top: 2px solid #B6B8BA;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .transaction-description-info {
  text-transform: none;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .mc-entity-profile-title {
  color: #64C0F5;
  margin-bottom: 12px;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .inherit-parent {
  color: #B6B8BA;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .entity-image {
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  border: 1px solid lightgrey;
  display: block;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .set-font {
  font-size: 12px;
  color: #B6B8BA;
  font-weight: 600;
  padding-right: 30px;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .position-top {
  margin-top: -1px;
  margin-left: 0;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .right-col-position {
  margin-top: -1px;
  margin-left: -30px;
}
.mc-entity-profile-wrapper .mc-entity-profile-content hr {
  margin-left: 8px;
}
.mc-entity-profile-wrapper .mc-entity-profile-content .mc-notify-wrapper {
  position: relative;
  margin-bottom: 35px;
  margin-left: 8px;
  top: -10px;
}
.mc-entity-profile-wrapper .mc-pen-icon {
  font-size: 12px;
  padding-left: 7px;
  cursor: pointer;
}
.mc-entity-profile-wrapper .mc-attributes {
  padding: 0 8px;
}
.mc-entity-profile-wrapper .mc-attributes .mc-table-local-attributes-wrapper {
  overflow: auto;
}
.mc-entity-profile-wrapper .mc-attributes .mc-table-local-attributes-wrapper .mc-table-local-attributes * {
  padding: 3px;
}
.mc-entity-profile-wrapper .mc-attributes .mc-table-local-attributes-wrapper .mc-table-local-attributes .mc-table-header-local-attributes {
  border-bottom: 2px solid #adb5bd;
}
.mc-entity-profile-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper {
  overflow: auto;
}
.mc-entity-profile-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper .mc-table-inherited-attributes * {
  padding: 3px;
}
.mc-entity-profile-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper .mc-table-inherited-attributes .mc-table-header-inherited-attributes {
  border-bottom: 2px solid #adb5bd;
}
.mc-entity-profile-wrapper .mc-filter-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}
.mc-entity-profile-wrapper .mc-input-field-wrapper {
  display: none;
}
.mc-entity-profile-wrapper .mc-search-top {
  top: 60px;
}

.mc-edit-entity-basic-info-modal .modal-dialog {
  max-width: 1000px;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content {
  border-radius: 0;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body {
  padding: 0 9% 3% 9%;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body #modal-basic-title {
  margin-bottom: 0;
  border-bottom: 3px solid #B6B8BA;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body .mc-notify-wrapper-modal-basic-info {
  position: relative;
  top: -30px;
  margin-bottom: 1.3%;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body .entity-form {
  background: #F5F5F5;
  padding: 5%;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body label {
  margin-bottom: 1%;
  color: #474747;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body input, .mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body textarea {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body textarea {
  resize: none;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body .hide-margin {
  margin-bottom: 0;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body .gray-text-info {
  font-size: 13px;
  color: #B6B8BA;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body .required-input {
  color: #ff0000;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-edit-entity-basic-info-modal .modal-dialog .modal-content .modal-body .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

.mc-edit-entity-attributes-modal .modal-dialog {
  max-width: 1250px;
}
.mc-edit-entity-attributes-modal .modal-dialog .modal-content {
  border-radius: 0;
}
.mc-edit-entity-attributes-modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.mc-edit-entity-attributes-modal .modal-dialog .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-edit-entity-attributes-modal .modal-dialog .modal-content .modal-body {
  padding: 0 9% 3% 9%;
}
.mc-edit-entity-attributes-modal .modal-dialog .modal-content .modal-body #modal-basic-title {
  margin-bottom: 0;
  border-bottom: 3px solid #B6B8BA;
}

.mc-edit-entity-password-policy-modal .modal-dialog {
  max-width: 1250px;
}
.mc-edit-entity-password-policy-modal .modal-dialog .modal-content {
  border-radius: 0;
}
.mc-edit-entity-password-policy-modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.mc-edit-entity-password-policy-modal .modal-dialog .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-edit-entity-password-policy-modal .modal-dialog .modal-content .modal-body {
  padding: 0 9% 3% 9%;
}
.mc-edit-entity-password-policy-modal .modal-dialog .modal-content .modal-body #modal-basic-title {
  margin-bottom: 0;
  border-bottom: 3px solid #B6B8BA;
}
.mc-edit-entity-password-policy-modal .modal-dialog .modal-content .modal-body .mc-notify-wrapper-modal-password-policy {
  position: relative;
  top: -30px;
  margin-bottom: 1.3%;
}

.enable-icon {
  margin-left: 5px;
  border-radius: 10px;
  border: 1px solid #35B1F1;
  outline: navajowhite;
  padding: 0 4px !important;
  font-size: 13px;
  background: #35B1F1;
  color: #f8f9fa;
}

.disable-icon {
  margin-left: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  outline: navajowhite;
  padding: 0 4px !important;
  font-size: 13px;
  background: lightgray;
  color: gray;
}

.info-icon {
  border-radius: 10px;
  border: 1px solid #bad8e8;
  outline: navajowhite;
  padding: 0 6px !important;
  font-size: 13px;
  font-weight: bold;
  background: #bad8e8;
  color: #35B1F1;
  cursor: pointer;
}

.description-attribute .tooltip-inner {
  background-color: #35b1f2;
  font-size: 125%;
}

.description-attribute .arrow::before {
  border-left-color: #35b1f2;
}

.fa-share-square {
  margin-top: -2px;
}

.fa-star {
  margin-top: -2px;
  margin-left: -9px;
}

.mc-pen-icon {
  font-size: 14px;
}

.mc-entity-action-btn {
  font-size: 16px;
  cursor: pointer;
}

.mc-entity-action-btn {
  font-size: 16px;
  cursor: pointer;
}

.mc-header-text {
  font-size: 12px;
  color: #b6b8ba;
  font-weight: 600;
}

.attributes-title-color {
  color: #B6B8BA;
}

.mc-select-background {
  background: #DFF1FB;
}

.mc-blue-text {
  color: #35B1F1;
}

.mc-float-right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
}

.mc-create-edit-product .modal-dialog {
  max-width: 1200px;
}
.mc-create-edit-product .modal-dialog .modal-content {
  padding: 10px 15px 15px;
  position: relative;
  border-radius: 0 !important;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-header {
  border: none !important;
}
.mc-create-edit-product .modal-dialog .modal-content .mc-create-product-modal-header {
  display: block;
  border-bottom: 0;
  padding: 15px 30px;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body {
  padding-top: 0;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .mc-product-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body #modal-basic-title {
  margin-bottom: 0;
  border-bottom: 3px solid #B6B8BA;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .mc-notify-wrapper {
  position: relative;
  top: -30px;
  margin-bottom: 1.3%;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .product-form {
  background: #F5F5F5;
  padding: 5%;
  border-top: 3px solid #B6B8BA;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .product-form .align-label {
  display: flex;
  align-items: center;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .product-form .label-position {
  word-break: break-word;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .product-form .euro-sign {
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 0;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body label {
  margin-bottom: 1%;
  color: #474747;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body input, .mc-create-edit-product .modal-dialog .modal-content .modal-body textarea {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body textarea {
  resize: none;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .hide-margin {
  margin-bottom: 0;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .gray-text-info {
  font-size: 13px;
  color: #B6B8BA;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .required-input {
  color: #ff0000;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-create-edit-product .modal-dialog .modal-content .modal-body .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

/*Edit product wrapper*/
.mc-edit-product-wrapper {
  height: fit-content;
  background-color: #f7f7f7;
  border-radius: 10px;
}
.mc-edit-product-wrapper .mc-notify-wrapper {
  position: relative;
  top: -4px;
  margin-bottom: 37px;
}
.mc-edit-product-wrapper .mc-edit-product-title {
  border-bottom: 3px solid #9ca0a3;
}

/*Edit product wrapper /end*/
#mc-create-edit-product-btn {
  color: #35b1ec;
  cursor: pointer;
  font-weight: 500;
}
#mc-create-edit-product-btn:hover {
  color: #149ada !important;
}

.mc-create-role-modal {
  /*Create role wrapper*/
  /*Search permission icon*/
  /*Search permission*/
  /*Permission number*/
  /*Permission list*/
  /*Selected permission list*/
}
.mc-create-role-modal section.container {
  position: relative;
}
.mc-create-role-modal section.container form {
  height: 460px;
}
.mc-create-role-modal .modal-content {
  border-radius: 0 !important;
  left: 100px;
}
.mc-create-role-modal .mc-create-role-summery-wrapper {
  height: 500px;
  overflow-y: scroll;
}
.mc-create-role-modal .modal-dialog {
  min-width: 1180px;
}
.mc-create-role-modal .mc-role-create-wrapper {
  /*Close modal button*/
  /*Modal header*/
  /*Modal body*/
}
.mc-create-role-modal .mc-role-create-wrapper .mc-close-button {
  font-size: -webkit-xxx-large;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-role-modal-header {
  padding: 15px 35px 5px;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-role-modal-header .close {
  margin: 0;
  font-weight: 100;
  font-size: 60px;
  position: absolute;
  right: 10px;
  top: 0;
  color: #b6b8ba;
  padding: 0 !important;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-role-modal-header button:focus {
  outline: none;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-create-role-modal-body {
  padding: 40px 20px;
  border-top: 3px solid #9ca0a3;
  border-bottom: 3px solid #9ca0a3;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-create-role-modal-body .mc-create-role-modal-edit-body {
  height: 500px;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-header-wrapper {
  padding-bottom: 40px;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-active {
  border-bottom: 3px solid rgb(42, 175, 244);
}
.mc-create-role-modal .mc-role-create-wrapper .mc-active .mc-selected-step {
  background: rgb(42, 175, 244);
  color: #fff;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-semi-active-role {
  color: #7dcdf6;
  border-bottom: 3px solid white;
}
.mc-create-role-modal .mc-role-create-wrapper .mc-selected-step {
  background: rgb(42, 175, 244);
  color: #fff;
}
.mc-create-role-modal .mc-search-icon {
  position: relative;
  left: -25px;
  color: #9ca0a3;
}
.mc-create-role-modal .mc-search-permissions {
  width: 350px;
}
.mc-create-role-modal .mc-permissions-number {
  font-size: 11px;
  background: rgb(42, 175, 244);
  color: #fff;
  padding: 2px 5px;
  border-radius: 50%;
}
.mc-create-role-modal .mc-permissions-list, .mc-create-role-modal .mc-permissions-module-wrapper {
  height: 300px;
  overflow-y: scroll;
}
.mc-create-role-modal .mc-selected-permissions-list {
  height: 436px !important;
  overflow-y: scroll;
}
.mc-create-role-modal .mc-selected-permissions-list .mc-selected-permissions-title {
  color: rgb(42, 175, 244);
  border-bottom: 3px solid #d3d3d3;
}
.mc-create-role-modal .mc-create-role-buttons-wrapper {
  width: fit-content;
  top: 0;
  float: right;
}
.mc-create-role-modal .mc-create-role-buttons-wrapper button {
  background-color: rgb(42, 175, 244);
  border: none;
}
.mc-create-role-modal .mc-create-role-buttons-wrapper button:hover {
  opacity: 0.8;
}

.mc-created-roles {
  background-color: #DFF1FB;
  margin: 0 20px;
  padding: 5px 15px;
}

.mc-edit-role-wrapper {
  padding: 0 35px;
}

.mc-role-label {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.mc-action-buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}

.mc-disabled-link {
  color: #d1d1d1 !important;
}
.mc-disabled-link:hover {
  color: #d1d1d1 !important;
}

/*Edit role wrapper*/
.mc-role-edit-wrapper {
  height: fit-content;
  background-color: #f7f7f7;
  border-radius: 10px;
}
.mc-role-edit-wrapper .mc-edit-role-title {
  border-bottom: 3px solid #9ca0a3;
}

/*Edit role wrapper /end*/
.mc-edit-role-button {
  font-size: 15px;
  padding-left: 5px;
}

.text-lowercase-role {
  text-transform: lowercase;
}

.text-lowercase-role::first-letter {
  text-transform: uppercase;
}

.mc-create-new-entity-modal .modal-dialog {
  max-width: 1350px;
}
.mc-create-new-entity-modal .mc-blue-text {
  color: #35B1F1;
}
.mc-create-new-entity-modal .mc-active {
  color: #35B1F1;
  border-bottom: 3px solid #35B1F1;
}
.mc-create-new-entity-modal .mc-active-background {
  background: #35B1F1;
}
.mc-create-new-entity-modal .mc-semi-active {
  color: #7dcdf6;
}
.mc-create-new-entity-modal .mc-semi-active-background {
  background-color: #7dcdf6;
}
.mc-create-new-entity-modal .mc-error-field {
  border-color: #EC230D;
}
.mc-create-new-entity-modal .mc-error-field:focus {
  border-color: #EC230D;
  box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, 0.25);
}
.mc-create-new-entity-modal .enable-icon {
  margin-left: 5px;
  border-radius: 10px;
  border: 1px solid #35B1F1;
  outline: navajowhite;
  padding: 0 4px !important;
  font-size: 13px;
  background: #35B1F1;
  color: #f8f9fa;
}
.mc-create-new-entity-modal .disable-icon {
  margin-left: 5px;
  border-radius: 10px;
  border: 1px solid gray;
  outline: navajowhite;
  padding: 0 4px !important;
  font-size: 13px;
  background: lightgray;
  color: gray;
}
.mc-create-new-entity-modal .info-icon {
  border-radius: 10px;
  border: 1px solid #bad8e8;
  outline: navajowhite;
  padding: 0 6px !important;
  font-size: 13px;
  font-weight: bold;
  background: #bad8e8;
  color: #35B1F1;
  cursor: pointer;
}
.mc-create-new-entity-modal .description-attribute .tooltip-inner {
  background-color: #35b1f2;
  font-size: 125%;
}
.mc-create-new-entity-modal .description-attribute .arrow::before {
  border-left-color: #35b1f2;
}
.mc-create-new-entity-modal .fa-share-square {
  margin-top: -2px;
}
.mc-create-new-entity-modal .fa-star {
  margin-top: -2px;
  margin-left: -9px;
}
.mc-create-new-entity-modal .mc-pen-icon {
  font-size: 14px;
}
.mc-create-new-entity-modal .border-bottom-row {
  border-bottom: 1px solid #adb5bd;
}
.mc-create-new-entity-modal .summary-entity-image {
  width: 100%;
  max-width: 120px;
  max-height: 120px;
  border: 1px solid lightgrey;
  display: block;
}
.mc-create-new-entity-modal .custom-control {
  display: inline-block;
}
.mc-create-new-entity-modal .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-create-new-entity-modal .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-create-new-entity-modal .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-create-new-entity-modal .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 6px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-create-new-entity-modal .custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
  cursor: pointer;
}
.mc-create-new-entity-modal .modal-content {
  border-radius: 0;
  padding: 10px 50px;
  position: relative;
}
.mc-create-new-entity-modal .modal-content .mc-create-entity-modal-header {
  padding: 16px 0 0;
}
.mc-create-new-entity-modal .modal-content .mc-entity-close-btn.close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0;
  position: relative;
  top: -13px;
  left: 21px;
  color: #B6B8BA;
}
.mc-create-new-entity-modal .modal-content .mc-entity-close-btn.close:focus {
  outline: none;
}
.mc-create-new-entity-modal .modal-content .modal-body {
  background: #f7f7f7;
  border-top: 3px solid #9ca0a3;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}
.mc-create-new-entity-modal .modal-content .modal-body section.container {
  max-width: 100%;
  padding: 0 25px;
}
.mc-create-new-entity-modal .modal-content .modal-body ul.nav.nav-tabs {
  justify-content: center !important;
  border-bottom: none;
}
.mc-create-new-entity-modal .modal-content .modal-body li.nav-item {
  padding: 0 10px;
}
.mc-create-new-entity-modal .modal-content .modal-body li.nav-item > a.nav-link {
  background: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.16);
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.mc-create-new-entity-modal .modal-content .modal-body li.nav-item > a.nav-link.active {
  border-bottom: 3px solid #35B1F1;
  color: #35B1F1;
}
.mc-create-new-entity-modal .modal-content .modal-body li.nav-item > a.nav-link.active .mc-notification-badge-gray {
  background: #35B1F1;
}
.mc-create-new-entity-modal .modal-content .modal-body .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-create-new-entity-modal .modal-content .modal-body .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-create-new-entity-modal .modal-content .modal-body .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-create-new-entity-modal .modal-content .modal-body .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-create-new-entity-modal .modal-content .modal-body .custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
}
.mc-create-new-entity-modal .modal-content .modal-body .mc-next-save-btn {
  background: #35b1ec;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;
}
.mc-create-new-entity-modal .modal-content .modal-body .mc-next-save-btn:focus {
  box-shadow: none;
}
.mc-create-new-entity-modal .modal-content .modal-body .mc-next-save-btn:hover {
  background: #128ac2;
}
.mc-create-new-entity-modal .mc-notify-wrapper-summary-entity {
  position: relative;
  top: -40px;
  margin-bottom: 30px;
}
.mc-create-new-entity-modal .mc-prev-btn {
  border: none;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.mc-create-new-entity-modal .mc-right-border-summary {
  border-right: 1px solid #9ca0a3;
}
.mc-create-new-entity-modal .mc-entity-summary-title {
  color: #35B1F1;
}
.mc-create-new-entity-modal .mc-entity-summary-title h6 {
  display: inline-block;
  font-size: 18px;
}
.mc-create-new-entity-modal .set-font {
  font-size: 12px;
  font-weight: 500;
}
.mc-create-new-entity-modal .right-basic-info-col {
  font-size: 15px;
  font-weight: 400;
}
.mc-create-new-entity-modal .left-pass-col {
  padding-right: 0;
}
.mc-create-new-entity-modal .right-pass-col {
  font-size: 14px;
  font-weight: 400;
  margin-top: -1px;
  padding-left: 10px;
  padding-right: 10px;
}
.mc-create-new-entity-modal .mc-entity-summary-create-btn {
  position: absolute;
  bottom: 16px;
  right: 25px;
  z-index: 100;
}
.mc-create-new-entity-modal .mc-entity-summary-createAdd-btn {
  position: absolute;
  bottom: 16px;
  right: -115px;
  z-index: 100;
}
.mc-create-new-entity-modal .mc-entity-summary-create-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mc-create-new-entity-modal .mc-entity-summary-create-btns .mc-next-save-btn:first-child {
  background-color: #109be3;
}
.mc-create-new-entity-modal footer.step-navigation-bar {
  margin-bottom: 0 !important;
  margin-top: 30px !important;
}

.mc-create-entity-btn {
  color: #35b1ec !important;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none !important;
}
.mc-create-entity-btn:hover {
  color: #149ada !important;
}

.mc-entity-basic-info .entity-basic-info-form {
  padding: 3% 3% 0;
}
.mc-entity-basic-info .entity-basic-info-form .mc-notify-wrapper-basic-info {
  position: relative;
  top: -18px;
  margin-bottom: 2.3%;
}
.mc-entity-basic-info .entity-basic-info-form .entity-image {
  width: 100%;
  max-width: 120px;
  max-height: 120px;
  border: 1px solid lightgrey;
  display: block;
}
.mc-entity-basic-info .entity-basic-info-form .hidden-input {
  opacity: 0;
  z-index: 10;
  position: absolute;
  width: 93px;
  height: 19px;
}
.mc-entity-basic-info .entity-basic-info-form .upload-img {
  color: #35b1ec;
  position: relative;
  top: -2px;
  font-size: 90%;
}
.mc-entity-basic-info label {
  margin-bottom: 1%;
  color: #474747;
}
.mc-entity-basic-info input, .mc-entity-basic-info textarea {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
}
.mc-entity-basic-info textarea {
  resize: none;
}
.mc-entity-basic-info .hide-margin {
  margin-bottom: 0;
}
.mc-entity-basic-info .gray-text-info {
  font-size: 13px;
  color: #B6B8BA;
}
.mc-entity-basic-info .required-input {
  color: #ff0000;
}
.mc-entity-basic-info .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-entity-basic-info .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

.mc-entity-attributes-area .mc-entity-attributes-wrapper {
  padding: 0 3%;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-notify-wrapper-attributes {
  position: relative;
  top: -32px;
  margin-bottom: 1.3%;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-local-attributes-wrapper {
  overflow: auto;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-local-attributes-wrapper .mc-table-local-attributes * {
  padding: 6px;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-local-attributes-wrapper .mc-table-local-attributes .mc-table-header-local-attributes {
  border-bottom: 3px solid #adb5bd;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-local-attributes-wrapper .mc-table-local-attributes .mc-table-header-local-attributes th {
  font-size: 13px;
  font-weight: 500;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-local-attributes-wrapper .mc-table-local-attributes .delete-local-attribute {
  font-size: 16px;
  color: gray;
  font-weight: 100;
  cursor: pointer;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .inheritSelection {
  cursor: pointer;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .attributes-subtitle {
  font-size: 18px;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper {
  overflow: auto;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper .mc-table-inherited-attributes * {
  padding: 6px;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper .mc-table-inherited-attributes .mc-table-header-inherited-attributes {
  border-bottom: 3px solid #adb5bd;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper .mc-table-inherited-attributes .mc-table-header-inherited-attributes th {
  font-size: 13px;
  font-weight: 500;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper .mc-table-inherited-attributes .label-wrapper {
  padding: 0 14px 0 0 !important;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-attributes .mc-table-inherited-attributes-wrapper .mc-table-inherited-attributes .label-wrapper .checkbox-attributes-position {
  top: 12px !important;
  left: 5px !important;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-entity-attributes-area .mc-entity-attributes-wrapper .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

.mc-entity-password-policy .entity-password-policy-form {
  padding: 3% 3% 0;
}
.mc-entity-password-policy .entity-password-policy-form .mc-notify-wrapper-password-policy {
  position: relative;
  top: -25px;
  margin-bottom: 1.3%;
}
.mc-entity-password-policy .entity-password-policy-form .mc-notify-wrapper-create-password-policy {
  position: relative;
  top: -33px !important;
  margin-bottom: 1.3%;
}
.mc-entity-password-policy .entity-password-policy-form .parent-password-policy .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-entity-password-policy .entity-password-policy-form .parent-password-policy .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-entity-password-policy .entity-password-policy-form .parent-password-policy .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-entity-password-policy .entity-password-policy-form .parent-password-policy .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 5px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-entity-password-policy .entity-password-policy-form .parent-password-policy .custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
}
.mc-entity-password-policy .entity-password-policy-form .invalid-number {
  padding-left: 3px;
  padding-right: 22px;
}
.mc-entity-password-policy .entity-password-policy-form .restore-policy {
  color: #64C0F5;
  display: inline-block;
  float: right;
  margin-top: -25px;
  cursor: pointer;
}
.mc-entity-password-policy .entity-password-policy-form .parent-policy-wrapper {
  border: 3px solid #B6B8BA;
  padding: 5%;
  position: relative;
}
.mc-entity-password-policy .entity-password-policy-form .parent-policy-wrapper .parent-policy-text {
  background: #F5F5F5;
  color: #B6B8BA;
  position: absolute;
  top: -14px;
  padding: 0 20px;
}
.mc-entity-password-policy .entity-password-policy-form .disable-restore-btn {
  color: #B7B7B9;
  pointer-events: none;
}
.mc-entity-password-policy .entity-password-policy-form .disable-password-policy-wrapper {
  pointer-events: none;
}
.mc-entity-password-policy .entity-password-policy-form .disable-password-policy-wrapper .disable-password-policy {
  background-color: #ced5e2;
  opacity: 0.4;
  pointer-events: none;
}
.mc-entity-password-policy .center-label-wrap {
  display: flex;
  align-items: center;
}
.mc-entity-password-policy label {
  margin-right: 10%;
  margin-bottom: 0;
  color: #474747;
}
.mc-entity-password-policy input {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
  width: 40%;
  display: inline-block;
  margin-right: 2%;
}
.mc-entity-password-policy .hide-margin {
  margin-bottom: 0;
}
.mc-entity-password-policy .gray-text-info {
  font-size: 13px;
  color: #B6B8BA;
}
.mc-entity-password-policy .required-input {
  color: #ff0000;
}
.mc-entity-password-policy .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-entity-password-policy .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

.mc-entity-local-attributes-modal .modal-dialog {
  max-width: 600px;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body {
  background-color: #EAEBF0;
  padding: 25px 35px 29px;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .required-input {
  color: #EC230D;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-local-attributes-title.modal-title {
  border-bottom: 1px solid #adb5bd;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .mc-create-entity-notify-wrapper {
  position: relative;
  top: -38px;
  margin-bottom: 47px;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form input, .mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form textarea {
  border-radius: 0;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .inheritable-radio-btns-group {
  margin-top: 31px;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .inheritable-wrapper {
  margin-top: 6px;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .required-wrapper {
  margin-top: 6px;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .disable-restore-btn {
  color: #B7B7B9;
  pointer-events: none;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .disable-checkbox {
  background-color: #e9ecef !important;
  pointer-events: none;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .mc-close-local-attribute-btn {
  float: left;
  font-size: 15px;
  font-weight: 500;
  color: black !important;
  cursor: pointer;
  outline: none;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .mc-save-local-attribute-btn {
  float: right;
  font-size: 15px;
  background-color: #35b1ec;
  color: #fff;
  padding: 7px 24px;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .mc-entity-attributes-form .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .custom-control {
  display: inline-block;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .custom-control.fill-checkbox {
  --color: #35B1F1;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 5px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #aaa;
  background-color: #f8f9fa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.mc-entity-local-attributes-modal .modal-dialog .modal-body .custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
  cursor: pointer;
}

.custom-control {
  display: inline-block;
}
.custom-control.fill-checkbox {
  --color: #35B1F1;
}
.custom-control.fill-checkbox .fill-control-input {
  display: none;
}
.custom-control.fill-checkbox .fill-control-input:checked ~ .fill-control-indicator {
  background-color: var(--color);
  border-color: var(--color);
  background-size: 80%;
}
.custom-control.fill-checkbox .fill-control-indicator {
  position: absolute;
  top: 6px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #aaa;
  background-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-control.fill-checkbox .fill-control-description {
  font-size: 14px;
  cursor: pointer;
}

.gray-background {
  background: #f7f7f7;
}

.mc-entity-label {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

ul.dashed {
  list-style-type: none;
}

ul.dashed > li:before {
  content: "-";
}

.mc-factoring ul {
  padding: 0;
}
.mc-factoring ul li {
  font-size: 16px !important;
  cursor: unset !important;
}

.mc-type-of-requests .modal-dialog {
  max-width: 400px;
}
.mc-type-of-requests .modal-dialog .modal-content {
  border-radius: 0;
  padding: 20px 30px 30px !important;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding-top: 5px;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-header .mc-product-close-btn span {
  position: absolute;
  right: 11px;
  top: 0;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body {
  padding: 0 !important;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body #modal-basic-title {
  margin-bottom: 0;
  border-bottom: 3px solid #B6B8BA;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .mc-notify-wrapper {
  position: relative;
  top: -15px;
  margin-bottom: 19px;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .category-form {
  background: #F5F5F5;
  padding: 5%;
  border-top: 3px solid #B6B8BA;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .category-form .align-label {
  display: flex;
  align-items: center;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .category-form .euro-sign {
  font-size: 14px;
  margin-left: 28px;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body label {
  margin-bottom: 1%;
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body input, .mc-type-of-requests .modal-dialog .modal-content .modal-body textarea {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body textarea {
  resize: none;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .hide-margin {
  margin-bottom: 0;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .gray-text-info {
  font-size: 13px;
  color: #B6B8BA;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .required-input {
  color: #ff0000;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-type-of-requests .modal-dialog .modal-content .modal-body .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

.mc-edit-entity-basic-info-modal .mc-factoring-modal-title {
  border-bottom: 3px solid #B6B8BA;
}

.mc-entity-factoring .mc-factoring-textbox {
  height: 50%;
}
.mc-entity-factoring .mc-factoring-textbox .error-msg {
  font-size: 75%;
  margin-right: 50px;
}
.mc-entity-factoring .mc-factoring-textbox input {
  width: 70%;
}
.mc-entity-factoring .mc-factoring-textbox label {
  width: 60%;
  padding-right: 30px !important;
}
.mc-entity-factoring .mcc-fi-contractStartDate .error-msg {
  font-size: 75%;
  margin-right: 227px !important;
}
.mc-entity-factoring .mcc-fi-contractStartDate,
.mc-entity-factoring .mcc-fi-contractEndDate {
  padding-left: 10px;
}
.mc-entity-factoring .mcc-fi-contractStartDate .error-msg,
.mc-entity-factoring .mcc-fi-contractEndDate .error-msg {
  font-size: 75%;
  margin-right: 172px;
}
.mc-entity-factoring .mcc-fi-contractStartDate input,
.mc-entity-factoring .mcc-fi-contractEndDate input {
  width: 40%;
}
.mc-entity-factoring .mcc-fi-contractStartDate label,
.mc-entity-factoring .mcc-fi-contractEndDate label {
  width: 80%;
  padding-right: 30px !important;
  font-size: 12px !important;
  color: #B6B8BA !important;
  font-weight: 600 !important;
}
.mc-entity-factoring form {
  padding: 3% 3%;
}
.mc-entity-factoring form input[type=text] {
  color: black;
  padding-left: 2px;
}
.mc-entity-factoring form label {
  font-size: 12px !important;
  color: #B6B8BA !important;
  font-weight: 600 !important;
}
.mc-entity-factoring form label i {
  position: absolute;
  right: 0;
}
.mc-entity-factoring .hide {
  display: none;
}
.mc-entity-factoring .show {
  display: block;
}
.mc-entity-factoring .btn-add {
  background: none !important;
  border: none;
  color: #B6B8BA;
  cursor: pointer;
}

.mc-hidden {
  display: none;
}

.mc-factoring-table {
  position: absolute;
  left: 0;
  right: 0;
  margin-right: -45px;
}
.mc-factoring-table button {
  font-size: 14px !important;
}

.mc-factoring-notify-message .mc-notify-message-wrapper {
  margin-top: 12px;
  z-index: 1;
}

.mc-add-consumer-modal .modal-dialog {
  max-width: 1200px;
}
.mc-add-consumer-modal .mc-active {
  color: #35B1F1;
  border-bottom: 3px solid #35B1F1;
}
.mc-add-consumer-modal .mc-active-background {
  background: #35B1F1;
}
.mc-add-consumer-modal .mc-semi-active {
  color: #7dcdf6;
}
.mc-add-consumer-modal .mc-semi-active-background {
  background-color: #7dcdf6;
}
.mc-add-consumer-modal .mc-create-user-modal-header {
  display: block;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 0;
}
.mc-add-consumer-modal .mc-create-user-modal-header .mc-create-user-entity-path {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #35b1ec;
  font-size: 20px;
  font-weight: 500;
}
.mc-add-consumer-modal .mc-create-user-modal-header .mc-create-user-entity-path span {
  display: flex;
  align-items: center;
}
.mc-add-consumer-modal .mc-create-user-modal-header .mc-create-user-entity-path span::after {
  content: "\f105";
  color: #8d969d;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 600;
  font-family: "Font Awesome 5 Free", serif;
  font-size: 14px;
}
.mc-add-consumer-modal .mc-create-user-modal-header .mc-create-user-entity-path span:last-child::after {
  content: "";
}
.mc-add-consumer-modal .modal-body {
  background: #f7f7f7;
  border-top: 3px solid #9ca0a3;
}
.mc-add-consumer-modal .modal-content {
  border-radius: 0;
  padding: 10px 50px 50px 50px;
  position: relative;
}
.mc-add-consumer-modal .mc-add-consumers-form {
  position: relative;
  justify-content: center;
}
.mc-add-consumer-modal .mc-add-consumers-form .mc-notify-message-wrapper {
  top: 10px;
}
.mc-add-consumer-modal .mc-add-consumers-form .mc-save-consumers-btn {
  text-align: right;
  padding: 0 20px;
}
.mc-add-consumer-modal .extended-info {
  border-left: 1px solid #9ca0a3;
}
.mc-add-consumer-modal .mc-consumers-summary-title {
  color: #35B1F1;
  padding-left: 15px;
}
.mc-add-consumer-modal .mc-consumers-summary-title .fa-pen {
  cursor: pointer;
}
.mc-add-consumer-modal .mc-consumers-summary-title h6 {
  display: inline-block;
}
.mc-add-consumer-modal .mc-consumers-summary-basic-info div:first-child {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.mc-add-consumer-modal .mc-consumers-summary-basic-info div:last-child {
  font-size: 16px;
}
.mc-add-consumer-modal .mc-consumers-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.mc-add-consumer-modal .mc-payment-pause {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mc-add-consumer-modal .mc-payment-pause label {
  padding-right: 10px;
  margin-bottom: 0;
}
.mc-add-consumer-modal .mc-payment-pause input:first-of-type {
  margin-right: 30px;
}
.mc-add-consumer-modal .mc-consumers-summary-value span:first-child {
  font-size: 14px;
}
.mc-add-consumer-modal .mc-consumers-summary-btn {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
}
.mc-add-consumer-modal .mc-custom-attributes-summary-view {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.mc-edit-consumers {
  margin-bottom: 50px;
}
.mc-edit-consumers .mc-edit-consumers-general {
  background: #f7f7f7;
  border-radius: 10px;
  padding: 20px 70px;
  min-height: 700px;
}
.mc-edit-consumers .mc-edit-consumers-general-title {
  border-bottom: 1px solid #9ca0a3;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 10px;
  text-align: center;
}
.mc-edit-consumers .mc-edit-consumers-general-title span {
  font-size: 25px;
  color: #3c4f5f;
}
.mc-edit-consumers .mc-edit-consumers-basic-info {
  padding: 20px 0 20px 0;
}
.mc-edit-consumers .mc-edit-consumers-basic-info-link:not([href]) {
  font-size: 20px;
  color: #35B1F1;
  cursor: pointer;
  padding-bottom: 10px;
  position: relative;
  padding-right: 30px;
  font-weight: 500;
}
.mc-edit-consumers .mc-edit-consumers-basic-info-link:not([href]):hover {
  color: #109be3;
}
.mc-edit-consumers .mc-edit-consumers-basic-info-link:not([href]) .fa-pen {
  position: absolute;
  right: 0;
  top: 0;
}
.mc-edit-consumers .mc-consumers-view-history-btn:not([href]) {
  font-size: 15px;
  font-weight: 500;
  color: #35B1F1;
  cursor: pointer;
  text-decoration: underline;
}
.mc-edit-consumers .mc-consumers-view-history-btn:not([href]):hover {
  color: #109be3;
  text-decoration: underline;
}
.mc-edit-consumers .mc-edit-consumers-block {
  margin: 0;
}
.mc-edit-consumers .mc-edit-consumers-preview-data {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.mc-edit-consumers .mc-edit-consumers-preview-field-name {
  color: #9ca0a3;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  flex-basis: 160px;
}
.mc-edit-consumers .mc-edit-consumers-preview-field-value {
  font-size: 16px;
  flex: 1;
  padding-left: 10px;
}
.mc-edit-consumers .mc-edit-consumers-paymentInfo .mc-filter-wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
}
.mc-edit-consumers .mc-edit-consumers-paymentInfo .mc-input-field-wrapper {
  display: none;
}
.mc-edit-consumers .mc-edit-consumers-paymentInfo .mc-search-top {
  top: 60px;
}

.mc-edit-consumers-modal-header .mc-edit-consumers-general-title {
  font-size: 22px;
  font-weight: 500;
}
.mc-edit-consumers-modal-header .mc-edit-consumers-general-title span {
  font-size: 25px;
  color: #3c4f5f;
}

.mc-date-picker-modal {
  text-align: center;
}
.mc-date-picker-modal .modal-dialog {
  max-width: 1000px;
}
.mc-date-picker-modal .modal-dialog .modal-content {
  border-radius: 0;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body {
  padding: 10% 13% 15%;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .mc-notify-wrapper-date {
  position: relative;
  top: -74px;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .label-from {
  position: relative;
  left: -62px;
  font-weight: bold;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .clear-date-from {
  position: relative;
  right: -62px;
  font-size: 15px;
  color: #35b1ec;
  cursor: pointer;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .clear-date-from:hover {
  color: #0697dc;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .label-till {
  position: relative;
  left: -70px;
  font-weight: bold;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .clear-date-till {
  position: relative;
  right: -70px;
  color: #35b1ec;
  font-size: 15px;
  cursor: pointer;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .clear-date-till:hover {
  color: #0697dc;
}
.mc-date-picker-modal .modal-dialog .modal-content .modal-body .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  position: relative;
  right: 60px;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}

/*MC job action wrapper*/
.mc-job-action-wrapper .mc-action {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #c8c8c8;
}
.mc-job-action-wrapper .mc-action._inactive {
  background-color: #c8c8c8;
  opacity: 0.6;
}
.mc-job-action-wrapper .mc-action._run {
  background-color: #59d08e;
}
.mc-job-action-wrapper .mc-action._run:hover {
  cursor: pointer;
  opacity: 0.8;
}
.mc-job-action-wrapper .mc-action._pause {
  background-color: #fa7236;
}
.mc-job-action-wrapper .mc-action._pause:hover {
  cursor: pointer;
  opacity: 0.8;
}
.mc-job-action-wrapper .mc-action._resume {
  background-color: #35b1f2;
}
.mc-job-action-wrapper .mc-action._resume:hover {
  cursor: pointer;
  opacity: 0.8;
}
.mc-job-action-wrapper .mc-action._unschedule {
  background-color: #f24937;
}
.mc-job-action-wrapper .mc-action._unschedule:hover {
  cursor: pointer;
  opacity: 0.8;
}
.mc-job-action-wrapper .mc-action .mc-action-icon {
  width: 12px;
  margin: 0 auto;
  position: relative;
  display: block;
  top: 10px;
}
.mc-job-action-wrapper .mc-action .mc-action-icon._pause {
  top: 9px;
  width: 9px;
}
.mc-job-action-wrapper .mc-action .mc-action-icon._unschedule {
  width: 8px;
  top: 11px;
}
.mc-job-action-wrapper .mc-action .mc-action-icon._resume {
  top: 9px;
}

.mc-schedule-job-wrapper .mc-tittle-wrapper {
  height: 60px;
}
.mc-schedule-job-wrapper .mc-error {
  position: relative;
  height: fit-content;
  margin-bottom: 30px;
  top: -4px;
}

/*Status component*/
.mc-status-active {
  color: #59d08e;
}

.mc-status-inactive {
  color: #b5b9bb;
}

.mc-status-draft {
  color: #35b1f2;
}

.mc-status-pending {
  color: #fa7236;
}

.mc-status-print {
  color: #707885;
}

.mc-status-running {
  color: #2fb48d;
}

.mc-status-scheduled {
  color: #ff9f23;
}

.mc-status-stopped {
  color: #e88074;
}

.mc-status-paused {
  color: #76c7f1;
}

.mc-status-blocked {
  color: #707885;
}

.mc-status-completed {
  color: #62C760;
}

.mc-status-send_now {
  color: #21bfc4;
}

.mc-status-error {
  color: #ff1800;
}

.mc-status-none {
  color: #dadada;
}

/*Job create edit modal wrapper*/
.mc-job-create-edit-modal .form-check-cronex-label {
  width: 400px;
}
.mc-job-create-edit-modal .mc-job-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.mc-job-create-edit-modal .modal-dialog {
  max-width: 1400px !important;
}
.mc-job-create-edit-modal .modal-header {
  border-bottom: 3px solid #B6B8BA;
  padding-left: 0;
}
.mc-job-create-edit-modal .modal-body {
  overflow-y: auto;
  background: #f5f5f5;
  height: 600px;
}
.mc-job-create-edit-modal .modal-body input {
  width: 100% !important;
}
.mc-job-create-edit-modal .modal-body textarea {
  width: 100%;
}
.mc-job-create-edit-modal .modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 20px 40px 40px !important;
  /*Close button*/
}
.mc-job-create-edit-modal .modal-content .mc-job-create-edit-modal-close-button {
  padding: 15px !important;
}
.mc-job-create-edit-modal .modal-content .mc-notify-wrapper {
  position: relative;
  height: 30px;
}
.mc-job-create-edit-modal .modal-content .mc-schedule-input-fields-wrapper {
  height: 150px;
}

/*Open modal button*/
.mc-job-create-edit-modal-button {
  color: #35b1ec;
  font-weight: 500;
  width: fit-content;
  float: right;
}

.mc-div-margin {
  margin-bottom: 10px;
}

/*Overview jobs*/
.mc-job-overview .mc-job-label {
  color: #9ca0a3;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.mc-job-overview .mc-edit-job-wrapper {
  margin-left: 0.8rem;
}

.mc-create-category .modal-dialog {
  max-width: 500px;
}
.mc-create-category .modal-dialog .modal-content {
  border-radius: 0;
  padding: 20px 30px 30px !important;
}
.mc-create-category .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding-top: 5px;
}
.mc-create-category .modal-dialog .modal-content .modal-header .mc-product-close-btn span {
  position: absolute;
  right: 11px;
  top: 0;
}
.mc-create-category .modal-dialog .modal-content .modal-header .mc-create-category-modal-header {
  display: block;
  border-bottom: 0;
  padding-left: 0 !important;
  padding-bottom: 10px;
}
.mc-create-category .modal-dialog .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-create-category .modal-dialog .modal-content .modal-body {
  padding: 0 !important;
}
.mc-create-category .modal-dialog .modal-content .modal-body #modal-basic-title {
  margin-bottom: 0;
  border-bottom: 3px solid #B6B8BA;
}
.mc-create-category .modal-dialog .modal-content .modal-body .mc-notify-wrapper {
  position: relative;
  top: -15px;
  margin-bottom: 19px;
}
.mc-create-category .modal-dialog .modal-content .modal-body .category-form {
  background: #F5F5F5;
  padding: 5%;
  border-top: 3px solid #B6B8BA;
}
.mc-create-category .modal-dialog .modal-content .modal-body .category-form .align-label {
  display: flex;
  align-items: center;
}
.mc-create-category .modal-dialog .modal-content .modal-body .category-form .euro-sign {
  font-size: 14px;
  margin-left: 28px;
}
.mc-create-category .modal-dialog .modal-content .modal-body label {
  margin-bottom: 1%;
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.mc-create-category .modal-dialog .modal-content .modal-body input, .mc-create-category .modal-dialog .modal-content .modal-body textarea {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
}
.mc-create-category .modal-dialog .modal-content .modal-body textarea {
  resize: none;
}
.mc-create-category .modal-dialog .modal-content .modal-body .hide-margin {
  margin-bottom: 0;
}
.mc-create-category .modal-dialog .modal-content .modal-body .gray-text-info {
  font-size: 13px;
  color: #B6B8BA;
}
.mc-create-category .modal-dialog .modal-content .modal-body .required-input {
  color: #ff0000;
}
.mc-create-category .modal-dialog .modal-content .modal-body .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}
.mc-create-category .modal-dialog .modal-content .modal-body .mc-disable-btn {
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
  background-color: rgba(0, 0, 0, 0.16) !important;
  color: #fff;
}

#mc-create-category-btn {
  color: #35b1ec;
  cursor: pointer;
  font-weight: 500;
}
#mc-create-category-btn:hover {
  color: #149ada !important;
}

.mc-invoice-section .mc-invoice-notify-wrapper {
  position: relative;
  top: -17px;
  margin-bottom: 30px;
  width: 100%;
}
.mc-invoice-section a.mc-create-modal-btn {
  color: #35b1ec !important;
  cursor: pointer;
  font-weight: 500;
}
.mc-invoice-section a.mc-create-modal-btn:hover {
  color: #149ada !important;
}

.mc-create-invoice-modal .modal-dialog {
  max-width: 1400px;
}
.mc-create-invoice-modal .modal-content {
  min-height: 700px;
}
.mc-create-invoice-modal .mc-active {
  color: #35B1F1;
  border-bottom: 3px solid #35B1F1;
}
.mc-create-invoice-modal .mc-active-background {
  background: #35B1F1;
}
.mc-create-invoice-modal .mc-semi-active {
  color: #7dcdf6;
}
.mc-create-invoice-modal .mc-semi-active-background {
  background-color: #7dcdf6;
}
.mc-create-invoice-modal .mc-error-field {
  border-color: #EC230D;
}
.mc-create-invoice-modal .mc-error-field:focus {
  border-color: #EC230D;
  box-shadow: 0 0 0 0.2rem rgba(236, 35, 13, 0.25);
}
.mc-create-invoice-modal .modal-content {
  border-radius: 0;
  padding: 10px 50px 50px 50px;
  position: relative;
}
.mc-create-invoice-modal .mc-create-user-modal-header {
  display: block;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 0;
}
.mc-create-invoice-modal .modal-body {
  background: #f7f7f7;
  border-top: 3px solid #9ca0a3;
  position: relative;
}
.mc-create-invoice-modal .mc-invoice-product-form section:first-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  max-width: 100%;
}
.mc-create-invoice-modal .mc-create-invoices-form .mc-table-component-wrapper {
  padding-left: 15px;
  padding-right: 0;
}
.mc-create-invoice-modal .mc-invoice-step {
  min-height: 550px;
}
.mc-create-invoice-modal .form-control.mc-dates-inputs[disabled] {
  background: #e9ecef;
}
.mc-create-invoice-modal .mc-dates-due-date {
  flex: 1;
}
.mc-create-invoice-modal .mc-date-label {
  min-width: 90px;
  text-transform: capitalize;
}
.mc-create-invoice-modal .mc-schedule-date {
  display: none;
}
.mc-create-invoice-modal .mc-show-send-delayed {
  display: none;
}
.mc-create-invoice-modal .mc-hide {
  display: none !important;
}
.mc-create-invoice-modal .mc-selected-schedule {
  background: #e5e5e5;
}
.mc-create-invoice-modal .mc-invoice-schedule-select {
  font-weight: 500;
}
.mc-create-invoice-modal .mc-selected-product-wrapper {
  height: 77px;
}
.mc-create-invoice-modal .mc-selected-products-list {
  width: 80%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.mc-create-invoice-modal .mc-selected-products-list .mc-list-item {
  background: #e8ecef;
  padding: 5px 15px;
  border: 1px solid #d9e0e5;
  margin: 5px 8px;
  display: flex;
  justify-content: flex-start;
}
.mc-create-invoice-modal .mc-selected-products-list .mc-list-item span {
  color: #474747;
  font-weight: 500;
}
.mc-create-invoice-modal .mc-user-summary-action-btn {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 32px;
  right: 0;
  z-index: 9;
}
.mc-create-invoice-modal .mc-invoice-summary-action-btn {
  padding-right: 60px;
}
.mc-create-invoice-modal .mc-invoice-preview-btn {
  border: 1px solid #109be3;
  border-radius: 25px;
  color: #109be3;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 25px;
  text-decoration: none;
  transition: 0.25s all ease-in-out;
}
.mc-create-invoice-modal .mc-invoice-preview-btn:hover {
  background: #0e84c2;
  color: #ffffff;
}
.mc-create-invoice-modal .mc-add-products-modal-close-area {
  position: relative;
  bottom: 30px;
  right: 33px;
  margin-top: 50px;
}
.mc-create-invoice-modal .mc-no-selected-products {
  height: 77px;
}
.mc-create-invoice-modal .mc-schedule-title {
  display: flex;
  align-items: center;
}
.mc-create-invoice-modal .mc-invoice-status-scheduled, .mc-create-invoice-modal .mc-invoice-status-draft {
  border-radius: 3px;
  font-size: 12px;
  margin-left: 8px;
  padding: 1px 5px;
}
.mc-create-invoice-modal .mc-invoice-status-draft {
  background: #4dbaf4;
}
.mc-create-invoice-modal .mc-invoice-status-scheduled {
  background: #ffaa3d;
}
.mc-create-invoice-modal .mc-fields-invoice-status-scheduled {
  background: #ffaa3d;
}
.mc-create-invoice-modal .mc-create-user-notify-wrapper {
  width: 100%;
}
.mc-create-invoice-modal .mc-notify-message-wrapper {
  position: relative;
}
.mc-create-invoice-modal .mc-action-btns {
  background-color: #109be3;
  cursor: pointer;
}

/* responsive for action buttons */
@media screen and (max-width: 1700px) {
  .mc-create-invoice-modal .mc-invoice-summary-action-btn {
    max-width: 60%;
  }
}
@media screen and (max-width: 1460px) {
  .mc-create-invoice-modal .mc-invoice-summary-action-btn {
    max-width: 80%;
  }
}
@media screen and (max-width: 1270px) {
  .mc-create-invoice-modal .mc-invoice-summary-action-btn {
    max-width: 100%;
    bottom: 85px !important;
    padding: 0 60px;
  }
}
/*Price adjustment wrapper*/
.mc-price-adjustment-wrapper {
  position: relative;
  /*Clear amount button*/
  /*Amount wrapper*/
  /*Additional discount checkbox*/
  /*Additional discount text*/
}
.mc-price-adjustment-wrapper .mc-clear-amount-button {
  position: absolute;
  width: fit-content;
  color: #35b1ec;
  font-size: 12px;
  text-align: left;
}
.mc-price-adjustment-wrapper .mc-amount-wrapper {
  width: 140px;
  float: right;
}
.mc-price-adjustment-wrapper .mc-additional-discount-checkbox {
  width: 12px;
  height: 12px;
}
.mc-price-adjustment-wrapper .mc-additional-discount-text {
  font-size: 14px;
}
.mc-price-adjustment-wrapper .mc-select-blue {
  background-color: rgba(42, 175, 244, 0.23);
}
.mc-price-adjustment-wrapper .mc-select-gray {
  background-color: #d3d3d3;
}
.mc-price-adjustment-wrapper .mc-invalid-additional-discount {
  padding-top: 13px;
  padding-right: calc(140px + 5px);
}

/*Price adjustment wrapper /end*/
.mc-apply-button-wrapper {
  position: absolute;
  top: -22px;
  right: 0;
  width: -webkit-fill-available;
  /*Apply button*/
}
.mc-apply-button-wrapper .dropdown-toggle::after {
  display: none;
}
.mc-apply-button-wrapper .mc-apply-button {
  position: absolute;
  width: 20px;
  height: 15px;
  top: 5px;
  right: 5px;
  border-radius: 3px;
  border: 1px solid #B6B8BA;
  background-color: #fff;
  opacity: 0.8;
}
.mc-apply-button-wrapper .mc-apply-button:focus {
  outline-style: none;
}
.mc-apply-button-wrapper .mc-apply-button .mc-more-options {
  position: absolute;
  top: -10px;
  right: 4px;
  height: 10px;
}
.mc-apply-button-wrapper .mc-apply-button:hover {
  opacity: 1;
  animation: 0.3s;
}
.mc-apply-button-wrapper .mc-options-dropdown {
  border: 1px solid #B6B8BA;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: fit-content;
  height: auto;
  z-index: 500;
  position: absolute;
  right: -27px;
  top: 22px;
  font-size: 0.8rem;
}
.mc-apply-button-wrapper .mc-options-dropdown .mc-option-dropdown-item:hover {
  cursor: pointer;
  background-color: #f7f7f7;
  opacity: 0.8;
}

.mc-bank-account .modal-dialog {
  max-width: 700px;
}
.mc-bank-account .modal-dialog .modal-content {
  border-radius: 0;
  padding: 20px 30px 30px !important;
}
.mc-bank-account .modal-dialog .modal-content .modal-header {
  border-bottom: none;
  padding-top: 5px;
}
.mc-bank-account .modal-dialog .modal-content .modal-header .mc-bank-account-modal-header {
  display: block;
  border-bottom: 0;
  padding-left: 0 !important;
  padding-bottom: 10px;
}
.mc-bank-account .modal-dialog .modal-content .modal-header .close {
  font-size: 60px;
  font-weight: 100;
  outline: none;
  padding: 0 10px;
  color: #B6B8BA;
}
.mc-bank-account .modal-dialog .modal-content .modal-body {
  padding: 0 !important;
}
.mc-bank-account .modal-dialog .modal-content .modal-body #modal-basic-title {
  margin-bottom: 0;
  border-bottom: 3px solid #B6B8BA;
}
.mc-bank-account .modal-dialog .modal-content .modal-body .mc-notify-wrapper {
  position: relative;
  top: -15px;
  margin-bottom: 19px;
}
.mc-bank-account .modal-dialog .modal-content .modal-body .bank-account-form {
  background: #F5F5F5;
  padding: 5%;
  border-top: 3px solid #B6B8BA;
}
.mc-bank-account .modal-dialog .modal-content .modal-body .bank-account-form .align-label {
  display: flex;
  align-items: center;
}
.mc-bank-account .modal-dialog .modal-content .modal-body label {
  margin-bottom: 1%;
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.mc-bank-account .modal-dialog .modal-content .modal-body input {
  border: 1px solid #E3ECF3;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 14px;
}
.mc-bank-account .modal-dialog .modal-content .modal-body .hide-margin {
  margin-bottom: 0;
}
.mc-bank-account .modal-dialog .modal-content .modal-body .gray-text-info {
  font-size: 13px;
  color: #B6B8BA;
}
.mc-bank-account .modal-dialog .modal-content .modal-body .required-input {
  color: #ff0000;
}
.mc-bank-account .modal-dialog .modal-content .modal-body .mc-confirm-btn {
  background-color: #35b1ec;
  color: #fff;
  padding: 4px 25px;
  float: right;
  border: none;
  outline: none;
  -webkit-border-radius: 50px 50px 50px;
  -moz-border-radius: 50px 50px 50px;
  border-radius: 50px 50px 50px;
}

.mc-export-sepa-modal .modal-dialog {
  min-width: 1280px;
}

.mc-transactions-table-description-column {
  width: 700px;
}

.mc-transaction-profile .content-wrapper .content-header {
  display: flex;
}
.mc-transaction-profile .content-wrapper .content-header .modal-title {
  padding-left: 10px;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-notify-message {
  height: 30px;
  width: auto;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label {
  display: flex;
  background-color: #E9F5FC;
  border-radius: 0 4px 4px 0;
  border-left: 8px solid #36B1F1;
  margin-bottom: 20px;
  height: 70px;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label h6 {
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 25px;
  padding-top: 27px;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status {
  padding-left: 20px;
  padding-top: 25px;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status .icon-tooltip {
  position: relative;
  display: inline-block;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status .icon-tooltip .icon-tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: #FFFFFF;
  color: #000000;
  text-align: center;
  border-radius: 4px;
  padding: 8px 4px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 70%;
  margin-left: -60px;
  font-size: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status .icon-tooltip .icon-tooltip-text:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 35%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status .icon-tooltip:hover .icon-tooltip-text {
  visibility: visible;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status .icon-tooltip:hover .icon-tooltip-hidden {
  visibility: hidden;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status img {
  margin-top: -5px;
  padding-right: 5px;
  width: auto;
  height: 19px;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-status-label .mc-transaction-status img:first-child {
  width: auto;
  height: 24px;
}
.mc-transaction-profile .content-wrapper .content-body .info-data {
  margin-bottom: 30px;
}
.mc-transaction-profile .content-wrapper .content-body .info-data .title {
  font-weight: 600;
  font-size: 14px;
}
.mc-transaction-profile .content-wrapper .content-body .info-data .mc-transaction-profile-left-column {
  background-color: #f8f8f8;
  min-height: 100%;
  border-radius: 0 4px 4px 0;
  margin: 0 15px;
  padding: 10px;
}
.mc-transaction-profile .content-wrapper .content-body .info-data .mc-transaction-profile-right-column {
  background-color: #E9F5FC;
  border-radius: 0 4px 4px 0;
  min-height: 100%;
  min-width: 38.5%;
  padding: 20px;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-history-heading {
  border-bottom: 1px solid #dee2e6;
  max-width: 98%;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-history-heading i {
  padding-right: 10px;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-history-heading h5 {
  text-transform: uppercase;
}
.mc-transaction-profile .content-wrapper .content-body .mc-transaction-profile-buttons {
  text-align: right;
  margin-top: 10px;
}
.mc-transaction-profile .content-wrapper .mc-description-bold .description {
  font-weight: 600;
  max-width: 50%;
  flex: 0 0 50%;
}
.mc-transaction-profile .content-wrapper .mc-description-bold .title {
  max-width: 50%;
  flex: 0 0 50%;
}
.mc-transaction-profile .content-wrapper .mc-transaction-money-col .description {
  max-width: 50%;
  flex: 0 0 50%;
  text-align: right;
}
.mc-transaction-profile .content-wrapper .mc-transaction-money-col .title {
  max-width: 50%;
  flex: 0 0 50%;
}

/*
**Use it for generating classes with parameter
*/
/*Height*/
.mc-g-height-1 {
  height: 1px;
}

/*Padding x-os*/
.mc-g-padding-x-1 {
  padding: 0 1px;
}

/*Font size*/
.mc-g-font-size-1 {
  font-size: 1px;
}

/*Height*/
.mc-g-height-2 {
  height: 2px;
}

/*Padding x-os*/
.mc-g-padding-x-2 {
  padding: 0 2px;
}

/*Font size*/
.mc-g-font-size-2 {
  font-size: 2px;
}

/*Height*/
.mc-g-height-3 {
  height: 3px;
}

/*Padding x-os*/
.mc-g-padding-x-3 {
  padding: 0 3px;
}

/*Font size*/
.mc-g-font-size-3 {
  font-size: 3px;
}

/*Height*/
.mc-g-height-4 {
  height: 4px;
}

/*Padding x-os*/
.mc-g-padding-x-4 {
  padding: 0 4px;
}

/*Font size*/
.mc-g-font-size-4 {
  font-size: 4px;
}

/*Height*/
.mc-g-height-5 {
  height: 5px;
}

/*Padding x-os*/
.mc-g-padding-x-5 {
  padding: 0 5px;
}

/*Font size*/
.mc-g-font-size-5 {
  font-size: 5px;
}

/*Height*/
.mc-g-height-6 {
  height: 6px;
}

/*Padding x-os*/
.mc-g-padding-x-6 {
  padding: 0 6px;
}

/*Font size*/
.mc-g-font-size-6 {
  font-size: 6px;
}

/*Height*/
.mc-g-height-7 {
  height: 7px;
}

/*Padding x-os*/
.mc-g-padding-x-7 {
  padding: 0 7px;
}

/*Font size*/
.mc-g-font-size-7 {
  font-size: 7px;
}

/*Height*/
.mc-g-height-8 {
  height: 8px;
}

/*Padding x-os*/
.mc-g-padding-x-8 {
  padding: 0 8px;
}

/*Font size*/
.mc-g-font-size-8 {
  font-size: 8px;
}

/*Height*/
.mc-g-height-9 {
  height: 9px;
}

/*Padding x-os*/
.mc-g-padding-x-9 {
  padding: 0 9px;
}

/*Font size*/
.mc-g-font-size-9 {
  font-size: 9px;
}

/*Height*/
.mc-g-height-10 {
  height: 10px;
}

/*Padding x-os*/
.mc-g-padding-x-10 {
  padding: 0 10px;
}

/*Font size*/
.mc-g-font-size-10 {
  font-size: 10px;
}

/*Height*/
.mc-g-height-11 {
  height: 11px;
}

/*Padding x-os*/
.mc-g-padding-x-11 {
  padding: 0 11px;
}

/*Font size*/
.mc-g-font-size-11 {
  font-size: 11px;
}

/*Height*/
.mc-g-height-12 {
  height: 12px;
}

/*Padding x-os*/
.mc-g-padding-x-12 {
  padding: 0 12px;
}

/*Font size*/
.mc-g-font-size-12 {
  font-size: 12px;
}

/*Height*/
.mc-g-height-13 {
  height: 13px;
}

/*Padding x-os*/
.mc-g-padding-x-13 {
  padding: 0 13px;
}

/*Font size*/
.mc-g-font-size-13 {
  font-size: 13px;
}

/*Height*/
.mc-g-height-14 {
  height: 14px;
}

/*Padding x-os*/
.mc-g-padding-x-14 {
  padding: 0 14px;
}

/*Font size*/
.mc-g-font-size-14 {
  font-size: 14px;
}

/*Height*/
.mc-g-height-15 {
  height: 15px;
}

/*Padding x-os*/
.mc-g-padding-x-15 {
  padding: 0 15px;
}

/*Font size*/
.mc-g-font-size-15 {
  font-size: 15px;
}

/*Height*/
.mc-g-height-16 {
  height: 16px;
}

/*Padding x-os*/
.mc-g-padding-x-16 {
  padding: 0 16px;
}

/*Font size*/
.mc-g-font-size-16 {
  font-size: 16px;
}

/*Height*/
.mc-g-height-17 {
  height: 17px;
}

/*Padding x-os*/
.mc-g-padding-x-17 {
  padding: 0 17px;
}

/*Font size*/
.mc-g-font-size-17 {
  font-size: 17px;
}

/*Height*/
.mc-g-height-18 {
  height: 18px;
}

/*Padding x-os*/
.mc-g-padding-x-18 {
  padding: 0 18px;
}

/*Font size*/
.mc-g-font-size-18 {
  font-size: 18px;
}

/*Height*/
.mc-g-height-19 {
  height: 19px;
}

/*Padding x-os*/
.mc-g-padding-x-19 {
  padding: 0 19px;
}

/*Font size*/
.mc-g-font-size-19 {
  font-size: 19px;
}

/*Height*/
.mc-g-height-20 {
  height: 20px;
}

/*Padding x-os*/
.mc-g-padding-x-20 {
  padding: 0 20px;
}

/*Font size*/
.mc-g-font-size-20 {
  font-size: 20px;
}

/*Height*/
.mc-g-height-21 {
  height: 21px;
}

/*Padding x-os*/
.mc-g-padding-x-21 {
  padding: 0 21px;
}

/*Font size*/
.mc-g-font-size-21 {
  font-size: 21px;
}

/*Height*/
.mc-g-height-22 {
  height: 22px;
}

/*Padding x-os*/
.mc-g-padding-x-22 {
  padding: 0 22px;
}

/*Font size*/
.mc-g-font-size-22 {
  font-size: 22px;
}

/*Height*/
.mc-g-height-23 {
  height: 23px;
}

/*Padding x-os*/
.mc-g-padding-x-23 {
  padding: 0 23px;
}

/*Font size*/
.mc-g-font-size-23 {
  font-size: 23px;
}

/*Height*/
.mc-g-height-24 {
  height: 24px;
}

/*Padding x-os*/
.mc-g-padding-x-24 {
  padding: 0 24px;
}

/*Font size*/
.mc-g-font-size-24 {
  font-size: 24px;
}

/*Height*/
.mc-g-height-25 {
  height: 25px;
}

/*Padding x-os*/
.mc-g-padding-x-25 {
  padding: 0 25px;
}

/*Font size*/
.mc-g-font-size-25 {
  font-size: 25px;
}

/*Height*/
.mc-g-height-26 {
  height: 26px;
}

/*Padding x-os*/
.mc-g-padding-x-26 {
  padding: 0 26px;
}

/*Font size*/
.mc-g-font-size-26 {
  font-size: 26px;
}

/*Height*/
.mc-g-height-27 {
  height: 27px;
}

/*Padding x-os*/
.mc-g-padding-x-27 {
  padding: 0 27px;
}

/*Font size*/
.mc-g-font-size-27 {
  font-size: 27px;
}

/*Height*/
.mc-g-height-28 {
  height: 28px;
}

/*Padding x-os*/
.mc-g-padding-x-28 {
  padding: 0 28px;
}

/*Font size*/
.mc-g-font-size-28 {
  font-size: 28px;
}

/*Height*/
.mc-g-height-29 {
  height: 29px;
}

/*Padding x-os*/
.mc-g-padding-x-29 {
  padding: 0 29px;
}

/*Font size*/
.mc-g-font-size-29 {
  font-size: 29px;
}

/*Height*/
.mc-g-height-30 {
  height: 30px;
}

/*Padding x-os*/
.mc-g-padding-x-30 {
  padding: 0 30px;
}

/*Font size*/
.mc-g-font-size-30 {
  font-size: 30px;
}

/*Height*/
.mc-g-height-31 {
  height: 31px;
}

/*Padding x-os*/
.mc-g-padding-x-31 {
  padding: 0 31px;
}

/*Font size*/
.mc-g-font-size-31 {
  font-size: 31px;
}

/*Height*/
.mc-g-height-32 {
  height: 32px;
}

/*Padding x-os*/
.mc-g-padding-x-32 {
  padding: 0 32px;
}

/*Font size*/
.mc-g-font-size-32 {
  font-size: 32px;
}

/*Height*/
.mc-g-height-33 {
  height: 33px;
}

/*Padding x-os*/
.mc-g-padding-x-33 {
  padding: 0 33px;
}

/*Font size*/
.mc-g-font-size-33 {
  font-size: 33px;
}

/*Height*/
.mc-g-height-34 {
  height: 34px;
}

/*Padding x-os*/
.mc-g-padding-x-34 {
  padding: 0 34px;
}

/*Font size*/
.mc-g-font-size-34 {
  font-size: 34px;
}

/*Height*/
.mc-g-height-35 {
  height: 35px;
}

/*Padding x-os*/
.mc-g-padding-x-35 {
  padding: 0 35px;
}

/*Font size*/
.mc-g-font-size-35 {
  font-size: 35px;
}

/*Height*/
.mc-g-height-36 {
  height: 36px;
}

/*Padding x-os*/
.mc-g-padding-x-36 {
  padding: 0 36px;
}

/*Font size*/
.mc-g-font-size-36 {
  font-size: 36px;
}

/*Height*/
.mc-g-height-37 {
  height: 37px;
}

/*Padding x-os*/
.mc-g-padding-x-37 {
  padding: 0 37px;
}

/*Font size*/
.mc-g-font-size-37 {
  font-size: 37px;
}

/*Height*/
.mc-g-height-38 {
  height: 38px;
}

/*Padding x-os*/
.mc-g-padding-x-38 {
  padding: 0 38px;
}

/*Font size*/
.mc-g-font-size-38 {
  font-size: 38px;
}

/*Height*/
.mc-g-height-39 {
  height: 39px;
}

/*Padding x-os*/
.mc-g-padding-x-39 {
  padding: 0 39px;
}

/*Font size*/
.mc-g-font-size-39 {
  font-size: 39px;
}

/*Height*/
.mc-g-height-40 {
  height: 40px;
}

/*Padding x-os*/
.mc-g-padding-x-40 {
  padding: 0 40px;
}

/*Font size*/
.mc-g-font-size-40 {
  font-size: 40px;
}

/*Height*/
.mc-g-height-41 {
  height: 41px;
}

/*Padding x-os*/
.mc-g-padding-x-41 {
  padding: 0 41px;
}

/*Font size*/
.mc-g-font-size-41 {
  font-size: 41px;
}

/*Height*/
.mc-g-height-42 {
  height: 42px;
}

/*Padding x-os*/
.mc-g-padding-x-42 {
  padding: 0 42px;
}

/*Font size*/
.mc-g-font-size-42 {
  font-size: 42px;
}

/*Height*/
.mc-g-height-43 {
  height: 43px;
}

/*Padding x-os*/
.mc-g-padding-x-43 {
  padding: 0 43px;
}

/*Font size*/
.mc-g-font-size-43 {
  font-size: 43px;
}

/*Height*/
.mc-g-height-44 {
  height: 44px;
}

/*Padding x-os*/
.mc-g-padding-x-44 {
  padding: 0 44px;
}

/*Font size*/
.mc-g-font-size-44 {
  font-size: 44px;
}

/*Height*/
.mc-g-height-45 {
  height: 45px;
}

/*Padding x-os*/
.mc-g-padding-x-45 {
  padding: 0 45px;
}

/*Font size*/
.mc-g-font-size-45 {
  font-size: 45px;
}

/*Height*/
.mc-g-height-46 {
  height: 46px;
}

/*Padding x-os*/
.mc-g-padding-x-46 {
  padding: 0 46px;
}

/*Font size*/
.mc-g-font-size-46 {
  font-size: 46px;
}

/*Height*/
.mc-g-height-47 {
  height: 47px;
}

/*Padding x-os*/
.mc-g-padding-x-47 {
  padding: 0 47px;
}

/*Font size*/
.mc-g-font-size-47 {
  font-size: 47px;
}

/*Height*/
.mc-g-height-48 {
  height: 48px;
}

/*Padding x-os*/
.mc-g-padding-x-48 {
  padding: 0 48px;
}

/*Font size*/
.mc-g-font-size-48 {
  font-size: 48px;
}

/*Height*/
.mc-g-height-49 {
  height: 49px;
}

/*Padding x-os*/
.mc-g-padding-x-49 {
  padding: 0 49px;
}

/*Font size*/
.mc-g-font-size-49 {
  font-size: 49px;
}

/*Height*/
.mc-g-height-50 {
  height: 50px;
}

/*Padding x-os*/
.mc-g-padding-x-50 {
  padding: 0 50px;
}

/*Font size*/
.mc-g-font-size-50 {
  font-size: 50px;
}

/*Height*/
.mc-g-height-51 {
  height: 51px;
}

/*Padding x-os*/
.mc-g-padding-x-51 {
  padding: 0 51px;
}

/*Font size*/
.mc-g-font-size-51 {
  font-size: 51px;
}

/*Height*/
.mc-g-height-52 {
  height: 52px;
}

/*Padding x-os*/
.mc-g-padding-x-52 {
  padding: 0 52px;
}

/*Font size*/
.mc-g-font-size-52 {
  font-size: 52px;
}

/*Height*/
.mc-g-height-53 {
  height: 53px;
}

/*Padding x-os*/
.mc-g-padding-x-53 {
  padding: 0 53px;
}

/*Font size*/
.mc-g-font-size-53 {
  font-size: 53px;
}

/*Height*/
.mc-g-height-54 {
  height: 54px;
}

/*Padding x-os*/
.mc-g-padding-x-54 {
  padding: 0 54px;
}

/*Font size*/
.mc-g-font-size-54 {
  font-size: 54px;
}

/*Height*/
.mc-g-height-55 {
  height: 55px;
}

/*Padding x-os*/
.mc-g-padding-x-55 {
  padding: 0 55px;
}

/*Font size*/
.mc-g-font-size-55 {
  font-size: 55px;
}

/*Height*/
.mc-g-height-56 {
  height: 56px;
}

/*Padding x-os*/
.mc-g-padding-x-56 {
  padding: 0 56px;
}

/*Font size*/
.mc-g-font-size-56 {
  font-size: 56px;
}

/*Height*/
.mc-g-height-57 {
  height: 57px;
}

/*Padding x-os*/
.mc-g-padding-x-57 {
  padding: 0 57px;
}

/*Font size*/
.mc-g-font-size-57 {
  font-size: 57px;
}

/*Height*/
.mc-g-height-58 {
  height: 58px;
}

/*Padding x-os*/
.mc-g-padding-x-58 {
  padding: 0 58px;
}

/*Font size*/
.mc-g-font-size-58 {
  font-size: 58px;
}

/*Height*/
.mc-g-height-59 {
  height: 59px;
}

/*Padding x-os*/
.mc-g-padding-x-59 {
  padding: 0 59px;
}

/*Font size*/
.mc-g-font-size-59 {
  font-size: 59px;
}

/*Height*/
.mc-g-height-60 {
  height: 60px;
}

/*Padding x-os*/
.mc-g-padding-x-60 {
  padding: 0 60px;
}

/*Font size*/
.mc-g-font-size-60 {
  font-size: 60px;
}

/*Height*/
.mc-g-height-61 {
  height: 61px;
}

/*Padding x-os*/
.mc-g-padding-x-61 {
  padding: 0 61px;
}

/*Font size*/
.mc-g-font-size-61 {
  font-size: 61px;
}

/*Height*/
.mc-g-height-62 {
  height: 62px;
}

/*Padding x-os*/
.mc-g-padding-x-62 {
  padding: 0 62px;
}

/*Font size*/
.mc-g-font-size-62 {
  font-size: 62px;
}

/*Height*/
.mc-g-height-63 {
  height: 63px;
}

/*Padding x-os*/
.mc-g-padding-x-63 {
  padding: 0 63px;
}

/*Font size*/
.mc-g-font-size-63 {
  font-size: 63px;
}

/*Height*/
.mc-g-height-64 {
  height: 64px;
}

/*Padding x-os*/
.mc-g-padding-x-64 {
  padding: 0 64px;
}

/*Font size*/
.mc-g-font-size-64 {
  font-size: 64px;
}

/*Height*/
.mc-g-height-65 {
  height: 65px;
}

/*Padding x-os*/
.mc-g-padding-x-65 {
  padding: 0 65px;
}

/*Font size*/
.mc-g-font-size-65 {
  font-size: 65px;
}

/*Height*/
.mc-g-height-66 {
  height: 66px;
}

/*Padding x-os*/
.mc-g-padding-x-66 {
  padding: 0 66px;
}

/*Font size*/
.mc-g-font-size-66 {
  font-size: 66px;
}

/*Height*/
.mc-g-height-67 {
  height: 67px;
}

/*Padding x-os*/
.mc-g-padding-x-67 {
  padding: 0 67px;
}

/*Font size*/
.mc-g-font-size-67 {
  font-size: 67px;
}

/*Height*/
.mc-g-height-68 {
  height: 68px;
}

/*Padding x-os*/
.mc-g-padding-x-68 {
  padding: 0 68px;
}

/*Font size*/
.mc-g-font-size-68 {
  font-size: 68px;
}

/*Height*/
.mc-g-height-69 {
  height: 69px;
}

/*Padding x-os*/
.mc-g-padding-x-69 {
  padding: 0 69px;
}

/*Font size*/
.mc-g-font-size-69 {
  font-size: 69px;
}

/*Height*/
.mc-g-height-70 {
  height: 70px;
}

/*Padding x-os*/
.mc-g-padding-x-70 {
  padding: 0 70px;
}

/*Font size*/
.mc-g-font-size-70 {
  font-size: 70px;
}

/*Height*/
.mc-g-height-71 {
  height: 71px;
}

/*Padding x-os*/
.mc-g-padding-x-71 {
  padding: 0 71px;
}

/*Font size*/
.mc-g-font-size-71 {
  font-size: 71px;
}

/*Height*/
.mc-g-height-72 {
  height: 72px;
}

/*Padding x-os*/
.mc-g-padding-x-72 {
  padding: 0 72px;
}

/*Font size*/
.mc-g-font-size-72 {
  font-size: 72px;
}

/*Height*/
.mc-g-height-73 {
  height: 73px;
}

/*Padding x-os*/
.mc-g-padding-x-73 {
  padding: 0 73px;
}

/*Font size*/
.mc-g-font-size-73 {
  font-size: 73px;
}

/*Height*/
.mc-g-height-74 {
  height: 74px;
}

/*Padding x-os*/
.mc-g-padding-x-74 {
  padding: 0 74px;
}

/*Font size*/
.mc-g-font-size-74 {
  font-size: 74px;
}

/*Height*/
.mc-g-height-75 {
  height: 75px;
}

/*Padding x-os*/
.mc-g-padding-x-75 {
  padding: 0 75px;
}

/*Font size*/
.mc-g-font-size-75 {
  font-size: 75px;
}

/*Height*/
.mc-g-height-76 {
  height: 76px;
}

/*Padding x-os*/
.mc-g-padding-x-76 {
  padding: 0 76px;
}

/*Font size*/
.mc-g-font-size-76 {
  font-size: 76px;
}

/*Height*/
.mc-g-height-77 {
  height: 77px;
}

/*Padding x-os*/
.mc-g-padding-x-77 {
  padding: 0 77px;
}

/*Font size*/
.mc-g-font-size-77 {
  font-size: 77px;
}

/*Height*/
.mc-g-height-78 {
  height: 78px;
}

/*Padding x-os*/
.mc-g-padding-x-78 {
  padding: 0 78px;
}

/*Font size*/
.mc-g-font-size-78 {
  font-size: 78px;
}

/*Height*/
.mc-g-height-79 {
  height: 79px;
}

/*Padding x-os*/
.mc-g-padding-x-79 {
  padding: 0 79px;
}

/*Font size*/
.mc-g-font-size-79 {
  font-size: 79px;
}

/*Height*/
.mc-g-height-80 {
  height: 80px;
}

/*Padding x-os*/
.mc-g-padding-x-80 {
  padding: 0 80px;
}

/*Font size*/
.mc-g-font-size-80 {
  font-size: 80px;
}

/*Height*/
.mc-g-height-81 {
  height: 81px;
}

/*Padding x-os*/
.mc-g-padding-x-81 {
  padding: 0 81px;
}

/*Font size*/
.mc-g-font-size-81 {
  font-size: 81px;
}

/*Height*/
.mc-g-height-82 {
  height: 82px;
}

/*Padding x-os*/
.mc-g-padding-x-82 {
  padding: 0 82px;
}

/*Font size*/
.mc-g-font-size-82 {
  font-size: 82px;
}

/*Height*/
.mc-g-height-83 {
  height: 83px;
}

/*Padding x-os*/
.mc-g-padding-x-83 {
  padding: 0 83px;
}

/*Font size*/
.mc-g-font-size-83 {
  font-size: 83px;
}

/*Height*/
.mc-g-height-84 {
  height: 84px;
}

/*Padding x-os*/
.mc-g-padding-x-84 {
  padding: 0 84px;
}

/*Font size*/
.mc-g-font-size-84 {
  font-size: 84px;
}

/*Height*/
.mc-g-height-85 {
  height: 85px;
}

/*Padding x-os*/
.mc-g-padding-x-85 {
  padding: 0 85px;
}

/*Font size*/
.mc-g-font-size-85 {
  font-size: 85px;
}

/*Height*/
.mc-g-height-86 {
  height: 86px;
}

/*Padding x-os*/
.mc-g-padding-x-86 {
  padding: 0 86px;
}

/*Font size*/
.mc-g-font-size-86 {
  font-size: 86px;
}

/*Height*/
.mc-g-height-87 {
  height: 87px;
}

/*Padding x-os*/
.mc-g-padding-x-87 {
  padding: 0 87px;
}

/*Font size*/
.mc-g-font-size-87 {
  font-size: 87px;
}

/*Height*/
.mc-g-height-88 {
  height: 88px;
}

/*Padding x-os*/
.mc-g-padding-x-88 {
  padding: 0 88px;
}

/*Font size*/
.mc-g-font-size-88 {
  font-size: 88px;
}

/*Height*/
.mc-g-height-89 {
  height: 89px;
}

/*Padding x-os*/
.mc-g-padding-x-89 {
  padding: 0 89px;
}

/*Font size*/
.mc-g-font-size-89 {
  font-size: 89px;
}

/*Height*/
.mc-g-height-90 {
  height: 90px;
}

/*Padding x-os*/
.mc-g-padding-x-90 {
  padding: 0 90px;
}

/*Font size*/
.mc-g-font-size-90 {
  font-size: 90px;
}

/*Height*/
.mc-g-height-91 {
  height: 91px;
}

/*Padding x-os*/
.mc-g-padding-x-91 {
  padding: 0 91px;
}

/*Font size*/
.mc-g-font-size-91 {
  font-size: 91px;
}

/*Height*/
.mc-g-height-92 {
  height: 92px;
}

/*Padding x-os*/
.mc-g-padding-x-92 {
  padding: 0 92px;
}

/*Font size*/
.mc-g-font-size-92 {
  font-size: 92px;
}

/*Height*/
.mc-g-height-93 {
  height: 93px;
}

/*Padding x-os*/
.mc-g-padding-x-93 {
  padding: 0 93px;
}

/*Font size*/
.mc-g-font-size-93 {
  font-size: 93px;
}

/*Height*/
.mc-g-height-94 {
  height: 94px;
}

/*Padding x-os*/
.mc-g-padding-x-94 {
  padding: 0 94px;
}

/*Font size*/
.mc-g-font-size-94 {
  font-size: 94px;
}

/*Height*/
.mc-g-height-95 {
  height: 95px;
}

/*Padding x-os*/
.mc-g-padding-x-95 {
  padding: 0 95px;
}

/*Font size*/
.mc-g-font-size-95 {
  font-size: 95px;
}

/*Height*/
.mc-g-height-96 {
  height: 96px;
}

/*Padding x-os*/
.mc-g-padding-x-96 {
  padding: 0 96px;
}

/*Font size*/
.mc-g-font-size-96 {
  font-size: 96px;
}

/*Height*/
.mc-g-height-97 {
  height: 97px;
}

/*Padding x-os*/
.mc-g-padding-x-97 {
  padding: 0 97px;
}

/*Font size*/
.mc-g-font-size-97 {
  font-size: 97px;
}

/*Height*/
.mc-g-height-98 {
  height: 98px;
}

/*Padding x-os*/
.mc-g-padding-x-98 {
  padding: 0 98px;
}

/*Font size*/
.mc-g-font-size-98 {
  font-size: 98px;
}

/*Height*/
.mc-g-height-99 {
  height: 99px;
}

/*Padding x-os*/
.mc-g-padding-x-99 {
  padding: 0 99px;
}

/*Font size*/
.mc-g-font-size-99 {
  font-size: 99px;
}

/*Height*/
.mc-g-height-100 {
  height: 100px;
}

/*Padding x-os*/
.mc-g-padding-x-100 {
  padding: 0 100px;
}

/*Font size*/
.mc-g-font-size-100 {
  font-size: 100px;
}

/*Height*/
.mc-g-height-101 {
  height: 101px;
}

/*Padding x-os*/
.mc-g-padding-x-101 {
  padding: 0 101px;
}

/*Font size*/
.mc-g-font-size-101 {
  font-size: 101px;
}

/*Height*/
.mc-g-height-102 {
  height: 102px;
}

/*Padding x-os*/
.mc-g-padding-x-102 {
  padding: 0 102px;
}

/*Font size*/
.mc-g-font-size-102 {
  font-size: 102px;
}

/*Height*/
.mc-g-height-103 {
  height: 103px;
}

/*Padding x-os*/
.mc-g-padding-x-103 {
  padding: 0 103px;
}

/*Font size*/
.mc-g-font-size-103 {
  font-size: 103px;
}

/*Height*/
.mc-g-height-104 {
  height: 104px;
}

/*Padding x-os*/
.mc-g-padding-x-104 {
  padding: 0 104px;
}

/*Font size*/
.mc-g-font-size-104 {
  font-size: 104px;
}

/*Height*/
.mc-g-height-105 {
  height: 105px;
}

/*Padding x-os*/
.mc-g-padding-x-105 {
  padding: 0 105px;
}

/*Font size*/
.mc-g-font-size-105 {
  font-size: 105px;
}

/*Height*/
.mc-g-height-106 {
  height: 106px;
}

/*Padding x-os*/
.mc-g-padding-x-106 {
  padding: 0 106px;
}

/*Font size*/
.mc-g-font-size-106 {
  font-size: 106px;
}

/*Height*/
.mc-g-height-107 {
  height: 107px;
}

/*Padding x-os*/
.mc-g-padding-x-107 {
  padding: 0 107px;
}

/*Font size*/
.mc-g-font-size-107 {
  font-size: 107px;
}

/*Height*/
.mc-g-height-108 {
  height: 108px;
}

/*Padding x-os*/
.mc-g-padding-x-108 {
  padding: 0 108px;
}

/*Font size*/
.mc-g-font-size-108 {
  font-size: 108px;
}

/*Height*/
.mc-g-height-109 {
  height: 109px;
}

/*Padding x-os*/
.mc-g-padding-x-109 {
  padding: 0 109px;
}

/*Font size*/
.mc-g-font-size-109 {
  font-size: 109px;
}

/*Height*/
.mc-g-height-110 {
  height: 110px;
}

/*Padding x-os*/
.mc-g-padding-x-110 {
  padding: 0 110px;
}

/*Font size*/
.mc-g-font-size-110 {
  font-size: 110px;
}

/*Height*/
.mc-g-height-111 {
  height: 111px;
}

/*Padding x-os*/
.mc-g-padding-x-111 {
  padding: 0 111px;
}

/*Font size*/
.mc-g-font-size-111 {
  font-size: 111px;
}

/*Height*/
.mc-g-height-112 {
  height: 112px;
}

/*Padding x-os*/
.mc-g-padding-x-112 {
  padding: 0 112px;
}

/*Font size*/
.mc-g-font-size-112 {
  font-size: 112px;
}

/*Height*/
.mc-g-height-113 {
  height: 113px;
}

/*Padding x-os*/
.mc-g-padding-x-113 {
  padding: 0 113px;
}

/*Font size*/
.mc-g-font-size-113 {
  font-size: 113px;
}

/*Height*/
.mc-g-height-114 {
  height: 114px;
}

/*Padding x-os*/
.mc-g-padding-x-114 {
  padding: 0 114px;
}

/*Font size*/
.mc-g-font-size-114 {
  font-size: 114px;
}

/*Height*/
.mc-g-height-115 {
  height: 115px;
}

/*Padding x-os*/
.mc-g-padding-x-115 {
  padding: 0 115px;
}

/*Font size*/
.mc-g-font-size-115 {
  font-size: 115px;
}

/*Height*/
.mc-g-height-116 {
  height: 116px;
}

/*Padding x-os*/
.mc-g-padding-x-116 {
  padding: 0 116px;
}

/*Font size*/
.mc-g-font-size-116 {
  font-size: 116px;
}

/*Height*/
.mc-g-height-117 {
  height: 117px;
}

/*Padding x-os*/
.mc-g-padding-x-117 {
  padding: 0 117px;
}

/*Font size*/
.mc-g-font-size-117 {
  font-size: 117px;
}

/*Height*/
.mc-g-height-118 {
  height: 118px;
}

/*Padding x-os*/
.mc-g-padding-x-118 {
  padding: 0 118px;
}

/*Font size*/
.mc-g-font-size-118 {
  font-size: 118px;
}

/*Height*/
.mc-g-height-119 {
  height: 119px;
}

/*Padding x-os*/
.mc-g-padding-x-119 {
  padding: 0 119px;
}

/*Font size*/
.mc-g-font-size-119 {
  font-size: 119px;
}

/*Height*/
.mc-g-height-120 {
  height: 120px;
}

/*Padding x-os*/
.mc-g-padding-x-120 {
  padding: 0 120px;
}

/*Font size*/
.mc-g-font-size-120 {
  font-size: 120px;
}

/*Height*/
.mc-g-height-121 {
  height: 121px;
}

/*Padding x-os*/
.mc-g-padding-x-121 {
  padding: 0 121px;
}

/*Font size*/
.mc-g-font-size-121 {
  font-size: 121px;
}

/*Height*/
.mc-g-height-122 {
  height: 122px;
}

/*Padding x-os*/
.mc-g-padding-x-122 {
  padding: 0 122px;
}

/*Font size*/
.mc-g-font-size-122 {
  font-size: 122px;
}

/*Height*/
.mc-g-height-123 {
  height: 123px;
}

/*Padding x-os*/
.mc-g-padding-x-123 {
  padding: 0 123px;
}

/*Font size*/
.mc-g-font-size-123 {
  font-size: 123px;
}

/*Height*/
.mc-g-height-124 {
  height: 124px;
}

/*Padding x-os*/
.mc-g-padding-x-124 {
  padding: 0 124px;
}

/*Font size*/
.mc-g-font-size-124 {
  font-size: 124px;
}

/*Height*/
.mc-g-height-125 {
  height: 125px;
}

/*Padding x-os*/
.mc-g-padding-x-125 {
  padding: 0 125px;
}

/*Font size*/
.mc-g-font-size-125 {
  font-size: 125px;
}

/*Height*/
.mc-g-height-126 {
  height: 126px;
}

/*Padding x-os*/
.mc-g-padding-x-126 {
  padding: 0 126px;
}

/*Font size*/
.mc-g-font-size-126 {
  font-size: 126px;
}

/*Height*/
.mc-g-height-127 {
  height: 127px;
}

/*Padding x-os*/
.mc-g-padding-x-127 {
  padding: 0 127px;
}

/*Font size*/
.mc-g-font-size-127 {
  font-size: 127px;
}

/*Height*/
.mc-g-height-128 {
  height: 128px;
}

/*Padding x-os*/
.mc-g-padding-x-128 {
  padding: 0 128px;
}

/*Font size*/
.mc-g-font-size-128 {
  font-size: 128px;
}

/*Height*/
.mc-g-height-129 {
  height: 129px;
}

/*Padding x-os*/
.mc-g-padding-x-129 {
  padding: 0 129px;
}

/*Font size*/
.mc-g-font-size-129 {
  font-size: 129px;
}

/*Height*/
.mc-g-height-130 {
  height: 130px;
}

/*Padding x-os*/
.mc-g-padding-x-130 {
  padding: 0 130px;
}

/*Font size*/
.mc-g-font-size-130 {
  font-size: 130px;
}

/*Height*/
.mc-g-height-131 {
  height: 131px;
}

/*Padding x-os*/
.mc-g-padding-x-131 {
  padding: 0 131px;
}

/*Font size*/
.mc-g-font-size-131 {
  font-size: 131px;
}

/*Height*/
.mc-g-height-132 {
  height: 132px;
}

/*Padding x-os*/
.mc-g-padding-x-132 {
  padding: 0 132px;
}

/*Font size*/
.mc-g-font-size-132 {
  font-size: 132px;
}

/*Height*/
.mc-g-height-133 {
  height: 133px;
}

/*Padding x-os*/
.mc-g-padding-x-133 {
  padding: 0 133px;
}

/*Font size*/
.mc-g-font-size-133 {
  font-size: 133px;
}

/*Height*/
.mc-g-height-134 {
  height: 134px;
}

/*Padding x-os*/
.mc-g-padding-x-134 {
  padding: 0 134px;
}

/*Font size*/
.mc-g-font-size-134 {
  font-size: 134px;
}

/*Height*/
.mc-g-height-135 {
  height: 135px;
}

/*Padding x-os*/
.mc-g-padding-x-135 {
  padding: 0 135px;
}

/*Font size*/
.mc-g-font-size-135 {
  font-size: 135px;
}

/*Height*/
.mc-g-height-136 {
  height: 136px;
}

/*Padding x-os*/
.mc-g-padding-x-136 {
  padding: 0 136px;
}

/*Font size*/
.mc-g-font-size-136 {
  font-size: 136px;
}

/*Height*/
.mc-g-height-137 {
  height: 137px;
}

/*Padding x-os*/
.mc-g-padding-x-137 {
  padding: 0 137px;
}

/*Font size*/
.mc-g-font-size-137 {
  font-size: 137px;
}

/*Height*/
.mc-g-height-138 {
  height: 138px;
}

/*Padding x-os*/
.mc-g-padding-x-138 {
  padding: 0 138px;
}

/*Font size*/
.mc-g-font-size-138 {
  font-size: 138px;
}

/*Height*/
.mc-g-height-139 {
  height: 139px;
}

/*Padding x-os*/
.mc-g-padding-x-139 {
  padding: 0 139px;
}

/*Font size*/
.mc-g-font-size-139 {
  font-size: 139px;
}

/*Height*/
.mc-g-height-140 {
  height: 140px;
}

/*Padding x-os*/
.mc-g-padding-x-140 {
  padding: 0 140px;
}

/*Font size*/
.mc-g-font-size-140 {
  font-size: 140px;
}

/*Height*/
.mc-g-height-141 {
  height: 141px;
}

/*Padding x-os*/
.mc-g-padding-x-141 {
  padding: 0 141px;
}

/*Font size*/
.mc-g-font-size-141 {
  font-size: 141px;
}

/*Height*/
.mc-g-height-142 {
  height: 142px;
}

/*Padding x-os*/
.mc-g-padding-x-142 {
  padding: 0 142px;
}

/*Font size*/
.mc-g-font-size-142 {
  font-size: 142px;
}

/*Height*/
.mc-g-height-143 {
  height: 143px;
}

/*Padding x-os*/
.mc-g-padding-x-143 {
  padding: 0 143px;
}

/*Font size*/
.mc-g-font-size-143 {
  font-size: 143px;
}

/*Height*/
.mc-g-height-144 {
  height: 144px;
}

/*Padding x-os*/
.mc-g-padding-x-144 {
  padding: 0 144px;
}

/*Font size*/
.mc-g-font-size-144 {
  font-size: 144px;
}

/*Height*/
.mc-g-height-145 {
  height: 145px;
}

/*Padding x-os*/
.mc-g-padding-x-145 {
  padding: 0 145px;
}

/*Font size*/
.mc-g-font-size-145 {
  font-size: 145px;
}

/*Height*/
.mc-g-height-146 {
  height: 146px;
}

/*Padding x-os*/
.mc-g-padding-x-146 {
  padding: 0 146px;
}

/*Font size*/
.mc-g-font-size-146 {
  font-size: 146px;
}

/*Height*/
.mc-g-height-147 {
  height: 147px;
}

/*Padding x-os*/
.mc-g-padding-x-147 {
  padding: 0 147px;
}

/*Font size*/
.mc-g-font-size-147 {
  font-size: 147px;
}

/*Height*/
.mc-g-height-148 {
  height: 148px;
}

/*Padding x-os*/
.mc-g-padding-x-148 {
  padding: 0 148px;
}

/*Font size*/
.mc-g-font-size-148 {
  font-size: 148px;
}

/*Height*/
.mc-g-height-149 {
  height: 149px;
}

/*Padding x-os*/
.mc-g-padding-x-149 {
  padding: 0 149px;
}

/*Font size*/
.mc-g-font-size-149 {
  font-size: 149px;
}

/*Height*/
.mc-g-height-150 {
  height: 150px;
}

/*Padding x-os*/
.mc-g-padding-x-150 {
  padding: 0 150px;
}

/*Font size*/
.mc-g-font-size-150 {
  font-size: 150px;
}

/*Height*/
.mc-g-height-151 {
  height: 151px;
}

/*Padding x-os*/
.mc-g-padding-x-151 {
  padding: 0 151px;
}

/*Font size*/
.mc-g-font-size-151 {
  font-size: 151px;
}

/*Height*/
.mc-g-height-152 {
  height: 152px;
}

/*Padding x-os*/
.mc-g-padding-x-152 {
  padding: 0 152px;
}

/*Font size*/
.mc-g-font-size-152 {
  font-size: 152px;
}

/*Height*/
.mc-g-height-153 {
  height: 153px;
}

/*Padding x-os*/
.mc-g-padding-x-153 {
  padding: 0 153px;
}

/*Font size*/
.mc-g-font-size-153 {
  font-size: 153px;
}

/*Height*/
.mc-g-height-154 {
  height: 154px;
}

/*Padding x-os*/
.mc-g-padding-x-154 {
  padding: 0 154px;
}

/*Font size*/
.mc-g-font-size-154 {
  font-size: 154px;
}

/*Height*/
.mc-g-height-155 {
  height: 155px;
}

/*Padding x-os*/
.mc-g-padding-x-155 {
  padding: 0 155px;
}

/*Font size*/
.mc-g-font-size-155 {
  font-size: 155px;
}

/*Height*/
.mc-g-height-156 {
  height: 156px;
}

/*Padding x-os*/
.mc-g-padding-x-156 {
  padding: 0 156px;
}

/*Font size*/
.mc-g-font-size-156 {
  font-size: 156px;
}

/*Height*/
.mc-g-height-157 {
  height: 157px;
}

/*Padding x-os*/
.mc-g-padding-x-157 {
  padding: 0 157px;
}

/*Font size*/
.mc-g-font-size-157 {
  font-size: 157px;
}

/*Height*/
.mc-g-height-158 {
  height: 158px;
}

/*Padding x-os*/
.mc-g-padding-x-158 {
  padding: 0 158px;
}

/*Font size*/
.mc-g-font-size-158 {
  font-size: 158px;
}

/*Height*/
.mc-g-height-159 {
  height: 159px;
}

/*Padding x-os*/
.mc-g-padding-x-159 {
  padding: 0 159px;
}

/*Font size*/
.mc-g-font-size-159 {
  font-size: 159px;
}

/*Height*/
.mc-g-height-160 {
  height: 160px;
}

/*Padding x-os*/
.mc-g-padding-x-160 {
  padding: 0 160px;
}

/*Font size*/
.mc-g-font-size-160 {
  font-size: 160px;
}

/*Height*/
.mc-g-height-161 {
  height: 161px;
}

/*Padding x-os*/
.mc-g-padding-x-161 {
  padding: 0 161px;
}

/*Font size*/
.mc-g-font-size-161 {
  font-size: 161px;
}

/*Height*/
.mc-g-height-162 {
  height: 162px;
}

/*Padding x-os*/
.mc-g-padding-x-162 {
  padding: 0 162px;
}

/*Font size*/
.mc-g-font-size-162 {
  font-size: 162px;
}

/*Height*/
.mc-g-height-163 {
  height: 163px;
}

/*Padding x-os*/
.mc-g-padding-x-163 {
  padding: 0 163px;
}

/*Font size*/
.mc-g-font-size-163 {
  font-size: 163px;
}

/*Height*/
.mc-g-height-164 {
  height: 164px;
}

/*Padding x-os*/
.mc-g-padding-x-164 {
  padding: 0 164px;
}

/*Font size*/
.mc-g-font-size-164 {
  font-size: 164px;
}

/*Height*/
.mc-g-height-165 {
  height: 165px;
}

/*Padding x-os*/
.mc-g-padding-x-165 {
  padding: 0 165px;
}

/*Font size*/
.mc-g-font-size-165 {
  font-size: 165px;
}

/*Height*/
.mc-g-height-166 {
  height: 166px;
}

/*Padding x-os*/
.mc-g-padding-x-166 {
  padding: 0 166px;
}

/*Font size*/
.mc-g-font-size-166 {
  font-size: 166px;
}

/*Height*/
.mc-g-height-167 {
  height: 167px;
}

/*Padding x-os*/
.mc-g-padding-x-167 {
  padding: 0 167px;
}

/*Font size*/
.mc-g-font-size-167 {
  font-size: 167px;
}

/*Height*/
.mc-g-height-168 {
  height: 168px;
}

/*Padding x-os*/
.mc-g-padding-x-168 {
  padding: 0 168px;
}

/*Font size*/
.mc-g-font-size-168 {
  font-size: 168px;
}

/*Height*/
.mc-g-height-169 {
  height: 169px;
}

/*Padding x-os*/
.mc-g-padding-x-169 {
  padding: 0 169px;
}

/*Font size*/
.mc-g-font-size-169 {
  font-size: 169px;
}

/*Height*/
.mc-g-height-170 {
  height: 170px;
}

/*Padding x-os*/
.mc-g-padding-x-170 {
  padding: 0 170px;
}

/*Font size*/
.mc-g-font-size-170 {
  font-size: 170px;
}

/*Height*/
.mc-g-height-171 {
  height: 171px;
}

/*Padding x-os*/
.mc-g-padding-x-171 {
  padding: 0 171px;
}

/*Font size*/
.mc-g-font-size-171 {
  font-size: 171px;
}

/*Height*/
.mc-g-height-172 {
  height: 172px;
}

/*Padding x-os*/
.mc-g-padding-x-172 {
  padding: 0 172px;
}

/*Font size*/
.mc-g-font-size-172 {
  font-size: 172px;
}

/*Height*/
.mc-g-height-173 {
  height: 173px;
}

/*Padding x-os*/
.mc-g-padding-x-173 {
  padding: 0 173px;
}

/*Font size*/
.mc-g-font-size-173 {
  font-size: 173px;
}

/*Height*/
.mc-g-height-174 {
  height: 174px;
}

/*Padding x-os*/
.mc-g-padding-x-174 {
  padding: 0 174px;
}

/*Font size*/
.mc-g-font-size-174 {
  font-size: 174px;
}

/*Height*/
.mc-g-height-175 {
  height: 175px;
}

/*Padding x-os*/
.mc-g-padding-x-175 {
  padding: 0 175px;
}

/*Font size*/
.mc-g-font-size-175 {
  font-size: 175px;
}

/*Height*/
.mc-g-height-176 {
  height: 176px;
}

/*Padding x-os*/
.mc-g-padding-x-176 {
  padding: 0 176px;
}

/*Font size*/
.mc-g-font-size-176 {
  font-size: 176px;
}

/*Height*/
.mc-g-height-177 {
  height: 177px;
}

/*Padding x-os*/
.mc-g-padding-x-177 {
  padding: 0 177px;
}

/*Font size*/
.mc-g-font-size-177 {
  font-size: 177px;
}

/*Height*/
.mc-g-height-178 {
  height: 178px;
}

/*Padding x-os*/
.mc-g-padding-x-178 {
  padding: 0 178px;
}

/*Font size*/
.mc-g-font-size-178 {
  font-size: 178px;
}

/*Height*/
.mc-g-height-179 {
  height: 179px;
}

/*Padding x-os*/
.mc-g-padding-x-179 {
  padding: 0 179px;
}

/*Font size*/
.mc-g-font-size-179 {
  font-size: 179px;
}

/*Height*/
.mc-g-height-180 {
  height: 180px;
}

/*Padding x-os*/
.mc-g-padding-x-180 {
  padding: 0 180px;
}

/*Font size*/
.mc-g-font-size-180 {
  font-size: 180px;
}

/*Height*/
.mc-g-height-181 {
  height: 181px;
}

/*Padding x-os*/
.mc-g-padding-x-181 {
  padding: 0 181px;
}

/*Font size*/
.mc-g-font-size-181 {
  font-size: 181px;
}

/*Height*/
.mc-g-height-182 {
  height: 182px;
}

/*Padding x-os*/
.mc-g-padding-x-182 {
  padding: 0 182px;
}

/*Font size*/
.mc-g-font-size-182 {
  font-size: 182px;
}

/*Height*/
.mc-g-height-183 {
  height: 183px;
}

/*Padding x-os*/
.mc-g-padding-x-183 {
  padding: 0 183px;
}

/*Font size*/
.mc-g-font-size-183 {
  font-size: 183px;
}

/*Height*/
.mc-g-height-184 {
  height: 184px;
}

/*Padding x-os*/
.mc-g-padding-x-184 {
  padding: 0 184px;
}

/*Font size*/
.mc-g-font-size-184 {
  font-size: 184px;
}

/*Height*/
.mc-g-height-185 {
  height: 185px;
}

/*Padding x-os*/
.mc-g-padding-x-185 {
  padding: 0 185px;
}

/*Font size*/
.mc-g-font-size-185 {
  font-size: 185px;
}

/*Height*/
.mc-g-height-186 {
  height: 186px;
}

/*Padding x-os*/
.mc-g-padding-x-186 {
  padding: 0 186px;
}

/*Font size*/
.mc-g-font-size-186 {
  font-size: 186px;
}

/*Height*/
.mc-g-height-187 {
  height: 187px;
}

/*Padding x-os*/
.mc-g-padding-x-187 {
  padding: 0 187px;
}

/*Font size*/
.mc-g-font-size-187 {
  font-size: 187px;
}

/*Height*/
.mc-g-height-188 {
  height: 188px;
}

/*Padding x-os*/
.mc-g-padding-x-188 {
  padding: 0 188px;
}

/*Font size*/
.mc-g-font-size-188 {
  font-size: 188px;
}

/*Height*/
.mc-g-height-189 {
  height: 189px;
}

/*Padding x-os*/
.mc-g-padding-x-189 {
  padding: 0 189px;
}

/*Font size*/
.mc-g-font-size-189 {
  font-size: 189px;
}

/*Height*/
.mc-g-height-190 {
  height: 190px;
}

/*Padding x-os*/
.mc-g-padding-x-190 {
  padding: 0 190px;
}

/*Font size*/
.mc-g-font-size-190 {
  font-size: 190px;
}

/*Height*/
.mc-g-height-191 {
  height: 191px;
}

/*Padding x-os*/
.mc-g-padding-x-191 {
  padding: 0 191px;
}

/*Font size*/
.mc-g-font-size-191 {
  font-size: 191px;
}

/*Height*/
.mc-g-height-192 {
  height: 192px;
}

/*Padding x-os*/
.mc-g-padding-x-192 {
  padding: 0 192px;
}

/*Font size*/
.mc-g-font-size-192 {
  font-size: 192px;
}

/*Height*/
.mc-g-height-193 {
  height: 193px;
}

/*Padding x-os*/
.mc-g-padding-x-193 {
  padding: 0 193px;
}

/*Font size*/
.mc-g-font-size-193 {
  font-size: 193px;
}

/*Height*/
.mc-g-height-194 {
  height: 194px;
}

/*Padding x-os*/
.mc-g-padding-x-194 {
  padding: 0 194px;
}

/*Font size*/
.mc-g-font-size-194 {
  font-size: 194px;
}

/*Height*/
.mc-g-height-195 {
  height: 195px;
}

/*Padding x-os*/
.mc-g-padding-x-195 {
  padding: 0 195px;
}

/*Font size*/
.mc-g-font-size-195 {
  font-size: 195px;
}

/*Height*/
.mc-g-height-196 {
  height: 196px;
}

/*Padding x-os*/
.mc-g-padding-x-196 {
  padding: 0 196px;
}

/*Font size*/
.mc-g-font-size-196 {
  font-size: 196px;
}

/*Height*/
.mc-g-height-197 {
  height: 197px;
}

/*Padding x-os*/
.mc-g-padding-x-197 {
  padding: 0 197px;
}

/*Font size*/
.mc-g-font-size-197 {
  font-size: 197px;
}

/*Height*/
.mc-g-height-198 {
  height: 198px;
}

/*Padding x-os*/
.mc-g-padding-x-198 {
  padding: 0 198px;
}

/*Font size*/
.mc-g-font-size-198 {
  font-size: 198px;
}

/*Height*/
.mc-g-height-199 {
  height: 199px;
}

/*Padding x-os*/
.mc-g-padding-x-199 {
  padding: 0 199px;
}

/*Font size*/
.mc-g-font-size-199 {
  font-size: 199px;
}

/*Height*/
.mc-g-height-200 {
  height: 200px;
}

/*Padding x-os*/
.mc-g-padding-x-200 {
  padding: 0 200px;
}

/*Font size*/
.mc-g-font-size-200 {
  font-size: 200px;
}

/*Height*/
.mc-g-height-201 {
  height: 201px;
}

/*Padding x-os*/
.mc-g-padding-x-201 {
  padding: 0 201px;
}

/*Font size*/
.mc-g-font-size-201 {
  font-size: 201px;
}

/*Height*/
.mc-g-height-202 {
  height: 202px;
}

/*Padding x-os*/
.mc-g-padding-x-202 {
  padding: 0 202px;
}

/*Font size*/
.mc-g-font-size-202 {
  font-size: 202px;
}

/*Height*/
.mc-g-height-203 {
  height: 203px;
}

/*Padding x-os*/
.mc-g-padding-x-203 {
  padding: 0 203px;
}

/*Font size*/
.mc-g-font-size-203 {
  font-size: 203px;
}

/*Height*/
.mc-g-height-204 {
  height: 204px;
}

/*Padding x-os*/
.mc-g-padding-x-204 {
  padding: 0 204px;
}

/*Font size*/
.mc-g-font-size-204 {
  font-size: 204px;
}

/*Height*/
.mc-g-height-205 {
  height: 205px;
}

/*Padding x-os*/
.mc-g-padding-x-205 {
  padding: 0 205px;
}

/*Font size*/
.mc-g-font-size-205 {
  font-size: 205px;
}

/*Height*/
.mc-g-height-206 {
  height: 206px;
}

/*Padding x-os*/
.mc-g-padding-x-206 {
  padding: 0 206px;
}

/*Font size*/
.mc-g-font-size-206 {
  font-size: 206px;
}

/*Height*/
.mc-g-height-207 {
  height: 207px;
}

/*Padding x-os*/
.mc-g-padding-x-207 {
  padding: 0 207px;
}

/*Font size*/
.mc-g-font-size-207 {
  font-size: 207px;
}

/*Height*/
.mc-g-height-208 {
  height: 208px;
}

/*Padding x-os*/
.mc-g-padding-x-208 {
  padding: 0 208px;
}

/*Font size*/
.mc-g-font-size-208 {
  font-size: 208px;
}

/*Height*/
.mc-g-height-209 {
  height: 209px;
}

/*Padding x-os*/
.mc-g-padding-x-209 {
  padding: 0 209px;
}

/*Font size*/
.mc-g-font-size-209 {
  font-size: 209px;
}

/*Height*/
.mc-g-height-210 {
  height: 210px;
}

/*Padding x-os*/
.mc-g-padding-x-210 {
  padding: 0 210px;
}

/*Font size*/
.mc-g-font-size-210 {
  font-size: 210px;
}

/*Height*/
.mc-g-height-211 {
  height: 211px;
}

/*Padding x-os*/
.mc-g-padding-x-211 {
  padding: 0 211px;
}

/*Font size*/
.mc-g-font-size-211 {
  font-size: 211px;
}

/*Height*/
.mc-g-height-212 {
  height: 212px;
}

/*Padding x-os*/
.mc-g-padding-x-212 {
  padding: 0 212px;
}

/*Font size*/
.mc-g-font-size-212 {
  font-size: 212px;
}

/*Height*/
.mc-g-height-213 {
  height: 213px;
}

/*Padding x-os*/
.mc-g-padding-x-213 {
  padding: 0 213px;
}

/*Font size*/
.mc-g-font-size-213 {
  font-size: 213px;
}

/*Height*/
.mc-g-height-214 {
  height: 214px;
}

/*Padding x-os*/
.mc-g-padding-x-214 {
  padding: 0 214px;
}

/*Font size*/
.mc-g-font-size-214 {
  font-size: 214px;
}

/*Height*/
.mc-g-height-215 {
  height: 215px;
}

/*Padding x-os*/
.mc-g-padding-x-215 {
  padding: 0 215px;
}

/*Font size*/
.mc-g-font-size-215 {
  font-size: 215px;
}

/*Height*/
.mc-g-height-216 {
  height: 216px;
}

/*Padding x-os*/
.mc-g-padding-x-216 {
  padding: 0 216px;
}

/*Font size*/
.mc-g-font-size-216 {
  font-size: 216px;
}

/*Height*/
.mc-g-height-217 {
  height: 217px;
}

/*Padding x-os*/
.mc-g-padding-x-217 {
  padding: 0 217px;
}

/*Font size*/
.mc-g-font-size-217 {
  font-size: 217px;
}

/*Height*/
.mc-g-height-218 {
  height: 218px;
}

/*Padding x-os*/
.mc-g-padding-x-218 {
  padding: 0 218px;
}

/*Font size*/
.mc-g-font-size-218 {
  font-size: 218px;
}

/*Height*/
.mc-g-height-219 {
  height: 219px;
}

/*Padding x-os*/
.mc-g-padding-x-219 {
  padding: 0 219px;
}

/*Font size*/
.mc-g-font-size-219 {
  font-size: 219px;
}

/*Height*/
.mc-g-height-220 {
  height: 220px;
}

/*Padding x-os*/
.mc-g-padding-x-220 {
  padding: 0 220px;
}

/*Font size*/
.mc-g-font-size-220 {
  font-size: 220px;
}

/*Height*/
.mc-g-height-221 {
  height: 221px;
}

/*Padding x-os*/
.mc-g-padding-x-221 {
  padding: 0 221px;
}

/*Font size*/
.mc-g-font-size-221 {
  font-size: 221px;
}

/*Height*/
.mc-g-height-222 {
  height: 222px;
}

/*Padding x-os*/
.mc-g-padding-x-222 {
  padding: 0 222px;
}

/*Font size*/
.mc-g-font-size-222 {
  font-size: 222px;
}

/*Height*/
.mc-g-height-223 {
  height: 223px;
}

/*Padding x-os*/
.mc-g-padding-x-223 {
  padding: 0 223px;
}

/*Font size*/
.mc-g-font-size-223 {
  font-size: 223px;
}

/*Height*/
.mc-g-height-224 {
  height: 224px;
}

/*Padding x-os*/
.mc-g-padding-x-224 {
  padding: 0 224px;
}

/*Font size*/
.mc-g-font-size-224 {
  font-size: 224px;
}

/*Height*/
.mc-g-height-225 {
  height: 225px;
}

/*Padding x-os*/
.mc-g-padding-x-225 {
  padding: 0 225px;
}

/*Font size*/
.mc-g-font-size-225 {
  font-size: 225px;
}

/*Height*/
.mc-g-height-226 {
  height: 226px;
}

/*Padding x-os*/
.mc-g-padding-x-226 {
  padding: 0 226px;
}

/*Font size*/
.mc-g-font-size-226 {
  font-size: 226px;
}

/*Height*/
.mc-g-height-227 {
  height: 227px;
}

/*Padding x-os*/
.mc-g-padding-x-227 {
  padding: 0 227px;
}

/*Font size*/
.mc-g-font-size-227 {
  font-size: 227px;
}

/*Height*/
.mc-g-height-228 {
  height: 228px;
}

/*Padding x-os*/
.mc-g-padding-x-228 {
  padding: 0 228px;
}

/*Font size*/
.mc-g-font-size-228 {
  font-size: 228px;
}

/*Height*/
.mc-g-height-229 {
  height: 229px;
}

/*Padding x-os*/
.mc-g-padding-x-229 {
  padding: 0 229px;
}

/*Font size*/
.mc-g-font-size-229 {
  font-size: 229px;
}

/*Height*/
.mc-g-height-230 {
  height: 230px;
}

/*Padding x-os*/
.mc-g-padding-x-230 {
  padding: 0 230px;
}

/*Font size*/
.mc-g-font-size-230 {
  font-size: 230px;
}

/*Height*/
.mc-g-height-231 {
  height: 231px;
}

/*Padding x-os*/
.mc-g-padding-x-231 {
  padding: 0 231px;
}

/*Font size*/
.mc-g-font-size-231 {
  font-size: 231px;
}

/*Height*/
.mc-g-height-232 {
  height: 232px;
}

/*Padding x-os*/
.mc-g-padding-x-232 {
  padding: 0 232px;
}

/*Font size*/
.mc-g-font-size-232 {
  font-size: 232px;
}

/*Height*/
.mc-g-height-233 {
  height: 233px;
}

/*Padding x-os*/
.mc-g-padding-x-233 {
  padding: 0 233px;
}

/*Font size*/
.mc-g-font-size-233 {
  font-size: 233px;
}

/*Height*/
.mc-g-height-234 {
  height: 234px;
}

/*Padding x-os*/
.mc-g-padding-x-234 {
  padding: 0 234px;
}

/*Font size*/
.mc-g-font-size-234 {
  font-size: 234px;
}

/*Height*/
.mc-g-height-235 {
  height: 235px;
}

/*Padding x-os*/
.mc-g-padding-x-235 {
  padding: 0 235px;
}

/*Font size*/
.mc-g-font-size-235 {
  font-size: 235px;
}

/*Height*/
.mc-g-height-236 {
  height: 236px;
}

/*Padding x-os*/
.mc-g-padding-x-236 {
  padding: 0 236px;
}

/*Font size*/
.mc-g-font-size-236 {
  font-size: 236px;
}

/*Height*/
.mc-g-height-237 {
  height: 237px;
}

/*Padding x-os*/
.mc-g-padding-x-237 {
  padding: 0 237px;
}

/*Font size*/
.mc-g-font-size-237 {
  font-size: 237px;
}

/*Height*/
.mc-g-height-238 {
  height: 238px;
}

/*Padding x-os*/
.mc-g-padding-x-238 {
  padding: 0 238px;
}

/*Font size*/
.mc-g-font-size-238 {
  font-size: 238px;
}

/*Height*/
.mc-g-height-239 {
  height: 239px;
}

/*Padding x-os*/
.mc-g-padding-x-239 {
  padding: 0 239px;
}

/*Font size*/
.mc-g-font-size-239 {
  font-size: 239px;
}

/*Height*/
.mc-g-height-240 {
  height: 240px;
}

/*Padding x-os*/
.mc-g-padding-x-240 {
  padding: 0 240px;
}

/*Font size*/
.mc-g-font-size-240 {
  font-size: 240px;
}

/*Height*/
.mc-g-height-241 {
  height: 241px;
}

/*Padding x-os*/
.mc-g-padding-x-241 {
  padding: 0 241px;
}

/*Font size*/
.mc-g-font-size-241 {
  font-size: 241px;
}

/*Height*/
.mc-g-height-242 {
  height: 242px;
}

/*Padding x-os*/
.mc-g-padding-x-242 {
  padding: 0 242px;
}

/*Font size*/
.mc-g-font-size-242 {
  font-size: 242px;
}

/*Height*/
.mc-g-height-243 {
  height: 243px;
}

/*Padding x-os*/
.mc-g-padding-x-243 {
  padding: 0 243px;
}

/*Font size*/
.mc-g-font-size-243 {
  font-size: 243px;
}

/*Height*/
.mc-g-height-244 {
  height: 244px;
}

/*Padding x-os*/
.mc-g-padding-x-244 {
  padding: 0 244px;
}

/*Font size*/
.mc-g-font-size-244 {
  font-size: 244px;
}

/*Height*/
.mc-g-height-245 {
  height: 245px;
}

/*Padding x-os*/
.mc-g-padding-x-245 {
  padding: 0 245px;
}

/*Font size*/
.mc-g-font-size-245 {
  font-size: 245px;
}

/*Height*/
.mc-g-height-246 {
  height: 246px;
}

/*Padding x-os*/
.mc-g-padding-x-246 {
  padding: 0 246px;
}

/*Font size*/
.mc-g-font-size-246 {
  font-size: 246px;
}

/*Height*/
.mc-g-height-247 {
  height: 247px;
}

/*Padding x-os*/
.mc-g-padding-x-247 {
  padding: 0 247px;
}

/*Font size*/
.mc-g-font-size-247 {
  font-size: 247px;
}

/*Height*/
.mc-g-height-248 {
  height: 248px;
}

/*Padding x-os*/
.mc-g-padding-x-248 {
  padding: 0 248px;
}

/*Font size*/
.mc-g-font-size-248 {
  font-size: 248px;
}

/*Height*/
.mc-g-height-249 {
  height: 249px;
}

/*Padding x-os*/
.mc-g-padding-x-249 {
  padding: 0 249px;
}

/*Font size*/
.mc-g-font-size-249 {
  font-size: 249px;
}

/*Height*/
.mc-g-height-250 {
  height: 250px;
}

/*Padding x-os*/
.mc-g-padding-x-250 {
  padding: 0 250px;
}

/*Font size*/
.mc-g-font-size-250 {
  font-size: 250px;
}

/*Height*/
.mc-g-height-251 {
  height: 251px;
}

/*Padding x-os*/
.mc-g-padding-x-251 {
  padding: 0 251px;
}

/*Font size*/
.mc-g-font-size-251 {
  font-size: 251px;
}

/*Height*/
.mc-g-height-252 {
  height: 252px;
}

/*Padding x-os*/
.mc-g-padding-x-252 {
  padding: 0 252px;
}

/*Font size*/
.mc-g-font-size-252 {
  font-size: 252px;
}

/*Height*/
.mc-g-height-253 {
  height: 253px;
}

/*Padding x-os*/
.mc-g-padding-x-253 {
  padding: 0 253px;
}

/*Font size*/
.mc-g-font-size-253 {
  font-size: 253px;
}

/*Height*/
.mc-g-height-254 {
  height: 254px;
}

/*Padding x-os*/
.mc-g-padding-x-254 {
  padding: 0 254px;
}

/*Font size*/
.mc-g-font-size-254 {
  font-size: 254px;
}

/*Height*/
.mc-g-height-255 {
  height: 255px;
}

/*Padding x-os*/
.mc-g-padding-x-255 {
  padding: 0 255px;
}

/*Font size*/
.mc-g-font-size-255 {
  font-size: 255px;
}

/*Height*/
.mc-g-height-256 {
  height: 256px;
}

/*Padding x-os*/
.mc-g-padding-x-256 {
  padding: 0 256px;
}

/*Font size*/
.mc-g-font-size-256 {
  font-size: 256px;
}

/*Height*/
.mc-g-height-257 {
  height: 257px;
}

/*Padding x-os*/
.mc-g-padding-x-257 {
  padding: 0 257px;
}

/*Font size*/
.mc-g-font-size-257 {
  font-size: 257px;
}

/*Height*/
.mc-g-height-258 {
  height: 258px;
}

/*Padding x-os*/
.mc-g-padding-x-258 {
  padding: 0 258px;
}

/*Font size*/
.mc-g-font-size-258 {
  font-size: 258px;
}

/*Height*/
.mc-g-height-259 {
  height: 259px;
}

/*Padding x-os*/
.mc-g-padding-x-259 {
  padding: 0 259px;
}

/*Font size*/
.mc-g-font-size-259 {
  font-size: 259px;
}

/*Height*/
.mc-g-height-260 {
  height: 260px;
}

/*Padding x-os*/
.mc-g-padding-x-260 {
  padding: 0 260px;
}

/*Font size*/
.mc-g-font-size-260 {
  font-size: 260px;
}

/*Height*/
.mc-g-height-261 {
  height: 261px;
}

/*Padding x-os*/
.mc-g-padding-x-261 {
  padding: 0 261px;
}

/*Font size*/
.mc-g-font-size-261 {
  font-size: 261px;
}

/*Height*/
.mc-g-height-262 {
  height: 262px;
}

/*Padding x-os*/
.mc-g-padding-x-262 {
  padding: 0 262px;
}

/*Font size*/
.mc-g-font-size-262 {
  font-size: 262px;
}

/*Height*/
.mc-g-height-263 {
  height: 263px;
}

/*Padding x-os*/
.mc-g-padding-x-263 {
  padding: 0 263px;
}

/*Font size*/
.mc-g-font-size-263 {
  font-size: 263px;
}

/*Height*/
.mc-g-height-264 {
  height: 264px;
}

/*Padding x-os*/
.mc-g-padding-x-264 {
  padding: 0 264px;
}

/*Font size*/
.mc-g-font-size-264 {
  font-size: 264px;
}

/*Height*/
.mc-g-height-265 {
  height: 265px;
}

/*Padding x-os*/
.mc-g-padding-x-265 {
  padding: 0 265px;
}

/*Font size*/
.mc-g-font-size-265 {
  font-size: 265px;
}

/*Height*/
.mc-g-height-266 {
  height: 266px;
}

/*Padding x-os*/
.mc-g-padding-x-266 {
  padding: 0 266px;
}

/*Font size*/
.mc-g-font-size-266 {
  font-size: 266px;
}

/*Height*/
.mc-g-height-267 {
  height: 267px;
}

/*Padding x-os*/
.mc-g-padding-x-267 {
  padding: 0 267px;
}

/*Font size*/
.mc-g-font-size-267 {
  font-size: 267px;
}

/*Height*/
.mc-g-height-268 {
  height: 268px;
}

/*Padding x-os*/
.mc-g-padding-x-268 {
  padding: 0 268px;
}

/*Font size*/
.mc-g-font-size-268 {
  font-size: 268px;
}

/*Height*/
.mc-g-height-269 {
  height: 269px;
}

/*Padding x-os*/
.mc-g-padding-x-269 {
  padding: 0 269px;
}

/*Font size*/
.mc-g-font-size-269 {
  font-size: 269px;
}

/*Height*/
.mc-g-height-270 {
  height: 270px;
}

/*Padding x-os*/
.mc-g-padding-x-270 {
  padding: 0 270px;
}

/*Font size*/
.mc-g-font-size-270 {
  font-size: 270px;
}

/*Height*/
.mc-g-height-271 {
  height: 271px;
}

/*Padding x-os*/
.mc-g-padding-x-271 {
  padding: 0 271px;
}

/*Font size*/
.mc-g-font-size-271 {
  font-size: 271px;
}

/*Height*/
.mc-g-height-272 {
  height: 272px;
}

/*Padding x-os*/
.mc-g-padding-x-272 {
  padding: 0 272px;
}

/*Font size*/
.mc-g-font-size-272 {
  font-size: 272px;
}

/*Height*/
.mc-g-height-273 {
  height: 273px;
}

/*Padding x-os*/
.mc-g-padding-x-273 {
  padding: 0 273px;
}

/*Font size*/
.mc-g-font-size-273 {
  font-size: 273px;
}

/*Height*/
.mc-g-height-274 {
  height: 274px;
}

/*Padding x-os*/
.mc-g-padding-x-274 {
  padding: 0 274px;
}

/*Font size*/
.mc-g-font-size-274 {
  font-size: 274px;
}

/*Height*/
.mc-g-height-275 {
  height: 275px;
}

/*Padding x-os*/
.mc-g-padding-x-275 {
  padding: 0 275px;
}

/*Font size*/
.mc-g-font-size-275 {
  font-size: 275px;
}

/*Height*/
.mc-g-height-276 {
  height: 276px;
}

/*Padding x-os*/
.mc-g-padding-x-276 {
  padding: 0 276px;
}

/*Font size*/
.mc-g-font-size-276 {
  font-size: 276px;
}

/*Height*/
.mc-g-height-277 {
  height: 277px;
}

/*Padding x-os*/
.mc-g-padding-x-277 {
  padding: 0 277px;
}

/*Font size*/
.mc-g-font-size-277 {
  font-size: 277px;
}

/*Height*/
.mc-g-height-278 {
  height: 278px;
}

/*Padding x-os*/
.mc-g-padding-x-278 {
  padding: 0 278px;
}

/*Font size*/
.mc-g-font-size-278 {
  font-size: 278px;
}

/*Height*/
.mc-g-height-279 {
  height: 279px;
}

/*Padding x-os*/
.mc-g-padding-x-279 {
  padding: 0 279px;
}

/*Font size*/
.mc-g-font-size-279 {
  font-size: 279px;
}

/*Height*/
.mc-g-height-280 {
  height: 280px;
}

/*Padding x-os*/
.mc-g-padding-x-280 {
  padding: 0 280px;
}

/*Font size*/
.mc-g-font-size-280 {
  font-size: 280px;
}

/*Height*/
.mc-g-height-281 {
  height: 281px;
}

/*Padding x-os*/
.mc-g-padding-x-281 {
  padding: 0 281px;
}

/*Font size*/
.mc-g-font-size-281 {
  font-size: 281px;
}

/*Height*/
.mc-g-height-282 {
  height: 282px;
}

/*Padding x-os*/
.mc-g-padding-x-282 {
  padding: 0 282px;
}

/*Font size*/
.mc-g-font-size-282 {
  font-size: 282px;
}

/*Height*/
.mc-g-height-283 {
  height: 283px;
}

/*Padding x-os*/
.mc-g-padding-x-283 {
  padding: 0 283px;
}

/*Font size*/
.mc-g-font-size-283 {
  font-size: 283px;
}

/*Height*/
.mc-g-height-284 {
  height: 284px;
}

/*Padding x-os*/
.mc-g-padding-x-284 {
  padding: 0 284px;
}

/*Font size*/
.mc-g-font-size-284 {
  font-size: 284px;
}

/*Height*/
.mc-g-height-285 {
  height: 285px;
}

/*Padding x-os*/
.mc-g-padding-x-285 {
  padding: 0 285px;
}

/*Font size*/
.mc-g-font-size-285 {
  font-size: 285px;
}

/*Height*/
.mc-g-height-286 {
  height: 286px;
}

/*Padding x-os*/
.mc-g-padding-x-286 {
  padding: 0 286px;
}

/*Font size*/
.mc-g-font-size-286 {
  font-size: 286px;
}

/*Height*/
.mc-g-height-287 {
  height: 287px;
}

/*Padding x-os*/
.mc-g-padding-x-287 {
  padding: 0 287px;
}

/*Font size*/
.mc-g-font-size-287 {
  font-size: 287px;
}

/*Height*/
.mc-g-height-288 {
  height: 288px;
}

/*Padding x-os*/
.mc-g-padding-x-288 {
  padding: 0 288px;
}

/*Font size*/
.mc-g-font-size-288 {
  font-size: 288px;
}

/*Height*/
.mc-g-height-289 {
  height: 289px;
}

/*Padding x-os*/
.mc-g-padding-x-289 {
  padding: 0 289px;
}

/*Font size*/
.mc-g-font-size-289 {
  font-size: 289px;
}

/*Height*/
.mc-g-height-290 {
  height: 290px;
}

/*Padding x-os*/
.mc-g-padding-x-290 {
  padding: 0 290px;
}

/*Font size*/
.mc-g-font-size-290 {
  font-size: 290px;
}

/*Height*/
.mc-g-height-291 {
  height: 291px;
}

/*Padding x-os*/
.mc-g-padding-x-291 {
  padding: 0 291px;
}

/*Font size*/
.mc-g-font-size-291 {
  font-size: 291px;
}

/*Height*/
.mc-g-height-292 {
  height: 292px;
}

/*Padding x-os*/
.mc-g-padding-x-292 {
  padding: 0 292px;
}

/*Font size*/
.mc-g-font-size-292 {
  font-size: 292px;
}

/*Height*/
.mc-g-height-293 {
  height: 293px;
}

/*Padding x-os*/
.mc-g-padding-x-293 {
  padding: 0 293px;
}

/*Font size*/
.mc-g-font-size-293 {
  font-size: 293px;
}

/*Height*/
.mc-g-height-294 {
  height: 294px;
}

/*Padding x-os*/
.mc-g-padding-x-294 {
  padding: 0 294px;
}

/*Font size*/
.mc-g-font-size-294 {
  font-size: 294px;
}

/*Height*/
.mc-g-height-295 {
  height: 295px;
}

/*Padding x-os*/
.mc-g-padding-x-295 {
  padding: 0 295px;
}

/*Font size*/
.mc-g-font-size-295 {
  font-size: 295px;
}

/*Height*/
.mc-g-height-296 {
  height: 296px;
}

/*Padding x-os*/
.mc-g-padding-x-296 {
  padding: 0 296px;
}

/*Font size*/
.mc-g-font-size-296 {
  font-size: 296px;
}

/*Height*/
.mc-g-height-297 {
  height: 297px;
}

/*Padding x-os*/
.mc-g-padding-x-297 {
  padding: 0 297px;
}

/*Font size*/
.mc-g-font-size-297 {
  font-size: 297px;
}

/*Height*/
.mc-g-height-298 {
  height: 298px;
}

/*Padding x-os*/
.mc-g-padding-x-298 {
  padding: 0 298px;
}

/*Font size*/
.mc-g-font-size-298 {
  font-size: 298px;
}

/*Height*/
.mc-g-height-299 {
  height: 299px;
}

/*Padding x-os*/
.mc-g-padding-x-299 {
  padding: 0 299px;
}

/*Font size*/
.mc-g-font-size-299 {
  font-size: 299px;
}

/*Height*/
.mc-g-height-300 {
  height: 300px;
}

/*Padding x-os*/
.mc-g-padding-x-300 {
  padding: 0 300px;
}

/*Font size*/
.mc-g-font-size-300 {
  font-size: 300px;
}

/*Height*/
.mc-g-height-301 {
  height: 301px;
}

/*Padding x-os*/
.mc-g-padding-x-301 {
  padding: 0 301px;
}

/*Font size*/
.mc-g-font-size-301 {
  font-size: 301px;
}

/*Height*/
.mc-g-height-302 {
  height: 302px;
}

/*Padding x-os*/
.mc-g-padding-x-302 {
  padding: 0 302px;
}

/*Font size*/
.mc-g-font-size-302 {
  font-size: 302px;
}

/*Height*/
.mc-g-height-303 {
  height: 303px;
}

/*Padding x-os*/
.mc-g-padding-x-303 {
  padding: 0 303px;
}

/*Font size*/
.mc-g-font-size-303 {
  font-size: 303px;
}

/*Height*/
.mc-g-height-304 {
  height: 304px;
}

/*Padding x-os*/
.mc-g-padding-x-304 {
  padding: 0 304px;
}

/*Font size*/
.mc-g-font-size-304 {
  font-size: 304px;
}

/*Height*/
.mc-g-height-305 {
  height: 305px;
}

/*Padding x-os*/
.mc-g-padding-x-305 {
  padding: 0 305px;
}

/*Font size*/
.mc-g-font-size-305 {
  font-size: 305px;
}

/*Height*/
.mc-g-height-306 {
  height: 306px;
}

/*Padding x-os*/
.mc-g-padding-x-306 {
  padding: 0 306px;
}

/*Font size*/
.mc-g-font-size-306 {
  font-size: 306px;
}

/*Height*/
.mc-g-height-307 {
  height: 307px;
}

/*Padding x-os*/
.mc-g-padding-x-307 {
  padding: 0 307px;
}

/*Font size*/
.mc-g-font-size-307 {
  font-size: 307px;
}

/*Height*/
.mc-g-height-308 {
  height: 308px;
}

/*Padding x-os*/
.mc-g-padding-x-308 {
  padding: 0 308px;
}

/*Font size*/
.mc-g-font-size-308 {
  font-size: 308px;
}

/*Height*/
.mc-g-height-309 {
  height: 309px;
}

/*Padding x-os*/
.mc-g-padding-x-309 {
  padding: 0 309px;
}

/*Font size*/
.mc-g-font-size-309 {
  font-size: 309px;
}

/*Height*/
.mc-g-height-310 {
  height: 310px;
}

/*Padding x-os*/
.mc-g-padding-x-310 {
  padding: 0 310px;
}

/*Font size*/
.mc-g-font-size-310 {
  font-size: 310px;
}

/*Height*/
.mc-g-height-311 {
  height: 311px;
}

/*Padding x-os*/
.mc-g-padding-x-311 {
  padding: 0 311px;
}

/*Font size*/
.mc-g-font-size-311 {
  font-size: 311px;
}

/*Height*/
.mc-g-height-312 {
  height: 312px;
}

/*Padding x-os*/
.mc-g-padding-x-312 {
  padding: 0 312px;
}

/*Font size*/
.mc-g-font-size-312 {
  font-size: 312px;
}

/*Height*/
.mc-g-height-313 {
  height: 313px;
}

/*Padding x-os*/
.mc-g-padding-x-313 {
  padding: 0 313px;
}

/*Font size*/
.mc-g-font-size-313 {
  font-size: 313px;
}

/*Height*/
.mc-g-height-314 {
  height: 314px;
}

/*Padding x-os*/
.mc-g-padding-x-314 {
  padding: 0 314px;
}

/*Font size*/
.mc-g-font-size-314 {
  font-size: 314px;
}

/*Height*/
.mc-g-height-315 {
  height: 315px;
}

/*Padding x-os*/
.mc-g-padding-x-315 {
  padding: 0 315px;
}

/*Font size*/
.mc-g-font-size-315 {
  font-size: 315px;
}

/*Height*/
.mc-g-height-316 {
  height: 316px;
}

/*Padding x-os*/
.mc-g-padding-x-316 {
  padding: 0 316px;
}

/*Font size*/
.mc-g-font-size-316 {
  font-size: 316px;
}

/*Height*/
.mc-g-height-317 {
  height: 317px;
}

/*Padding x-os*/
.mc-g-padding-x-317 {
  padding: 0 317px;
}

/*Font size*/
.mc-g-font-size-317 {
  font-size: 317px;
}

/*Height*/
.mc-g-height-318 {
  height: 318px;
}

/*Padding x-os*/
.mc-g-padding-x-318 {
  padding: 0 318px;
}

/*Font size*/
.mc-g-font-size-318 {
  font-size: 318px;
}

/*Height*/
.mc-g-height-319 {
  height: 319px;
}

/*Padding x-os*/
.mc-g-padding-x-319 {
  padding: 0 319px;
}

/*Font size*/
.mc-g-font-size-319 {
  font-size: 319px;
}

/*Height*/
.mc-g-height-320 {
  height: 320px;
}

/*Padding x-os*/
.mc-g-padding-x-320 {
  padding: 0 320px;
}

/*Font size*/
.mc-g-font-size-320 {
  font-size: 320px;
}

/*Height*/
.mc-g-height-321 {
  height: 321px;
}

/*Padding x-os*/
.mc-g-padding-x-321 {
  padding: 0 321px;
}

/*Font size*/
.mc-g-font-size-321 {
  font-size: 321px;
}

/*Height*/
.mc-g-height-322 {
  height: 322px;
}

/*Padding x-os*/
.mc-g-padding-x-322 {
  padding: 0 322px;
}

/*Font size*/
.mc-g-font-size-322 {
  font-size: 322px;
}

/*Height*/
.mc-g-height-323 {
  height: 323px;
}

/*Padding x-os*/
.mc-g-padding-x-323 {
  padding: 0 323px;
}

/*Font size*/
.mc-g-font-size-323 {
  font-size: 323px;
}

/*Height*/
.mc-g-height-324 {
  height: 324px;
}

/*Padding x-os*/
.mc-g-padding-x-324 {
  padding: 0 324px;
}

/*Font size*/
.mc-g-font-size-324 {
  font-size: 324px;
}

/*Height*/
.mc-g-height-325 {
  height: 325px;
}

/*Padding x-os*/
.mc-g-padding-x-325 {
  padding: 0 325px;
}

/*Font size*/
.mc-g-font-size-325 {
  font-size: 325px;
}

/*Height*/
.mc-g-height-326 {
  height: 326px;
}

/*Padding x-os*/
.mc-g-padding-x-326 {
  padding: 0 326px;
}

/*Font size*/
.mc-g-font-size-326 {
  font-size: 326px;
}

/*Height*/
.mc-g-height-327 {
  height: 327px;
}

/*Padding x-os*/
.mc-g-padding-x-327 {
  padding: 0 327px;
}

/*Font size*/
.mc-g-font-size-327 {
  font-size: 327px;
}

/*Height*/
.mc-g-height-328 {
  height: 328px;
}

/*Padding x-os*/
.mc-g-padding-x-328 {
  padding: 0 328px;
}

/*Font size*/
.mc-g-font-size-328 {
  font-size: 328px;
}

/*Height*/
.mc-g-height-329 {
  height: 329px;
}

/*Padding x-os*/
.mc-g-padding-x-329 {
  padding: 0 329px;
}

/*Font size*/
.mc-g-font-size-329 {
  font-size: 329px;
}

/*Height*/
.mc-g-height-330 {
  height: 330px;
}

/*Padding x-os*/
.mc-g-padding-x-330 {
  padding: 0 330px;
}

/*Font size*/
.mc-g-font-size-330 {
  font-size: 330px;
}

/*Height*/
.mc-g-height-331 {
  height: 331px;
}

/*Padding x-os*/
.mc-g-padding-x-331 {
  padding: 0 331px;
}

/*Font size*/
.mc-g-font-size-331 {
  font-size: 331px;
}

/*Height*/
.mc-g-height-332 {
  height: 332px;
}

/*Padding x-os*/
.mc-g-padding-x-332 {
  padding: 0 332px;
}

/*Font size*/
.mc-g-font-size-332 {
  font-size: 332px;
}

/*Height*/
.mc-g-height-333 {
  height: 333px;
}

/*Padding x-os*/
.mc-g-padding-x-333 {
  padding: 0 333px;
}

/*Font size*/
.mc-g-font-size-333 {
  font-size: 333px;
}

/*Height*/
.mc-g-height-334 {
  height: 334px;
}

/*Padding x-os*/
.mc-g-padding-x-334 {
  padding: 0 334px;
}

/*Font size*/
.mc-g-font-size-334 {
  font-size: 334px;
}

/*Height*/
.mc-g-height-335 {
  height: 335px;
}

/*Padding x-os*/
.mc-g-padding-x-335 {
  padding: 0 335px;
}

/*Font size*/
.mc-g-font-size-335 {
  font-size: 335px;
}

/*Height*/
.mc-g-height-336 {
  height: 336px;
}

/*Padding x-os*/
.mc-g-padding-x-336 {
  padding: 0 336px;
}

/*Font size*/
.mc-g-font-size-336 {
  font-size: 336px;
}

/*Height*/
.mc-g-height-337 {
  height: 337px;
}

/*Padding x-os*/
.mc-g-padding-x-337 {
  padding: 0 337px;
}

/*Font size*/
.mc-g-font-size-337 {
  font-size: 337px;
}

/*Height*/
.mc-g-height-338 {
  height: 338px;
}

/*Padding x-os*/
.mc-g-padding-x-338 {
  padding: 0 338px;
}

/*Font size*/
.mc-g-font-size-338 {
  font-size: 338px;
}

/*Height*/
.mc-g-height-339 {
  height: 339px;
}

/*Padding x-os*/
.mc-g-padding-x-339 {
  padding: 0 339px;
}

/*Font size*/
.mc-g-font-size-339 {
  font-size: 339px;
}

/*Height*/
.mc-g-height-340 {
  height: 340px;
}

/*Padding x-os*/
.mc-g-padding-x-340 {
  padding: 0 340px;
}

/*Font size*/
.mc-g-font-size-340 {
  font-size: 340px;
}

/*Height*/
.mc-g-height-341 {
  height: 341px;
}

/*Padding x-os*/
.mc-g-padding-x-341 {
  padding: 0 341px;
}

/*Font size*/
.mc-g-font-size-341 {
  font-size: 341px;
}

/*Height*/
.mc-g-height-342 {
  height: 342px;
}

/*Padding x-os*/
.mc-g-padding-x-342 {
  padding: 0 342px;
}

/*Font size*/
.mc-g-font-size-342 {
  font-size: 342px;
}

/*Height*/
.mc-g-height-343 {
  height: 343px;
}

/*Padding x-os*/
.mc-g-padding-x-343 {
  padding: 0 343px;
}

/*Font size*/
.mc-g-font-size-343 {
  font-size: 343px;
}

/*Height*/
.mc-g-height-344 {
  height: 344px;
}

/*Padding x-os*/
.mc-g-padding-x-344 {
  padding: 0 344px;
}

/*Font size*/
.mc-g-font-size-344 {
  font-size: 344px;
}

/*Height*/
.mc-g-height-345 {
  height: 345px;
}

/*Padding x-os*/
.mc-g-padding-x-345 {
  padding: 0 345px;
}

/*Font size*/
.mc-g-font-size-345 {
  font-size: 345px;
}

/*Height*/
.mc-g-height-346 {
  height: 346px;
}

/*Padding x-os*/
.mc-g-padding-x-346 {
  padding: 0 346px;
}

/*Font size*/
.mc-g-font-size-346 {
  font-size: 346px;
}

/*Height*/
.mc-g-height-347 {
  height: 347px;
}

/*Padding x-os*/
.mc-g-padding-x-347 {
  padding: 0 347px;
}

/*Font size*/
.mc-g-font-size-347 {
  font-size: 347px;
}

/*Height*/
.mc-g-height-348 {
  height: 348px;
}

/*Padding x-os*/
.mc-g-padding-x-348 {
  padding: 0 348px;
}

/*Font size*/
.mc-g-font-size-348 {
  font-size: 348px;
}

/*Height*/
.mc-g-height-349 {
  height: 349px;
}

/*Padding x-os*/
.mc-g-padding-x-349 {
  padding: 0 349px;
}

/*Font size*/
.mc-g-font-size-349 {
  font-size: 349px;
}

/*Height*/
.mc-g-height-350 {
  height: 350px;
}

/*Padding x-os*/
.mc-g-padding-x-350 {
  padding: 0 350px;
}

/*Font size*/
.mc-g-font-size-350 {
  font-size: 350px;
}

/*Height*/
.mc-g-height-351 {
  height: 351px;
}

/*Padding x-os*/
.mc-g-padding-x-351 {
  padding: 0 351px;
}

/*Font size*/
.mc-g-font-size-351 {
  font-size: 351px;
}

/*Height*/
.mc-g-height-352 {
  height: 352px;
}

/*Padding x-os*/
.mc-g-padding-x-352 {
  padding: 0 352px;
}

/*Font size*/
.mc-g-font-size-352 {
  font-size: 352px;
}

/*Height*/
.mc-g-height-353 {
  height: 353px;
}

/*Padding x-os*/
.mc-g-padding-x-353 {
  padding: 0 353px;
}

/*Font size*/
.mc-g-font-size-353 {
  font-size: 353px;
}

/*Height*/
.mc-g-height-354 {
  height: 354px;
}

/*Padding x-os*/
.mc-g-padding-x-354 {
  padding: 0 354px;
}

/*Font size*/
.mc-g-font-size-354 {
  font-size: 354px;
}

/*Height*/
.mc-g-height-355 {
  height: 355px;
}

/*Padding x-os*/
.mc-g-padding-x-355 {
  padding: 0 355px;
}

/*Font size*/
.mc-g-font-size-355 {
  font-size: 355px;
}

/*Height*/
.mc-g-height-356 {
  height: 356px;
}

/*Padding x-os*/
.mc-g-padding-x-356 {
  padding: 0 356px;
}

/*Font size*/
.mc-g-font-size-356 {
  font-size: 356px;
}

/*Height*/
.mc-g-height-357 {
  height: 357px;
}

/*Padding x-os*/
.mc-g-padding-x-357 {
  padding: 0 357px;
}

/*Font size*/
.mc-g-font-size-357 {
  font-size: 357px;
}

/*Height*/
.mc-g-height-358 {
  height: 358px;
}

/*Padding x-os*/
.mc-g-padding-x-358 {
  padding: 0 358px;
}

/*Font size*/
.mc-g-font-size-358 {
  font-size: 358px;
}

/*Height*/
.mc-g-height-359 {
  height: 359px;
}

/*Padding x-os*/
.mc-g-padding-x-359 {
  padding: 0 359px;
}

/*Font size*/
.mc-g-font-size-359 {
  font-size: 359px;
}

/*Height*/
.mc-g-height-360 {
  height: 360px;
}

/*Padding x-os*/
.mc-g-padding-x-360 {
  padding: 0 360px;
}

/*Font size*/
.mc-g-font-size-360 {
  font-size: 360px;
}

/*Height*/
.mc-g-height-361 {
  height: 361px;
}

/*Padding x-os*/
.mc-g-padding-x-361 {
  padding: 0 361px;
}

/*Font size*/
.mc-g-font-size-361 {
  font-size: 361px;
}

/*Height*/
.mc-g-height-362 {
  height: 362px;
}

/*Padding x-os*/
.mc-g-padding-x-362 {
  padding: 0 362px;
}

/*Font size*/
.mc-g-font-size-362 {
  font-size: 362px;
}

/*Height*/
.mc-g-height-363 {
  height: 363px;
}

/*Padding x-os*/
.mc-g-padding-x-363 {
  padding: 0 363px;
}

/*Font size*/
.mc-g-font-size-363 {
  font-size: 363px;
}

/*Height*/
.mc-g-height-364 {
  height: 364px;
}

/*Padding x-os*/
.mc-g-padding-x-364 {
  padding: 0 364px;
}

/*Font size*/
.mc-g-font-size-364 {
  font-size: 364px;
}

/*Height*/
.mc-g-height-365 {
  height: 365px;
}

/*Padding x-os*/
.mc-g-padding-x-365 {
  padding: 0 365px;
}

/*Font size*/
.mc-g-font-size-365 {
  font-size: 365px;
}

/*Height*/
.mc-g-height-366 {
  height: 366px;
}

/*Padding x-os*/
.mc-g-padding-x-366 {
  padding: 0 366px;
}

/*Font size*/
.mc-g-font-size-366 {
  font-size: 366px;
}

/*Height*/
.mc-g-height-367 {
  height: 367px;
}

/*Padding x-os*/
.mc-g-padding-x-367 {
  padding: 0 367px;
}

/*Font size*/
.mc-g-font-size-367 {
  font-size: 367px;
}

/*Height*/
.mc-g-height-368 {
  height: 368px;
}

/*Padding x-os*/
.mc-g-padding-x-368 {
  padding: 0 368px;
}

/*Font size*/
.mc-g-font-size-368 {
  font-size: 368px;
}

/*Height*/
.mc-g-height-369 {
  height: 369px;
}

/*Padding x-os*/
.mc-g-padding-x-369 {
  padding: 0 369px;
}

/*Font size*/
.mc-g-font-size-369 {
  font-size: 369px;
}

/*Height*/
.mc-g-height-370 {
  height: 370px;
}

/*Padding x-os*/
.mc-g-padding-x-370 {
  padding: 0 370px;
}

/*Font size*/
.mc-g-font-size-370 {
  font-size: 370px;
}

/*Height*/
.mc-g-height-371 {
  height: 371px;
}

/*Padding x-os*/
.mc-g-padding-x-371 {
  padding: 0 371px;
}

/*Font size*/
.mc-g-font-size-371 {
  font-size: 371px;
}

/*Height*/
.mc-g-height-372 {
  height: 372px;
}

/*Padding x-os*/
.mc-g-padding-x-372 {
  padding: 0 372px;
}

/*Font size*/
.mc-g-font-size-372 {
  font-size: 372px;
}

/*Height*/
.mc-g-height-373 {
  height: 373px;
}

/*Padding x-os*/
.mc-g-padding-x-373 {
  padding: 0 373px;
}

/*Font size*/
.mc-g-font-size-373 {
  font-size: 373px;
}

/*Height*/
.mc-g-height-374 {
  height: 374px;
}

/*Padding x-os*/
.mc-g-padding-x-374 {
  padding: 0 374px;
}

/*Font size*/
.mc-g-font-size-374 {
  font-size: 374px;
}

/*Height*/
.mc-g-height-375 {
  height: 375px;
}

/*Padding x-os*/
.mc-g-padding-x-375 {
  padding: 0 375px;
}

/*Font size*/
.mc-g-font-size-375 {
  font-size: 375px;
}

/*Height*/
.mc-g-height-376 {
  height: 376px;
}

/*Padding x-os*/
.mc-g-padding-x-376 {
  padding: 0 376px;
}

/*Font size*/
.mc-g-font-size-376 {
  font-size: 376px;
}

/*Height*/
.mc-g-height-377 {
  height: 377px;
}

/*Padding x-os*/
.mc-g-padding-x-377 {
  padding: 0 377px;
}

/*Font size*/
.mc-g-font-size-377 {
  font-size: 377px;
}

/*Height*/
.mc-g-height-378 {
  height: 378px;
}

/*Padding x-os*/
.mc-g-padding-x-378 {
  padding: 0 378px;
}

/*Font size*/
.mc-g-font-size-378 {
  font-size: 378px;
}

/*Height*/
.mc-g-height-379 {
  height: 379px;
}

/*Padding x-os*/
.mc-g-padding-x-379 {
  padding: 0 379px;
}

/*Font size*/
.mc-g-font-size-379 {
  font-size: 379px;
}

/*Height*/
.mc-g-height-380 {
  height: 380px;
}

/*Padding x-os*/
.mc-g-padding-x-380 {
  padding: 0 380px;
}

/*Font size*/
.mc-g-font-size-380 {
  font-size: 380px;
}

/*Height*/
.mc-g-height-381 {
  height: 381px;
}

/*Padding x-os*/
.mc-g-padding-x-381 {
  padding: 0 381px;
}

/*Font size*/
.mc-g-font-size-381 {
  font-size: 381px;
}

/*Height*/
.mc-g-height-382 {
  height: 382px;
}

/*Padding x-os*/
.mc-g-padding-x-382 {
  padding: 0 382px;
}

/*Font size*/
.mc-g-font-size-382 {
  font-size: 382px;
}

/*Height*/
.mc-g-height-383 {
  height: 383px;
}

/*Padding x-os*/
.mc-g-padding-x-383 {
  padding: 0 383px;
}

/*Font size*/
.mc-g-font-size-383 {
  font-size: 383px;
}

/*Height*/
.mc-g-height-384 {
  height: 384px;
}

/*Padding x-os*/
.mc-g-padding-x-384 {
  padding: 0 384px;
}

/*Font size*/
.mc-g-font-size-384 {
  font-size: 384px;
}

/*Height*/
.mc-g-height-385 {
  height: 385px;
}

/*Padding x-os*/
.mc-g-padding-x-385 {
  padding: 0 385px;
}

/*Font size*/
.mc-g-font-size-385 {
  font-size: 385px;
}

/*Height*/
.mc-g-height-386 {
  height: 386px;
}

/*Padding x-os*/
.mc-g-padding-x-386 {
  padding: 0 386px;
}

/*Font size*/
.mc-g-font-size-386 {
  font-size: 386px;
}

/*Height*/
.mc-g-height-387 {
  height: 387px;
}

/*Padding x-os*/
.mc-g-padding-x-387 {
  padding: 0 387px;
}

/*Font size*/
.mc-g-font-size-387 {
  font-size: 387px;
}

/*Height*/
.mc-g-height-388 {
  height: 388px;
}

/*Padding x-os*/
.mc-g-padding-x-388 {
  padding: 0 388px;
}

/*Font size*/
.mc-g-font-size-388 {
  font-size: 388px;
}

/*Height*/
.mc-g-height-389 {
  height: 389px;
}

/*Padding x-os*/
.mc-g-padding-x-389 {
  padding: 0 389px;
}

/*Font size*/
.mc-g-font-size-389 {
  font-size: 389px;
}

/*Height*/
.mc-g-height-390 {
  height: 390px;
}

/*Padding x-os*/
.mc-g-padding-x-390 {
  padding: 0 390px;
}

/*Font size*/
.mc-g-font-size-390 {
  font-size: 390px;
}

/*Height*/
.mc-g-height-391 {
  height: 391px;
}

/*Padding x-os*/
.mc-g-padding-x-391 {
  padding: 0 391px;
}

/*Font size*/
.mc-g-font-size-391 {
  font-size: 391px;
}

/*Height*/
.mc-g-height-392 {
  height: 392px;
}

/*Padding x-os*/
.mc-g-padding-x-392 {
  padding: 0 392px;
}

/*Font size*/
.mc-g-font-size-392 {
  font-size: 392px;
}

/*Height*/
.mc-g-height-393 {
  height: 393px;
}

/*Padding x-os*/
.mc-g-padding-x-393 {
  padding: 0 393px;
}

/*Font size*/
.mc-g-font-size-393 {
  font-size: 393px;
}

/*Height*/
.mc-g-height-394 {
  height: 394px;
}

/*Padding x-os*/
.mc-g-padding-x-394 {
  padding: 0 394px;
}

/*Font size*/
.mc-g-font-size-394 {
  font-size: 394px;
}

/*Height*/
.mc-g-height-395 {
  height: 395px;
}

/*Padding x-os*/
.mc-g-padding-x-395 {
  padding: 0 395px;
}

/*Font size*/
.mc-g-font-size-395 {
  font-size: 395px;
}

/*Height*/
.mc-g-height-396 {
  height: 396px;
}

/*Padding x-os*/
.mc-g-padding-x-396 {
  padding: 0 396px;
}

/*Font size*/
.mc-g-font-size-396 {
  font-size: 396px;
}

/*Height*/
.mc-g-height-397 {
  height: 397px;
}

/*Padding x-os*/
.mc-g-padding-x-397 {
  padding: 0 397px;
}

/*Font size*/
.mc-g-font-size-397 {
  font-size: 397px;
}

/*Height*/
.mc-g-height-398 {
  height: 398px;
}

/*Padding x-os*/
.mc-g-padding-x-398 {
  padding: 0 398px;
}

/*Font size*/
.mc-g-font-size-398 {
  font-size: 398px;
}

/*Height*/
.mc-g-height-399 {
  height: 399px;
}

/*Padding x-os*/
.mc-g-padding-x-399 {
  padding: 0 399px;
}

/*Font size*/
.mc-g-font-size-399 {
  font-size: 399px;
}

/*Height*/
.mc-g-height-400 {
  height: 400px;
}

/*Padding x-os*/
.mc-g-padding-x-400 {
  padding: 0 400px;
}

/*Font size*/
.mc-g-font-size-400 {
  font-size: 400px;
}

/*Height*/
.mc-g-height-401 {
  height: 401px;
}

/*Padding x-os*/
.mc-g-padding-x-401 {
  padding: 0 401px;
}

/*Font size*/
.mc-g-font-size-401 {
  font-size: 401px;
}

/*Height*/
.mc-g-height-402 {
  height: 402px;
}

/*Padding x-os*/
.mc-g-padding-x-402 {
  padding: 0 402px;
}

/*Font size*/
.mc-g-font-size-402 {
  font-size: 402px;
}

/*Height*/
.mc-g-height-403 {
  height: 403px;
}

/*Padding x-os*/
.mc-g-padding-x-403 {
  padding: 0 403px;
}

/*Font size*/
.mc-g-font-size-403 {
  font-size: 403px;
}

/*Height*/
.mc-g-height-404 {
  height: 404px;
}

/*Padding x-os*/
.mc-g-padding-x-404 {
  padding: 0 404px;
}

/*Font size*/
.mc-g-font-size-404 {
  font-size: 404px;
}

/*Height*/
.mc-g-height-405 {
  height: 405px;
}

/*Padding x-os*/
.mc-g-padding-x-405 {
  padding: 0 405px;
}

/*Font size*/
.mc-g-font-size-405 {
  font-size: 405px;
}

/*Height*/
.mc-g-height-406 {
  height: 406px;
}

/*Padding x-os*/
.mc-g-padding-x-406 {
  padding: 0 406px;
}

/*Font size*/
.mc-g-font-size-406 {
  font-size: 406px;
}

/*Height*/
.mc-g-height-407 {
  height: 407px;
}

/*Padding x-os*/
.mc-g-padding-x-407 {
  padding: 0 407px;
}

/*Font size*/
.mc-g-font-size-407 {
  font-size: 407px;
}

/*Height*/
.mc-g-height-408 {
  height: 408px;
}

/*Padding x-os*/
.mc-g-padding-x-408 {
  padding: 0 408px;
}

/*Font size*/
.mc-g-font-size-408 {
  font-size: 408px;
}

/*Height*/
.mc-g-height-409 {
  height: 409px;
}

/*Padding x-os*/
.mc-g-padding-x-409 {
  padding: 0 409px;
}

/*Font size*/
.mc-g-font-size-409 {
  font-size: 409px;
}

/*Height*/
.mc-g-height-410 {
  height: 410px;
}

/*Padding x-os*/
.mc-g-padding-x-410 {
  padding: 0 410px;
}

/*Font size*/
.mc-g-font-size-410 {
  font-size: 410px;
}

/*Height*/
.mc-g-height-411 {
  height: 411px;
}

/*Padding x-os*/
.mc-g-padding-x-411 {
  padding: 0 411px;
}

/*Font size*/
.mc-g-font-size-411 {
  font-size: 411px;
}

/*Height*/
.mc-g-height-412 {
  height: 412px;
}

/*Padding x-os*/
.mc-g-padding-x-412 {
  padding: 0 412px;
}

/*Font size*/
.mc-g-font-size-412 {
  font-size: 412px;
}

/*Height*/
.mc-g-height-413 {
  height: 413px;
}

/*Padding x-os*/
.mc-g-padding-x-413 {
  padding: 0 413px;
}

/*Font size*/
.mc-g-font-size-413 {
  font-size: 413px;
}

/*Height*/
.mc-g-height-414 {
  height: 414px;
}

/*Padding x-os*/
.mc-g-padding-x-414 {
  padding: 0 414px;
}

/*Font size*/
.mc-g-font-size-414 {
  font-size: 414px;
}

/*Height*/
.mc-g-height-415 {
  height: 415px;
}

/*Padding x-os*/
.mc-g-padding-x-415 {
  padding: 0 415px;
}

/*Font size*/
.mc-g-font-size-415 {
  font-size: 415px;
}

/*Height*/
.mc-g-height-416 {
  height: 416px;
}

/*Padding x-os*/
.mc-g-padding-x-416 {
  padding: 0 416px;
}

/*Font size*/
.mc-g-font-size-416 {
  font-size: 416px;
}

/*Height*/
.mc-g-height-417 {
  height: 417px;
}

/*Padding x-os*/
.mc-g-padding-x-417 {
  padding: 0 417px;
}

/*Font size*/
.mc-g-font-size-417 {
  font-size: 417px;
}

/*Height*/
.mc-g-height-418 {
  height: 418px;
}

/*Padding x-os*/
.mc-g-padding-x-418 {
  padding: 0 418px;
}

/*Font size*/
.mc-g-font-size-418 {
  font-size: 418px;
}

/*Height*/
.mc-g-height-419 {
  height: 419px;
}

/*Padding x-os*/
.mc-g-padding-x-419 {
  padding: 0 419px;
}

/*Font size*/
.mc-g-font-size-419 {
  font-size: 419px;
}

/*Height*/
.mc-g-height-420 {
  height: 420px;
}

/*Padding x-os*/
.mc-g-padding-x-420 {
  padding: 0 420px;
}

/*Font size*/
.mc-g-font-size-420 {
  font-size: 420px;
}

/*Height*/
.mc-g-height-421 {
  height: 421px;
}

/*Padding x-os*/
.mc-g-padding-x-421 {
  padding: 0 421px;
}

/*Font size*/
.mc-g-font-size-421 {
  font-size: 421px;
}

/*Height*/
.mc-g-height-422 {
  height: 422px;
}

/*Padding x-os*/
.mc-g-padding-x-422 {
  padding: 0 422px;
}

/*Font size*/
.mc-g-font-size-422 {
  font-size: 422px;
}

/*Height*/
.mc-g-height-423 {
  height: 423px;
}

/*Padding x-os*/
.mc-g-padding-x-423 {
  padding: 0 423px;
}

/*Font size*/
.mc-g-font-size-423 {
  font-size: 423px;
}

/*Height*/
.mc-g-height-424 {
  height: 424px;
}

/*Padding x-os*/
.mc-g-padding-x-424 {
  padding: 0 424px;
}

/*Font size*/
.mc-g-font-size-424 {
  font-size: 424px;
}

/*Height*/
.mc-g-height-425 {
  height: 425px;
}

/*Padding x-os*/
.mc-g-padding-x-425 {
  padding: 0 425px;
}

/*Font size*/
.mc-g-font-size-425 {
  font-size: 425px;
}

/*Height*/
.mc-g-height-426 {
  height: 426px;
}

/*Padding x-os*/
.mc-g-padding-x-426 {
  padding: 0 426px;
}

/*Font size*/
.mc-g-font-size-426 {
  font-size: 426px;
}

/*Height*/
.mc-g-height-427 {
  height: 427px;
}

/*Padding x-os*/
.mc-g-padding-x-427 {
  padding: 0 427px;
}

/*Font size*/
.mc-g-font-size-427 {
  font-size: 427px;
}

/*Height*/
.mc-g-height-428 {
  height: 428px;
}

/*Padding x-os*/
.mc-g-padding-x-428 {
  padding: 0 428px;
}

/*Font size*/
.mc-g-font-size-428 {
  font-size: 428px;
}

/*Height*/
.mc-g-height-429 {
  height: 429px;
}

/*Padding x-os*/
.mc-g-padding-x-429 {
  padding: 0 429px;
}

/*Font size*/
.mc-g-font-size-429 {
  font-size: 429px;
}

/*Height*/
.mc-g-height-430 {
  height: 430px;
}

/*Padding x-os*/
.mc-g-padding-x-430 {
  padding: 0 430px;
}

/*Font size*/
.mc-g-font-size-430 {
  font-size: 430px;
}

/*Height*/
.mc-g-height-431 {
  height: 431px;
}

/*Padding x-os*/
.mc-g-padding-x-431 {
  padding: 0 431px;
}

/*Font size*/
.mc-g-font-size-431 {
  font-size: 431px;
}

/*Height*/
.mc-g-height-432 {
  height: 432px;
}

/*Padding x-os*/
.mc-g-padding-x-432 {
  padding: 0 432px;
}

/*Font size*/
.mc-g-font-size-432 {
  font-size: 432px;
}

/*Height*/
.mc-g-height-433 {
  height: 433px;
}

/*Padding x-os*/
.mc-g-padding-x-433 {
  padding: 0 433px;
}

/*Font size*/
.mc-g-font-size-433 {
  font-size: 433px;
}

/*Height*/
.mc-g-height-434 {
  height: 434px;
}

/*Padding x-os*/
.mc-g-padding-x-434 {
  padding: 0 434px;
}

/*Font size*/
.mc-g-font-size-434 {
  font-size: 434px;
}

/*Height*/
.mc-g-height-435 {
  height: 435px;
}

/*Padding x-os*/
.mc-g-padding-x-435 {
  padding: 0 435px;
}

/*Font size*/
.mc-g-font-size-435 {
  font-size: 435px;
}

/*Height*/
.mc-g-height-436 {
  height: 436px;
}

/*Padding x-os*/
.mc-g-padding-x-436 {
  padding: 0 436px;
}

/*Font size*/
.mc-g-font-size-436 {
  font-size: 436px;
}

/*Height*/
.mc-g-height-437 {
  height: 437px;
}

/*Padding x-os*/
.mc-g-padding-x-437 {
  padding: 0 437px;
}

/*Font size*/
.mc-g-font-size-437 {
  font-size: 437px;
}

/*Height*/
.mc-g-height-438 {
  height: 438px;
}

/*Padding x-os*/
.mc-g-padding-x-438 {
  padding: 0 438px;
}

/*Font size*/
.mc-g-font-size-438 {
  font-size: 438px;
}

/*Height*/
.mc-g-height-439 {
  height: 439px;
}

/*Padding x-os*/
.mc-g-padding-x-439 {
  padding: 0 439px;
}

/*Font size*/
.mc-g-font-size-439 {
  font-size: 439px;
}

/*Height*/
.mc-g-height-440 {
  height: 440px;
}

/*Padding x-os*/
.mc-g-padding-x-440 {
  padding: 0 440px;
}

/*Font size*/
.mc-g-font-size-440 {
  font-size: 440px;
}

/*Height*/
.mc-g-height-441 {
  height: 441px;
}

/*Padding x-os*/
.mc-g-padding-x-441 {
  padding: 0 441px;
}

/*Font size*/
.mc-g-font-size-441 {
  font-size: 441px;
}

/*Height*/
.mc-g-height-442 {
  height: 442px;
}

/*Padding x-os*/
.mc-g-padding-x-442 {
  padding: 0 442px;
}

/*Font size*/
.mc-g-font-size-442 {
  font-size: 442px;
}

/*Height*/
.mc-g-height-443 {
  height: 443px;
}

/*Padding x-os*/
.mc-g-padding-x-443 {
  padding: 0 443px;
}

/*Font size*/
.mc-g-font-size-443 {
  font-size: 443px;
}

/*Height*/
.mc-g-height-444 {
  height: 444px;
}

/*Padding x-os*/
.mc-g-padding-x-444 {
  padding: 0 444px;
}

/*Font size*/
.mc-g-font-size-444 {
  font-size: 444px;
}

/*Height*/
.mc-g-height-445 {
  height: 445px;
}

/*Padding x-os*/
.mc-g-padding-x-445 {
  padding: 0 445px;
}

/*Font size*/
.mc-g-font-size-445 {
  font-size: 445px;
}

/*Height*/
.mc-g-height-446 {
  height: 446px;
}

/*Padding x-os*/
.mc-g-padding-x-446 {
  padding: 0 446px;
}

/*Font size*/
.mc-g-font-size-446 {
  font-size: 446px;
}

/*Height*/
.mc-g-height-447 {
  height: 447px;
}

/*Padding x-os*/
.mc-g-padding-x-447 {
  padding: 0 447px;
}

/*Font size*/
.mc-g-font-size-447 {
  font-size: 447px;
}

/*Height*/
.mc-g-height-448 {
  height: 448px;
}

/*Padding x-os*/
.mc-g-padding-x-448 {
  padding: 0 448px;
}

/*Font size*/
.mc-g-font-size-448 {
  font-size: 448px;
}

/*Height*/
.mc-g-height-449 {
  height: 449px;
}

/*Padding x-os*/
.mc-g-padding-x-449 {
  padding: 0 449px;
}

/*Font size*/
.mc-g-font-size-449 {
  font-size: 449px;
}

/*Height*/
.mc-g-height-450 {
  height: 450px;
}

/*Padding x-os*/
.mc-g-padding-x-450 {
  padding: 0 450px;
}

/*Font size*/
.mc-g-font-size-450 {
  font-size: 450px;
}

/*Height*/
.mc-g-height-451 {
  height: 451px;
}

/*Padding x-os*/
.mc-g-padding-x-451 {
  padding: 0 451px;
}

/*Font size*/
.mc-g-font-size-451 {
  font-size: 451px;
}

/*Height*/
.mc-g-height-452 {
  height: 452px;
}

/*Padding x-os*/
.mc-g-padding-x-452 {
  padding: 0 452px;
}

/*Font size*/
.mc-g-font-size-452 {
  font-size: 452px;
}

/*Height*/
.mc-g-height-453 {
  height: 453px;
}

/*Padding x-os*/
.mc-g-padding-x-453 {
  padding: 0 453px;
}

/*Font size*/
.mc-g-font-size-453 {
  font-size: 453px;
}

/*Height*/
.mc-g-height-454 {
  height: 454px;
}

/*Padding x-os*/
.mc-g-padding-x-454 {
  padding: 0 454px;
}

/*Font size*/
.mc-g-font-size-454 {
  font-size: 454px;
}

/*Height*/
.mc-g-height-455 {
  height: 455px;
}

/*Padding x-os*/
.mc-g-padding-x-455 {
  padding: 0 455px;
}

/*Font size*/
.mc-g-font-size-455 {
  font-size: 455px;
}

/*Height*/
.mc-g-height-456 {
  height: 456px;
}

/*Padding x-os*/
.mc-g-padding-x-456 {
  padding: 0 456px;
}

/*Font size*/
.mc-g-font-size-456 {
  font-size: 456px;
}

/*Height*/
.mc-g-height-457 {
  height: 457px;
}

/*Padding x-os*/
.mc-g-padding-x-457 {
  padding: 0 457px;
}

/*Font size*/
.mc-g-font-size-457 {
  font-size: 457px;
}

/*Height*/
.mc-g-height-458 {
  height: 458px;
}

/*Padding x-os*/
.mc-g-padding-x-458 {
  padding: 0 458px;
}

/*Font size*/
.mc-g-font-size-458 {
  font-size: 458px;
}

/*Height*/
.mc-g-height-459 {
  height: 459px;
}

/*Padding x-os*/
.mc-g-padding-x-459 {
  padding: 0 459px;
}

/*Font size*/
.mc-g-font-size-459 {
  font-size: 459px;
}

/*Height*/
.mc-g-height-460 {
  height: 460px;
}

/*Padding x-os*/
.mc-g-padding-x-460 {
  padding: 0 460px;
}

/*Font size*/
.mc-g-font-size-460 {
  font-size: 460px;
}

/*Height*/
.mc-g-height-461 {
  height: 461px;
}

/*Padding x-os*/
.mc-g-padding-x-461 {
  padding: 0 461px;
}

/*Font size*/
.mc-g-font-size-461 {
  font-size: 461px;
}

/*Height*/
.mc-g-height-462 {
  height: 462px;
}

/*Padding x-os*/
.mc-g-padding-x-462 {
  padding: 0 462px;
}

/*Font size*/
.mc-g-font-size-462 {
  font-size: 462px;
}

/*Height*/
.mc-g-height-463 {
  height: 463px;
}

/*Padding x-os*/
.mc-g-padding-x-463 {
  padding: 0 463px;
}

/*Font size*/
.mc-g-font-size-463 {
  font-size: 463px;
}

/*Height*/
.mc-g-height-464 {
  height: 464px;
}

/*Padding x-os*/
.mc-g-padding-x-464 {
  padding: 0 464px;
}

/*Font size*/
.mc-g-font-size-464 {
  font-size: 464px;
}

/*Height*/
.mc-g-height-465 {
  height: 465px;
}

/*Padding x-os*/
.mc-g-padding-x-465 {
  padding: 0 465px;
}

/*Font size*/
.mc-g-font-size-465 {
  font-size: 465px;
}

/*Height*/
.mc-g-height-466 {
  height: 466px;
}

/*Padding x-os*/
.mc-g-padding-x-466 {
  padding: 0 466px;
}

/*Font size*/
.mc-g-font-size-466 {
  font-size: 466px;
}

/*Height*/
.mc-g-height-467 {
  height: 467px;
}

/*Padding x-os*/
.mc-g-padding-x-467 {
  padding: 0 467px;
}

/*Font size*/
.mc-g-font-size-467 {
  font-size: 467px;
}

/*Height*/
.mc-g-height-468 {
  height: 468px;
}

/*Padding x-os*/
.mc-g-padding-x-468 {
  padding: 0 468px;
}

/*Font size*/
.mc-g-font-size-468 {
  font-size: 468px;
}

/*Height*/
.mc-g-height-469 {
  height: 469px;
}

/*Padding x-os*/
.mc-g-padding-x-469 {
  padding: 0 469px;
}

/*Font size*/
.mc-g-font-size-469 {
  font-size: 469px;
}

/*Height*/
.mc-g-height-470 {
  height: 470px;
}

/*Padding x-os*/
.mc-g-padding-x-470 {
  padding: 0 470px;
}

/*Font size*/
.mc-g-font-size-470 {
  font-size: 470px;
}

/*Height*/
.mc-g-height-471 {
  height: 471px;
}

/*Padding x-os*/
.mc-g-padding-x-471 {
  padding: 0 471px;
}

/*Font size*/
.mc-g-font-size-471 {
  font-size: 471px;
}

/*Height*/
.mc-g-height-472 {
  height: 472px;
}

/*Padding x-os*/
.mc-g-padding-x-472 {
  padding: 0 472px;
}

/*Font size*/
.mc-g-font-size-472 {
  font-size: 472px;
}

/*Height*/
.mc-g-height-473 {
  height: 473px;
}

/*Padding x-os*/
.mc-g-padding-x-473 {
  padding: 0 473px;
}

/*Font size*/
.mc-g-font-size-473 {
  font-size: 473px;
}

/*Height*/
.mc-g-height-474 {
  height: 474px;
}

/*Padding x-os*/
.mc-g-padding-x-474 {
  padding: 0 474px;
}

/*Font size*/
.mc-g-font-size-474 {
  font-size: 474px;
}

/*Height*/
.mc-g-height-475 {
  height: 475px;
}

/*Padding x-os*/
.mc-g-padding-x-475 {
  padding: 0 475px;
}

/*Font size*/
.mc-g-font-size-475 {
  font-size: 475px;
}

/*Height*/
.mc-g-height-476 {
  height: 476px;
}

/*Padding x-os*/
.mc-g-padding-x-476 {
  padding: 0 476px;
}

/*Font size*/
.mc-g-font-size-476 {
  font-size: 476px;
}

/*Height*/
.mc-g-height-477 {
  height: 477px;
}

/*Padding x-os*/
.mc-g-padding-x-477 {
  padding: 0 477px;
}

/*Font size*/
.mc-g-font-size-477 {
  font-size: 477px;
}

/*Height*/
.mc-g-height-478 {
  height: 478px;
}

/*Padding x-os*/
.mc-g-padding-x-478 {
  padding: 0 478px;
}

/*Font size*/
.mc-g-font-size-478 {
  font-size: 478px;
}

/*Height*/
.mc-g-height-479 {
  height: 479px;
}

/*Padding x-os*/
.mc-g-padding-x-479 {
  padding: 0 479px;
}

/*Font size*/
.mc-g-font-size-479 {
  font-size: 479px;
}

/*Height*/
.mc-g-height-480 {
  height: 480px;
}

/*Padding x-os*/
.mc-g-padding-x-480 {
  padding: 0 480px;
}

/*Font size*/
.mc-g-font-size-480 {
  font-size: 480px;
}

/*Height*/
.mc-g-height-481 {
  height: 481px;
}

/*Padding x-os*/
.mc-g-padding-x-481 {
  padding: 0 481px;
}

/*Font size*/
.mc-g-font-size-481 {
  font-size: 481px;
}

/*Height*/
.mc-g-height-482 {
  height: 482px;
}

/*Padding x-os*/
.mc-g-padding-x-482 {
  padding: 0 482px;
}

/*Font size*/
.mc-g-font-size-482 {
  font-size: 482px;
}

/*Height*/
.mc-g-height-483 {
  height: 483px;
}

/*Padding x-os*/
.mc-g-padding-x-483 {
  padding: 0 483px;
}

/*Font size*/
.mc-g-font-size-483 {
  font-size: 483px;
}

/*Height*/
.mc-g-height-484 {
  height: 484px;
}

/*Padding x-os*/
.mc-g-padding-x-484 {
  padding: 0 484px;
}

/*Font size*/
.mc-g-font-size-484 {
  font-size: 484px;
}

/*Height*/
.mc-g-height-485 {
  height: 485px;
}

/*Padding x-os*/
.mc-g-padding-x-485 {
  padding: 0 485px;
}

/*Font size*/
.mc-g-font-size-485 {
  font-size: 485px;
}

/*Height*/
.mc-g-height-486 {
  height: 486px;
}

/*Padding x-os*/
.mc-g-padding-x-486 {
  padding: 0 486px;
}

/*Font size*/
.mc-g-font-size-486 {
  font-size: 486px;
}

/*Height*/
.mc-g-height-487 {
  height: 487px;
}

/*Padding x-os*/
.mc-g-padding-x-487 {
  padding: 0 487px;
}

/*Font size*/
.mc-g-font-size-487 {
  font-size: 487px;
}

/*Height*/
.mc-g-height-488 {
  height: 488px;
}

/*Padding x-os*/
.mc-g-padding-x-488 {
  padding: 0 488px;
}

/*Font size*/
.mc-g-font-size-488 {
  font-size: 488px;
}

/*Height*/
.mc-g-height-489 {
  height: 489px;
}

/*Padding x-os*/
.mc-g-padding-x-489 {
  padding: 0 489px;
}

/*Font size*/
.mc-g-font-size-489 {
  font-size: 489px;
}

/*Height*/
.mc-g-height-490 {
  height: 490px;
}

/*Padding x-os*/
.mc-g-padding-x-490 {
  padding: 0 490px;
}

/*Font size*/
.mc-g-font-size-490 {
  font-size: 490px;
}

/*Height*/
.mc-g-height-491 {
  height: 491px;
}

/*Padding x-os*/
.mc-g-padding-x-491 {
  padding: 0 491px;
}

/*Font size*/
.mc-g-font-size-491 {
  font-size: 491px;
}

/*Height*/
.mc-g-height-492 {
  height: 492px;
}

/*Padding x-os*/
.mc-g-padding-x-492 {
  padding: 0 492px;
}

/*Font size*/
.mc-g-font-size-492 {
  font-size: 492px;
}

/*Height*/
.mc-g-height-493 {
  height: 493px;
}

/*Padding x-os*/
.mc-g-padding-x-493 {
  padding: 0 493px;
}

/*Font size*/
.mc-g-font-size-493 {
  font-size: 493px;
}

/*Height*/
.mc-g-height-494 {
  height: 494px;
}

/*Padding x-os*/
.mc-g-padding-x-494 {
  padding: 0 494px;
}

/*Font size*/
.mc-g-font-size-494 {
  font-size: 494px;
}

/*Height*/
.mc-g-height-495 {
  height: 495px;
}

/*Padding x-os*/
.mc-g-padding-x-495 {
  padding: 0 495px;
}

/*Font size*/
.mc-g-font-size-495 {
  font-size: 495px;
}

/*Height*/
.mc-g-height-496 {
  height: 496px;
}

/*Padding x-os*/
.mc-g-padding-x-496 {
  padding: 0 496px;
}

/*Font size*/
.mc-g-font-size-496 {
  font-size: 496px;
}

/*Height*/
.mc-g-height-497 {
  height: 497px;
}

/*Padding x-os*/
.mc-g-padding-x-497 {
  padding: 0 497px;
}

/*Font size*/
.mc-g-font-size-497 {
  font-size: 497px;
}

/*Height*/
.mc-g-height-498 {
  height: 498px;
}

/*Padding x-os*/
.mc-g-padding-x-498 {
  padding: 0 498px;
}

/*Font size*/
.mc-g-font-size-498 {
  font-size: 498px;
}

/*Height*/
.mc-g-height-499 {
  height: 499px;
}

/*Padding x-os*/
.mc-g-padding-x-499 {
  padding: 0 499px;
}

/*Font size*/
.mc-g-font-size-499 {
  font-size: 499px;
}

/*Height*/
.mc-g-height-500 {
  height: 500px;
}

/*Padding x-os*/
.mc-g-padding-x-500 {
  padding: 0 500px;
}

/*Font size*/
.mc-g-font-size-500 {
  font-size: 500px;
}

/*Height*/
.mc-g-height-501 {
  height: 501px;
}

/*Padding x-os*/
.mc-g-padding-x-501 {
  padding: 0 501px;
}

/*Font size*/
.mc-g-font-size-501 {
  font-size: 501px;
}

/*Height*/
.mc-g-height-502 {
  height: 502px;
}

/*Padding x-os*/
.mc-g-padding-x-502 {
  padding: 0 502px;
}

/*Font size*/
.mc-g-font-size-502 {
  font-size: 502px;
}

/*Height*/
.mc-g-height-503 {
  height: 503px;
}

/*Padding x-os*/
.mc-g-padding-x-503 {
  padding: 0 503px;
}

/*Font size*/
.mc-g-font-size-503 {
  font-size: 503px;
}

/*Height*/
.mc-g-height-504 {
  height: 504px;
}

/*Padding x-os*/
.mc-g-padding-x-504 {
  padding: 0 504px;
}

/*Font size*/
.mc-g-font-size-504 {
  font-size: 504px;
}

/*Height*/
.mc-g-height-505 {
  height: 505px;
}

/*Padding x-os*/
.mc-g-padding-x-505 {
  padding: 0 505px;
}

/*Font size*/
.mc-g-font-size-505 {
  font-size: 505px;
}

/*Height*/
.mc-g-height-506 {
  height: 506px;
}

/*Padding x-os*/
.mc-g-padding-x-506 {
  padding: 0 506px;
}

/*Font size*/
.mc-g-font-size-506 {
  font-size: 506px;
}

/*Height*/
.mc-g-height-507 {
  height: 507px;
}

/*Padding x-os*/
.mc-g-padding-x-507 {
  padding: 0 507px;
}

/*Font size*/
.mc-g-font-size-507 {
  font-size: 507px;
}

/*Height*/
.mc-g-height-508 {
  height: 508px;
}

/*Padding x-os*/
.mc-g-padding-x-508 {
  padding: 0 508px;
}

/*Font size*/
.mc-g-font-size-508 {
  font-size: 508px;
}

/*Height*/
.mc-g-height-509 {
  height: 509px;
}

/*Padding x-os*/
.mc-g-padding-x-509 {
  padding: 0 509px;
}

/*Font size*/
.mc-g-font-size-509 {
  font-size: 509px;
}

/*Height*/
.mc-g-height-510 {
  height: 510px;
}

/*Padding x-os*/
.mc-g-padding-x-510 {
  padding: 0 510px;
}

/*Font size*/
.mc-g-font-size-510 {
  font-size: 510px;
}

/*Height*/
.mc-g-height-511 {
  height: 511px;
}

/*Padding x-os*/
.mc-g-padding-x-511 {
  padding: 0 511px;
}

/*Font size*/
.mc-g-font-size-511 {
  font-size: 511px;
}

/*Height*/
.mc-g-height-512 {
  height: 512px;
}

/*Padding x-os*/
.mc-g-padding-x-512 {
  padding: 0 512px;
}

/*Font size*/
.mc-g-font-size-512 {
  font-size: 512px;
}

/*Height*/
.mc-g-height-513 {
  height: 513px;
}

/*Padding x-os*/
.mc-g-padding-x-513 {
  padding: 0 513px;
}

/*Font size*/
.mc-g-font-size-513 {
  font-size: 513px;
}

/*Height*/
.mc-g-height-514 {
  height: 514px;
}

/*Padding x-os*/
.mc-g-padding-x-514 {
  padding: 0 514px;
}

/*Font size*/
.mc-g-font-size-514 {
  font-size: 514px;
}

/*Height*/
.mc-g-height-515 {
  height: 515px;
}

/*Padding x-os*/
.mc-g-padding-x-515 {
  padding: 0 515px;
}

/*Font size*/
.mc-g-font-size-515 {
  font-size: 515px;
}

/*Height*/
.mc-g-height-516 {
  height: 516px;
}

/*Padding x-os*/
.mc-g-padding-x-516 {
  padding: 0 516px;
}

/*Font size*/
.mc-g-font-size-516 {
  font-size: 516px;
}

/*Height*/
.mc-g-height-517 {
  height: 517px;
}

/*Padding x-os*/
.mc-g-padding-x-517 {
  padding: 0 517px;
}

/*Font size*/
.mc-g-font-size-517 {
  font-size: 517px;
}

/*Height*/
.mc-g-height-518 {
  height: 518px;
}

/*Padding x-os*/
.mc-g-padding-x-518 {
  padding: 0 518px;
}

/*Font size*/
.mc-g-font-size-518 {
  font-size: 518px;
}

/*Height*/
.mc-g-height-519 {
  height: 519px;
}

/*Padding x-os*/
.mc-g-padding-x-519 {
  padding: 0 519px;
}

/*Font size*/
.mc-g-font-size-519 {
  font-size: 519px;
}

/*Height*/
.mc-g-height-520 {
  height: 520px;
}

/*Padding x-os*/
.mc-g-padding-x-520 {
  padding: 0 520px;
}

/*Font size*/
.mc-g-font-size-520 {
  font-size: 520px;
}

/*Height*/
.mc-g-height-521 {
  height: 521px;
}

/*Padding x-os*/
.mc-g-padding-x-521 {
  padding: 0 521px;
}

/*Font size*/
.mc-g-font-size-521 {
  font-size: 521px;
}

/*Height*/
.mc-g-height-522 {
  height: 522px;
}

/*Padding x-os*/
.mc-g-padding-x-522 {
  padding: 0 522px;
}

/*Font size*/
.mc-g-font-size-522 {
  font-size: 522px;
}

/*Height*/
.mc-g-height-523 {
  height: 523px;
}

/*Padding x-os*/
.mc-g-padding-x-523 {
  padding: 0 523px;
}

/*Font size*/
.mc-g-font-size-523 {
  font-size: 523px;
}

/*Height*/
.mc-g-height-524 {
  height: 524px;
}

/*Padding x-os*/
.mc-g-padding-x-524 {
  padding: 0 524px;
}

/*Font size*/
.mc-g-font-size-524 {
  font-size: 524px;
}

/*Height*/
.mc-g-height-525 {
  height: 525px;
}

/*Padding x-os*/
.mc-g-padding-x-525 {
  padding: 0 525px;
}

/*Font size*/
.mc-g-font-size-525 {
  font-size: 525px;
}

/*Height*/
.mc-g-height-526 {
  height: 526px;
}

/*Padding x-os*/
.mc-g-padding-x-526 {
  padding: 0 526px;
}

/*Font size*/
.mc-g-font-size-526 {
  font-size: 526px;
}

/*Height*/
.mc-g-height-527 {
  height: 527px;
}

/*Padding x-os*/
.mc-g-padding-x-527 {
  padding: 0 527px;
}

/*Font size*/
.mc-g-font-size-527 {
  font-size: 527px;
}

/*Height*/
.mc-g-height-528 {
  height: 528px;
}

/*Padding x-os*/
.mc-g-padding-x-528 {
  padding: 0 528px;
}

/*Font size*/
.mc-g-font-size-528 {
  font-size: 528px;
}

/*Height*/
.mc-g-height-529 {
  height: 529px;
}

/*Padding x-os*/
.mc-g-padding-x-529 {
  padding: 0 529px;
}

/*Font size*/
.mc-g-font-size-529 {
  font-size: 529px;
}

/*Height*/
.mc-g-height-530 {
  height: 530px;
}

/*Padding x-os*/
.mc-g-padding-x-530 {
  padding: 0 530px;
}

/*Font size*/
.mc-g-font-size-530 {
  font-size: 530px;
}

/*Height*/
.mc-g-height-531 {
  height: 531px;
}

/*Padding x-os*/
.mc-g-padding-x-531 {
  padding: 0 531px;
}

/*Font size*/
.mc-g-font-size-531 {
  font-size: 531px;
}

/*Height*/
.mc-g-height-532 {
  height: 532px;
}

/*Padding x-os*/
.mc-g-padding-x-532 {
  padding: 0 532px;
}

/*Font size*/
.mc-g-font-size-532 {
  font-size: 532px;
}

/*Height*/
.mc-g-height-533 {
  height: 533px;
}

/*Padding x-os*/
.mc-g-padding-x-533 {
  padding: 0 533px;
}

/*Font size*/
.mc-g-font-size-533 {
  font-size: 533px;
}

/*Height*/
.mc-g-height-534 {
  height: 534px;
}

/*Padding x-os*/
.mc-g-padding-x-534 {
  padding: 0 534px;
}

/*Font size*/
.mc-g-font-size-534 {
  font-size: 534px;
}

/*Height*/
.mc-g-height-535 {
  height: 535px;
}

/*Padding x-os*/
.mc-g-padding-x-535 {
  padding: 0 535px;
}

/*Font size*/
.mc-g-font-size-535 {
  font-size: 535px;
}

/*Height*/
.mc-g-height-536 {
  height: 536px;
}

/*Padding x-os*/
.mc-g-padding-x-536 {
  padding: 0 536px;
}

/*Font size*/
.mc-g-font-size-536 {
  font-size: 536px;
}

/*Height*/
.mc-g-height-537 {
  height: 537px;
}

/*Padding x-os*/
.mc-g-padding-x-537 {
  padding: 0 537px;
}

/*Font size*/
.mc-g-font-size-537 {
  font-size: 537px;
}

/*Height*/
.mc-g-height-538 {
  height: 538px;
}

/*Padding x-os*/
.mc-g-padding-x-538 {
  padding: 0 538px;
}

/*Font size*/
.mc-g-font-size-538 {
  font-size: 538px;
}

/*Height*/
.mc-g-height-539 {
  height: 539px;
}

/*Padding x-os*/
.mc-g-padding-x-539 {
  padding: 0 539px;
}

/*Font size*/
.mc-g-font-size-539 {
  font-size: 539px;
}

/*Height*/
.mc-g-height-540 {
  height: 540px;
}

/*Padding x-os*/
.mc-g-padding-x-540 {
  padding: 0 540px;
}

/*Font size*/
.mc-g-font-size-540 {
  font-size: 540px;
}

/*Height*/
.mc-g-height-541 {
  height: 541px;
}

/*Padding x-os*/
.mc-g-padding-x-541 {
  padding: 0 541px;
}

/*Font size*/
.mc-g-font-size-541 {
  font-size: 541px;
}

/*Height*/
.mc-g-height-542 {
  height: 542px;
}

/*Padding x-os*/
.mc-g-padding-x-542 {
  padding: 0 542px;
}

/*Font size*/
.mc-g-font-size-542 {
  font-size: 542px;
}

/*Height*/
.mc-g-height-543 {
  height: 543px;
}

/*Padding x-os*/
.mc-g-padding-x-543 {
  padding: 0 543px;
}

/*Font size*/
.mc-g-font-size-543 {
  font-size: 543px;
}

/*Height*/
.mc-g-height-544 {
  height: 544px;
}

/*Padding x-os*/
.mc-g-padding-x-544 {
  padding: 0 544px;
}

/*Font size*/
.mc-g-font-size-544 {
  font-size: 544px;
}

/*Height*/
.mc-g-height-545 {
  height: 545px;
}

/*Padding x-os*/
.mc-g-padding-x-545 {
  padding: 0 545px;
}

/*Font size*/
.mc-g-font-size-545 {
  font-size: 545px;
}

/*Height*/
.mc-g-height-546 {
  height: 546px;
}

/*Padding x-os*/
.mc-g-padding-x-546 {
  padding: 0 546px;
}

/*Font size*/
.mc-g-font-size-546 {
  font-size: 546px;
}

/*Height*/
.mc-g-height-547 {
  height: 547px;
}

/*Padding x-os*/
.mc-g-padding-x-547 {
  padding: 0 547px;
}

/*Font size*/
.mc-g-font-size-547 {
  font-size: 547px;
}

/*Height*/
.mc-g-height-548 {
  height: 548px;
}

/*Padding x-os*/
.mc-g-padding-x-548 {
  padding: 0 548px;
}

/*Font size*/
.mc-g-font-size-548 {
  font-size: 548px;
}

/*Height*/
.mc-g-height-549 {
  height: 549px;
}

/*Padding x-os*/
.mc-g-padding-x-549 {
  padding: 0 549px;
}

/*Font size*/
.mc-g-font-size-549 {
  font-size: 549px;
}

/*Height*/
.mc-g-height-550 {
  height: 550px;
}

/*Padding x-os*/
.mc-g-padding-x-550 {
  padding: 0 550px;
}

/*Font size*/
.mc-g-font-size-550 {
  font-size: 550px;
}

/*Height*/
.mc-g-height-551 {
  height: 551px;
}

/*Padding x-os*/
.mc-g-padding-x-551 {
  padding: 0 551px;
}

/*Font size*/
.mc-g-font-size-551 {
  font-size: 551px;
}

/*Height*/
.mc-g-height-552 {
  height: 552px;
}

/*Padding x-os*/
.mc-g-padding-x-552 {
  padding: 0 552px;
}

/*Font size*/
.mc-g-font-size-552 {
  font-size: 552px;
}

/*Height*/
.mc-g-height-553 {
  height: 553px;
}

/*Padding x-os*/
.mc-g-padding-x-553 {
  padding: 0 553px;
}

/*Font size*/
.mc-g-font-size-553 {
  font-size: 553px;
}

/*Height*/
.mc-g-height-554 {
  height: 554px;
}

/*Padding x-os*/
.mc-g-padding-x-554 {
  padding: 0 554px;
}

/*Font size*/
.mc-g-font-size-554 {
  font-size: 554px;
}

/*Height*/
.mc-g-height-555 {
  height: 555px;
}

/*Padding x-os*/
.mc-g-padding-x-555 {
  padding: 0 555px;
}

/*Font size*/
.mc-g-font-size-555 {
  font-size: 555px;
}

/*Height*/
.mc-g-height-556 {
  height: 556px;
}

/*Padding x-os*/
.mc-g-padding-x-556 {
  padding: 0 556px;
}

/*Font size*/
.mc-g-font-size-556 {
  font-size: 556px;
}

/*Height*/
.mc-g-height-557 {
  height: 557px;
}

/*Padding x-os*/
.mc-g-padding-x-557 {
  padding: 0 557px;
}

/*Font size*/
.mc-g-font-size-557 {
  font-size: 557px;
}

/*Height*/
.mc-g-height-558 {
  height: 558px;
}

/*Padding x-os*/
.mc-g-padding-x-558 {
  padding: 0 558px;
}

/*Font size*/
.mc-g-font-size-558 {
  font-size: 558px;
}

/*Height*/
.mc-g-height-559 {
  height: 559px;
}

/*Padding x-os*/
.mc-g-padding-x-559 {
  padding: 0 559px;
}

/*Font size*/
.mc-g-font-size-559 {
  font-size: 559px;
}

/*Height*/
.mc-g-height-560 {
  height: 560px;
}

/*Padding x-os*/
.mc-g-padding-x-560 {
  padding: 0 560px;
}

/*Font size*/
.mc-g-font-size-560 {
  font-size: 560px;
}

/*Height*/
.mc-g-height-561 {
  height: 561px;
}

/*Padding x-os*/
.mc-g-padding-x-561 {
  padding: 0 561px;
}

/*Font size*/
.mc-g-font-size-561 {
  font-size: 561px;
}

/*Height*/
.mc-g-height-562 {
  height: 562px;
}

/*Padding x-os*/
.mc-g-padding-x-562 {
  padding: 0 562px;
}

/*Font size*/
.mc-g-font-size-562 {
  font-size: 562px;
}

/*Height*/
.mc-g-height-563 {
  height: 563px;
}

/*Padding x-os*/
.mc-g-padding-x-563 {
  padding: 0 563px;
}

/*Font size*/
.mc-g-font-size-563 {
  font-size: 563px;
}

/*Height*/
.mc-g-height-564 {
  height: 564px;
}

/*Padding x-os*/
.mc-g-padding-x-564 {
  padding: 0 564px;
}

/*Font size*/
.mc-g-font-size-564 {
  font-size: 564px;
}

/*Height*/
.mc-g-height-565 {
  height: 565px;
}

/*Padding x-os*/
.mc-g-padding-x-565 {
  padding: 0 565px;
}

/*Font size*/
.mc-g-font-size-565 {
  font-size: 565px;
}

/*Height*/
.mc-g-height-566 {
  height: 566px;
}

/*Padding x-os*/
.mc-g-padding-x-566 {
  padding: 0 566px;
}

/*Font size*/
.mc-g-font-size-566 {
  font-size: 566px;
}

/*Height*/
.mc-g-height-567 {
  height: 567px;
}

/*Padding x-os*/
.mc-g-padding-x-567 {
  padding: 0 567px;
}

/*Font size*/
.mc-g-font-size-567 {
  font-size: 567px;
}

/*Height*/
.mc-g-height-568 {
  height: 568px;
}

/*Padding x-os*/
.mc-g-padding-x-568 {
  padding: 0 568px;
}

/*Font size*/
.mc-g-font-size-568 {
  font-size: 568px;
}

/*Height*/
.mc-g-height-569 {
  height: 569px;
}

/*Padding x-os*/
.mc-g-padding-x-569 {
  padding: 0 569px;
}

/*Font size*/
.mc-g-font-size-569 {
  font-size: 569px;
}

/*Height*/
.mc-g-height-570 {
  height: 570px;
}

/*Padding x-os*/
.mc-g-padding-x-570 {
  padding: 0 570px;
}

/*Font size*/
.mc-g-font-size-570 {
  font-size: 570px;
}

/*Height*/
.mc-g-height-571 {
  height: 571px;
}

/*Padding x-os*/
.mc-g-padding-x-571 {
  padding: 0 571px;
}

/*Font size*/
.mc-g-font-size-571 {
  font-size: 571px;
}

/*Height*/
.mc-g-height-572 {
  height: 572px;
}

/*Padding x-os*/
.mc-g-padding-x-572 {
  padding: 0 572px;
}

/*Font size*/
.mc-g-font-size-572 {
  font-size: 572px;
}

/*Height*/
.mc-g-height-573 {
  height: 573px;
}

/*Padding x-os*/
.mc-g-padding-x-573 {
  padding: 0 573px;
}

/*Font size*/
.mc-g-font-size-573 {
  font-size: 573px;
}

/*Height*/
.mc-g-height-574 {
  height: 574px;
}

/*Padding x-os*/
.mc-g-padding-x-574 {
  padding: 0 574px;
}

/*Font size*/
.mc-g-font-size-574 {
  font-size: 574px;
}

/*Height*/
.mc-g-height-575 {
  height: 575px;
}

/*Padding x-os*/
.mc-g-padding-x-575 {
  padding: 0 575px;
}

/*Font size*/
.mc-g-font-size-575 {
  font-size: 575px;
}

/*Height*/
.mc-g-height-576 {
  height: 576px;
}

/*Padding x-os*/
.mc-g-padding-x-576 {
  padding: 0 576px;
}

/*Font size*/
.mc-g-font-size-576 {
  font-size: 576px;
}

/*Height*/
.mc-g-height-577 {
  height: 577px;
}

/*Padding x-os*/
.mc-g-padding-x-577 {
  padding: 0 577px;
}

/*Font size*/
.mc-g-font-size-577 {
  font-size: 577px;
}

/*Height*/
.mc-g-height-578 {
  height: 578px;
}

/*Padding x-os*/
.mc-g-padding-x-578 {
  padding: 0 578px;
}

/*Font size*/
.mc-g-font-size-578 {
  font-size: 578px;
}

/*Height*/
.mc-g-height-579 {
  height: 579px;
}

/*Padding x-os*/
.mc-g-padding-x-579 {
  padding: 0 579px;
}

/*Font size*/
.mc-g-font-size-579 {
  font-size: 579px;
}

/*Height*/
.mc-g-height-580 {
  height: 580px;
}

/*Padding x-os*/
.mc-g-padding-x-580 {
  padding: 0 580px;
}

/*Font size*/
.mc-g-font-size-580 {
  font-size: 580px;
}

/*Height*/
.mc-g-height-581 {
  height: 581px;
}

/*Padding x-os*/
.mc-g-padding-x-581 {
  padding: 0 581px;
}

/*Font size*/
.mc-g-font-size-581 {
  font-size: 581px;
}

/*Height*/
.mc-g-height-582 {
  height: 582px;
}

/*Padding x-os*/
.mc-g-padding-x-582 {
  padding: 0 582px;
}

/*Font size*/
.mc-g-font-size-582 {
  font-size: 582px;
}

/*Height*/
.mc-g-height-583 {
  height: 583px;
}

/*Padding x-os*/
.mc-g-padding-x-583 {
  padding: 0 583px;
}

/*Font size*/
.mc-g-font-size-583 {
  font-size: 583px;
}

/*Height*/
.mc-g-height-584 {
  height: 584px;
}

/*Padding x-os*/
.mc-g-padding-x-584 {
  padding: 0 584px;
}

/*Font size*/
.mc-g-font-size-584 {
  font-size: 584px;
}

/*Height*/
.mc-g-height-585 {
  height: 585px;
}

/*Padding x-os*/
.mc-g-padding-x-585 {
  padding: 0 585px;
}

/*Font size*/
.mc-g-font-size-585 {
  font-size: 585px;
}

/*Height*/
.mc-g-height-586 {
  height: 586px;
}

/*Padding x-os*/
.mc-g-padding-x-586 {
  padding: 0 586px;
}

/*Font size*/
.mc-g-font-size-586 {
  font-size: 586px;
}

/*Height*/
.mc-g-height-587 {
  height: 587px;
}

/*Padding x-os*/
.mc-g-padding-x-587 {
  padding: 0 587px;
}

/*Font size*/
.mc-g-font-size-587 {
  font-size: 587px;
}

/*Height*/
.mc-g-height-588 {
  height: 588px;
}

/*Padding x-os*/
.mc-g-padding-x-588 {
  padding: 0 588px;
}

/*Font size*/
.mc-g-font-size-588 {
  font-size: 588px;
}

/*Height*/
.mc-g-height-589 {
  height: 589px;
}

/*Padding x-os*/
.mc-g-padding-x-589 {
  padding: 0 589px;
}

/*Font size*/
.mc-g-font-size-589 {
  font-size: 589px;
}

/*Height*/
.mc-g-height-590 {
  height: 590px;
}

/*Padding x-os*/
.mc-g-padding-x-590 {
  padding: 0 590px;
}

/*Font size*/
.mc-g-font-size-590 {
  font-size: 590px;
}

/*Height*/
.mc-g-height-591 {
  height: 591px;
}

/*Padding x-os*/
.mc-g-padding-x-591 {
  padding: 0 591px;
}

/*Font size*/
.mc-g-font-size-591 {
  font-size: 591px;
}

/*Height*/
.mc-g-height-592 {
  height: 592px;
}

/*Padding x-os*/
.mc-g-padding-x-592 {
  padding: 0 592px;
}

/*Font size*/
.mc-g-font-size-592 {
  font-size: 592px;
}

/*Height*/
.mc-g-height-593 {
  height: 593px;
}

/*Padding x-os*/
.mc-g-padding-x-593 {
  padding: 0 593px;
}

/*Font size*/
.mc-g-font-size-593 {
  font-size: 593px;
}

/*Height*/
.mc-g-height-594 {
  height: 594px;
}

/*Padding x-os*/
.mc-g-padding-x-594 {
  padding: 0 594px;
}

/*Font size*/
.mc-g-font-size-594 {
  font-size: 594px;
}

/*Height*/
.mc-g-height-595 {
  height: 595px;
}

/*Padding x-os*/
.mc-g-padding-x-595 {
  padding: 0 595px;
}

/*Font size*/
.mc-g-font-size-595 {
  font-size: 595px;
}

/*Height*/
.mc-g-height-596 {
  height: 596px;
}

/*Padding x-os*/
.mc-g-padding-x-596 {
  padding: 0 596px;
}

/*Font size*/
.mc-g-font-size-596 {
  font-size: 596px;
}

/*Height*/
.mc-g-height-597 {
  height: 597px;
}

/*Padding x-os*/
.mc-g-padding-x-597 {
  padding: 0 597px;
}

/*Font size*/
.mc-g-font-size-597 {
  font-size: 597px;
}

/*Height*/
.mc-g-height-598 {
  height: 598px;
}

/*Padding x-os*/
.mc-g-padding-x-598 {
  padding: 0 598px;
}

/*Font size*/
.mc-g-font-size-598 {
  font-size: 598px;
}

/*Height*/
.mc-g-height-599 {
  height: 599px;
}

/*Padding x-os*/
.mc-g-padding-x-599 {
  padding: 0 599px;
}

/*Font size*/
.mc-g-font-size-599 {
  font-size: 599px;
}

/*Height*/
.mc-g-height-600 {
  height: 600px;
}

/*Padding x-os*/
.mc-g-padding-x-600 {
  padding: 0 600px;
}

/*Font size*/
.mc-g-font-size-600 {
  font-size: 600px;
}

/*Height*/
.mc-g-height-601 {
  height: 601px;
}

/*Padding x-os*/
.mc-g-padding-x-601 {
  padding: 0 601px;
}

/*Font size*/
.mc-g-font-size-601 {
  font-size: 601px;
}

/*Height*/
.mc-g-height-602 {
  height: 602px;
}

/*Padding x-os*/
.mc-g-padding-x-602 {
  padding: 0 602px;
}

/*Font size*/
.mc-g-font-size-602 {
  font-size: 602px;
}

/*Height*/
.mc-g-height-603 {
  height: 603px;
}

/*Padding x-os*/
.mc-g-padding-x-603 {
  padding: 0 603px;
}

/*Font size*/
.mc-g-font-size-603 {
  font-size: 603px;
}

/*Height*/
.mc-g-height-604 {
  height: 604px;
}

/*Padding x-os*/
.mc-g-padding-x-604 {
  padding: 0 604px;
}

/*Font size*/
.mc-g-font-size-604 {
  font-size: 604px;
}

/*Height*/
.mc-g-height-605 {
  height: 605px;
}

/*Padding x-os*/
.mc-g-padding-x-605 {
  padding: 0 605px;
}

/*Font size*/
.mc-g-font-size-605 {
  font-size: 605px;
}

/*Height*/
.mc-g-height-606 {
  height: 606px;
}

/*Padding x-os*/
.mc-g-padding-x-606 {
  padding: 0 606px;
}

/*Font size*/
.mc-g-font-size-606 {
  font-size: 606px;
}

/*Height*/
.mc-g-height-607 {
  height: 607px;
}

/*Padding x-os*/
.mc-g-padding-x-607 {
  padding: 0 607px;
}

/*Font size*/
.mc-g-font-size-607 {
  font-size: 607px;
}

/*Height*/
.mc-g-height-608 {
  height: 608px;
}

/*Padding x-os*/
.mc-g-padding-x-608 {
  padding: 0 608px;
}

/*Font size*/
.mc-g-font-size-608 {
  font-size: 608px;
}

/*Height*/
.mc-g-height-609 {
  height: 609px;
}

/*Padding x-os*/
.mc-g-padding-x-609 {
  padding: 0 609px;
}

/*Font size*/
.mc-g-font-size-609 {
  font-size: 609px;
}

/*Height*/
.mc-g-height-610 {
  height: 610px;
}

/*Padding x-os*/
.mc-g-padding-x-610 {
  padding: 0 610px;
}

/*Font size*/
.mc-g-font-size-610 {
  font-size: 610px;
}

/*Height*/
.mc-g-height-611 {
  height: 611px;
}

/*Padding x-os*/
.mc-g-padding-x-611 {
  padding: 0 611px;
}

/*Font size*/
.mc-g-font-size-611 {
  font-size: 611px;
}

/*Height*/
.mc-g-height-612 {
  height: 612px;
}

/*Padding x-os*/
.mc-g-padding-x-612 {
  padding: 0 612px;
}

/*Font size*/
.mc-g-font-size-612 {
  font-size: 612px;
}

/*Height*/
.mc-g-height-613 {
  height: 613px;
}

/*Padding x-os*/
.mc-g-padding-x-613 {
  padding: 0 613px;
}

/*Font size*/
.mc-g-font-size-613 {
  font-size: 613px;
}

/*Height*/
.mc-g-height-614 {
  height: 614px;
}

/*Padding x-os*/
.mc-g-padding-x-614 {
  padding: 0 614px;
}

/*Font size*/
.mc-g-font-size-614 {
  font-size: 614px;
}

/*Height*/
.mc-g-height-615 {
  height: 615px;
}

/*Padding x-os*/
.mc-g-padding-x-615 {
  padding: 0 615px;
}

/*Font size*/
.mc-g-font-size-615 {
  font-size: 615px;
}

/*Height*/
.mc-g-height-616 {
  height: 616px;
}

/*Padding x-os*/
.mc-g-padding-x-616 {
  padding: 0 616px;
}

/*Font size*/
.mc-g-font-size-616 {
  font-size: 616px;
}

/*Height*/
.mc-g-height-617 {
  height: 617px;
}

/*Padding x-os*/
.mc-g-padding-x-617 {
  padding: 0 617px;
}

/*Font size*/
.mc-g-font-size-617 {
  font-size: 617px;
}

/*Height*/
.mc-g-height-618 {
  height: 618px;
}

/*Padding x-os*/
.mc-g-padding-x-618 {
  padding: 0 618px;
}

/*Font size*/
.mc-g-font-size-618 {
  font-size: 618px;
}

/*Height*/
.mc-g-height-619 {
  height: 619px;
}

/*Padding x-os*/
.mc-g-padding-x-619 {
  padding: 0 619px;
}

/*Font size*/
.mc-g-font-size-619 {
  font-size: 619px;
}

/*Height*/
.mc-g-height-620 {
  height: 620px;
}

/*Padding x-os*/
.mc-g-padding-x-620 {
  padding: 0 620px;
}

/*Font size*/
.mc-g-font-size-620 {
  font-size: 620px;
}

/*Height*/
.mc-g-height-621 {
  height: 621px;
}

/*Padding x-os*/
.mc-g-padding-x-621 {
  padding: 0 621px;
}

/*Font size*/
.mc-g-font-size-621 {
  font-size: 621px;
}

/*Height*/
.mc-g-height-622 {
  height: 622px;
}

/*Padding x-os*/
.mc-g-padding-x-622 {
  padding: 0 622px;
}

/*Font size*/
.mc-g-font-size-622 {
  font-size: 622px;
}

/*Height*/
.mc-g-height-623 {
  height: 623px;
}

/*Padding x-os*/
.mc-g-padding-x-623 {
  padding: 0 623px;
}

/*Font size*/
.mc-g-font-size-623 {
  font-size: 623px;
}

/*Height*/
.mc-g-height-624 {
  height: 624px;
}

/*Padding x-os*/
.mc-g-padding-x-624 {
  padding: 0 624px;
}

/*Font size*/
.mc-g-font-size-624 {
  font-size: 624px;
}

/*Height*/
.mc-g-height-625 {
  height: 625px;
}

/*Padding x-os*/
.mc-g-padding-x-625 {
  padding: 0 625px;
}

/*Font size*/
.mc-g-font-size-625 {
  font-size: 625px;
}

/*Height*/
.mc-g-height-626 {
  height: 626px;
}

/*Padding x-os*/
.mc-g-padding-x-626 {
  padding: 0 626px;
}

/*Font size*/
.mc-g-font-size-626 {
  font-size: 626px;
}

/*Height*/
.mc-g-height-627 {
  height: 627px;
}

/*Padding x-os*/
.mc-g-padding-x-627 {
  padding: 0 627px;
}

/*Font size*/
.mc-g-font-size-627 {
  font-size: 627px;
}

/*Height*/
.mc-g-height-628 {
  height: 628px;
}

/*Padding x-os*/
.mc-g-padding-x-628 {
  padding: 0 628px;
}

/*Font size*/
.mc-g-font-size-628 {
  font-size: 628px;
}

/*Height*/
.mc-g-height-629 {
  height: 629px;
}

/*Padding x-os*/
.mc-g-padding-x-629 {
  padding: 0 629px;
}

/*Font size*/
.mc-g-font-size-629 {
  font-size: 629px;
}

/*Height*/
.mc-g-height-630 {
  height: 630px;
}

/*Padding x-os*/
.mc-g-padding-x-630 {
  padding: 0 630px;
}

/*Font size*/
.mc-g-font-size-630 {
  font-size: 630px;
}

/*Height*/
.mc-g-height-631 {
  height: 631px;
}

/*Padding x-os*/
.mc-g-padding-x-631 {
  padding: 0 631px;
}

/*Font size*/
.mc-g-font-size-631 {
  font-size: 631px;
}

/*Height*/
.mc-g-height-632 {
  height: 632px;
}

/*Padding x-os*/
.mc-g-padding-x-632 {
  padding: 0 632px;
}

/*Font size*/
.mc-g-font-size-632 {
  font-size: 632px;
}

/*Height*/
.mc-g-height-633 {
  height: 633px;
}

/*Padding x-os*/
.mc-g-padding-x-633 {
  padding: 0 633px;
}

/*Font size*/
.mc-g-font-size-633 {
  font-size: 633px;
}

/*Height*/
.mc-g-height-634 {
  height: 634px;
}

/*Padding x-os*/
.mc-g-padding-x-634 {
  padding: 0 634px;
}

/*Font size*/
.mc-g-font-size-634 {
  font-size: 634px;
}

/*Height*/
.mc-g-height-635 {
  height: 635px;
}

/*Padding x-os*/
.mc-g-padding-x-635 {
  padding: 0 635px;
}

/*Font size*/
.mc-g-font-size-635 {
  font-size: 635px;
}

/*Height*/
.mc-g-height-636 {
  height: 636px;
}

/*Padding x-os*/
.mc-g-padding-x-636 {
  padding: 0 636px;
}

/*Font size*/
.mc-g-font-size-636 {
  font-size: 636px;
}

/*Height*/
.mc-g-height-637 {
  height: 637px;
}

/*Padding x-os*/
.mc-g-padding-x-637 {
  padding: 0 637px;
}

/*Font size*/
.mc-g-font-size-637 {
  font-size: 637px;
}

/*Height*/
.mc-g-height-638 {
  height: 638px;
}

/*Padding x-os*/
.mc-g-padding-x-638 {
  padding: 0 638px;
}

/*Font size*/
.mc-g-font-size-638 {
  font-size: 638px;
}

/*Height*/
.mc-g-height-639 {
  height: 639px;
}

/*Padding x-os*/
.mc-g-padding-x-639 {
  padding: 0 639px;
}

/*Font size*/
.mc-g-font-size-639 {
  font-size: 639px;
}

/*Height*/
.mc-g-height-640 {
  height: 640px;
}

/*Padding x-os*/
.mc-g-padding-x-640 {
  padding: 0 640px;
}

/*Font size*/
.mc-g-font-size-640 {
  font-size: 640px;
}

/*Height*/
.mc-g-height-641 {
  height: 641px;
}

/*Padding x-os*/
.mc-g-padding-x-641 {
  padding: 0 641px;
}

/*Font size*/
.mc-g-font-size-641 {
  font-size: 641px;
}

/*Height*/
.mc-g-height-642 {
  height: 642px;
}

/*Padding x-os*/
.mc-g-padding-x-642 {
  padding: 0 642px;
}

/*Font size*/
.mc-g-font-size-642 {
  font-size: 642px;
}

/*Height*/
.mc-g-height-643 {
  height: 643px;
}

/*Padding x-os*/
.mc-g-padding-x-643 {
  padding: 0 643px;
}

/*Font size*/
.mc-g-font-size-643 {
  font-size: 643px;
}

/*Height*/
.mc-g-height-644 {
  height: 644px;
}

/*Padding x-os*/
.mc-g-padding-x-644 {
  padding: 0 644px;
}

/*Font size*/
.mc-g-font-size-644 {
  font-size: 644px;
}

/*Height*/
.mc-g-height-645 {
  height: 645px;
}

/*Padding x-os*/
.mc-g-padding-x-645 {
  padding: 0 645px;
}

/*Font size*/
.mc-g-font-size-645 {
  font-size: 645px;
}

/*Height*/
.mc-g-height-646 {
  height: 646px;
}

/*Padding x-os*/
.mc-g-padding-x-646 {
  padding: 0 646px;
}

/*Font size*/
.mc-g-font-size-646 {
  font-size: 646px;
}

/*Height*/
.mc-g-height-647 {
  height: 647px;
}

/*Padding x-os*/
.mc-g-padding-x-647 {
  padding: 0 647px;
}

/*Font size*/
.mc-g-font-size-647 {
  font-size: 647px;
}

/*Height*/
.mc-g-height-648 {
  height: 648px;
}

/*Padding x-os*/
.mc-g-padding-x-648 {
  padding: 0 648px;
}

/*Font size*/
.mc-g-font-size-648 {
  font-size: 648px;
}

/*Height*/
.mc-g-height-649 {
  height: 649px;
}

/*Padding x-os*/
.mc-g-padding-x-649 {
  padding: 0 649px;
}

/*Font size*/
.mc-g-font-size-649 {
  font-size: 649px;
}

/*Height*/
.mc-g-height-650 {
  height: 650px;
}

/*Padding x-os*/
.mc-g-padding-x-650 {
  padding: 0 650px;
}

/*Font size*/
.mc-g-font-size-650 {
  font-size: 650px;
}

/*Height*/
.mc-g-height-651 {
  height: 651px;
}

/*Padding x-os*/
.mc-g-padding-x-651 {
  padding: 0 651px;
}

/*Font size*/
.mc-g-font-size-651 {
  font-size: 651px;
}

/*Height*/
.mc-g-height-652 {
  height: 652px;
}

/*Padding x-os*/
.mc-g-padding-x-652 {
  padding: 0 652px;
}

/*Font size*/
.mc-g-font-size-652 {
  font-size: 652px;
}

/*Height*/
.mc-g-height-653 {
  height: 653px;
}

/*Padding x-os*/
.mc-g-padding-x-653 {
  padding: 0 653px;
}

/*Font size*/
.mc-g-font-size-653 {
  font-size: 653px;
}

/*Height*/
.mc-g-height-654 {
  height: 654px;
}

/*Padding x-os*/
.mc-g-padding-x-654 {
  padding: 0 654px;
}

/*Font size*/
.mc-g-font-size-654 {
  font-size: 654px;
}

/*Height*/
.mc-g-height-655 {
  height: 655px;
}

/*Padding x-os*/
.mc-g-padding-x-655 {
  padding: 0 655px;
}

/*Font size*/
.mc-g-font-size-655 {
  font-size: 655px;
}

/*Height*/
.mc-g-height-656 {
  height: 656px;
}

/*Padding x-os*/
.mc-g-padding-x-656 {
  padding: 0 656px;
}

/*Font size*/
.mc-g-font-size-656 {
  font-size: 656px;
}

/*Height*/
.mc-g-height-657 {
  height: 657px;
}

/*Padding x-os*/
.mc-g-padding-x-657 {
  padding: 0 657px;
}

/*Font size*/
.mc-g-font-size-657 {
  font-size: 657px;
}

/*Height*/
.mc-g-height-658 {
  height: 658px;
}

/*Padding x-os*/
.mc-g-padding-x-658 {
  padding: 0 658px;
}

/*Font size*/
.mc-g-font-size-658 {
  font-size: 658px;
}

/*Height*/
.mc-g-height-659 {
  height: 659px;
}

/*Padding x-os*/
.mc-g-padding-x-659 {
  padding: 0 659px;
}

/*Font size*/
.mc-g-font-size-659 {
  font-size: 659px;
}

/*Height*/
.mc-g-height-660 {
  height: 660px;
}

/*Padding x-os*/
.mc-g-padding-x-660 {
  padding: 0 660px;
}

/*Font size*/
.mc-g-font-size-660 {
  font-size: 660px;
}

/*Height*/
.mc-g-height-661 {
  height: 661px;
}

/*Padding x-os*/
.mc-g-padding-x-661 {
  padding: 0 661px;
}

/*Font size*/
.mc-g-font-size-661 {
  font-size: 661px;
}

/*Height*/
.mc-g-height-662 {
  height: 662px;
}

/*Padding x-os*/
.mc-g-padding-x-662 {
  padding: 0 662px;
}

/*Font size*/
.mc-g-font-size-662 {
  font-size: 662px;
}

/*Height*/
.mc-g-height-663 {
  height: 663px;
}

/*Padding x-os*/
.mc-g-padding-x-663 {
  padding: 0 663px;
}

/*Font size*/
.mc-g-font-size-663 {
  font-size: 663px;
}

/*Height*/
.mc-g-height-664 {
  height: 664px;
}

/*Padding x-os*/
.mc-g-padding-x-664 {
  padding: 0 664px;
}

/*Font size*/
.mc-g-font-size-664 {
  font-size: 664px;
}

/*Height*/
.mc-g-height-665 {
  height: 665px;
}

/*Padding x-os*/
.mc-g-padding-x-665 {
  padding: 0 665px;
}

/*Font size*/
.mc-g-font-size-665 {
  font-size: 665px;
}

/*Height*/
.mc-g-height-666 {
  height: 666px;
}

/*Padding x-os*/
.mc-g-padding-x-666 {
  padding: 0 666px;
}

/*Font size*/
.mc-g-font-size-666 {
  font-size: 666px;
}

/*Height*/
.mc-g-height-667 {
  height: 667px;
}

/*Padding x-os*/
.mc-g-padding-x-667 {
  padding: 0 667px;
}

/*Font size*/
.mc-g-font-size-667 {
  font-size: 667px;
}

/*Height*/
.mc-g-height-668 {
  height: 668px;
}

/*Padding x-os*/
.mc-g-padding-x-668 {
  padding: 0 668px;
}

/*Font size*/
.mc-g-font-size-668 {
  font-size: 668px;
}

/*Height*/
.mc-g-height-669 {
  height: 669px;
}

/*Padding x-os*/
.mc-g-padding-x-669 {
  padding: 0 669px;
}

/*Font size*/
.mc-g-font-size-669 {
  font-size: 669px;
}

/*Height*/
.mc-g-height-670 {
  height: 670px;
}

/*Padding x-os*/
.mc-g-padding-x-670 {
  padding: 0 670px;
}

/*Font size*/
.mc-g-font-size-670 {
  font-size: 670px;
}

/*Height*/
.mc-g-height-671 {
  height: 671px;
}

/*Padding x-os*/
.mc-g-padding-x-671 {
  padding: 0 671px;
}

/*Font size*/
.mc-g-font-size-671 {
  font-size: 671px;
}

/*Height*/
.mc-g-height-672 {
  height: 672px;
}

/*Padding x-os*/
.mc-g-padding-x-672 {
  padding: 0 672px;
}

/*Font size*/
.mc-g-font-size-672 {
  font-size: 672px;
}

/*Height*/
.mc-g-height-673 {
  height: 673px;
}

/*Padding x-os*/
.mc-g-padding-x-673 {
  padding: 0 673px;
}

/*Font size*/
.mc-g-font-size-673 {
  font-size: 673px;
}

/*Height*/
.mc-g-height-674 {
  height: 674px;
}

/*Padding x-os*/
.mc-g-padding-x-674 {
  padding: 0 674px;
}

/*Font size*/
.mc-g-font-size-674 {
  font-size: 674px;
}

/*Height*/
.mc-g-height-675 {
  height: 675px;
}

/*Padding x-os*/
.mc-g-padding-x-675 {
  padding: 0 675px;
}

/*Font size*/
.mc-g-font-size-675 {
  font-size: 675px;
}

/*Height*/
.mc-g-height-676 {
  height: 676px;
}

/*Padding x-os*/
.mc-g-padding-x-676 {
  padding: 0 676px;
}

/*Font size*/
.mc-g-font-size-676 {
  font-size: 676px;
}

/*Height*/
.mc-g-height-677 {
  height: 677px;
}

/*Padding x-os*/
.mc-g-padding-x-677 {
  padding: 0 677px;
}

/*Font size*/
.mc-g-font-size-677 {
  font-size: 677px;
}

/*Height*/
.mc-g-height-678 {
  height: 678px;
}

/*Padding x-os*/
.mc-g-padding-x-678 {
  padding: 0 678px;
}

/*Font size*/
.mc-g-font-size-678 {
  font-size: 678px;
}

/*Height*/
.mc-g-height-679 {
  height: 679px;
}

/*Padding x-os*/
.mc-g-padding-x-679 {
  padding: 0 679px;
}

/*Font size*/
.mc-g-font-size-679 {
  font-size: 679px;
}

/*Height*/
.mc-g-height-680 {
  height: 680px;
}

/*Padding x-os*/
.mc-g-padding-x-680 {
  padding: 0 680px;
}

/*Font size*/
.mc-g-font-size-680 {
  font-size: 680px;
}

/*Height*/
.mc-g-height-681 {
  height: 681px;
}

/*Padding x-os*/
.mc-g-padding-x-681 {
  padding: 0 681px;
}

/*Font size*/
.mc-g-font-size-681 {
  font-size: 681px;
}

/*Height*/
.mc-g-height-682 {
  height: 682px;
}

/*Padding x-os*/
.mc-g-padding-x-682 {
  padding: 0 682px;
}

/*Font size*/
.mc-g-font-size-682 {
  font-size: 682px;
}

/*Height*/
.mc-g-height-683 {
  height: 683px;
}

/*Padding x-os*/
.mc-g-padding-x-683 {
  padding: 0 683px;
}

/*Font size*/
.mc-g-font-size-683 {
  font-size: 683px;
}

/*Height*/
.mc-g-height-684 {
  height: 684px;
}

/*Padding x-os*/
.mc-g-padding-x-684 {
  padding: 0 684px;
}

/*Font size*/
.mc-g-font-size-684 {
  font-size: 684px;
}

/*Height*/
.mc-g-height-685 {
  height: 685px;
}

/*Padding x-os*/
.mc-g-padding-x-685 {
  padding: 0 685px;
}

/*Font size*/
.mc-g-font-size-685 {
  font-size: 685px;
}

/*Height*/
.mc-g-height-686 {
  height: 686px;
}

/*Padding x-os*/
.mc-g-padding-x-686 {
  padding: 0 686px;
}

/*Font size*/
.mc-g-font-size-686 {
  font-size: 686px;
}

/*Height*/
.mc-g-height-687 {
  height: 687px;
}

/*Padding x-os*/
.mc-g-padding-x-687 {
  padding: 0 687px;
}

/*Font size*/
.mc-g-font-size-687 {
  font-size: 687px;
}

/*Height*/
.mc-g-height-688 {
  height: 688px;
}

/*Padding x-os*/
.mc-g-padding-x-688 {
  padding: 0 688px;
}

/*Font size*/
.mc-g-font-size-688 {
  font-size: 688px;
}

/*Height*/
.mc-g-height-689 {
  height: 689px;
}

/*Padding x-os*/
.mc-g-padding-x-689 {
  padding: 0 689px;
}

/*Font size*/
.mc-g-font-size-689 {
  font-size: 689px;
}

/*Height*/
.mc-g-height-690 {
  height: 690px;
}

/*Padding x-os*/
.mc-g-padding-x-690 {
  padding: 0 690px;
}

/*Font size*/
.mc-g-font-size-690 {
  font-size: 690px;
}

/*Height*/
.mc-g-height-691 {
  height: 691px;
}

/*Padding x-os*/
.mc-g-padding-x-691 {
  padding: 0 691px;
}

/*Font size*/
.mc-g-font-size-691 {
  font-size: 691px;
}

/*Height*/
.mc-g-height-692 {
  height: 692px;
}

/*Padding x-os*/
.mc-g-padding-x-692 {
  padding: 0 692px;
}

/*Font size*/
.mc-g-font-size-692 {
  font-size: 692px;
}

/*Height*/
.mc-g-height-693 {
  height: 693px;
}

/*Padding x-os*/
.mc-g-padding-x-693 {
  padding: 0 693px;
}

/*Font size*/
.mc-g-font-size-693 {
  font-size: 693px;
}

/*Height*/
.mc-g-height-694 {
  height: 694px;
}

/*Padding x-os*/
.mc-g-padding-x-694 {
  padding: 0 694px;
}

/*Font size*/
.mc-g-font-size-694 {
  font-size: 694px;
}

/*Height*/
.mc-g-height-695 {
  height: 695px;
}

/*Padding x-os*/
.mc-g-padding-x-695 {
  padding: 0 695px;
}

/*Font size*/
.mc-g-font-size-695 {
  font-size: 695px;
}

/*Height*/
.mc-g-height-696 {
  height: 696px;
}

/*Padding x-os*/
.mc-g-padding-x-696 {
  padding: 0 696px;
}

/*Font size*/
.mc-g-font-size-696 {
  font-size: 696px;
}

/*Height*/
.mc-g-height-697 {
  height: 697px;
}

/*Padding x-os*/
.mc-g-padding-x-697 {
  padding: 0 697px;
}

/*Font size*/
.mc-g-font-size-697 {
  font-size: 697px;
}

/*Height*/
.mc-g-height-698 {
  height: 698px;
}

/*Padding x-os*/
.mc-g-padding-x-698 {
  padding: 0 698px;
}

/*Font size*/
.mc-g-font-size-698 {
  font-size: 698px;
}

/*Height*/
.mc-g-height-699 {
  height: 699px;
}

/*Padding x-os*/
.mc-g-padding-x-699 {
  padding: 0 699px;
}

/*Font size*/
.mc-g-font-size-699 {
  font-size: 699px;
}

/*Height*/
.mc-g-height-700 {
  height: 700px;
}

/*Padding x-os*/
.mc-g-padding-x-700 {
  padding: 0 700px;
}

/*Font size*/
.mc-g-font-size-700 {
  font-size: 700px;
}

/*Height*/
.mc-g-height-701 {
  height: 701px;
}

/*Padding x-os*/
.mc-g-padding-x-701 {
  padding: 0 701px;
}

/*Font size*/
.mc-g-font-size-701 {
  font-size: 701px;
}

/*Height*/
.mc-g-height-702 {
  height: 702px;
}

/*Padding x-os*/
.mc-g-padding-x-702 {
  padding: 0 702px;
}

/*Font size*/
.mc-g-font-size-702 {
  font-size: 702px;
}

/*Height*/
.mc-g-height-703 {
  height: 703px;
}

/*Padding x-os*/
.mc-g-padding-x-703 {
  padding: 0 703px;
}

/*Font size*/
.mc-g-font-size-703 {
  font-size: 703px;
}

/*Height*/
.mc-g-height-704 {
  height: 704px;
}

/*Padding x-os*/
.mc-g-padding-x-704 {
  padding: 0 704px;
}

/*Font size*/
.mc-g-font-size-704 {
  font-size: 704px;
}

/*Height*/
.mc-g-height-705 {
  height: 705px;
}

/*Padding x-os*/
.mc-g-padding-x-705 {
  padding: 0 705px;
}

/*Font size*/
.mc-g-font-size-705 {
  font-size: 705px;
}

/*Height*/
.mc-g-height-706 {
  height: 706px;
}

/*Padding x-os*/
.mc-g-padding-x-706 {
  padding: 0 706px;
}

/*Font size*/
.mc-g-font-size-706 {
  font-size: 706px;
}

/*Height*/
.mc-g-height-707 {
  height: 707px;
}

/*Padding x-os*/
.mc-g-padding-x-707 {
  padding: 0 707px;
}

/*Font size*/
.mc-g-font-size-707 {
  font-size: 707px;
}

/*Height*/
.mc-g-height-708 {
  height: 708px;
}

/*Padding x-os*/
.mc-g-padding-x-708 {
  padding: 0 708px;
}

/*Font size*/
.mc-g-font-size-708 {
  font-size: 708px;
}

/*Height*/
.mc-g-height-709 {
  height: 709px;
}

/*Padding x-os*/
.mc-g-padding-x-709 {
  padding: 0 709px;
}

/*Font size*/
.mc-g-font-size-709 {
  font-size: 709px;
}

/*Height*/
.mc-g-height-710 {
  height: 710px;
}

/*Padding x-os*/
.mc-g-padding-x-710 {
  padding: 0 710px;
}

/*Font size*/
.mc-g-font-size-710 {
  font-size: 710px;
}

/*Height*/
.mc-g-height-711 {
  height: 711px;
}

/*Padding x-os*/
.mc-g-padding-x-711 {
  padding: 0 711px;
}

/*Font size*/
.mc-g-font-size-711 {
  font-size: 711px;
}

/*Height*/
.mc-g-height-712 {
  height: 712px;
}

/*Padding x-os*/
.mc-g-padding-x-712 {
  padding: 0 712px;
}

/*Font size*/
.mc-g-font-size-712 {
  font-size: 712px;
}

/*Height*/
.mc-g-height-713 {
  height: 713px;
}

/*Padding x-os*/
.mc-g-padding-x-713 {
  padding: 0 713px;
}

/*Font size*/
.mc-g-font-size-713 {
  font-size: 713px;
}

/*Height*/
.mc-g-height-714 {
  height: 714px;
}

/*Padding x-os*/
.mc-g-padding-x-714 {
  padding: 0 714px;
}

/*Font size*/
.mc-g-font-size-714 {
  font-size: 714px;
}

/*Height*/
.mc-g-height-715 {
  height: 715px;
}

/*Padding x-os*/
.mc-g-padding-x-715 {
  padding: 0 715px;
}

/*Font size*/
.mc-g-font-size-715 {
  font-size: 715px;
}

/*Height*/
.mc-g-height-716 {
  height: 716px;
}

/*Padding x-os*/
.mc-g-padding-x-716 {
  padding: 0 716px;
}

/*Font size*/
.mc-g-font-size-716 {
  font-size: 716px;
}

/*Height*/
.mc-g-height-717 {
  height: 717px;
}

/*Padding x-os*/
.mc-g-padding-x-717 {
  padding: 0 717px;
}

/*Font size*/
.mc-g-font-size-717 {
  font-size: 717px;
}

/*Height*/
.mc-g-height-718 {
  height: 718px;
}

/*Padding x-os*/
.mc-g-padding-x-718 {
  padding: 0 718px;
}

/*Font size*/
.mc-g-font-size-718 {
  font-size: 718px;
}

/*Height*/
.mc-g-height-719 {
  height: 719px;
}

/*Padding x-os*/
.mc-g-padding-x-719 {
  padding: 0 719px;
}

/*Font size*/
.mc-g-font-size-719 {
  font-size: 719px;
}

/*Height*/
.mc-g-height-720 {
  height: 720px;
}

/*Padding x-os*/
.mc-g-padding-x-720 {
  padding: 0 720px;
}

/*Font size*/
.mc-g-font-size-720 {
  font-size: 720px;
}

/*Height*/
.mc-g-height-721 {
  height: 721px;
}

/*Padding x-os*/
.mc-g-padding-x-721 {
  padding: 0 721px;
}

/*Font size*/
.mc-g-font-size-721 {
  font-size: 721px;
}

/*Height*/
.mc-g-height-722 {
  height: 722px;
}

/*Padding x-os*/
.mc-g-padding-x-722 {
  padding: 0 722px;
}

/*Font size*/
.mc-g-font-size-722 {
  font-size: 722px;
}

/*Height*/
.mc-g-height-723 {
  height: 723px;
}

/*Padding x-os*/
.mc-g-padding-x-723 {
  padding: 0 723px;
}

/*Font size*/
.mc-g-font-size-723 {
  font-size: 723px;
}

/*Height*/
.mc-g-height-724 {
  height: 724px;
}

/*Padding x-os*/
.mc-g-padding-x-724 {
  padding: 0 724px;
}

/*Font size*/
.mc-g-font-size-724 {
  font-size: 724px;
}

/*Height*/
.mc-g-height-725 {
  height: 725px;
}

/*Padding x-os*/
.mc-g-padding-x-725 {
  padding: 0 725px;
}

/*Font size*/
.mc-g-font-size-725 {
  font-size: 725px;
}

/*Height*/
.mc-g-height-726 {
  height: 726px;
}

/*Padding x-os*/
.mc-g-padding-x-726 {
  padding: 0 726px;
}

/*Font size*/
.mc-g-font-size-726 {
  font-size: 726px;
}

/*Height*/
.mc-g-height-727 {
  height: 727px;
}

/*Padding x-os*/
.mc-g-padding-x-727 {
  padding: 0 727px;
}

/*Font size*/
.mc-g-font-size-727 {
  font-size: 727px;
}

/*Height*/
.mc-g-height-728 {
  height: 728px;
}

/*Padding x-os*/
.mc-g-padding-x-728 {
  padding: 0 728px;
}

/*Font size*/
.mc-g-font-size-728 {
  font-size: 728px;
}

/*Height*/
.mc-g-height-729 {
  height: 729px;
}

/*Padding x-os*/
.mc-g-padding-x-729 {
  padding: 0 729px;
}

/*Font size*/
.mc-g-font-size-729 {
  font-size: 729px;
}

/*Height*/
.mc-g-height-730 {
  height: 730px;
}

/*Padding x-os*/
.mc-g-padding-x-730 {
  padding: 0 730px;
}

/*Font size*/
.mc-g-font-size-730 {
  font-size: 730px;
}

/*Height*/
.mc-g-height-731 {
  height: 731px;
}

/*Padding x-os*/
.mc-g-padding-x-731 {
  padding: 0 731px;
}

/*Font size*/
.mc-g-font-size-731 {
  font-size: 731px;
}

/*Height*/
.mc-g-height-732 {
  height: 732px;
}

/*Padding x-os*/
.mc-g-padding-x-732 {
  padding: 0 732px;
}

/*Font size*/
.mc-g-font-size-732 {
  font-size: 732px;
}

/*Height*/
.mc-g-height-733 {
  height: 733px;
}

/*Padding x-os*/
.mc-g-padding-x-733 {
  padding: 0 733px;
}

/*Font size*/
.mc-g-font-size-733 {
  font-size: 733px;
}

/*Height*/
.mc-g-height-734 {
  height: 734px;
}

/*Padding x-os*/
.mc-g-padding-x-734 {
  padding: 0 734px;
}

/*Font size*/
.mc-g-font-size-734 {
  font-size: 734px;
}

/*Height*/
.mc-g-height-735 {
  height: 735px;
}

/*Padding x-os*/
.mc-g-padding-x-735 {
  padding: 0 735px;
}

/*Font size*/
.mc-g-font-size-735 {
  font-size: 735px;
}

/*Height*/
.mc-g-height-736 {
  height: 736px;
}

/*Padding x-os*/
.mc-g-padding-x-736 {
  padding: 0 736px;
}

/*Font size*/
.mc-g-font-size-736 {
  font-size: 736px;
}

/*Height*/
.mc-g-height-737 {
  height: 737px;
}

/*Padding x-os*/
.mc-g-padding-x-737 {
  padding: 0 737px;
}

/*Font size*/
.mc-g-font-size-737 {
  font-size: 737px;
}

/*Height*/
.mc-g-height-738 {
  height: 738px;
}

/*Padding x-os*/
.mc-g-padding-x-738 {
  padding: 0 738px;
}

/*Font size*/
.mc-g-font-size-738 {
  font-size: 738px;
}

/*Height*/
.mc-g-height-739 {
  height: 739px;
}

/*Padding x-os*/
.mc-g-padding-x-739 {
  padding: 0 739px;
}

/*Font size*/
.mc-g-font-size-739 {
  font-size: 739px;
}

/*Height*/
.mc-g-height-740 {
  height: 740px;
}

/*Padding x-os*/
.mc-g-padding-x-740 {
  padding: 0 740px;
}

/*Font size*/
.mc-g-font-size-740 {
  font-size: 740px;
}

/*Height*/
.mc-g-height-741 {
  height: 741px;
}

/*Padding x-os*/
.mc-g-padding-x-741 {
  padding: 0 741px;
}

/*Font size*/
.mc-g-font-size-741 {
  font-size: 741px;
}

/*Height*/
.mc-g-height-742 {
  height: 742px;
}

/*Padding x-os*/
.mc-g-padding-x-742 {
  padding: 0 742px;
}

/*Font size*/
.mc-g-font-size-742 {
  font-size: 742px;
}

/*Height*/
.mc-g-height-743 {
  height: 743px;
}

/*Padding x-os*/
.mc-g-padding-x-743 {
  padding: 0 743px;
}

/*Font size*/
.mc-g-font-size-743 {
  font-size: 743px;
}

/*Height*/
.mc-g-height-744 {
  height: 744px;
}

/*Padding x-os*/
.mc-g-padding-x-744 {
  padding: 0 744px;
}

/*Font size*/
.mc-g-font-size-744 {
  font-size: 744px;
}

/*Height*/
.mc-g-height-745 {
  height: 745px;
}

/*Padding x-os*/
.mc-g-padding-x-745 {
  padding: 0 745px;
}

/*Font size*/
.mc-g-font-size-745 {
  font-size: 745px;
}

/*Height*/
.mc-g-height-746 {
  height: 746px;
}

/*Padding x-os*/
.mc-g-padding-x-746 {
  padding: 0 746px;
}

/*Font size*/
.mc-g-font-size-746 {
  font-size: 746px;
}

/*Height*/
.mc-g-height-747 {
  height: 747px;
}

/*Padding x-os*/
.mc-g-padding-x-747 {
  padding: 0 747px;
}

/*Font size*/
.mc-g-font-size-747 {
  font-size: 747px;
}

/*Height*/
.mc-g-height-748 {
  height: 748px;
}

/*Padding x-os*/
.mc-g-padding-x-748 {
  padding: 0 748px;
}

/*Font size*/
.mc-g-font-size-748 {
  font-size: 748px;
}

/*Height*/
.mc-g-height-749 {
  height: 749px;
}

/*Padding x-os*/
.mc-g-padding-x-749 {
  padding: 0 749px;
}

/*Font size*/
.mc-g-font-size-749 {
  font-size: 749px;
}

/*Height*/
.mc-g-height-750 {
  height: 750px;
}

/*Padding x-os*/
.mc-g-padding-x-750 {
  padding: 0 750px;
}

/*Font size*/
.mc-g-font-size-750 {
  font-size: 750px;
}

/*Height*/
.mc-g-height-751 {
  height: 751px;
}

/*Padding x-os*/
.mc-g-padding-x-751 {
  padding: 0 751px;
}

/*Font size*/
.mc-g-font-size-751 {
  font-size: 751px;
}

/*Height*/
.mc-g-height-752 {
  height: 752px;
}

/*Padding x-os*/
.mc-g-padding-x-752 {
  padding: 0 752px;
}

/*Font size*/
.mc-g-font-size-752 {
  font-size: 752px;
}

/*Height*/
.mc-g-height-753 {
  height: 753px;
}

/*Padding x-os*/
.mc-g-padding-x-753 {
  padding: 0 753px;
}

/*Font size*/
.mc-g-font-size-753 {
  font-size: 753px;
}

/*Height*/
.mc-g-height-754 {
  height: 754px;
}

/*Padding x-os*/
.mc-g-padding-x-754 {
  padding: 0 754px;
}

/*Font size*/
.mc-g-font-size-754 {
  font-size: 754px;
}

/*Height*/
.mc-g-height-755 {
  height: 755px;
}

/*Padding x-os*/
.mc-g-padding-x-755 {
  padding: 0 755px;
}

/*Font size*/
.mc-g-font-size-755 {
  font-size: 755px;
}

/*Height*/
.mc-g-height-756 {
  height: 756px;
}

/*Padding x-os*/
.mc-g-padding-x-756 {
  padding: 0 756px;
}

/*Font size*/
.mc-g-font-size-756 {
  font-size: 756px;
}

/*Height*/
.mc-g-height-757 {
  height: 757px;
}

/*Padding x-os*/
.mc-g-padding-x-757 {
  padding: 0 757px;
}

/*Font size*/
.mc-g-font-size-757 {
  font-size: 757px;
}

/*Height*/
.mc-g-height-758 {
  height: 758px;
}

/*Padding x-os*/
.mc-g-padding-x-758 {
  padding: 0 758px;
}

/*Font size*/
.mc-g-font-size-758 {
  font-size: 758px;
}

/*Height*/
.mc-g-height-759 {
  height: 759px;
}

/*Padding x-os*/
.mc-g-padding-x-759 {
  padding: 0 759px;
}

/*Font size*/
.mc-g-font-size-759 {
  font-size: 759px;
}

/*Height*/
.mc-g-height-760 {
  height: 760px;
}

/*Padding x-os*/
.mc-g-padding-x-760 {
  padding: 0 760px;
}

/*Font size*/
.mc-g-font-size-760 {
  font-size: 760px;
}

/*Height*/
.mc-g-height-761 {
  height: 761px;
}

/*Padding x-os*/
.mc-g-padding-x-761 {
  padding: 0 761px;
}

/*Font size*/
.mc-g-font-size-761 {
  font-size: 761px;
}

/*Height*/
.mc-g-height-762 {
  height: 762px;
}

/*Padding x-os*/
.mc-g-padding-x-762 {
  padding: 0 762px;
}

/*Font size*/
.mc-g-font-size-762 {
  font-size: 762px;
}

/*Height*/
.mc-g-height-763 {
  height: 763px;
}

/*Padding x-os*/
.mc-g-padding-x-763 {
  padding: 0 763px;
}

/*Font size*/
.mc-g-font-size-763 {
  font-size: 763px;
}

/*Height*/
.mc-g-height-764 {
  height: 764px;
}

/*Padding x-os*/
.mc-g-padding-x-764 {
  padding: 0 764px;
}

/*Font size*/
.mc-g-font-size-764 {
  font-size: 764px;
}

/*Height*/
.mc-g-height-765 {
  height: 765px;
}

/*Padding x-os*/
.mc-g-padding-x-765 {
  padding: 0 765px;
}

/*Font size*/
.mc-g-font-size-765 {
  font-size: 765px;
}

/*Height*/
.mc-g-height-766 {
  height: 766px;
}

/*Padding x-os*/
.mc-g-padding-x-766 {
  padding: 0 766px;
}

/*Font size*/
.mc-g-font-size-766 {
  font-size: 766px;
}

/*Height*/
.mc-g-height-767 {
  height: 767px;
}

/*Padding x-os*/
.mc-g-padding-x-767 {
  padding: 0 767px;
}

/*Font size*/
.mc-g-font-size-767 {
  font-size: 767px;
}

/*Height*/
.mc-g-height-768 {
  height: 768px;
}

/*Padding x-os*/
.mc-g-padding-x-768 {
  padding: 0 768px;
}

/*Font size*/
.mc-g-font-size-768 {
  font-size: 768px;
}

/*Height*/
.mc-g-height-769 {
  height: 769px;
}

/*Padding x-os*/
.mc-g-padding-x-769 {
  padding: 0 769px;
}

/*Font size*/
.mc-g-font-size-769 {
  font-size: 769px;
}

/*Height*/
.mc-g-height-770 {
  height: 770px;
}

/*Padding x-os*/
.mc-g-padding-x-770 {
  padding: 0 770px;
}

/*Font size*/
.mc-g-font-size-770 {
  font-size: 770px;
}

/*Height*/
.mc-g-height-771 {
  height: 771px;
}

/*Padding x-os*/
.mc-g-padding-x-771 {
  padding: 0 771px;
}

/*Font size*/
.mc-g-font-size-771 {
  font-size: 771px;
}

/*Height*/
.mc-g-height-772 {
  height: 772px;
}

/*Padding x-os*/
.mc-g-padding-x-772 {
  padding: 0 772px;
}

/*Font size*/
.mc-g-font-size-772 {
  font-size: 772px;
}

/*Height*/
.mc-g-height-773 {
  height: 773px;
}

/*Padding x-os*/
.mc-g-padding-x-773 {
  padding: 0 773px;
}

/*Font size*/
.mc-g-font-size-773 {
  font-size: 773px;
}

/*Height*/
.mc-g-height-774 {
  height: 774px;
}

/*Padding x-os*/
.mc-g-padding-x-774 {
  padding: 0 774px;
}

/*Font size*/
.mc-g-font-size-774 {
  font-size: 774px;
}

/*Height*/
.mc-g-height-775 {
  height: 775px;
}

/*Padding x-os*/
.mc-g-padding-x-775 {
  padding: 0 775px;
}

/*Font size*/
.mc-g-font-size-775 {
  font-size: 775px;
}

/*Height*/
.mc-g-height-776 {
  height: 776px;
}

/*Padding x-os*/
.mc-g-padding-x-776 {
  padding: 0 776px;
}

/*Font size*/
.mc-g-font-size-776 {
  font-size: 776px;
}

/*Height*/
.mc-g-height-777 {
  height: 777px;
}

/*Padding x-os*/
.mc-g-padding-x-777 {
  padding: 0 777px;
}

/*Font size*/
.mc-g-font-size-777 {
  font-size: 777px;
}

/*Height*/
.mc-g-height-778 {
  height: 778px;
}

/*Padding x-os*/
.mc-g-padding-x-778 {
  padding: 0 778px;
}

/*Font size*/
.mc-g-font-size-778 {
  font-size: 778px;
}

/*Height*/
.mc-g-height-779 {
  height: 779px;
}

/*Padding x-os*/
.mc-g-padding-x-779 {
  padding: 0 779px;
}

/*Font size*/
.mc-g-font-size-779 {
  font-size: 779px;
}

/*Height*/
.mc-g-height-780 {
  height: 780px;
}

/*Padding x-os*/
.mc-g-padding-x-780 {
  padding: 0 780px;
}

/*Font size*/
.mc-g-font-size-780 {
  font-size: 780px;
}

/*Height*/
.mc-g-height-781 {
  height: 781px;
}

/*Padding x-os*/
.mc-g-padding-x-781 {
  padding: 0 781px;
}

/*Font size*/
.mc-g-font-size-781 {
  font-size: 781px;
}

/*Height*/
.mc-g-height-782 {
  height: 782px;
}

/*Padding x-os*/
.mc-g-padding-x-782 {
  padding: 0 782px;
}

/*Font size*/
.mc-g-font-size-782 {
  font-size: 782px;
}

/*Height*/
.mc-g-height-783 {
  height: 783px;
}

/*Padding x-os*/
.mc-g-padding-x-783 {
  padding: 0 783px;
}

/*Font size*/
.mc-g-font-size-783 {
  font-size: 783px;
}

/*Height*/
.mc-g-height-784 {
  height: 784px;
}

/*Padding x-os*/
.mc-g-padding-x-784 {
  padding: 0 784px;
}

/*Font size*/
.mc-g-font-size-784 {
  font-size: 784px;
}

/*Height*/
.mc-g-height-785 {
  height: 785px;
}

/*Padding x-os*/
.mc-g-padding-x-785 {
  padding: 0 785px;
}

/*Font size*/
.mc-g-font-size-785 {
  font-size: 785px;
}

/*Height*/
.mc-g-height-786 {
  height: 786px;
}

/*Padding x-os*/
.mc-g-padding-x-786 {
  padding: 0 786px;
}

/*Font size*/
.mc-g-font-size-786 {
  font-size: 786px;
}

/*Height*/
.mc-g-height-787 {
  height: 787px;
}

/*Padding x-os*/
.mc-g-padding-x-787 {
  padding: 0 787px;
}

/*Font size*/
.mc-g-font-size-787 {
  font-size: 787px;
}

/*Height*/
.mc-g-height-788 {
  height: 788px;
}

/*Padding x-os*/
.mc-g-padding-x-788 {
  padding: 0 788px;
}

/*Font size*/
.mc-g-font-size-788 {
  font-size: 788px;
}

/*Height*/
.mc-g-height-789 {
  height: 789px;
}

/*Padding x-os*/
.mc-g-padding-x-789 {
  padding: 0 789px;
}

/*Font size*/
.mc-g-font-size-789 {
  font-size: 789px;
}

/*Height*/
.mc-g-height-790 {
  height: 790px;
}

/*Padding x-os*/
.mc-g-padding-x-790 {
  padding: 0 790px;
}

/*Font size*/
.mc-g-font-size-790 {
  font-size: 790px;
}

/*Height*/
.mc-g-height-791 {
  height: 791px;
}

/*Padding x-os*/
.mc-g-padding-x-791 {
  padding: 0 791px;
}

/*Font size*/
.mc-g-font-size-791 {
  font-size: 791px;
}

/*Height*/
.mc-g-height-792 {
  height: 792px;
}

/*Padding x-os*/
.mc-g-padding-x-792 {
  padding: 0 792px;
}

/*Font size*/
.mc-g-font-size-792 {
  font-size: 792px;
}

/*Height*/
.mc-g-height-793 {
  height: 793px;
}

/*Padding x-os*/
.mc-g-padding-x-793 {
  padding: 0 793px;
}

/*Font size*/
.mc-g-font-size-793 {
  font-size: 793px;
}

/*Height*/
.mc-g-height-794 {
  height: 794px;
}

/*Padding x-os*/
.mc-g-padding-x-794 {
  padding: 0 794px;
}

/*Font size*/
.mc-g-font-size-794 {
  font-size: 794px;
}

/*Height*/
.mc-g-height-795 {
  height: 795px;
}

/*Padding x-os*/
.mc-g-padding-x-795 {
  padding: 0 795px;
}

/*Font size*/
.mc-g-font-size-795 {
  font-size: 795px;
}

/*Height*/
.mc-g-height-796 {
  height: 796px;
}

/*Padding x-os*/
.mc-g-padding-x-796 {
  padding: 0 796px;
}

/*Font size*/
.mc-g-font-size-796 {
  font-size: 796px;
}

/*Height*/
.mc-g-height-797 {
  height: 797px;
}

/*Padding x-os*/
.mc-g-padding-x-797 {
  padding: 0 797px;
}

/*Font size*/
.mc-g-font-size-797 {
  font-size: 797px;
}

/*Height*/
.mc-g-height-798 {
  height: 798px;
}

/*Padding x-os*/
.mc-g-padding-x-798 {
  padding: 0 798px;
}

/*Font size*/
.mc-g-font-size-798 {
  font-size: 798px;
}

/*Height*/
.mc-g-height-799 {
  height: 799px;
}

/*Padding x-os*/
.mc-g-padding-x-799 {
  padding: 0 799px;
}

/*Font size*/
.mc-g-font-size-799 {
  font-size: 799px;
}

/*Height*/
.mc-g-height-800 {
  height: 800px;
}

/*Padding x-os*/
.mc-g-padding-x-800 {
  padding: 0 800px;
}

/*Font size*/
.mc-g-font-size-800 {
  font-size: 800px;
}

/*Height*/
.mc-g-height-801 {
  height: 801px;
}

/*Padding x-os*/
.mc-g-padding-x-801 {
  padding: 0 801px;
}

/*Font size*/
.mc-g-font-size-801 {
  font-size: 801px;
}

/*Height*/
.mc-g-height-802 {
  height: 802px;
}

/*Padding x-os*/
.mc-g-padding-x-802 {
  padding: 0 802px;
}

/*Font size*/
.mc-g-font-size-802 {
  font-size: 802px;
}

/*Height*/
.mc-g-height-803 {
  height: 803px;
}

/*Padding x-os*/
.mc-g-padding-x-803 {
  padding: 0 803px;
}

/*Font size*/
.mc-g-font-size-803 {
  font-size: 803px;
}

/*Height*/
.mc-g-height-804 {
  height: 804px;
}

/*Padding x-os*/
.mc-g-padding-x-804 {
  padding: 0 804px;
}

/*Font size*/
.mc-g-font-size-804 {
  font-size: 804px;
}

/*Height*/
.mc-g-height-805 {
  height: 805px;
}

/*Padding x-os*/
.mc-g-padding-x-805 {
  padding: 0 805px;
}

/*Font size*/
.mc-g-font-size-805 {
  font-size: 805px;
}

/*Height*/
.mc-g-height-806 {
  height: 806px;
}

/*Padding x-os*/
.mc-g-padding-x-806 {
  padding: 0 806px;
}

/*Font size*/
.mc-g-font-size-806 {
  font-size: 806px;
}

/*Height*/
.mc-g-height-807 {
  height: 807px;
}

/*Padding x-os*/
.mc-g-padding-x-807 {
  padding: 0 807px;
}

/*Font size*/
.mc-g-font-size-807 {
  font-size: 807px;
}

/*Height*/
.mc-g-height-808 {
  height: 808px;
}

/*Padding x-os*/
.mc-g-padding-x-808 {
  padding: 0 808px;
}

/*Font size*/
.mc-g-font-size-808 {
  font-size: 808px;
}

/*Height*/
.mc-g-height-809 {
  height: 809px;
}

/*Padding x-os*/
.mc-g-padding-x-809 {
  padding: 0 809px;
}

/*Font size*/
.mc-g-font-size-809 {
  font-size: 809px;
}

/*Height*/
.mc-g-height-810 {
  height: 810px;
}

/*Padding x-os*/
.mc-g-padding-x-810 {
  padding: 0 810px;
}

/*Font size*/
.mc-g-font-size-810 {
  font-size: 810px;
}

/*Height*/
.mc-g-height-811 {
  height: 811px;
}

/*Padding x-os*/
.mc-g-padding-x-811 {
  padding: 0 811px;
}

/*Font size*/
.mc-g-font-size-811 {
  font-size: 811px;
}

/*Height*/
.mc-g-height-812 {
  height: 812px;
}

/*Padding x-os*/
.mc-g-padding-x-812 {
  padding: 0 812px;
}

/*Font size*/
.mc-g-font-size-812 {
  font-size: 812px;
}

/*Height*/
.mc-g-height-813 {
  height: 813px;
}

/*Padding x-os*/
.mc-g-padding-x-813 {
  padding: 0 813px;
}

/*Font size*/
.mc-g-font-size-813 {
  font-size: 813px;
}

/*Height*/
.mc-g-height-814 {
  height: 814px;
}

/*Padding x-os*/
.mc-g-padding-x-814 {
  padding: 0 814px;
}

/*Font size*/
.mc-g-font-size-814 {
  font-size: 814px;
}

/*Height*/
.mc-g-height-815 {
  height: 815px;
}

/*Padding x-os*/
.mc-g-padding-x-815 {
  padding: 0 815px;
}

/*Font size*/
.mc-g-font-size-815 {
  font-size: 815px;
}

/*Height*/
.mc-g-height-816 {
  height: 816px;
}

/*Padding x-os*/
.mc-g-padding-x-816 {
  padding: 0 816px;
}

/*Font size*/
.mc-g-font-size-816 {
  font-size: 816px;
}

/*Height*/
.mc-g-height-817 {
  height: 817px;
}

/*Padding x-os*/
.mc-g-padding-x-817 {
  padding: 0 817px;
}

/*Font size*/
.mc-g-font-size-817 {
  font-size: 817px;
}

/*Height*/
.mc-g-height-818 {
  height: 818px;
}

/*Padding x-os*/
.mc-g-padding-x-818 {
  padding: 0 818px;
}

/*Font size*/
.mc-g-font-size-818 {
  font-size: 818px;
}

/*Height*/
.mc-g-height-819 {
  height: 819px;
}

/*Padding x-os*/
.mc-g-padding-x-819 {
  padding: 0 819px;
}

/*Font size*/
.mc-g-font-size-819 {
  font-size: 819px;
}

/*Height*/
.mc-g-height-820 {
  height: 820px;
}

/*Padding x-os*/
.mc-g-padding-x-820 {
  padding: 0 820px;
}

/*Font size*/
.mc-g-font-size-820 {
  font-size: 820px;
}

/*Height*/
.mc-g-height-821 {
  height: 821px;
}

/*Padding x-os*/
.mc-g-padding-x-821 {
  padding: 0 821px;
}

/*Font size*/
.mc-g-font-size-821 {
  font-size: 821px;
}

/*Height*/
.mc-g-height-822 {
  height: 822px;
}

/*Padding x-os*/
.mc-g-padding-x-822 {
  padding: 0 822px;
}

/*Font size*/
.mc-g-font-size-822 {
  font-size: 822px;
}

/*Height*/
.mc-g-height-823 {
  height: 823px;
}

/*Padding x-os*/
.mc-g-padding-x-823 {
  padding: 0 823px;
}

/*Font size*/
.mc-g-font-size-823 {
  font-size: 823px;
}

/*Height*/
.mc-g-height-824 {
  height: 824px;
}

/*Padding x-os*/
.mc-g-padding-x-824 {
  padding: 0 824px;
}

/*Font size*/
.mc-g-font-size-824 {
  font-size: 824px;
}

/*Height*/
.mc-g-height-825 {
  height: 825px;
}

/*Padding x-os*/
.mc-g-padding-x-825 {
  padding: 0 825px;
}

/*Font size*/
.mc-g-font-size-825 {
  font-size: 825px;
}

/*Height*/
.mc-g-height-826 {
  height: 826px;
}

/*Padding x-os*/
.mc-g-padding-x-826 {
  padding: 0 826px;
}

/*Font size*/
.mc-g-font-size-826 {
  font-size: 826px;
}

/*Height*/
.mc-g-height-827 {
  height: 827px;
}

/*Padding x-os*/
.mc-g-padding-x-827 {
  padding: 0 827px;
}

/*Font size*/
.mc-g-font-size-827 {
  font-size: 827px;
}

/*Height*/
.mc-g-height-828 {
  height: 828px;
}

/*Padding x-os*/
.mc-g-padding-x-828 {
  padding: 0 828px;
}

/*Font size*/
.mc-g-font-size-828 {
  font-size: 828px;
}

/*Height*/
.mc-g-height-829 {
  height: 829px;
}

/*Padding x-os*/
.mc-g-padding-x-829 {
  padding: 0 829px;
}

/*Font size*/
.mc-g-font-size-829 {
  font-size: 829px;
}

/*Height*/
.mc-g-height-830 {
  height: 830px;
}

/*Padding x-os*/
.mc-g-padding-x-830 {
  padding: 0 830px;
}

/*Font size*/
.mc-g-font-size-830 {
  font-size: 830px;
}

/*Height*/
.mc-g-height-831 {
  height: 831px;
}

/*Padding x-os*/
.mc-g-padding-x-831 {
  padding: 0 831px;
}

/*Font size*/
.mc-g-font-size-831 {
  font-size: 831px;
}

/*Height*/
.mc-g-height-832 {
  height: 832px;
}

/*Padding x-os*/
.mc-g-padding-x-832 {
  padding: 0 832px;
}

/*Font size*/
.mc-g-font-size-832 {
  font-size: 832px;
}

/*Height*/
.mc-g-height-833 {
  height: 833px;
}

/*Padding x-os*/
.mc-g-padding-x-833 {
  padding: 0 833px;
}

/*Font size*/
.mc-g-font-size-833 {
  font-size: 833px;
}

/*Height*/
.mc-g-height-834 {
  height: 834px;
}

/*Padding x-os*/
.mc-g-padding-x-834 {
  padding: 0 834px;
}

/*Font size*/
.mc-g-font-size-834 {
  font-size: 834px;
}

/*Height*/
.mc-g-height-835 {
  height: 835px;
}

/*Padding x-os*/
.mc-g-padding-x-835 {
  padding: 0 835px;
}

/*Font size*/
.mc-g-font-size-835 {
  font-size: 835px;
}

/*Height*/
.mc-g-height-836 {
  height: 836px;
}

/*Padding x-os*/
.mc-g-padding-x-836 {
  padding: 0 836px;
}

/*Font size*/
.mc-g-font-size-836 {
  font-size: 836px;
}

/*Height*/
.mc-g-height-837 {
  height: 837px;
}

/*Padding x-os*/
.mc-g-padding-x-837 {
  padding: 0 837px;
}

/*Font size*/
.mc-g-font-size-837 {
  font-size: 837px;
}

/*Height*/
.mc-g-height-838 {
  height: 838px;
}

/*Padding x-os*/
.mc-g-padding-x-838 {
  padding: 0 838px;
}

/*Font size*/
.mc-g-font-size-838 {
  font-size: 838px;
}

/*Height*/
.mc-g-height-839 {
  height: 839px;
}

/*Padding x-os*/
.mc-g-padding-x-839 {
  padding: 0 839px;
}

/*Font size*/
.mc-g-font-size-839 {
  font-size: 839px;
}

/*Height*/
.mc-g-height-840 {
  height: 840px;
}

/*Padding x-os*/
.mc-g-padding-x-840 {
  padding: 0 840px;
}

/*Font size*/
.mc-g-font-size-840 {
  font-size: 840px;
}

/*Height*/
.mc-g-height-841 {
  height: 841px;
}

/*Padding x-os*/
.mc-g-padding-x-841 {
  padding: 0 841px;
}

/*Font size*/
.mc-g-font-size-841 {
  font-size: 841px;
}

/*Height*/
.mc-g-height-842 {
  height: 842px;
}

/*Padding x-os*/
.mc-g-padding-x-842 {
  padding: 0 842px;
}

/*Font size*/
.mc-g-font-size-842 {
  font-size: 842px;
}

/*Height*/
.mc-g-height-843 {
  height: 843px;
}

/*Padding x-os*/
.mc-g-padding-x-843 {
  padding: 0 843px;
}

/*Font size*/
.mc-g-font-size-843 {
  font-size: 843px;
}

/*Height*/
.mc-g-height-844 {
  height: 844px;
}

/*Padding x-os*/
.mc-g-padding-x-844 {
  padding: 0 844px;
}

/*Font size*/
.mc-g-font-size-844 {
  font-size: 844px;
}

/*Height*/
.mc-g-height-845 {
  height: 845px;
}

/*Padding x-os*/
.mc-g-padding-x-845 {
  padding: 0 845px;
}

/*Font size*/
.mc-g-font-size-845 {
  font-size: 845px;
}

/*Height*/
.mc-g-height-846 {
  height: 846px;
}

/*Padding x-os*/
.mc-g-padding-x-846 {
  padding: 0 846px;
}

/*Font size*/
.mc-g-font-size-846 {
  font-size: 846px;
}

/*Height*/
.mc-g-height-847 {
  height: 847px;
}

/*Padding x-os*/
.mc-g-padding-x-847 {
  padding: 0 847px;
}

/*Font size*/
.mc-g-font-size-847 {
  font-size: 847px;
}

/*Height*/
.mc-g-height-848 {
  height: 848px;
}

/*Padding x-os*/
.mc-g-padding-x-848 {
  padding: 0 848px;
}

/*Font size*/
.mc-g-font-size-848 {
  font-size: 848px;
}

/*Height*/
.mc-g-height-849 {
  height: 849px;
}

/*Padding x-os*/
.mc-g-padding-x-849 {
  padding: 0 849px;
}

/*Font size*/
.mc-g-font-size-849 {
  font-size: 849px;
}

/*Height*/
.mc-g-height-850 {
  height: 850px;
}

/*Padding x-os*/
.mc-g-padding-x-850 {
  padding: 0 850px;
}

/*Font size*/
.mc-g-font-size-850 {
  font-size: 850px;
}

/*Height*/
.mc-g-height-851 {
  height: 851px;
}

/*Padding x-os*/
.mc-g-padding-x-851 {
  padding: 0 851px;
}

/*Font size*/
.mc-g-font-size-851 {
  font-size: 851px;
}

/*Height*/
.mc-g-height-852 {
  height: 852px;
}

/*Padding x-os*/
.mc-g-padding-x-852 {
  padding: 0 852px;
}

/*Font size*/
.mc-g-font-size-852 {
  font-size: 852px;
}

/*Height*/
.mc-g-height-853 {
  height: 853px;
}

/*Padding x-os*/
.mc-g-padding-x-853 {
  padding: 0 853px;
}

/*Font size*/
.mc-g-font-size-853 {
  font-size: 853px;
}

/*Height*/
.mc-g-height-854 {
  height: 854px;
}

/*Padding x-os*/
.mc-g-padding-x-854 {
  padding: 0 854px;
}

/*Font size*/
.mc-g-font-size-854 {
  font-size: 854px;
}

/*Height*/
.mc-g-height-855 {
  height: 855px;
}

/*Padding x-os*/
.mc-g-padding-x-855 {
  padding: 0 855px;
}

/*Font size*/
.mc-g-font-size-855 {
  font-size: 855px;
}

/*Height*/
.mc-g-height-856 {
  height: 856px;
}

/*Padding x-os*/
.mc-g-padding-x-856 {
  padding: 0 856px;
}

/*Font size*/
.mc-g-font-size-856 {
  font-size: 856px;
}

/*Height*/
.mc-g-height-857 {
  height: 857px;
}

/*Padding x-os*/
.mc-g-padding-x-857 {
  padding: 0 857px;
}

/*Font size*/
.mc-g-font-size-857 {
  font-size: 857px;
}

/*Height*/
.mc-g-height-858 {
  height: 858px;
}

/*Padding x-os*/
.mc-g-padding-x-858 {
  padding: 0 858px;
}

/*Font size*/
.mc-g-font-size-858 {
  font-size: 858px;
}

/*Height*/
.mc-g-height-859 {
  height: 859px;
}

/*Padding x-os*/
.mc-g-padding-x-859 {
  padding: 0 859px;
}

/*Font size*/
.mc-g-font-size-859 {
  font-size: 859px;
}

/*Height*/
.mc-g-height-860 {
  height: 860px;
}

/*Padding x-os*/
.mc-g-padding-x-860 {
  padding: 0 860px;
}

/*Font size*/
.mc-g-font-size-860 {
  font-size: 860px;
}

/*Height*/
.mc-g-height-861 {
  height: 861px;
}

/*Padding x-os*/
.mc-g-padding-x-861 {
  padding: 0 861px;
}

/*Font size*/
.mc-g-font-size-861 {
  font-size: 861px;
}

/*Height*/
.mc-g-height-862 {
  height: 862px;
}

/*Padding x-os*/
.mc-g-padding-x-862 {
  padding: 0 862px;
}

/*Font size*/
.mc-g-font-size-862 {
  font-size: 862px;
}

/*Height*/
.mc-g-height-863 {
  height: 863px;
}

/*Padding x-os*/
.mc-g-padding-x-863 {
  padding: 0 863px;
}

/*Font size*/
.mc-g-font-size-863 {
  font-size: 863px;
}

/*Height*/
.mc-g-height-864 {
  height: 864px;
}

/*Padding x-os*/
.mc-g-padding-x-864 {
  padding: 0 864px;
}

/*Font size*/
.mc-g-font-size-864 {
  font-size: 864px;
}

/*Height*/
.mc-g-height-865 {
  height: 865px;
}

/*Padding x-os*/
.mc-g-padding-x-865 {
  padding: 0 865px;
}

/*Font size*/
.mc-g-font-size-865 {
  font-size: 865px;
}

/*Height*/
.mc-g-height-866 {
  height: 866px;
}

/*Padding x-os*/
.mc-g-padding-x-866 {
  padding: 0 866px;
}

/*Font size*/
.mc-g-font-size-866 {
  font-size: 866px;
}

/*Height*/
.mc-g-height-867 {
  height: 867px;
}

/*Padding x-os*/
.mc-g-padding-x-867 {
  padding: 0 867px;
}

/*Font size*/
.mc-g-font-size-867 {
  font-size: 867px;
}

/*Height*/
.mc-g-height-868 {
  height: 868px;
}

/*Padding x-os*/
.mc-g-padding-x-868 {
  padding: 0 868px;
}

/*Font size*/
.mc-g-font-size-868 {
  font-size: 868px;
}

/*Height*/
.mc-g-height-869 {
  height: 869px;
}

/*Padding x-os*/
.mc-g-padding-x-869 {
  padding: 0 869px;
}

/*Font size*/
.mc-g-font-size-869 {
  font-size: 869px;
}

/*Height*/
.mc-g-height-870 {
  height: 870px;
}

/*Padding x-os*/
.mc-g-padding-x-870 {
  padding: 0 870px;
}

/*Font size*/
.mc-g-font-size-870 {
  font-size: 870px;
}

/*Height*/
.mc-g-height-871 {
  height: 871px;
}

/*Padding x-os*/
.mc-g-padding-x-871 {
  padding: 0 871px;
}

/*Font size*/
.mc-g-font-size-871 {
  font-size: 871px;
}

/*Height*/
.mc-g-height-872 {
  height: 872px;
}

/*Padding x-os*/
.mc-g-padding-x-872 {
  padding: 0 872px;
}

/*Font size*/
.mc-g-font-size-872 {
  font-size: 872px;
}

/*Height*/
.mc-g-height-873 {
  height: 873px;
}

/*Padding x-os*/
.mc-g-padding-x-873 {
  padding: 0 873px;
}

/*Font size*/
.mc-g-font-size-873 {
  font-size: 873px;
}

/*Height*/
.mc-g-height-874 {
  height: 874px;
}

/*Padding x-os*/
.mc-g-padding-x-874 {
  padding: 0 874px;
}

/*Font size*/
.mc-g-font-size-874 {
  font-size: 874px;
}

/*Height*/
.mc-g-height-875 {
  height: 875px;
}

/*Padding x-os*/
.mc-g-padding-x-875 {
  padding: 0 875px;
}

/*Font size*/
.mc-g-font-size-875 {
  font-size: 875px;
}

/*Height*/
.mc-g-height-876 {
  height: 876px;
}

/*Padding x-os*/
.mc-g-padding-x-876 {
  padding: 0 876px;
}

/*Font size*/
.mc-g-font-size-876 {
  font-size: 876px;
}

/*Height*/
.mc-g-height-877 {
  height: 877px;
}

/*Padding x-os*/
.mc-g-padding-x-877 {
  padding: 0 877px;
}

/*Font size*/
.mc-g-font-size-877 {
  font-size: 877px;
}

/*Height*/
.mc-g-height-878 {
  height: 878px;
}

/*Padding x-os*/
.mc-g-padding-x-878 {
  padding: 0 878px;
}

/*Font size*/
.mc-g-font-size-878 {
  font-size: 878px;
}

/*Height*/
.mc-g-height-879 {
  height: 879px;
}

/*Padding x-os*/
.mc-g-padding-x-879 {
  padding: 0 879px;
}

/*Font size*/
.mc-g-font-size-879 {
  font-size: 879px;
}

/*Height*/
.mc-g-height-880 {
  height: 880px;
}

/*Padding x-os*/
.mc-g-padding-x-880 {
  padding: 0 880px;
}

/*Font size*/
.mc-g-font-size-880 {
  font-size: 880px;
}

/*Height*/
.mc-g-height-881 {
  height: 881px;
}

/*Padding x-os*/
.mc-g-padding-x-881 {
  padding: 0 881px;
}

/*Font size*/
.mc-g-font-size-881 {
  font-size: 881px;
}

/*Height*/
.mc-g-height-882 {
  height: 882px;
}

/*Padding x-os*/
.mc-g-padding-x-882 {
  padding: 0 882px;
}

/*Font size*/
.mc-g-font-size-882 {
  font-size: 882px;
}

/*Height*/
.mc-g-height-883 {
  height: 883px;
}

/*Padding x-os*/
.mc-g-padding-x-883 {
  padding: 0 883px;
}

/*Font size*/
.mc-g-font-size-883 {
  font-size: 883px;
}

/*Height*/
.mc-g-height-884 {
  height: 884px;
}

/*Padding x-os*/
.mc-g-padding-x-884 {
  padding: 0 884px;
}

/*Font size*/
.mc-g-font-size-884 {
  font-size: 884px;
}

/*Height*/
.mc-g-height-885 {
  height: 885px;
}

/*Padding x-os*/
.mc-g-padding-x-885 {
  padding: 0 885px;
}

/*Font size*/
.mc-g-font-size-885 {
  font-size: 885px;
}

/*Height*/
.mc-g-height-886 {
  height: 886px;
}

/*Padding x-os*/
.mc-g-padding-x-886 {
  padding: 0 886px;
}

/*Font size*/
.mc-g-font-size-886 {
  font-size: 886px;
}

/*Height*/
.mc-g-height-887 {
  height: 887px;
}

/*Padding x-os*/
.mc-g-padding-x-887 {
  padding: 0 887px;
}

/*Font size*/
.mc-g-font-size-887 {
  font-size: 887px;
}

/*Height*/
.mc-g-height-888 {
  height: 888px;
}

/*Padding x-os*/
.mc-g-padding-x-888 {
  padding: 0 888px;
}

/*Font size*/
.mc-g-font-size-888 {
  font-size: 888px;
}

/*Height*/
.mc-g-height-889 {
  height: 889px;
}

/*Padding x-os*/
.mc-g-padding-x-889 {
  padding: 0 889px;
}

/*Font size*/
.mc-g-font-size-889 {
  font-size: 889px;
}

/*Height*/
.mc-g-height-890 {
  height: 890px;
}

/*Padding x-os*/
.mc-g-padding-x-890 {
  padding: 0 890px;
}

/*Font size*/
.mc-g-font-size-890 {
  font-size: 890px;
}

/*Height*/
.mc-g-height-891 {
  height: 891px;
}

/*Padding x-os*/
.mc-g-padding-x-891 {
  padding: 0 891px;
}

/*Font size*/
.mc-g-font-size-891 {
  font-size: 891px;
}

/*Height*/
.mc-g-height-892 {
  height: 892px;
}

/*Padding x-os*/
.mc-g-padding-x-892 {
  padding: 0 892px;
}

/*Font size*/
.mc-g-font-size-892 {
  font-size: 892px;
}

/*Height*/
.mc-g-height-893 {
  height: 893px;
}

/*Padding x-os*/
.mc-g-padding-x-893 {
  padding: 0 893px;
}

/*Font size*/
.mc-g-font-size-893 {
  font-size: 893px;
}

/*Height*/
.mc-g-height-894 {
  height: 894px;
}

/*Padding x-os*/
.mc-g-padding-x-894 {
  padding: 0 894px;
}

/*Font size*/
.mc-g-font-size-894 {
  font-size: 894px;
}

/*Height*/
.mc-g-height-895 {
  height: 895px;
}

/*Padding x-os*/
.mc-g-padding-x-895 {
  padding: 0 895px;
}

/*Font size*/
.mc-g-font-size-895 {
  font-size: 895px;
}

/*Height*/
.mc-g-height-896 {
  height: 896px;
}

/*Padding x-os*/
.mc-g-padding-x-896 {
  padding: 0 896px;
}

/*Font size*/
.mc-g-font-size-896 {
  font-size: 896px;
}

/*Height*/
.mc-g-height-897 {
  height: 897px;
}

/*Padding x-os*/
.mc-g-padding-x-897 {
  padding: 0 897px;
}

/*Font size*/
.mc-g-font-size-897 {
  font-size: 897px;
}

/*Height*/
.mc-g-height-898 {
  height: 898px;
}

/*Padding x-os*/
.mc-g-padding-x-898 {
  padding: 0 898px;
}

/*Font size*/
.mc-g-font-size-898 {
  font-size: 898px;
}

/*Height*/
.mc-g-height-899 {
  height: 899px;
}

/*Padding x-os*/
.mc-g-padding-x-899 {
  padding: 0 899px;
}

/*Font size*/
.mc-g-font-size-899 {
  font-size: 899px;
}

/*Height*/
.mc-g-height-900 {
  height: 900px;
}

/*Padding x-os*/
.mc-g-padding-x-900 {
  padding: 0 900px;
}

/*Font size*/
.mc-g-font-size-900 {
  font-size: 900px;
}

/*Height*/
.mc-g-height-901 {
  height: 901px;
}

/*Padding x-os*/
.mc-g-padding-x-901 {
  padding: 0 901px;
}

/*Font size*/
.mc-g-font-size-901 {
  font-size: 901px;
}

/*Height*/
.mc-g-height-902 {
  height: 902px;
}

/*Padding x-os*/
.mc-g-padding-x-902 {
  padding: 0 902px;
}

/*Font size*/
.mc-g-font-size-902 {
  font-size: 902px;
}

/*Height*/
.mc-g-height-903 {
  height: 903px;
}

/*Padding x-os*/
.mc-g-padding-x-903 {
  padding: 0 903px;
}

/*Font size*/
.mc-g-font-size-903 {
  font-size: 903px;
}

/*Height*/
.mc-g-height-904 {
  height: 904px;
}

/*Padding x-os*/
.mc-g-padding-x-904 {
  padding: 0 904px;
}

/*Font size*/
.mc-g-font-size-904 {
  font-size: 904px;
}

/*Height*/
.mc-g-height-905 {
  height: 905px;
}

/*Padding x-os*/
.mc-g-padding-x-905 {
  padding: 0 905px;
}

/*Font size*/
.mc-g-font-size-905 {
  font-size: 905px;
}

/*Height*/
.mc-g-height-906 {
  height: 906px;
}

/*Padding x-os*/
.mc-g-padding-x-906 {
  padding: 0 906px;
}

/*Font size*/
.mc-g-font-size-906 {
  font-size: 906px;
}

/*Height*/
.mc-g-height-907 {
  height: 907px;
}

/*Padding x-os*/
.mc-g-padding-x-907 {
  padding: 0 907px;
}

/*Font size*/
.mc-g-font-size-907 {
  font-size: 907px;
}

/*Height*/
.mc-g-height-908 {
  height: 908px;
}

/*Padding x-os*/
.mc-g-padding-x-908 {
  padding: 0 908px;
}

/*Font size*/
.mc-g-font-size-908 {
  font-size: 908px;
}

/*Height*/
.mc-g-height-909 {
  height: 909px;
}

/*Padding x-os*/
.mc-g-padding-x-909 {
  padding: 0 909px;
}

/*Font size*/
.mc-g-font-size-909 {
  font-size: 909px;
}

/*Height*/
.mc-g-height-910 {
  height: 910px;
}

/*Padding x-os*/
.mc-g-padding-x-910 {
  padding: 0 910px;
}

/*Font size*/
.mc-g-font-size-910 {
  font-size: 910px;
}

/*Height*/
.mc-g-height-911 {
  height: 911px;
}

/*Padding x-os*/
.mc-g-padding-x-911 {
  padding: 0 911px;
}

/*Font size*/
.mc-g-font-size-911 {
  font-size: 911px;
}

/*Height*/
.mc-g-height-912 {
  height: 912px;
}

/*Padding x-os*/
.mc-g-padding-x-912 {
  padding: 0 912px;
}

/*Font size*/
.mc-g-font-size-912 {
  font-size: 912px;
}

/*Height*/
.mc-g-height-913 {
  height: 913px;
}

/*Padding x-os*/
.mc-g-padding-x-913 {
  padding: 0 913px;
}

/*Font size*/
.mc-g-font-size-913 {
  font-size: 913px;
}

/*Height*/
.mc-g-height-914 {
  height: 914px;
}

/*Padding x-os*/
.mc-g-padding-x-914 {
  padding: 0 914px;
}

/*Font size*/
.mc-g-font-size-914 {
  font-size: 914px;
}

/*Height*/
.mc-g-height-915 {
  height: 915px;
}

/*Padding x-os*/
.mc-g-padding-x-915 {
  padding: 0 915px;
}

/*Font size*/
.mc-g-font-size-915 {
  font-size: 915px;
}

/*Height*/
.mc-g-height-916 {
  height: 916px;
}

/*Padding x-os*/
.mc-g-padding-x-916 {
  padding: 0 916px;
}

/*Font size*/
.mc-g-font-size-916 {
  font-size: 916px;
}

/*Height*/
.mc-g-height-917 {
  height: 917px;
}

/*Padding x-os*/
.mc-g-padding-x-917 {
  padding: 0 917px;
}

/*Font size*/
.mc-g-font-size-917 {
  font-size: 917px;
}

/*Height*/
.mc-g-height-918 {
  height: 918px;
}

/*Padding x-os*/
.mc-g-padding-x-918 {
  padding: 0 918px;
}

/*Font size*/
.mc-g-font-size-918 {
  font-size: 918px;
}

/*Height*/
.mc-g-height-919 {
  height: 919px;
}

/*Padding x-os*/
.mc-g-padding-x-919 {
  padding: 0 919px;
}

/*Font size*/
.mc-g-font-size-919 {
  font-size: 919px;
}

/*Height*/
.mc-g-height-920 {
  height: 920px;
}

/*Padding x-os*/
.mc-g-padding-x-920 {
  padding: 0 920px;
}

/*Font size*/
.mc-g-font-size-920 {
  font-size: 920px;
}

/*Height*/
.mc-g-height-921 {
  height: 921px;
}

/*Padding x-os*/
.mc-g-padding-x-921 {
  padding: 0 921px;
}

/*Font size*/
.mc-g-font-size-921 {
  font-size: 921px;
}

/*Height*/
.mc-g-height-922 {
  height: 922px;
}

/*Padding x-os*/
.mc-g-padding-x-922 {
  padding: 0 922px;
}

/*Font size*/
.mc-g-font-size-922 {
  font-size: 922px;
}

/*Height*/
.mc-g-height-923 {
  height: 923px;
}

/*Padding x-os*/
.mc-g-padding-x-923 {
  padding: 0 923px;
}

/*Font size*/
.mc-g-font-size-923 {
  font-size: 923px;
}

/*Height*/
.mc-g-height-924 {
  height: 924px;
}

/*Padding x-os*/
.mc-g-padding-x-924 {
  padding: 0 924px;
}

/*Font size*/
.mc-g-font-size-924 {
  font-size: 924px;
}

/*Height*/
.mc-g-height-925 {
  height: 925px;
}

/*Padding x-os*/
.mc-g-padding-x-925 {
  padding: 0 925px;
}

/*Font size*/
.mc-g-font-size-925 {
  font-size: 925px;
}

/*Height*/
.mc-g-height-926 {
  height: 926px;
}

/*Padding x-os*/
.mc-g-padding-x-926 {
  padding: 0 926px;
}

/*Font size*/
.mc-g-font-size-926 {
  font-size: 926px;
}

/*Height*/
.mc-g-height-927 {
  height: 927px;
}

/*Padding x-os*/
.mc-g-padding-x-927 {
  padding: 0 927px;
}

/*Font size*/
.mc-g-font-size-927 {
  font-size: 927px;
}

/*Height*/
.mc-g-height-928 {
  height: 928px;
}

/*Padding x-os*/
.mc-g-padding-x-928 {
  padding: 0 928px;
}

/*Font size*/
.mc-g-font-size-928 {
  font-size: 928px;
}

/*Height*/
.mc-g-height-929 {
  height: 929px;
}

/*Padding x-os*/
.mc-g-padding-x-929 {
  padding: 0 929px;
}

/*Font size*/
.mc-g-font-size-929 {
  font-size: 929px;
}

/*Height*/
.mc-g-height-930 {
  height: 930px;
}

/*Padding x-os*/
.mc-g-padding-x-930 {
  padding: 0 930px;
}

/*Font size*/
.mc-g-font-size-930 {
  font-size: 930px;
}

/*Height*/
.mc-g-height-931 {
  height: 931px;
}

/*Padding x-os*/
.mc-g-padding-x-931 {
  padding: 0 931px;
}

/*Font size*/
.mc-g-font-size-931 {
  font-size: 931px;
}

/*Height*/
.mc-g-height-932 {
  height: 932px;
}

/*Padding x-os*/
.mc-g-padding-x-932 {
  padding: 0 932px;
}

/*Font size*/
.mc-g-font-size-932 {
  font-size: 932px;
}

/*Height*/
.mc-g-height-933 {
  height: 933px;
}

/*Padding x-os*/
.mc-g-padding-x-933 {
  padding: 0 933px;
}

/*Font size*/
.mc-g-font-size-933 {
  font-size: 933px;
}

/*Height*/
.mc-g-height-934 {
  height: 934px;
}

/*Padding x-os*/
.mc-g-padding-x-934 {
  padding: 0 934px;
}

/*Font size*/
.mc-g-font-size-934 {
  font-size: 934px;
}

/*Height*/
.mc-g-height-935 {
  height: 935px;
}

/*Padding x-os*/
.mc-g-padding-x-935 {
  padding: 0 935px;
}

/*Font size*/
.mc-g-font-size-935 {
  font-size: 935px;
}

/*Height*/
.mc-g-height-936 {
  height: 936px;
}

/*Padding x-os*/
.mc-g-padding-x-936 {
  padding: 0 936px;
}

/*Font size*/
.mc-g-font-size-936 {
  font-size: 936px;
}

/*Height*/
.mc-g-height-937 {
  height: 937px;
}

/*Padding x-os*/
.mc-g-padding-x-937 {
  padding: 0 937px;
}

/*Font size*/
.mc-g-font-size-937 {
  font-size: 937px;
}

/*Height*/
.mc-g-height-938 {
  height: 938px;
}

/*Padding x-os*/
.mc-g-padding-x-938 {
  padding: 0 938px;
}

/*Font size*/
.mc-g-font-size-938 {
  font-size: 938px;
}

/*Height*/
.mc-g-height-939 {
  height: 939px;
}

/*Padding x-os*/
.mc-g-padding-x-939 {
  padding: 0 939px;
}

/*Font size*/
.mc-g-font-size-939 {
  font-size: 939px;
}

/*Height*/
.mc-g-height-940 {
  height: 940px;
}

/*Padding x-os*/
.mc-g-padding-x-940 {
  padding: 0 940px;
}

/*Font size*/
.mc-g-font-size-940 {
  font-size: 940px;
}

/*Height*/
.mc-g-height-941 {
  height: 941px;
}

/*Padding x-os*/
.mc-g-padding-x-941 {
  padding: 0 941px;
}

/*Font size*/
.mc-g-font-size-941 {
  font-size: 941px;
}

/*Height*/
.mc-g-height-942 {
  height: 942px;
}

/*Padding x-os*/
.mc-g-padding-x-942 {
  padding: 0 942px;
}

/*Font size*/
.mc-g-font-size-942 {
  font-size: 942px;
}

/*Height*/
.mc-g-height-943 {
  height: 943px;
}

/*Padding x-os*/
.mc-g-padding-x-943 {
  padding: 0 943px;
}

/*Font size*/
.mc-g-font-size-943 {
  font-size: 943px;
}

/*Height*/
.mc-g-height-944 {
  height: 944px;
}

/*Padding x-os*/
.mc-g-padding-x-944 {
  padding: 0 944px;
}

/*Font size*/
.mc-g-font-size-944 {
  font-size: 944px;
}

/*Height*/
.mc-g-height-945 {
  height: 945px;
}

/*Padding x-os*/
.mc-g-padding-x-945 {
  padding: 0 945px;
}

/*Font size*/
.mc-g-font-size-945 {
  font-size: 945px;
}

/*Height*/
.mc-g-height-946 {
  height: 946px;
}

/*Padding x-os*/
.mc-g-padding-x-946 {
  padding: 0 946px;
}

/*Font size*/
.mc-g-font-size-946 {
  font-size: 946px;
}

/*Height*/
.mc-g-height-947 {
  height: 947px;
}

/*Padding x-os*/
.mc-g-padding-x-947 {
  padding: 0 947px;
}

/*Font size*/
.mc-g-font-size-947 {
  font-size: 947px;
}

/*Height*/
.mc-g-height-948 {
  height: 948px;
}

/*Padding x-os*/
.mc-g-padding-x-948 {
  padding: 0 948px;
}

/*Font size*/
.mc-g-font-size-948 {
  font-size: 948px;
}

/*Height*/
.mc-g-height-949 {
  height: 949px;
}

/*Padding x-os*/
.mc-g-padding-x-949 {
  padding: 0 949px;
}

/*Font size*/
.mc-g-font-size-949 {
  font-size: 949px;
}

/*Height*/
.mc-g-height-950 {
  height: 950px;
}

/*Padding x-os*/
.mc-g-padding-x-950 {
  padding: 0 950px;
}

/*Font size*/
.mc-g-font-size-950 {
  font-size: 950px;
}

/*Height*/
.mc-g-height-951 {
  height: 951px;
}

/*Padding x-os*/
.mc-g-padding-x-951 {
  padding: 0 951px;
}

/*Font size*/
.mc-g-font-size-951 {
  font-size: 951px;
}

/*Height*/
.mc-g-height-952 {
  height: 952px;
}

/*Padding x-os*/
.mc-g-padding-x-952 {
  padding: 0 952px;
}

/*Font size*/
.mc-g-font-size-952 {
  font-size: 952px;
}

/*Height*/
.mc-g-height-953 {
  height: 953px;
}

/*Padding x-os*/
.mc-g-padding-x-953 {
  padding: 0 953px;
}

/*Font size*/
.mc-g-font-size-953 {
  font-size: 953px;
}

/*Height*/
.mc-g-height-954 {
  height: 954px;
}

/*Padding x-os*/
.mc-g-padding-x-954 {
  padding: 0 954px;
}

/*Font size*/
.mc-g-font-size-954 {
  font-size: 954px;
}

/*Height*/
.mc-g-height-955 {
  height: 955px;
}

/*Padding x-os*/
.mc-g-padding-x-955 {
  padding: 0 955px;
}

/*Font size*/
.mc-g-font-size-955 {
  font-size: 955px;
}

/*Height*/
.mc-g-height-956 {
  height: 956px;
}

/*Padding x-os*/
.mc-g-padding-x-956 {
  padding: 0 956px;
}

/*Font size*/
.mc-g-font-size-956 {
  font-size: 956px;
}

/*Height*/
.mc-g-height-957 {
  height: 957px;
}

/*Padding x-os*/
.mc-g-padding-x-957 {
  padding: 0 957px;
}

/*Font size*/
.mc-g-font-size-957 {
  font-size: 957px;
}

/*Height*/
.mc-g-height-958 {
  height: 958px;
}

/*Padding x-os*/
.mc-g-padding-x-958 {
  padding: 0 958px;
}

/*Font size*/
.mc-g-font-size-958 {
  font-size: 958px;
}

/*Height*/
.mc-g-height-959 {
  height: 959px;
}

/*Padding x-os*/
.mc-g-padding-x-959 {
  padding: 0 959px;
}

/*Font size*/
.mc-g-font-size-959 {
  font-size: 959px;
}

/*Height*/
.mc-g-height-960 {
  height: 960px;
}

/*Padding x-os*/
.mc-g-padding-x-960 {
  padding: 0 960px;
}

/*Font size*/
.mc-g-font-size-960 {
  font-size: 960px;
}

/*Height*/
.mc-g-height-961 {
  height: 961px;
}

/*Padding x-os*/
.mc-g-padding-x-961 {
  padding: 0 961px;
}

/*Font size*/
.mc-g-font-size-961 {
  font-size: 961px;
}

/*Height*/
.mc-g-height-962 {
  height: 962px;
}

/*Padding x-os*/
.mc-g-padding-x-962 {
  padding: 0 962px;
}

/*Font size*/
.mc-g-font-size-962 {
  font-size: 962px;
}

/*Height*/
.mc-g-height-963 {
  height: 963px;
}

/*Padding x-os*/
.mc-g-padding-x-963 {
  padding: 0 963px;
}

/*Font size*/
.mc-g-font-size-963 {
  font-size: 963px;
}

/*Height*/
.mc-g-height-964 {
  height: 964px;
}

/*Padding x-os*/
.mc-g-padding-x-964 {
  padding: 0 964px;
}

/*Font size*/
.mc-g-font-size-964 {
  font-size: 964px;
}

/*Height*/
.mc-g-height-965 {
  height: 965px;
}

/*Padding x-os*/
.mc-g-padding-x-965 {
  padding: 0 965px;
}

/*Font size*/
.mc-g-font-size-965 {
  font-size: 965px;
}

/*Height*/
.mc-g-height-966 {
  height: 966px;
}

/*Padding x-os*/
.mc-g-padding-x-966 {
  padding: 0 966px;
}

/*Font size*/
.mc-g-font-size-966 {
  font-size: 966px;
}

/*Height*/
.mc-g-height-967 {
  height: 967px;
}

/*Padding x-os*/
.mc-g-padding-x-967 {
  padding: 0 967px;
}

/*Font size*/
.mc-g-font-size-967 {
  font-size: 967px;
}

/*Height*/
.mc-g-height-968 {
  height: 968px;
}

/*Padding x-os*/
.mc-g-padding-x-968 {
  padding: 0 968px;
}

/*Font size*/
.mc-g-font-size-968 {
  font-size: 968px;
}

/*Height*/
.mc-g-height-969 {
  height: 969px;
}

/*Padding x-os*/
.mc-g-padding-x-969 {
  padding: 0 969px;
}

/*Font size*/
.mc-g-font-size-969 {
  font-size: 969px;
}

/*Height*/
.mc-g-height-970 {
  height: 970px;
}

/*Padding x-os*/
.mc-g-padding-x-970 {
  padding: 0 970px;
}

/*Font size*/
.mc-g-font-size-970 {
  font-size: 970px;
}

/*Height*/
.mc-g-height-971 {
  height: 971px;
}

/*Padding x-os*/
.mc-g-padding-x-971 {
  padding: 0 971px;
}

/*Font size*/
.mc-g-font-size-971 {
  font-size: 971px;
}

/*Height*/
.mc-g-height-972 {
  height: 972px;
}

/*Padding x-os*/
.mc-g-padding-x-972 {
  padding: 0 972px;
}

/*Font size*/
.mc-g-font-size-972 {
  font-size: 972px;
}

/*Height*/
.mc-g-height-973 {
  height: 973px;
}

/*Padding x-os*/
.mc-g-padding-x-973 {
  padding: 0 973px;
}

/*Font size*/
.mc-g-font-size-973 {
  font-size: 973px;
}

/*Height*/
.mc-g-height-974 {
  height: 974px;
}

/*Padding x-os*/
.mc-g-padding-x-974 {
  padding: 0 974px;
}

/*Font size*/
.mc-g-font-size-974 {
  font-size: 974px;
}

/*Height*/
.mc-g-height-975 {
  height: 975px;
}

/*Padding x-os*/
.mc-g-padding-x-975 {
  padding: 0 975px;
}

/*Font size*/
.mc-g-font-size-975 {
  font-size: 975px;
}

/*Height*/
.mc-g-height-976 {
  height: 976px;
}

/*Padding x-os*/
.mc-g-padding-x-976 {
  padding: 0 976px;
}

/*Font size*/
.mc-g-font-size-976 {
  font-size: 976px;
}

/*Height*/
.mc-g-height-977 {
  height: 977px;
}

/*Padding x-os*/
.mc-g-padding-x-977 {
  padding: 0 977px;
}

/*Font size*/
.mc-g-font-size-977 {
  font-size: 977px;
}

/*Height*/
.mc-g-height-978 {
  height: 978px;
}

/*Padding x-os*/
.mc-g-padding-x-978 {
  padding: 0 978px;
}

/*Font size*/
.mc-g-font-size-978 {
  font-size: 978px;
}

/*Height*/
.mc-g-height-979 {
  height: 979px;
}

/*Padding x-os*/
.mc-g-padding-x-979 {
  padding: 0 979px;
}

/*Font size*/
.mc-g-font-size-979 {
  font-size: 979px;
}

/*Height*/
.mc-g-height-980 {
  height: 980px;
}

/*Padding x-os*/
.mc-g-padding-x-980 {
  padding: 0 980px;
}

/*Font size*/
.mc-g-font-size-980 {
  font-size: 980px;
}

/*Height*/
.mc-g-height-981 {
  height: 981px;
}

/*Padding x-os*/
.mc-g-padding-x-981 {
  padding: 0 981px;
}

/*Font size*/
.mc-g-font-size-981 {
  font-size: 981px;
}

/*Height*/
.mc-g-height-982 {
  height: 982px;
}

/*Padding x-os*/
.mc-g-padding-x-982 {
  padding: 0 982px;
}

/*Font size*/
.mc-g-font-size-982 {
  font-size: 982px;
}

/*Height*/
.mc-g-height-983 {
  height: 983px;
}

/*Padding x-os*/
.mc-g-padding-x-983 {
  padding: 0 983px;
}

/*Font size*/
.mc-g-font-size-983 {
  font-size: 983px;
}

/*Height*/
.mc-g-height-984 {
  height: 984px;
}

/*Padding x-os*/
.mc-g-padding-x-984 {
  padding: 0 984px;
}

/*Font size*/
.mc-g-font-size-984 {
  font-size: 984px;
}

/*Height*/
.mc-g-height-985 {
  height: 985px;
}

/*Padding x-os*/
.mc-g-padding-x-985 {
  padding: 0 985px;
}

/*Font size*/
.mc-g-font-size-985 {
  font-size: 985px;
}

/*Height*/
.mc-g-height-986 {
  height: 986px;
}

/*Padding x-os*/
.mc-g-padding-x-986 {
  padding: 0 986px;
}

/*Font size*/
.mc-g-font-size-986 {
  font-size: 986px;
}

/*Height*/
.mc-g-height-987 {
  height: 987px;
}

/*Padding x-os*/
.mc-g-padding-x-987 {
  padding: 0 987px;
}

/*Font size*/
.mc-g-font-size-987 {
  font-size: 987px;
}

/*Height*/
.mc-g-height-988 {
  height: 988px;
}

/*Padding x-os*/
.mc-g-padding-x-988 {
  padding: 0 988px;
}

/*Font size*/
.mc-g-font-size-988 {
  font-size: 988px;
}

/*Height*/
.mc-g-height-989 {
  height: 989px;
}

/*Padding x-os*/
.mc-g-padding-x-989 {
  padding: 0 989px;
}

/*Font size*/
.mc-g-font-size-989 {
  font-size: 989px;
}

/*Height*/
.mc-g-height-990 {
  height: 990px;
}

/*Padding x-os*/
.mc-g-padding-x-990 {
  padding: 0 990px;
}

/*Font size*/
.mc-g-font-size-990 {
  font-size: 990px;
}

/*Height*/
.mc-g-height-991 {
  height: 991px;
}

/*Padding x-os*/
.mc-g-padding-x-991 {
  padding: 0 991px;
}

/*Font size*/
.mc-g-font-size-991 {
  font-size: 991px;
}

/*Height*/
.mc-g-height-992 {
  height: 992px;
}

/*Padding x-os*/
.mc-g-padding-x-992 {
  padding: 0 992px;
}

/*Font size*/
.mc-g-font-size-992 {
  font-size: 992px;
}

/*Height*/
.mc-g-height-993 {
  height: 993px;
}

/*Padding x-os*/
.mc-g-padding-x-993 {
  padding: 0 993px;
}

/*Font size*/
.mc-g-font-size-993 {
  font-size: 993px;
}

/*Height*/
.mc-g-height-994 {
  height: 994px;
}

/*Padding x-os*/
.mc-g-padding-x-994 {
  padding: 0 994px;
}

/*Font size*/
.mc-g-font-size-994 {
  font-size: 994px;
}

/*Height*/
.mc-g-height-995 {
  height: 995px;
}

/*Padding x-os*/
.mc-g-padding-x-995 {
  padding: 0 995px;
}

/*Font size*/
.mc-g-font-size-995 {
  font-size: 995px;
}

/*Height*/
.mc-g-height-996 {
  height: 996px;
}

/*Padding x-os*/
.mc-g-padding-x-996 {
  padding: 0 996px;
}

/*Font size*/
.mc-g-font-size-996 {
  font-size: 996px;
}

/*Height*/
.mc-g-height-997 {
  height: 997px;
}

/*Padding x-os*/
.mc-g-padding-x-997 {
  padding: 0 997px;
}

/*Font size*/
.mc-g-font-size-997 {
  font-size: 997px;
}

/*Height*/
.mc-g-height-998 {
  height: 998px;
}

/*Padding x-os*/
.mc-g-padding-x-998 {
  padding: 0 998px;
}

/*Font size*/
.mc-g-font-size-998 {
  font-size: 998px;
}

/*Height*/
.mc-g-height-999 {
  height: 999px;
}

/*Padding x-os*/
.mc-g-padding-x-999 {
  padding: 0 999px;
}

/*Font size*/
.mc-g-font-size-999 {
  font-size: 999px;
}

/*Height*/
.mc-g-height-1000 {
  height: 1000px;
}

/*Padding x-os*/
.mc-g-padding-x-1000 {
  padding: 0 1000px;
}

/*Font size*/
.mc-g-font-size-1000 {
  font-size: 1000px;
}

/*.validation-error {
  .border-error {
    border: 1px solid $mc-red-color-text !important;
  }

  .error-msg {
    font-size: 15px;
    color: $mc-red-color-text;
    text-align: right;
    margin-bottom: 5px;
  }

  .error-msg-left {
    font-size: 15px;
    color: $mc-red-color-text;
    text-align: left;
    margin-bottom: 5px;
  }
}*/
.mc-add-product-to-invoice-wrapper {
  display: block;
}
.mc-add-product-to-invoice-wrapper .mc-add-product-input-wrapper {
  width: auto;
  display: inline-flex;
}
.mc-add-product-to-invoice-wrapper .mc-add-product-quantity-input-field {
  max-width: 70px;
  text-align: center;
}
.mc-add-product-to-invoice-wrapper input[type=number]::-webkit-inner-spin-button,
.mc-add-product-to-invoice-wrapper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mc-add-product-to-invoice-wrapper .mc-decrement-item {
  background-color: #FEE2DF;
  color: #EC230D;
}
.mc-add-product-to-invoice-wrapper .mc-decrement-item:hover {
  background-color: #EC230D;
  color: #fff;
  animation: ease-in-out;
}
.mc-add-product-to-invoice-wrapper .mc-increment-item {
  background-color: #D7F4ED;
  color: #0BB683;
}
.mc-add-product-to-invoice-wrapper .mc-increment-item:hover {
  background-color: #0BB683;
  color: #fff;
  animation: ease-in-out;
}
.mc-add-product-to-invoice-wrapper .mc-button-no-selected-products {
  background: #adb5bd;
  color: #fff;
  opacity: 0.65;
}
.mc-add-product-to-invoice-wrapper .mc-button-no-selected-products:hover {
  background-color: #0BB683;
  color: #fff;
  animation: ease-in-out;
}

.mc-menu-arrow {
  cursor: pointer;
}
.mc-menu-arrow svg g path {
  fill: #3B457C;
}
.mc-menu-arrow_active svg g path {
  fill: #fff;
}

.menu-item-icon {
  position: relative;
  top: -2px;
  left: 15px;
}
.menu-item-icon_collapsed {
  top: 0;
}
.menu-item-icon_active svg g path {
  fill: #fff;
}

.active-tab {
  background: linear-gradient(267.71deg, #60C1C7 0%, #4E358C 100%) !important;
  color: #fff;
}
.active-tab .active-parent {
  color: #fff !important;
}
.active-tab span svg path {
  fill: #fff;
}

.mc-sidebar-submenu {
  background: #fff;
}

.mc-sidebar-link.active {
  background: #5eabbe;
  color: #fff;
}

.submenu-item-indicator {
  margin-left: 3rem;
  border-left: 3px solid #5eabbe;
}

.tooltip {
  z-index: 500;
}

.modal .tooltip {
  z-index: 1100;
}

/*button styles*/
.btn-outline-new {
  border: 2px solid #36B1F1;
  color: #36B1F1;
  font-weight: 500;
  background-color: transparent;
  padding: 8px 15px;
  border-radius: 4px;
  transition: all 0.3s ease-in;
}
.btn-outline-new:hover, .btn-outline-new:focus {
  background-color: #36B1F1;
  border: 2px solid #36B1F1 !important;
  color: #FFF;
  outline: none;
  box-shadow: none;
}
.btn-outline-new .dropdown-toggle:focus, .btn-outline-new .dropdown-toggle:active {
  background-color: #36B1F1;
  border: 2px solid #36B1F1;
}

.content-body-slim {
  padding: 1rem;
  height: calc(80vh - 260px);
  overflow: auto;
  width: 100%;
}

/*breadcrumbs*/
.breadcrumbs {
  cursor: pointer;
  color: #36B1F1;
  font-weight: 500;
}
.breadcrumbs .current-position {
  color: #212529;
  cursor: auto;
}
.breadcrumbs .current-position:hover {
  text-decoration: none;
}
.breadcrumbs span {
  padding: 0 3px;
}
.breadcrumbs span:hover {
  text-decoration: underline;
}

/*breadcrumbs*/
/* general forms and contracts*/
.inner-content {
  padding: 20px 30px;
}

.form-inner-content {
  padding: 10px 0 10px 30px;
}
.form-inner-content label {
  font-size: 1rem !important;
}

.info-summary {
  background-color: #E5F8FF;
  border-radius: 4px;
  margin-bottom: 20px;
}

.info-summary-top {
  background-color: #E5F8FF;
  border-radius: 0 0 4px 4px;
  margin-bottom: 20px;
}
.info-summary-top h6 {
  color: #36B1F1;
  margin: 10px 0 0 0;
  padding-bottom: 5px;
}

/*blue-header*/
.info-summary-header {
  background-color: #36B1F1;
  border-radius: 4px 4px 0 0;
}
.info-summary-header h4 {
  padding: 10px 20px;
  font-size: 1rem;
  color: #FFF;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
}
.info-summary-header i {
  font-size: 0.8rem;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.85;
  transition: all 0.3s ease;
}
.info-summary-header i:hover {
  opacity: 1;
}

/*blue-header*/
.info-container2 {
  border-radius: 4px;
  border: 1px solid #DAEEF8;
  background-color: #E9F5FC;
  line-height: 2rem;
}

.info-summary-header-lite {
  background-color: #E9F5FC;
  border-radius: 4px 4px 0 0;
  border: 1px solid #DAEEF8;
}
.info-summary-header-lite h4 {
  padding: 10px 20px;
  font-size: 1.1rem;
  color: #2F2F2F;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}
.info-summary-header-lite i {
  font-size: 0.8rem;
  margin-left: 5px;
  cursor: pointer;
  opacity: 0.85;
  transition: all 0.3s ease;
  color: #36B1F1;
}
.info-summary-header-lite i:hover {
  opacity: 1;
}

.title-icon {
  font-size: 1.1rem !important;
  margin-right: 5px;
  cursor: auto !important;
}

.info-summary-lite {
  background-color: #F8F8F8;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid #EBEBEB;
  height: 100%;
}
.info-summary-lite .description {
  word-break: break-all;
}

.info-summary-top-lite {
  background-color: #F8F8F8;
  border-radius: 0 0 4px 4px;
  margin-bottom: 20px;
}
.info-summary-top-lite h6 {
  color: #36B1F1;
  margin: 10px 0 5px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #EDEDED;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.5);
}

.info-summary-blue {
  border: 1px solid #DAEEF8 !important;
  border-radius: 4px !important;
  background-color: #eef9ff !important;
}

/*Button above the table lesser padding*/
.table-top-btn {
  z-index: 10;
}
.table-top-btn button {
  margin-bottom: -60px;
}

/*/Button above the table lesser padding*/
.actions-subtitle img {
  float: left;
  margin-right: 10px;
}

.content-header-slim {
  padding: 0.25rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.header-icon {
  float: left;
  margin-right: 5px;
}

.header-icon img {
  margin-top: 7px;
}

.payment-details h5 {
  border-bottom: 1px solid #dee2e6;
  margin: 20px 0;
  padding-bottom: 5px;
}

.date-field {
  position: relative;
  margin-top: 26px;
}
.date-field i {
  position: absolute;
  display: flex;
  flex-basis: inherit;
  justify-content: flex-end;
  margin-right: 1.25rem;
  font-size: 18px;
  color: #32424f;
  right: 0;
  top: 17px;
  z-index: 10;
  pointer-events: none;
}

.table-action-icon {
  margin-right: 1rem;
  display: inline-block;
  padding: 0;
}
.table-action-icon img {
  height: 18px;
  width: auto;
  margin-right: 5px;
}

.email-body-emf .description {
  background-color: #E9F5FC;
  padding: 10px;
  font-style: italic;
  border-radius: 4px;
  margin: 5px 0 0 10px;
  max-width: 360px;
  white-space: pre-wrap;
  border: 1px solid #EFEFEF;
  display: block;
}
.email-body-emf .description:empty {
  background-color: transparent;
}

.criteria-tag {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #FFF;
  margin: 5px;
  display: inline-block;
  border: 2px solid #E9F5FC;
}

.criteria-tag-alt {
  color: #2FA0D6;
  padding: 6px 10px;
  border-radius: 4px;
  background-color: white;
  margin: 5px;
  display: inline-block;
  border: 2px solid #E9F5FC;
  font-style: italic;
}
.criteria-tag-alt:empty {
  display: none;
}

.section-header {
  background-color: #E9F5FC;
  border-radius: 4px 4px 0 0;
  padding: 1.1rem 0.8rem;
  margin-bottom: 1rem;
}
.section-header h2 {
  text-transform: uppercase;
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
}
.section-header h3 {
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.inner-section {
  padding: 1rem;
}

.section-sub-header {
  background-color: #EFEFEF;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
}
.section-sub-header h3 {
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
.section-sub-header img {
  position: absolute;
  top: 10px;
  right: 8px;
}

.input-wrap {
  display: flex;
}
.input-wrap span {
  margin: 0 10px;
}

.input-wrap p {
  position: relative;
  bottom: 35px;
  margin: 0 10px;
}

.input-wrap-move {
  margin-left: 180px;
}

.contract-request {
  position: relative;
}
.contract-request a {
  color: #36B1F1 !important;
  cursor: pointer !important;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;
}
.contract-request a:hover {
  color: #35B1EC !important;
  border-bottom: 1px solid #35B1EC;
}

.input-right input {
  text-align: right;
}

.simple-icon-link i {
  cursor: pointer;
  color: #3EB2EF;
  display: inline-block;
  margin-left: 5px;
  transition: color 0.3s ease;
  font-size: 1rem;
}
.simple-icon-link i:hover {
  color: #60BBE6;
}

/*Error message above buttons*/
.above-footer {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 50px);
  background-color: white;
  padding: 10px 10px 10px 5px;
  z-index: 101;
}

.message-background {
  width: 100%;
}

/*/Error message above buttons*/
/*Run items description*/
.run-wrap {
  padding: 1rem;
  margin: 10px 0;
}
.run-wrap p {
  margin: -20px 0 5px 1.7rem;
  font-size: 0.9rem;
  font-style: italic;
  border-left: 4px solid #36B1F1;
  padding: 10px;
  background-color: #F8F8F8;
  border-radius: 0 4px 4px 0;
}

/*Radio items description*/
.radio-item-wrap {
  padding: 1rem 0;
  margin: 10px 0;
}
.radio-item-wrap p {
  margin: -20px 0 5px 1.7rem;
  font-size: 1rem;
  border-left: 4px solid #36B1F1;
  padding: 10px;
  background-color: #F8F8F8;
  border-radius: 0 4px 4px 0;
}
.radio-item-wrap .important-item .description {
  color: #36B1F1;
  font-weight: 500;
}

.attachment-link {
  margin-left: 102px;
  padding-bottom: 0px;
  cursor: pointer;
}
.mc-button-link:hover {
  opacity: 0.8;
}

.gen-info p {
  background-color: #F8F8F8;
  padding: 10px;
  border-radius: 4px;
  color: rgba(33, 37, 41, 0.7);
}
.gen-info p i {
  color: rgba(33, 37, 41, 0.3);
  margin-right: 2px;
  font-size: 0.9rem;
}

.mcc-fi input:focus {
  border-color: #36B1F1 !important;
}

.icon-blue {
  color: #36B1F1;
  margin-right: 5px;
}

.icon-gray {
  color: gray;
  margin-right: 5px;
  opacity: 40%;
}

.icon-red {
  color: red;
  margin-right: 5px;
}

.info-icon-positioning {
  position: absolute;
  top: 32px;
  right: 10px;
}

.info-icon-positioning2 {
  position: absolute;
  top: 0px;
  right: 10px;
}

.info-icon-positioning3 {
  position: absolute;
  top: -40px;
  right: 10px;
}

.info-icon-positioning4 {
  position: absolute;
  top: 40px;
  right: 10px;
}

.info-icon-positioning5 {
  position: absolute;
  top: 72px;
  right: 10px;
}

.index100 {
  z-index: 100;
}

.blue-info-msg {
  color: #36B1F1;
  font-size: 14px;
}
.blue-info-msg i {
  font-size: 1rem;
}

/***** STEPPER ****/
.stepper-progress-wrap {
  position: relative;
  padding: 1rem 0.5rem;
  display: block;
  width: 550px;
  margin: 0 auto 10px auto;
}

.stepper-progress {
  position: absolute;
  top: -45px;
  padding: 1rem;
}
.stepper-progress .step-count {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 10px;
}
.stepper-progress .step-done {
  border-top: 10px solid #36B1F1;
  padding-top: 5px;
  width: 150px;
  float: left;
  margin: 0 10px;
  text-align: center;
  font-size: 0.85rem;
}
.stepper-progress .step-not-done {
  border-top: 10px solid #F2F2F2;
  padding-top: 5px;
  width: 150px;
  float: left;
  margin: 0 10px;
  text-align: center;
  font-size: 0.85rem;
}

.multi-row-error .error-msg {
  max-width: 300px;
}

/*SERVICES TABLE*/
.services-price-table .mc-table-wrapper .mc-table td {
  padding: 12px 6px;
}
.services-price-table table th:nth-of-type(1n) {
  max-width: 2.5%;
}
.services-price-table table th:nth-of-type(2n) {
  max-width: 2.5%;
}
.services-price-table table th:nth-of-type(3n) {
  max-width: 20%;
}
.services-price-table table th:nth-of-type(4n) {
  width: 30%;
}
.services-price-table table th:nth-of-type(5n) {
  width: 12.5%;
}
.services-price-table table th:nth-of-type(6n) {
  width: 20%;
}
.services-price-table table th:nth-of-type(7n) {
  width: 12.5%;
}

/* longer input */
.longer-input-300 .single-input {
  width: 300px;
}

/* Tooltip fix */
.tooltip-wide {
  position: relative;
  min-width: 240px;
}

/*pause transactions*/
.pause-icon-holster {
  display: flex;
}
.pause-icon-holster i {
  align-self: center;
  padding: 0 0.6rem;
  font-size: 1.3rem;
}

.mc-transaction-status-label button {
  margin: 12px;
}

.important-data {
  margin-top: 10px;
  border-radius: 4px;
  background-color: #FFF1E5;
}

/* role templates */
.role-template-wrap label {
  font-size: 1.2rem !important;
  font-weight: 500;
}

.template-desc p {
  font-size: 1rem !important;
  font-weight: 500;
  font-style: normal;
}
.template-desc .description-txt {
  font-weight: 400;
}
.template-desc .from-entity {
  color: #36B1F1;
  font-weight: 500;
}

.desc-uppercase .title {
  text-transform: uppercase;
}

.mc-filter-button {
  border-radius: 4px;
}

/*FANCY LABEL*/
/*REDESIGNED SEARCH*/
.fancy-label {
  position: relative;
  background-color: #FAF8F8 !important;
  border: 1px solid #E2DFDF;
  border-radius: 4px;
  padding-top: 20px;
}
.fancy-label label {
  position: absolute;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  color: #36B1F1;
  padding: 2px 2px 10px 5px;
}
.fancy-label .wide-input .mcc-fi-select select {
  width: 150px;
}
.fancy-label .mcc-fi-select select {
  padding: 12px 8px 4px 8px !important;
  min-width: 120px;
}
.fancy-label form {
  margin-top: 26px;
}
.fancy-label input {
  min-height: 52px;
}
.fancy-label select {
  min-height: 52px;
}
.fancy-label .multi-select-field {
  margin-top: 26px;
}
.fancy-label .multi-select-field .mcc-fi-date-picker label:before {
  top: 25px !important;
}
.fancy-label .multi-select-field .mcc-fi-date-picker label {
  top: -7px !important;
  left: -2px !important;
}
.fancy-label .multi-select-field i {
  top: 23px !important;
}
.fancy-label .multi-select-field .mc-multiple-items-clear {
  left: 180px;
}

/* Width control for multiselect filter */
.extra-wide-input .width-control {
  width: 300px;
}
.extra-wide-input .mc-multiple-items-clear {
  left: 271px !important;
}

.table-title:not(:first-child) {
  padding-top: 30px;
  border-top: 1px solid #DBDBDB;
  width: calc(100% - 1.4rem);
}
.table-title h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

#mcc-fi-txbSearchTerm {
  padding-right: 35px;
  text-indent: 6px;
  background-image: url("/assets/images/search-icon.svg");
  background-position: 96% 50%;
  background-repeat: no-repeat;
}

/* Pricelists */
.service-item-name {
  color: #06608E;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.pricing-main-radios {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem 0.2rem 1rem;
}
.pricing-main-radios .mat-mdc-radio-group {
  width: 100%;
}
.pricing-main-radios .mat-mdc-radio-group ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
}
.pricing-main-radios .mat-mdc-radio-group ul li {
  display: block;
  width: 33.3%;
}

.editable-item {
  position: relative;
}
.editable-item .description {
  padding-right: 160px;
}

.edit-pen {
  position: absolute;
  top: 0;
  right: 120px;
}
.edit-pen i {
  cursor: pointer;
  font-size: 0.8rem;
}

.mx-btn {
  max-height: 42px;
}

/*BILLING*/
.total-row {
  background-color: #D9F2FF;
  border-top: 1px solid #36B1F1;
  border-bottom: 1px solid #36B1F1;
  padding: 15px 10px;
  margin-top: -48px;
  display: flex;
  justify-content: flex-end;
  padding-left: 65%;
}
.total-row .main-label {
  display: block;
  width: 86%;
}
.total-row .main-label h5 {
  text-align: right;
  padding-right: 60px;
}

.sum-total {
  display: flex;
  min-width: 14%;
  font-weight: 500;
}

.table-template span {
  padding: 16px 10px;
}
.table-template .table-header, .table-template .table-footer {
  background-color: #D9F2FF !important;
  font-weight: 500;
  font-size: 1.1rem;
}
.table-template .table-footer2 {
  background-color: #D4FBD8 !important;
  border-top: 1px solid #29D63B;
  border-bottom: 1px solid #29D63B;
}
.table-template .row:nth-child(even) {
  background-color: #fff;
}
.table-template .row:nth-child(odd) {
  background-color: #f2f2f2;
}

/*BATCH TABLE*/
.blue-batch-header {
  padding: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 4px 4px 0 0;
  background-color: #D9F2FF;
  margin: 1rem 0 0.5rem 0;
}

.batch-table {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  margin: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  max-width: calc(100% - 1.4rem);
}
.batch-table .row {
  padding: 1rem 0.25rem;
  font-size: 1.2rem;
  /*
      &:last-child{
        border-bottom:1px solid #f4f4f4;
    }
  */
}
.batch-table .row h4 {
  font-weight: 500;
  font-size: 1.2rem;
}
.batch-table .row h3 {
  color: #36B1F1;
  font-size: 1.4rem;
  font-weight: 500;
}
.batch-table .row:not(:first-child) {
  border-top: 1px solid #f4f4f4;
}

.icon-orange {
  font-size: 2.2rem;
  color: #ffae42;
  margin-top: 20px;
}

.standout {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 30px 20px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}

/** pseudo messages**/
.info-question {
  display: block;
  padding: 20px;
}
.info-question i {
  font-size: 2.2rem;
  color: #ffae42;
  float: left;
  margin-right: 0.5rem;
}
.info-question span {
  float: left;
  font-weight: 500;
}

.info-warning {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
}
.info-warning i {
  font-size: 2.2rem;
  float: left;
  margin-right: 0.5rem;
  margin-top: 0;
}
.info-warning span {
  float: left;
  font-weight: 500;
}
.info-warning .single-line-txt {
  margin-top: 5px;
}

.icon-img {
  width: 36px;
  height: 36px;
  margin-right: 0.5rem;
}

/* custom tooltip - longer*/
.icon-tooltip2 {
  position: relative;
  display: inline-block;
}

.icon-tooltip2 .icon-tooltip-text2 {
  visibility: hidden;
  width: 260px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 70%;
  margin-left: -60px;
  font-size: 12px;
}

.icon-tooltip2 .icon-tooltip-text2:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 22%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.icon-tooltip2:hover .icon-tooltip-text2 {
  visibility: visible;
}

.icon-tooltip2:hover .icon-tooltip-hidden2 {
  visibility: hidden;
}

.dropdown-item:hover {
  background-color: #32424f;
  color: #FFF;
}
.dropdown-item:hover i {
  color: #FFF !important;
}
.dropdown-item i {
  width: 20px;
  margin-right: 5px;
  color: #32424f;
}

/*tool tip on top*/
.tooltip-wider .tooltip .tooltip-inner {
  max-width: 500px;
}

/*Tooltip fix when error message is too long*/
.long-error-msg {
  position: absolute !important;
  left: 180px;
}

/*multiple select clear button fix*/
.old-label .mc-multiple-items-clear {
  display: none;
}

/* drop-down button fix*/
.dropdown-menu-right {
  right: 20px !important;
  left: auto !important;
}

/*Firefox Scrollbar fix*/
.mc-scrollbar-vertical {
  scrollbar-width: none;
}

/*mac OS select input fix*/
.mcc-fi-select select {
  height: 42px;
}

/*mac OS Safari sidebar fix*/
.submenu-item {
  align-items: center;
  display: flex;
}

.medium-date .mc-multiple-items-clear {
  top: -16px !important;
  right: 0 !important;
}

.title-large {
  font-size: 1.5rem;
  color: #3B457C;
  font-weight: bold;
  text-transform: uppercase;
}

.title-medium-dark {
  font-family: "Maven Pro", sans-serif;
  font-size: 1.25rem;
  color: #3B457C;
  font-weight: bold;
  text-transform: uppercase;
}

.title-medium-blue {
  color: #5eabbe;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.title-medium-dark-normal {
  font-size: 1.125rem;
  color: #3B457C;
  font-width: bold;
}

.title-medium-light {
  font-size: 1.25rem;
  color: #fff;
  font-weight: bold;
}

.title-large-light {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
}

.title-small-dark {
  font-size: 1rem;
  color: #3B457C;
}

.body-text-medium {
  font-size: 1rem;
  color: #3B457C;
}

.body-text-medium-bold {
  font-size: 1rem;
  color: #3B457C;
  font-weight: bold;
}

.body-text-small {
  font-size: 0.875rem;
  color: #3B457C;
}

.body-text-small-bold {
  font-size: 0.875rem;
  color: #3B457C;
  font-weight: bold;
}

.body-text-small-faded {
  font-size: 0.875rem;
  color: #3B457C;
  font-weight: 400;
  opacity: 0.7;
}

.mt-small {
  margin-top: 0.625rem;
}

.mt-medium {
  margin-top: 0.938rem;
}

.mt-large {
  margin-top: 1.25rem;
}

.mt-extra-large {
  margin-top: 1.875rem;
}

.mr-smallest {
  margin-right: 0.313rem;
}

.mr-small {
  margin-right: 0.625rem;
}

.mr-large {
  margin-right: 1.25rem;
}

.mr-medium {
  margin-right: 0.938rem;
}

.mb-small {
  margin-bottom: 0.625rem;
}

.mb-smallest {
  margin-bottom: 0.313rem;
}

.mb-medium {
  margin-bottom: 0.938rem;
}

.mb-large {
  margin-bottom: 1.25rem !important;
}

.mb-extra-large {
  margin-bottom: 1.875rem;
}

.ml-small {
  margin-left: 0.625rem;
}

.ml-medium {
  margin-left: 0.938rem;
}

.ml-smallest {
  margin-left: 0.313rem;
}

.ml-large {
  margin-left: 1.25rem;
}

.ml-extra-large {
  margin-left: 1.875rem;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.card-wrapper {
  display: grid;
  grid-gap: 25px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
}

.card-wrapper-medium {
  grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
}

.card {
  border-radius: 4px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 10px;
}
.card .card-inner-wrapper {
  display: flex;
  align-items: center;
}
.card.card-flex-end {
  display: flex;
  justify-content: flex-end;
}
.card_title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
}
.card_description {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.info-card {
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #FFF;
  border: 1px solid rgba(1, 21, 34, 0.2);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
}
.info-card .info-card-label-value {
  display: flex;
  align-items: center;
  flex: 1 1 25%;
}
.info-card .info-card-label-value .label {
  display: flex;
  align-items: center;
  margin-right: 0.313rem;
  color: rgba(59, 69, 124, 0.5);
  font-size: 1rem;
  line-height: 20px;
}
.info-card .info-card-label-value .value {
  color: #3B457C;
  font-size: 1rem;
  font-weight: 700;
  line-height: 20px;
}

.settings-card_header {
  display: flex;
  justify-content: space-between;
  margin: -15px;
  padding: 20px;
  border-bottom: 1px solid rgba(1, 21, 34, 0.2);
  align-items: center;
}
.settings-card_header_left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.settings-card_header_left_icon ::ng-deep svg {
  width: 30px;
  height: 30px;
}
.settings-card_header_left_icon ::ng-deep svg g {
  opacity: 1;
}
.settings-card_header_left_title {
  color: #5eabbe;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.settings-card_header_right {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.settings-card_header_right_title {
  color: #5eabbe;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
.settings-card_header_right_icon ::ng-deep svg {
  width: 24px;
  height: 24px;
}
.settings-card_confirm {
  margin: 0 -15px;
  padding: 20px 20px 10px 20px;
  border-top: 1px solid rgba(1, 21, 34, 0.2);
}

.input-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  width: 100%;
}
.input-wrapper .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0;
}

.input-inner-icon {
  position: absolute;
  top: 5px;
  right: -3px;
  background-color: #fff;
}

/* Fonts */
/* Colors */
/* Shadows */
/*Media query values*/
/*Buttons*/
/* Custom dropdown*/
/*Job action bg colors*/
/*Status icon colors*/
/* Dates */
/* Consumer */
/* Filter */
/* Font Sizes */
/* Margins */
.mat-mdc-paginator {
  color: #3B457C;
  font-size: 14px;
  border-top: 1px solid rgb(194, 193, 193);
}
.mat-mdc-paginator .mat-mdc-paginator-page-size-label, .mat-mdc-paginator .mat-mdc-paginator-range-label {
  font-family: "Maven Pro", sans-serif;
  color: #3B457C;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.mat-mdc-paginator .mat-select-value {
  font-size: 16px;
}
.mat-mdc-paginator .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
  height: 36px;
}
.mat-mdc-paginator .mat-mdc-paginator-page-size {
  display: flex;
  align-items: center;
}
.mat-mdc-paginator .mat-mdc-paginator-page-size .mat-mdc-paginator-page-size-label {
  margin-right: 10px;
}
.mat-mdc-paginator .mat-mdc-paginator-page-size .mat-form-field-infix {
  border: none;
}
.mat-mdc-paginator .mat-paginator-page-size-select {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid rgba(59, 69, 124, 0.38);
}
.mat-mdc-paginator .mat-paginator-page-size-select .mat-form-field-wrapper {
  width: 60px;
}
.mat-mdc-paginator .mat-paginator-page-size-select .mat-form-field-wrapper .mat-select-value {
  top: 0 !important;
}
.mat-mdc-paginator .mat-paginator-page-size-select .mat-form-field-wrapper .mat-select-trigger {
  top: 10px;
}
.mat-mdc-paginator .mat-paginator-page-size-select .mat-select-arrow {
  margin: 0;
  top: 0 !important;
}
.mat-mdc-paginator .mat-form-field-underline {
  display: none;
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.mat-mdc-paginator-container {
  height: 50px;
  min-height: 50px !important;
}

.mat-option-text {
  font-size: 16px;
  font-family: "Maven Pro", sans-serif;
}

/* Fonts */
/* Colors */
/* Shadows */
/*Media query values*/
/*Buttons*/
/* Custom dropdown*/
/*Job action bg colors*/
/*Status icon colors*/
/* Dates */
/* Consumer */
/* Filter */
/* Font Sizes */
/* Margins */
.mat-mdc-checkbox label {
  margin: 0px;
  font-family: "Maven Pro", sans-serif;
}

.mat-mdc-checkbox-ripple .mat-mdc-ripple-element,
.mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
  background-color: #042F4D !important;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}

.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mat-mdc-checkbox-indeterminate .mdc-checkbox__background {
  background-color: #042F4D !important;
  border-color: #042F4D !important;
}

.mat-mdc-checkbox-frame {
  border-color: #042F4D !important;
}

.mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.mat-bottom-sheet-container {
  padding: 0px !important;
  min-width: 40px !important;
}

.mat-mdc-row {
  overflow: visible !important;
}

@supports (-moz-appearance: none) {
  .mat-mdc-select {
    top: -5px !important;
    position: relative;
  }
  .mat-mdc-select-value, .mat-mdc-select-arrow {
    top: -20px !important;
    position: relative;
  }
}
.mat-expansion-panel-body {
  padding: 0px 24px !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-flex,
.mat-form-field-appearance-outline .mat-mdc-text-field-flex {
  height: 44px;
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above--float-above {
  top: auto;
  margin-top: 5px;
}

.mdc-floating-label {
  top: 20px !important;
}

.mdc-floating-label--float-above {
  top: 26px !important;
  font-size: 16px;
  width: fit-content;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 15px;
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

mat-radio-group {
  display: flex;
  margin-left: -10px;
  margin-top: -5px;
}

.mat-mdc-radio-group ul li {
  height: 32px;
}

.mat-mdc-select-value,
.mat-mdc-select-arrow,
.mat-date-range-input,
.mat-mdc-input-element {
  position: relative;
  top: -5px;
}

.mat-expansion-indicator {
  display: none;
}

.mat-mdc-form-field-icon-suffix {
  top: -10px !important;
  right: -10px !important;
}

.mat-mdc-form-field-wrapper {
  margin: 0 !important;
  padding: 0;
}

.newStyleDate .mdc-floating-label--float-above {
  margin-left: 0 !important;
}

.mk-mat-filter-container {
  display: flex;
  position: relative;
  padding: 15px 0px;
  background-color: #fff;
  border-radius: 0px 0px 4px 4px;
}
.mk-mat-filter-container .filter-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 10px;
  width: 100%;
}
.mk-mat-filter-container .filter-inputs .mat-mdc-form-field {
  width: 100%;
  margin-bottom: -10px;
}
.mk-mat-filter-container .filter-inputs input {
  color: #202A33;
}
.mk-mat-filter-container .date-field {
  margin-top: 0 !important;
}
.mk-mat-filter-container .date-field .mcc-fi-date-picker {
  width: 100%;
}
.mk-mat-filter-container .date-field .mcc-fi-date-picker .date-range-input {
  padding-left: 15px !important;
}
.mk-mat-filter-container .date-field .form-group, .mk-mat-filter-container .date-field .input-group, .mk-mat-filter-container .date-field .input-group input {
  width: 100%;
}
.mk-mat-filter-container .date-field .form-group {
  position: relative;
}
.mk-mat-filter-container .date-field .form-group i {
  position: absolute;
  display: flex;
  flex-basis: inherit;
  justify-content: flex-end;
  margin-right: 1.25rem;
  font-size: 18px;
  color: #32424f;
  right: 0;
  top: 12px;
  z-index: 10;
  pointer-events: none;
}
.mk-mat-filter-container .date-range-input {
  height: 42px;
}
.mk-mat-filter-container .mc-range-date-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -7px !important;
  left: 29px !important;
}

.filter-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: calc(100% + 50px);
  border-top: 1px solid rgba(1, 21, 34, 0.2);
  padding: 10px;
  margin-left: -25px;
}
.filter-actions .clear-all {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  font-size: 14px;
  color: #5eabbe !important;
  font-weight: 500;
  background-color: #ffffff !important;
}
.filter-actions .clear-all:hover {
  background-color: #ffffff !important;
}
.filter-actions .filter-button {
  margin-top: 3px;
  background: #5eabbe !important;
  color: #ffffff !important;
  border-radius: 4px;
  width: 100px;
  height: 36px;
  transition: all 200ms cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 14px;
}
.filter-actions .filter-button:hover {
  background-color: #4392a6 !important;
}

.mat-mdc-select-panel {
  width: calc(100% + 18px);
  margin-top: 0;
  margin-left: 0;
  min-width: 100px !important;
  max-width: none !important;
  padding: 0 !important;
}

.dialog-select {
  width: calc(100% + 22px);
  min-width: 100px !important;
  max-width: none !important;
}

.mat-mdc-tooltip {
  color: #ffffff;
  font-size: 12px;
  background-color: #32424f;
}

.mat-mdc-menu-panel {
  min-height: 50px !important;
  max-width: fit-content !important;
}

mat-option {
  border-bottom: 1px solid #c8c8c8;
}

mat-option:last-child {
  border-bottom: none;
}

.mat-mdc-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 !important;
}
.mat-mdc-menu-content .mat-mdc-menu-item {
  height: 100%;
  font-weight: 500;
}
.mat-mdc-menu-content .mat-mdc-menu-item:hover {
  background-color: #e1f4fc;
}

.mat-column-getVATString-- div {
  display: flex;
  align-items: center;
  min-width: max-content;
}
.mat-column-getVATString-- div .icon-tooltip {
  margin: 0px 0px 0px 0.625rem !important;
}
.mat-column-getVATString-- div .icon-tooltip img {
  margin: 0px;
}

.cdk-overlay-container {
  z-index: 9999;
}

.dialog .mat-mdc-dialog-content {
  height: calc(100% - 130px);
  padding: 5px 0 !important;
}
.dialog_title {
  font-size: 16px;
  font-weight: 700;
  color: #333867;
  margin-bottom: 10px;
}
.dialog_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.dialog_header:after {
  content: "";
  position: absolute;
  height: 1px;
  background: rgba(1, 21, 34, 0.2);
  bottom: 0;
  width: calc(100% + 60px);
  margin-left: -35px;
}
.dialog_header_title {
  font-family: Maven Pro;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #53589B;
}
.dialog_header_icon {
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
}
.dialog_footer {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  padding-top: 20px;
  width: calc(100% - 10px);
  margin-left: 5px;
}
.dialog_footer:after {
  content: "";
  position: absolute;
  height: 1px;
  background: rgba(1, 21, 34, 0.2);
  top: 0;
  right: -30px;
  left: -30px;
}

.dialog .mat-mdc-dialog-container {
  overflow-x: hidden !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #5eabbe;
}

.mdc-text-field--outlined .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-notched-outline__trailing {
  border-color: rgb(212, 212, 212) !important;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #5eabbe !important;
}

.mat-mdc-form-field.mat-focused .mdc-floating-label--float-above {
  color: #5eabbe !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: #5eabbe !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none !important;
  margin-left: -2px;
}

.mat-calendar-header, .mat-calendar-content {
  padding: 0 !important;
}

.mat-calendar-body-selected {
  background-color: #5eabbe;
}

.mat-calendar-body-in-range::before {
  background: rgba(36, 174, 201, 0.3);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: none;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background: rgba(36, 174, 201, 0.3);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  background-color: #5eabbe !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #fff;
  border: none;
}

.mat-datepicker-toggle-active {
  color: #5eabbe;
}

.date-range .mat-date-range-input-separator,
.date-range input {
  cursor: context-menu !important;
}

.mat-datepicker-toggle {
  top: 11px;
  right: 10px;
  fill: #53589B;
  position: relative;
}

.custom-table {
  width: 100%;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  flex: 1;
}
.custom-table thead {
  height: 50px;
  border-radius: 4px;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.custom-table thead tr {
  height: 44px;
}
.custom-table thead th:first-child {
  padding-left: 15px;
  text-align: left;
}
.custom-table thead th:last-child {
  padding-right: 15px;
  text-align: right;
}
.custom-table tbody tr {
  height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.custom-table tbody td:first-child {
  padding-left: 15px;
  text-align: left;
}
.custom-table tbody td:last-child {
  padding-right: 15px;
  text-align: right;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}