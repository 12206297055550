.mc-two-fa {
  height: inherit;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(58,120,151,.01);

  .mc-card {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    .mc-card-body {
      position: relative;
    }

    //Mobile icon section
    .mc-icon-section {
      .mc-mobile-icon {
        height: 100px;
        padding: 7px
      }

      .mc-android-icon {
        @extend .mc-mobile-icon
      }

      .mc-ios-icon {
        @extend .mc-mobile-icon
      }
    }

    // QR code
    .mc-qr-code {
      display: block;
      width: fit-content;
      margin: 30px auto;
    }

    .mc-qr-code-form {
      display: block;
      position: relative;
      width: 100%;
      padding-top: 75px;
    }
  }

  //Mobile design
  @media only screen and (max-width: $small-device) {

    // MC two fa wrapper div
    height: auto;
    padding: 30px 0;
  }
}

