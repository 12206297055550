$cc-blue: #36B1F1;
$cc-lightblue: #E9F5FC;
$cc-midblue: #E5F8FF;
$cc-gray: #EFEFEF;
$cc-light: #F8F8F8;
$txt-black: #212529;



/*button styles*/

.btn-outline-new{
  border:2px solid $cc-blue;
  color:$cc-blue;
  font-weight: 500;
  background-color: transparent;
  padding: 8px 15px;
  border-radius: 4px;
  transition: all 0.3s ease-in;


  &:hover, &:focus{
    background-color: $cc-blue;
    border:2px solid $cc-blue !important;
    color: #FFF;
    outline: none;
    box-shadow: none;
  }

  .dropdown-toggle{
    &:focus, &:active{
      background-color: $cc-blue;
      border:2px solid $cc-blue;
    }
  }
}



.content-body-slim {
  padding: 1rem;
  height: calc(80vh - 260px);
  overflow: auto;
  width: 100%;
}



  /*breadcrumbs*/

.breadcrumbs {
  cursor: pointer;
  color: $cc-blue;
  font-weight: 500;

  .current-position {
    color: $txt-black;
    cursor: auto;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    padding: 0 3px;

    &:hover {
      text-decoration: underline;
    }
  }
}

/*breadcrumbs*/

/* general forms and contracts*/

.inner-content {
  padding: 20px 30px;
}

.form-inner-content {
  padding: 10px 0 10px 30px;

  label {
    font-size: 1rem !important;
  }
}

.info-summary {
  background-color: $cc-midblue;
  border-radius: 4px;
  margin-bottom: 20px;
}

.info-summary-top {
  background-color: $cc-midblue;
  border-radius: 0 0 4px 4px;
  margin-bottom: 20px;

  h6 {
    color: $cc-blue;
    margin: 10px 0 0 0;
    padding-bottom: 5px;
  }
}

/*blue-header*/

.info-summary-header {
  background-color: $cc-blue;
  border-radius: 4px 4px 0 0;

  h4 {
    padding: 10px 20px;
    font-size: 1rem;
    color: #FFF;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
  }

  i {
    font-size: 0.8rem;
    margin-left: 5px;
    cursor: pointer;
    opacity: 0.85;
    transition: all 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}

/*blue-header*/
.info-container2 {
  border-radius: 4px;
  border: 1px solid #DAEEF8;
  background-color: $cc-lightblue;
  line-height: 2rem;
}


.info-summary-header-lite {
  background-color: $cc-lightblue;
  border-radius: 4px 4px 0 0;
  border: 1px solid #DAEEF8;

  h4 {
    padding: 10px 20px;
    font-size: 1.1rem;
    color: #2F2F2F;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }

  i {
    font-size: 0.8rem;
    margin-left: 5px;
    cursor: pointer;
    opacity: 0.85;
    transition: all 0.3s ease;
    color: $cc-blue;

    &:hover {
      opacity: 1;
    }
  }
}

.title-icon {
  font-size: 1.1rem !important;
  margin-right: 5px;
  cursor: auto !important;
}

.info-summary-lite {
  background-color: $cc-light;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid #EBEBEB;
  height: 100%;

  .description{
    word-break: break-all;
  }
}

.info-summary-top-lite {
  background-color: $cc-light;
  border-radius: 0 0 4px 4px;
  margin-bottom: 20px;

  h6 {
    color: $cc-blue;
    margin: 10px 0 5px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #EDEDED;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, .5);
  }
}

.info-summary-blue {
  border: 1px solid #DAEEF8 !important;
  border-radius: 4px !important;
  background-color: #eef9ff !important;
}

/*Button above the table lesser padding*/

.table-top-btn {
  z-index: 10;

  button {
    margin-bottom: -60px;
  }
}

/*/Button above the table lesser padding*/


.actions-subtitle img {
  float: left;
  margin-right: 10px;
}


.content-header-slim {
  padding: 0.25rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.header-icon {
  float: left;
  margin-right: 5px;
}

.header-icon img {
  margin-top: 7px;
}

.payment-details h5 {
  border-bottom: 1px solid #dee2e6;
  margin: 20px 0;
  padding-bottom: 5px;
}

.date-field {
  position: relative;
  margin-top: 26px;

  i {
    position: absolute;
    display: flex;
    flex-basis: inherit;
    justify-content: flex-end;
    margin-right: $margin-large;
    font-size: $medium-font;
    color: $mc-header-color;
    right: 0;
    top: 17px;
    z-index: 10;
    pointer-events: none;
  }
}

.table-action-icon {
  margin-right: 1rem;
  display: inline-block;
  padding: 0;

  img {
    height: 18px;
    width: auto;
    margin-right: 5px;
  }

}

.email-body-emf .description {
  background-color: $cc-lightblue;
  padding: 10px;
  font-style: italic;
  border-radius: 4px;
  margin: 5px 0 0 10px;
  max-width: 360px;
  white-space: pre-wrap;
  border: 1px solid $cc-gray;
  display: block;

  &:empty {
    background-color: transparent;
  }
}

.criteria-tag {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #FFF;
  margin: 5px;
  display: inline-block;
  border: 2px solid $cc-lightblue;
}

.criteria-tag-alt {
  color: #2FA0D6;
  padding: 6px 10px;
  border-radius: 4px;
  background-color: white;
  margin: 5px;
  display: inline-block;
  border: 2px solid $cc-lightblue;
  font-style: italic;

  &:empty {
    display: none;
  }
}

.section-header {
  background-color: $cc-lightblue;
  border-radius: 4px 4px 0 0;
  padding: 1.1rem 0.8rem;
  margin-bottom: 1rem;

  h2 {
    text-transform: uppercase;
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
  }

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

}

.inner-section {
  padding: 1rem;
}

.section-sub-header {
  background-color: $cc-gray;
  padding: 0.5rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;

  h3 {
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }

  img {
    position: absolute;
    top: 10px;
    right: 8px;
  }

}

.input-wrap {
  display: flex;

  span {
    margin: 0 10px;
  }
}

.input-wrap p {
  position: relative;
  bottom: 35px;
  margin: 0 10px;
}

.input-wrap-move {
  margin-left: 180px;
}

.contract-request {
  position: relative;

  a {
    color: $cc-blue !important;
    cursor: pointer !important;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparent;

    &:hover {
      color: #35B1EC !important;
      border-bottom: 1px solid #35B1EC;
    }
  }
}

.input-right input {
  text-align: right;
}

.simple-icon-link i {
  cursor: pointer;
  color: #3EB2EF;
  display: inline-block;
  margin-left: 5px;
  transition: color 0.3s ease;
  font-size: 1rem;

  &:hover {
    color: #60BBE6;
  }
}


/*Error message above buttons*/

.above-footer {
  position: absolute;
  bottom: 0px;
  width: calc(100% - 50px);
  background-color: white;
  padding: 10px 10px 10px 5px;
  z-index: 101;
}

.message-background {
  width: 100%;
}

/*/Error message above buttons*/


/*Run items description*/
.run-wrap {
  padding: 1rem;
  margin: 10px 0;

  p {
    margin: -20px 0 5px 1.7rem;
    font-size: 0.9rem;
    font-style: italic;
    border-left: 4px solid $cc-blue;
    padding: 10px;
    background-color: #F8F8F8;
    border-radius: 0 4px 4px 0;
  }
}

/*Radio items description*/
.radio-item-wrap {
  padding: 1rem 0;
  margin: 10px 0;

  p {
    margin: -20px 0 5px 1.7rem;
    font-size: 1rem;
    border-left: 4px solid $cc-blue;
    padding: 10px;
    background-color: #F8F8F8;
    border-radius: 0 4px 4px 0;
  }

  .important-item .description{
    color: $cc-blue;
    font-weight: 500;
  }
}


.attachment-link {
  margin-left: 102px;
  padding-bottom: 0px;
  //border-bottom: 1px solid transparent;
  cursor: pointer;
  //transition: all 0.3s ease;

  &:hover {
    //border-bottom: 1px solid rgba($cc-blue, 0.8);
  }
}

.mc-button-link {
  &:hover {
    opacity: 0.8;
  }
}

.gen-info {

  p {
    background-color: $cc-light;
    padding: 10px;
    border-radius: 4px;
    color: rgba($txt-black, 0.7);

    i {
      color: rgba($txt-black, 0.3);
      margin-right: 2px;
      font-size: 0.9rem;
    }

  }
}

.mcc-fi input:focus {
  border-color: $cc-blue !important;
}

.icon-blue {
  color: $cc-blue;
  margin-right: 5px;
}

.icon-gray {
  color: gray;
  margin-right: 5px;
  opacity: 40%;
}

.icon-red {
  color: red;
  margin-right: 5px;
}

.info-icon-positioning {
  position: absolute;
  top: 32px;
  right: 10px;
}

.info-icon-positioning2 {
  position: absolute;
  top: 0px;
  right: 10px;
}

.info-icon-positioning3 {
  position: absolute;
  top: -40px;
  right: 10px;
}

.info-icon-positioning4 {
  position: absolute;
  top: 40px;
  right: 10px;
}

.info-icon-positioning5 {
  position: absolute;
  top: 72px;
  right: 10px;
}

.index100 {
  z-index: 100;
}

.blue-info-msg {
  color: $cc-blue;
  font-size: 14px;

  i {
    font-size: 1rem;
  }
}

/***** STEPPER ****/
.stepper-progress-wrap {
  position: relative;
  padding: 1rem 0.5rem;
  display: block;
  width: 550px;
  margin: 0 auto 10px auto;
}

.stepper-progress {
  position: absolute;
  top: -45px;
  padding: 1rem;

  .step-count {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .step-done {
    border-top: 10px solid $cc-blue;
    padding-top: 5px;
    width: 150px;
    float: left;
    margin: 0 10px;
    text-align: center;
    font-size: 0.85rem;
  }

  .step-not-done {
    border-top: 10px solid #F2F2F2;
    padding-top: 5px;
    width: 150px;
    float: left;
    margin: 0 10px;
    text-align: center;
    font-size: 0.85rem;
  }

}

.multi-row-error .error-msg {
  max-width: 300px;
}

/*SERVICES TABLE*/
.services-price-table {

  .mc-table-wrapper .mc-table td{
    padding: 12px 6px;
  }
  table {

    th {

      &:nth-of-type(1n) {
        max-width: 2.5%;
      }
      &:nth-of-type(2n) {
        max-width: 2.5%;
      }
      &:nth-of-type(3n) {
        max-width: 20%;
      }
      &:nth-of-type(4n) {
        width: 30%;
      }
      &:nth-of-type(5n) {
        width: 12.5%;
      }
      &:nth-of-type(6n) {
        width: 20%;
      }
      &:nth-of-type(7n) {
        width: 12.5%;
      }
    }
  }
}

/* longer input */

.longer-input-300 {
  .single-input {
    width: 300px;
  }
}

/* Tooltip fix */
.tooltip-wide {
  position: relative;
  min-width: 240px;
}

/*pause transactions*/
.pause-icon-holster {
  display: flex;

  i {
    align-self: center;
    padding: 0 0.6rem;
    font-size: 1.3rem;
  }
}

.mc-transaction-status-label button {
  margin: 12px;
}

.important-data {
  margin-top: 10px;
  border-radius: 4px;
  background-color: #FFF1E5;
}

/* role templates */

.role-template-wrap {
  label {
    font-size: 1.2rem !important;
    font-weight: 500;
  }

}

.template-desc {

  p {
    font-size: 1rem !important;
    font-weight: 500;
    font-style: normal;
  }

  .description-txt {
    font-weight: 400;
  }

  .from-entity {
    color: $cc-blue;
    font-weight: 500;
  }

}

.desc-uppercase .title {
  text-transform: uppercase;
}

.mc-filter-button {
  border-radius: 4px;
}

/*FANCY LABEL*/
/*REDESIGNED SEARCH*/
.fancy-label {
  position: relative;
  background-color: #FAF8F8 !important;
  border: 1px solid #E2DFDF;
  border-radius: 4px;
  padding-top: 20px;

  label {
    position: absolute;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    color: $cc-blue;
    padding: 2px 2px 10px 5px;
  }


  .wide-input {
    & .mcc-fi-select select {
      width: 150px;
    }
  }

  .mcc-fi-select select {
    padding: 12px 8px 4px 8px !important;
    min-width: 120px;
  }

  form {
    margin-top: 26px;
  }

  input {
    min-height: 52px;
  }

  select {
    min-height: 52px;
  }

  .multi-select-field {
    margin-top: 26px;

    & .mcc-fi-date-picker label:before {
      top: 25px !important;
    }
    & .mcc-fi-date-picker label {
      top: -7px !important;
      left: -2px !important;
    }
    & i {
      top: 23px !important;
    }

    & .mc-multiple-items-clear {
      left: 180px;
    }
  }
}

/* Width control for multiselect filter */

.extra-wide-input {
  & .width-control {
    width: 300px;
  }

  & .mc-multiple-items-clear {
    left: 271px !important;
  }
}

.table-title{
  &:not(:first-child){
    padding-top: 30px;
    border-top: 1px solid #DBDBDB ;
    width: calc(100% - 1.4rem);
  }

  h2{
    font-size: 1.6rem;
    font-weight: 500;
  }
}

#mcc-fi-txbSearchTerm {
  padding-right: 35px;
  text-indent: 6px;
  background-image: url("/assets/images/search-icon.svg");
  background-position: 96% 50%;
  background-repeat: no-repeat;
}


/* Pricelists */
.service-item-name {
  color: #06608E;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.pricing-main-radios {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1rem 0.2rem 1rem;

  .mat-mdc-radio-group {
    width: 100%;
    ul {
      margin: 0;
      padding: 0;
      overflow: hidden;
      width: 100%;

      li {
        display: block;
        width: 33.3%;
      }
    }
  }
}

.editable-item {
  position: relative;

  .description {
    padding-right: 160px;
  }
}
.edit-pen{
  position: absolute;
  top: 0;
  right: 120px;
i{
  cursor: pointer;
  font-size: 0.8rem;
}
}

.mx-btn{
  max-height: 42px;
}

/*BILLING*/
.total-row{
  background-color: #D9F2FF;
  border-top: 1px solid $cc-blue;
  border-bottom: 1px solid $cc-blue;
  padding: 15px 10px;
  margin-top:-48px;
  display: flex;
  justify-content: flex-end;
  padding-left: 65%;



  .main-label{
    display: block;
    width: 86%;

    h5{
      text-align: right;
      padding-right: 60px;

    }
  }

  }

  .sum-total{
    display: flex;
    min-width: 14%;
font-weight: 500;
  }


.table-template{
  span{
    padding: 16px 10px;
  }

  .table-header, .table-footer{
    background-color: #D9F2FF !important;
    font-weight: 500;
    font-size: 1.1rem;
  }

  .table-footer2{
    background-color: #D4FBD8 !important;
    border-top: 1px solid #29D63B;
    border-bottom: 1px solid #29D63B;
  }
  .row:nth-child(even){
background-color: #fff;
}
  .row:nth-child(odd){
    background-color: #f2f2f2;
  }

}


/*BATCH TABLE*/

.blue-batch-header{
  padding: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  border-radius: 4px 4px 0 0;
  background-color: #D9F2FF;
  margin: 1rem 0 0.5rem 0;
}

.batch-table{
  border-radius: 4px;
  border:1px solid #f0f0f0;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.08);
  margin: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  max-width: calc(100% - 1.4rem);

  .row{
    padding: 1rem 0.25rem;
    font-size: 1.2rem;

    h4{
      font-weight: 500;
      font-size: 1.2rem;
    }

    h3{
      color: $cc-blue;
      font-size: 1.4rem;
      font-weight: 500;
    }

    &:not(:first-child){
      border-top:1px solid #f4f4f4;
    }
/*
    &:last-child{
      border-bottom:1px solid #f4f4f4;
  }
*/
}
}

.icon-orange{
  font-size: 2.2rem;
  color: #ffae42;
  margin-top:20px;
}

.standout{
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 30px 20px;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
}
/** pseudo messages**/
.info-question{
  display: block;
  padding: 20px;
  i{
    font-size: 2.2rem;
    color: #ffae42;
    float: left;
    margin-right: 0.5rem;
  }

  span{
    float: left;
    font-weight: 500;
  }
}



.info-warning{
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15);
  padding: 1rem;
border-radius:4px;
  display: flex;
  align-items: center;
  width: 100%;
  i{
    font-size: 2.2rem;
    float: left;
    margin-right: 0.5rem;
    margin-top: 0;
  }

  span{
    float: left;
    font-weight: 500;
  }

  .single-line-txt{
    margin-top: 5px;
  }
}

.icon-img{
  width: 36px;
  height: 36px;
  margin-right: 0.5rem;
}

  /* custom tooltip - longer*/
  .icon-tooltip2 {
    position: relative;
    display: inline-block;
  }
  .icon-tooltip2 .icon-tooltip-text2 {
    visibility: hidden;
    width: 260px;
    background-color: black;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 6px;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 70%;
    margin-left: -60px;
    font-size: 12px;
  }
  .icon-tooltip2 .icon-tooltip-text2:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 22%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  .icon-tooltip2:hover .icon-tooltip-text2 {
    visibility: visible;
  }
  .icon-tooltip2:hover .icon-tooltip-hidden2 {
    visibility: hidden;
  }

.dropdown-item{

    &:hover{
      background-color: $mc-header-color;
      color:#FFF;

      i{
        color: #FFF !important;
      }
    }
  i{
    width:20px;
    margin-right: 5px;
    color: $mc-header-color;
  }

  }


/*tool tip on top*/
.tooltip-wider  .tooltip .tooltip-inner{
  max-width: 500px;
}

  /*Tooltip fix when error message is too long*/
  .long-error-msg {
    position: absolute !important;
    left: 180px;
  }

  /*multiple select clear button fix*/

  .old-label {
    .mc-multiple-items-clear {
      display: none;
    }
  }


  /* drop-down button fix*/
.dropdown-menu-right {
  right: 20px !important;
  left: auto !important;
}




  /*Firefox Scrollbar fix*/
  .mc-scrollbar-vertical {
    scrollbar-width: none;
  }

  /*mac OS select input fix*/
  .mcc-fi-select select {
    height: 42px;
  }


  /*mac OS Safari sidebar fix*/
  .submenu-item {
    align-items: center;
    display: flex;
  }

.medium-date {
  & .mc-multiple-items-clear {
    top: -16px !important;
    right: 0 !important;
  }
}

// TYPOGRAPHY
.title-large {
  font-size: $font-large;
  color: $cc-subtitle-color;
  font-weight: bold;
  text-transform: uppercase;
}

.title-medium-dark {
  font-family: $font-secondary;
  font-size: $font-medium;
  color: $cc-subtitle-color;
  font-weight: bold;
  text-transform: uppercase;
}

.title-medium-blue {
  color: $cc-primary-color;
  font-size: $middle-font;
  font-weight: 700;
  text-transform: uppercase;
}

.title-medium-dark-normal {
  font-size: $font-middle;
  color: $cc-subtitle-color;
  font-width: bold;
}

.title-medium-light {
  font-size: $font-medium;
  color: $bg_color;
  font-weight: bold;
}

.title-large-light {
  font-size: $font-large;
  color: $bg_color;
  font-weight: bold;
}

.title-small-dark {
  font-size: $font-small;
  color: $cc-subtitle-color;
}

.body-text-medium {
  font-size: $font-small;
  color: $cc-subtitle-color;
}

.body-text-medium-bold {
  font-size: $font-small;
  color: $cc-subtitle-color;
  font-weight: bold;
}

.body-text-small {
  font-size: $font-smallest;
  color: $cc-subtitle-color;
}

.body-text-small-bold {
  font-size: $font-smallest;
  color: $cc-subtitle-color;
  font-weight: bold;
}

.body-text-small-faded {
  font-size: $font-smallest;
  color: $cc-subtitle-color;
  font-weight: 400;
  opacity: .7;
}

// Margin and Padding helper classes
.mt-small {
  margin-top: $margin-small;
}

.mt-medium {
  margin-top: $margin-medium;
}

.mt-large {
  margin-top: $margin-large;
}
.mt-extra-large {
  margin-top: $margin-extra-large;
}

.mr-smallest {
  margin-right: $margin-smallest;
}

.mr-small {
  margin-right: $margin-small;
}

.mr-large {
  margin-right: $margin-large;
}

.mr-medium {
  margin-right: $margin-medium;
}

.mb-small {
  margin-bottom: $margin-small;
}

.mb-smallest {
  margin-bottom: $margin-smallest;
}

.mb-medium {
  margin-bottom: $margin-medium;
}

.mb-large {
  margin-bottom: $margin-large !important;
}

.mb-extra-large {
  margin-bottom: $margin-extra-large;
}

.ml-small {
  margin-left: $margin-small;
}

.ml-medium {
  margin-left: $margin-medium;
}

.ml-smallest {
  margin-left: $margin-smallest;
}

.ml-large {
  margin-left: $margin-large;
}

.ml-extra-large {
  margin-left: $margin-extra-large;
}

// Flex helper classes
.flex {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
