/* Fonts */

$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Maven Pro', sans-serif;

/* Colors */
$bg_color: #fff;
$nav_color: #F7F8FC;

// general-colors
$mc-blue-text-color: #35b1ec;
$mc-silver-border-color: #555f68;
$mc-gray-border-color: #B6B8BA;
$mc-gray-text-1: #8d969d;
$mc-gray-text-2: #e1eaef;
$mc-gray-text-3: #ccdfe8;
$mc-header-color: #32424f;
$mc-notification-color: #f97f3b;
$mc-white-background: #f8f9fa;
$mc-aliceblue: #f0f8ff;
$mc-gray-line: #9ca0a3;
$mc-gray-background: #f7f7f7;
$mc-gray-background-2: #eeedf2;
$mc-table-border-color: #d3d3d3;
$mc-white-color: #fff;
$mc-black-color: #000;
$mc-border-color-gray: #dee2e6;
$mc-gray-color: rgb(132,142,149);

$mc-red-color-text: #ff0000;

// sidebar-color
$mc-sidebar-color: #202a33;

$cc-primary-color: #5eabbe;
$cc-secondary-color: #042F4D;
$cc-subtitle-color: #3B457C;
$cc-background-color: #f2f2f2;
$cc-action-button-color: #53589B;
$cc-table-hover-color: #ebeff1;
$cc-border-color: rgba(59, 69, 124, 0.38);


// login
$login-header-color: #e8ecef;
$text-color: #888;
$login-gray-color: rgba(0, 0, 0, 0.16);
$login-blue-color: #35B1F1;
$login-label-color: #474747;
$login-input-color: #E3ECF3;

$primary_color: #2a3a8e;
$blue-filter-color: #3a7897;
$primary_color_hover: #384ac3;
$primary_color_alt: rgba(72, 96, 255, 0.11);

$secondary_color: #8bc341;
$secondary_color_hover: #597d2c;
$secondary_color_alt: rgba(139, 195, 65, 0.11);

$third_color: #222;
$third_color_alt: rgba(34, 34, 34, 0.1);

$error_background_color: #FEE2DF;
$error_color: #EC230D;
$error_color_alt: rgba(158, 12, 16, 0.11);

$success_background_color: #D7F4ED;
$success_color: #0BB683;

$warning_color: #e9a849;
$warning_color_alt: rgba(233, 168, 73, 0.11);

/* Shadows */
$main_shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.3);
$card_shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.05);
$general-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

/*Media query values*/
$extra-small-device: 375px;
$small-device: 768px;
$medium-device: 1024px;
$middle-device: 1200px;
$large-device: 1280px;

/*Buttons*/
$submit-button-color: rgba(42, 175, 244, 1);
$back-button-color: #BDBFCC;
$cancel-button-color: #BDBFCB;
$light-blue-color: rgba(42, 175, 244, 0.23);
$dialog-text-color: #333867;
$dialog-light-text-color: #33386799;

$table-selected-row: #DFF1FB;
//Profile-user
$personal-info-box: #F5F5F5;

$title-color: #64C0F5;

/* Custom dropdown*/
$dd-grey-color-1: #DBE2E8;
$dd-grey-color-2: #e8ecef;
$dd-grey-color-3: #55616D;

/*Job action bg colors*/
$job-inactive: #c8c8c8;
$job-start: #59d08e;
$job-pause: #fa7236;
$job-stop: #f24937;
$job-resume: #35b1f2;
//Info btn
$mc-info-btn-color: #35b1f2;

/*Status icon colors*/
$status-active: #59d08e;
$status-draft: #35b1f2;
$status-pending: #fa7236;
$status-inactive: #b5b9bb;
$status-running: #2fb48d;
$status-scheduled: #ff9f23;
$status-send-now: #21bfc4;
$status-stopped: #e88074;
$status-paused: #76c7f1;
$status-blocked: #707885;
$status-completed: #62C760;
$status-error: #ff1800;
$status-none: #dadada;

/* Dates */
$mc-datepicker-bg: #ecf0f3;
$mc-datepicker-week: #dde8ee;
$mc-datepicker-date: #35b0f3;
$mc-datepicker-day-selected: #a5d7f2;

/* Consumer */
$primary-consumer-color: #21b59c;

/* Filter */
$filter-bg: #fff;
$light-gray: rgba(50,66,79,0.6);
$calendar-range-bg: rgba(36,174,201,0.3);

// Transitions
$hover-transition: all 200ms cubic-bezier(0.25, 0.8, 0.25, 1);

// Font sizes
$smallest-font: 12px;
$small-font: 14px;
$middle-font: 16px;
$medium-font: 18px;
$large-font: 22px;
$largest-font: 26px;
$extra-large-font: 32px;

// Border
$border-radius-small: 4px;
$top-border-radius: $border-radius-small $border-radius-small 0px 0px;
$bottom-border-radius: 0px 0px $border-radius-small $border-radius-small;
$top-bottom-right-border-radius: $border-radius-small$border-radius-small;

// Margin
$margin-small: 5px;
$margin-medium: 10px;
$margin-large: 15px;
$margin-extra-large: 20px;

// Padding
$padding-small: 5px;
$padding-medium: 10px;
$padding-large: 15px;
$padding-extra-large: 20px;

// New Mat Colors
$primary-text-color: #202A33;
$primary-light-blue-color: #35b1ec;
$primary-light-blue-color-lightened: lighten($primary-light-blue-color, 37);
$white-color: #ffffff;

/* Font Sizes */
$font-large: 1.5rem; // 24px
$font-medium: 1.25rem; // 20px
$font-middle: 1.125rem; // 18px
$font-small: 1rem; // 16px
$font-smallest: 0.875rem; // 14px
$font-extra-small: 0.75rem; // 12px

/* Margins */
$margin-smallest: 0.313rem; // 5px
$margin-small: 0.625rem; // 10px
$margin-medium: 0.938rem; // 15px
$margin-large: 1.25rem; // 20px
$margin-largest: 1.563rem; // 25px
$margin-extra-large: 1.875rem; // 30px
