
.mc-menu-arrow {
  cursor: pointer;
  svg g path {
    fill: $cc-subtitle-color;
  }
  &_active {
    svg g path {
      fill: $filter-bg;
    }
  }
}

.menu-item-icon {
  position: relative;
  top: -2px;
  left: 15px;
  &_collapsed {
    top: 0;
  }
  &_active {
    svg g path {
      fill: $filter-bg;
    }
  }
}

.active-tab {
  background: linear-gradient(267.71deg, #60C1C7 0%, #4E358C 100%) !important;
  color: $filter-bg;
  .active-parent {
    color: $filter-bg !important;
  }
  span svg path {
    fill: $filter-bg;
  }
}

.mc-sidebar-submenu {
  background: $filter-bg;
}

.mc-sidebar-link {
  &.active {
    background: $cc-primary-color;
    color: $filter-bg;
  }
}

.submenu-item-indicator{
  margin-left: 3rem;
  border-left: 3px solid $cc-primary-color;
}
